import axios, { AxiosPromise } from 'axios';

import { TemplateEmail } from '../types/TemplateEmail';
import { CONTEXT_PATH } from './contextPath';

export const findAllTemplateEmail = (): AxiosPromise<TemplateEmail[]> =>
  axios.get(`${CONTEXT_PATH}/template-email`);

export const loadById = (id: number): AxiosPromise<TemplateEmail> =>
  axios.get(`${CONTEXT_PATH}/template-email/${id}`);

export const save = (
  templateEmail: TemplateEmail
): AxiosPromise<TemplateEmail> =>
  axios.put(
    `${CONTEXT_PATH}/template-email/${templateEmail.id}`,
    templateEmail
  );
