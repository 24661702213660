import {
  Alert,
  Button,
  Col,
  Container,
  FormikAutocomplete,
  FormikInputInteger,
  FormikSelect,
  Loading,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import download from 'downloadjs';
import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';

import { tipoRelatorioVariacaoValores, tiposMovimento } from '../../enums';
import { PessoaService, RelatorioVariacaoValoresService } from '../../service';
import { EnumValue, Pessoa } from '../../types';
import { RelatorioVariacaoValoresDTO } from '../../types/RelatorioVariacaoValoresDTO';

const RelatorioVariacaoValoresFormPage: React.FC = () => {
  const validationSchema = Yup.object().shape({
    exercicioInicial: Yup.number()
      .label('Exercício Base')
      .required(),
    exercicioFinal: Yup.number()
      .label('Exercício Comparação')
      .required()
      .test(
        'exercicioFinalMaiorQueInicial',
        'O Exercício Comparação não pode ser menor do que o Exercício Base',
        function(value: number) {
          const { exercicioInicial } = this.parent;
          return exercicioInicial <= value;
        }
      )
      .test('exerciciosIguais', 'Os exercícios devem ser diferentes', function(
        value: number
      ) {
        const { exercicioInicial } = this.parent;
        return exercicioInicial !== value;
      }),
    tipoRelatorio: Yup.string()
      .required()
      .label('Tipo Relatório')
  });

  const [loading, setLoading] = useState(false);

  function dataURLtoFile(dataurl: any, filename: any) {
    var bstr = atob(dataurl),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename);
  }

  const onSubmit = async (values: any) => {
    setLoading(true);
    await RelatorioVariacaoValoresService.carregarRelatorioVariacaoValores(
      values
    )
      .then((response: any) => {
        if (response.status === 204) {
          Alert.info({
            title: 'Nenhum registro encontrado para o relatório.'
          });
        } else {
          const data = response.data;
          const filename = `Relatorio Variação Valores.xls`;

          download(dataURLtoFile(data, filename), filename);
        }
      })
      .catch((error: any) => {
        Alert.error({ title: `Ocorreu um erro ao gerar o relatório.` }, error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <SectionTitle>Filtro</SectionTitle>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={{}}
        onSubmit={onSubmit}
        render={(form: FormikProps<RelatorioVariacaoValoresDTO>) => (
          <>
            <Row>
              <FormikAutocomplete
                data-test-id="autocomplete-pessoa"
                name="pessoa"
                placeholder="Pesquisar por Cnpj/Cpf ou Nome"
                label="Pessoa/Empresa (CPF/CNPJ)"
                onSearch={PessoaService.search}
                size={2}
                getOptionLabel={(value: Pessoa) =>
                  `${value?.cnpjCpf} - ${value.nome}`
                }
                getOptionValue={(value: Pessoa) => value.id}
              />
              <FormikInputInteger
                size={2}
                label="Exercício Base"
                name="exercicioInicial"
              />
              <FormikInputInteger
                size={2}
                label="Exercício Comparação"
                name="exercicioFinal"
              />
              <FormikInputInteger
                name="percentualVariacao"
                label="Percentual de diferença"
                size={2}
                hint="Percentuais acima do informado serão apresentados no relatório"
              />
              <FormikSelect<EnumValue>
                size={2}
                options={tiposMovimento}
                getOptionValue={(value: any) => value.codigo}
                getOptionLabel={(value: any) => value.descricao}
                label="Tipo Movimento"
                name="tipoMovimento"
              />
              <FormikSelect<EnumValue>
                size={2}
                options={tipoRelatorioVariacaoValores}
                getOptionValue={(value: any) => value.codigo}
                getOptionLabel={(value: any) => value.descricao}
                label="Tipo Relatório"
                name="tipoRelatorio"
              />
            </Row>
            <Row>
              <Col md={12}>
                <div className="form-group">
                  <Button onClick={form.submitForm} type="submit">
                    Imprimir
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      />
    </Container>
  );
};

export default RelatorioVariacaoValoresFormPage;
