import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from './contextPath';
import { ReadonlyService } from './ReadonlyService';
import { Entity } from './utils';

export const CrudService = <T extends Entity>(
  basePath: string,
  defaultSort?: object,
  filterAutocomplete?: (search: string) => string
) => {
  const parsedPath = basePath.startsWith('/')
    ? basePath.substring(1)
    : basePath;
  const path = `${CONTEXT_PATH}/${parsedPath}`;

  const readonlyService = ReadonlyService<T>(
    basePath,
    defaultSort,
    filterAutocomplete
  );

  const save = (form: T): AxiosPromise<T> =>
    form.id === undefined || form.id === 0
      ? axios.post(`${path}`, form)
      : axios.put(`${path}/${form.id}`, form);

  const deleteById = (id?: number): AxiosPromise<T> =>
    axios.delete(`${path}/${id}`);

  return {
    ...readonlyService,
    save,
    deleteById
  };
};
