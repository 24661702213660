import { FormattedDate, Panel, Table } from '@elotech/components';
import React from 'react';

const ArquivosList = ({ arquivosImportados }) => {
  return (
    <Panel isTable style={{ marginTop: '10px' }}>
      <Table values={arquivosImportados || []} keyExtractor={item => item.id}>
        <Table.Column
          header="Nome do arquivo"
          value={item => item.nomeArquivo}
        />

        <Table.Column
          header="Data Importação"
          value={item => (
            <FormattedDate value={item.dataImportacao || ''} timeZone="UTC" />
          )}
        />
      </Table>
    </Panel>
  );
};

ArquivosList.propTypes = {};
export default ArquivosList;
