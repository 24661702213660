import { ActionButton, SearchPagination } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const AnexoPublicoList = ({
  cadastroDocumento,
  edit,
  exclude,
  searchWithPage
}) => {
  return (
    <>
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>Código</th>
              <th>Título</th>
              <th style={{ textAlign: 'center' }}>Ano</th>
              <th style={{ textAlign: 'center' }}>Número</th>
              <th>Descrição</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {cadastroDocumento &&
              cadastroDocumento.content &&
              cadastroDocumento.content.map(cadastroDocumento => {
                return (
                  <tr key={cadastroDocumento.id}>
                    <td style={{ textAlign: 'center' }}>
                      {cadastroDocumento.id}
                    </td>
                    <td>{cadastroDocumento.titulo}</td>
                    <td style={{ textAlign: 'center' }}>
                      {cadastroDocumento.ano}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {cadastroDocumento.numero}
                    </td>
                    <td>{cadastroDocumento.descricao}</td>
                    <td>
                      <div className="btn-actions">
                        <div className="btn-actions-inner">
                          <ActionButton
                            data-test-id={`buttonEdit${cadastroDocumento.id}`}
                            key="edit-button"
                            icon="pencil-alt"
                            label="Editar"
                            onClick={() => edit(cadastroDocumento.id)}
                          />
                          <ActionButton
                            data-test-id={`buttonExclude${cadastroDocumento.id}`}
                            key="exclude-button"
                            icon="trash-alt"
                            label="Excluir"
                            onClick={() => exclude(cadastroDocumento.id)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            {(!cadastroDocumento || cadastroDocumento.totalElements === 0) && (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan="7">
                  Sem registros
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {cadastroDocumento && (
        <SearchPagination
          page={cadastroDocumento}
          searchWithPage={searchWithPage}
        />
      )}
    </>
  );
};

AnexoPublicoList.propTypes = {
  cadastroDocumento: PropTypes.object.isRequired,
  edit: PropTypes.func,
  exclude: PropTypes.func,
  searchWithPage: PropTypes.func
};

export default AnexoPublicoList;
