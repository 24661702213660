import DenunciaFormPage from '../pages/denuncias/DenunciaFormPage';
import DenunciaListPage from '../pages/denuncias/DenunciaListPage';
import { Roles } from '../utils/Roles';

export const routesDenuncias = [
  {
    path: '/denuncias',
    component: DenunciaListPage,
    icon: 'volume-up',
    title: 'Denúncias',
    role: Roles.denuncias_read.name
  },
  {
    path: '/denuncias/analisar/:id',
    component: DenunciaFormPage,
    icon: 'pencil-alt',
    title: 'Editar',
    parent: '/denuncias'
  }
];

export default routesDenuncias;
