import * as Types from './types';

const handleLoadKeycloak = state => ({
  ...state,
  keycloakLoaded: true
});

const handlers = {
  [Types.KEYCLOAK_LOADED]: handleLoadKeycloak
};

const initialState = {
  keycloakLoaded: false
};

const ConfigReducer = (state = initialState, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};

export default ConfigReducer;
