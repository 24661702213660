import { AutoComplete, Col, ErrorText, Row } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const errorMessages = {
  cadastroGeral: 'Campo obrigatório!'
};

const EmpresaNewForm = ({
  empresa,
  errorEmpresa,
  loadCadastrosMobiliarios,
  onSaveEmpresa,
  onCancelEmpresa,
  onSelectEmpresa,
  optionLabel
}) => {
  return (
    <div className="panel-body">
      <Fragment>
        <Row>
          <Col md={7}>
            <label htmlFor="adicionarEmpresa">Empresa</label>
            <AutoComplete
              data-test-id="autocomplete-empresa"
              name="cadastroGeral"
              className={errorEmpresa.cadastroGeral ? 'error' : ''}
              placeholder="Pesquisar por cnpj ou nome da empresa"
              value={empresa.cadastroGeral}
              onSearch={loadCadastrosMobiliarios}
              onSelect={onSelectEmpresa}
              getOptionLabel={optionLabel}
            />
            <i aria-hidden="true" className="fa fa-search input-icon" />
            {errorEmpresa.cadastroGeral && (
              <ErrorText>{errorMessages.cadastroGeral}</ErrorText>
            )}
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <button
              data-test-id="btnSalvarEmpresa"
              className="btn positive inline mt-xs"
              type="button"
              onClick={onSaveEmpresa}
            >
              Salvar
            </button>
            <button
              data-test-id="btnCancelarEmpresa"
              className="btn negative inline mt-xs"
              type="button"
              onClick={onCancelEmpresa}
            >
              Cancelar
            </button>
          </Col>
        </Row>
      </Fragment>
    </div>
  );
};

EmpresaNewForm.propTypes = {
  empresa: PropTypes.object.isRequired,
  errorEmpresa: PropTypes.object.isRequired,
  loadCadastrosMobiliarios: PropTypes.func.isRequired,
  onSaveEmpresa: PropTypes.func.isRequired,
  onCancelEmpresa: PropTypes.func.isRequired,
  onSelectEmpresa: PropTypes.func.isRequired,
  filter: PropTypes.func.isRequired,
  optionLabel: PropTypes.func.isRequired
};

export default EmpresaNewForm;
