import {
  Container,
  FAB,
  Hint,
  Loading,
  NotificationActions
} from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert2';

import { AcessoWebService, withService } from '../../../service';
import ViewFormFixed from './ViewFormFixed';
import ViewList from './ViewList';

export class ViewListPage extends Component {
  static propTypes = {
    acessoWebService: PropTypes.object.isRequired
  };

  state = {
    acessoWeb: {
      nome: '',
      cpf: '',
      dataCadastro: '',
      telefone: '',
      celular: '',
      email: '',
      acessoEmpresas: []
    },
    loading: false,
    idAcessoWeb: null
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.findAcessoById(id);
  }

  loadAcessoWebSuccess = response => {
    this.setState({
      acessoWeb: response.data,
      loading: false
    });
  };

  loadAcessoWebError = error => {
    this.setState({ loading: false });
    this.props.showNotification({
      level: 'error',
      message: 'Não foi possível carregar o acesso web.'
    });
  };

  removerAcessoWeEmpresaSuccess = response => {
    this.findAcessoById(this.state.idAcessoWeb);
    swal('Removido', 'Acesso Removido', 'success');
  };

  removerAcessoWeEmpresaError = error => {
    this.setState({ loading: false });
    this.props.showNotification({
      level: 'error',
      message: 'Não foi possível remover o acesso web empresa.'
    });
  };

  onRemove = id => {
    swal({
      title: 'Remover Acesso?',
      text: 'Você deseja remover acesso de usuário?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim'
    }).then(result => {
      if (result.value) {
        this.props.acessoWebService
          .removerAcessoWeEmpresa(id)
          .then(this.removerAcessoWeEmpresaSuccess)
          .catch(this.removerAcessoWeEmpresaError);
      }
    });
  };

  findAcessoById = id => {
    if (id) {
      this.setState({
        loading: true,
        idAcessoWeb: id
      });
      this.props.acessoWebService
        .loadAcessoWebWithAcessoEmpresas(id)
        .then(this.loadAcessoWebSuccess)
        .catch(this.loadAcessoWebError);
    }
  };

  onBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { loading, acessoWeb } = this.state;
    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-acessos-cadastrados"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <div className="panel table-responsive">
          <div className="panel-title">Acesso de Usuário</div>
          <div className="panel-body">
            <Loading loading={loading} />
            <ViewFormFixed acessoWeb={acessoWeb} />
            <ViewList
              acessoEmpressas={acessoWeb.acessoEmpresas}
              onRemove={this.onRemove}
            />
          </div>
          <div className="btn-save">
            <FAB
              data-test-id="buttonVoltar"
              icon="arrow-left"
              iconColor="white"
              title="Voltar"
              onClick={this.onBack}
            />
          </div>
        </div>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const ComponentWithService = withService({
  acessoWebService: AcessoWebService
})(ViewListPage);

export default connect(null, mapDispatchToProps)(ComponentWithService);
