import * as Types from './types';

export const getUserDataSuccess = userData => ({
  type: Types.GET_USER_DATA,
  payload: userData
});

export const userExistsVerification = payload => ({
  type: Types.USER_EXISTS_VALIDATION,
  payload
});

export const loadUserProfile = payload => ({
  type: Types.LOAD_USER_PROFILE,
  payload
});
