import AnexoPublicoListPage from '../pages/anexos-publicos/anexo-publico/AnexoPublicoListPage';
import AnexoPublicoNewEditFormPage from '../pages/anexos-publicos/anexo-publico/AnexoPublicoNewEditFormPage';
import GrupoAnexoPublicoFormPage from '../pages/anexos-publicos/grupo-anexo-publico/GrupoAnexoPublicoFormPage';
import GrupoAnexoPublicoListPage from '../pages/anexos-publicos/grupo-anexo-publico/GrupoAnexoPublicoListPage';
import { Roles } from '../utils/Roles';

export const routesAnexosPublicos = [
  {
    path: '/anexos-publicos/grupo-anexo-publico',
    component: GrupoAnexoPublicoListPage,
    icon: 'folder-open',
    title: 'Grupo Anexo Público',
    role: Roles.grupo_anexo_publico_read.name
  },
  {
    path: '/anexos-publicos/grupo-anexo-publico/novo',
    component: GrupoAnexoPublicoFormPage,
    icon: 'file',
    title: 'Novo',
    parent: '/anexos-publicos/grupo-anexo-publico',
    role: Roles.grupo_anexo_publico_write.name
  },
  {
    path: '/anexos-publicos/grupo-anexo-publico/editar/:id',
    component: GrupoAnexoPublicoFormPage,
    icon: 'pencil-alt',
    title: 'Editar',
    parent: '/anexos-publicos/grupo-anexo-publico',
    role: Roles.grupo_anexo_publico_write.name
  },
  {
    path: '/anexos-publicos/anexo-publico',
    component: AnexoPublicoListPage,
    icon: 'file-signature',
    title: 'Anexo Público',
    role: Roles.anexo_publico_read.name
  },
  {
    path: '/anexos-publicos/anexo-publico/novo',
    component: AnexoPublicoNewEditFormPage,
    icon: 'file-signature',
    title: 'Novo',
    parent: '/anexos-publicos/anexo-publico',
    role: Roles.anexo_publico_write.name
  },
  {
    path: '/anexos-publicos/anexo-publico/editar/:id',
    component: AnexoPublicoNewEditFormPage,
    icon: 'pencil-alt',
    title: 'Editar',
    parent: '/anexos-publicos/anexo-publico',
    role: Roles.anexo_publico_write.name
  }
];

export default routesAnexosPublicos;
