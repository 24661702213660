import { Field, FormattedDate, ListPage } from '@elotech/components';
import { History } from 'history';
import { Alert } from 'iss-common/utils';
import React from 'react';

import ConcursoService from '../../../service/ConcursoService';
import { Concurso } from '../../../types/Concurso';

type Props = {
  history: Pick<History, 'push'>;
};

const field = [
  {
    type: 'NUMBER',
    name: 'id',
    label: 'Número'
  },
  {
    type: 'STRING',
    name: 'nomeConcurso',
    label: 'Nome'
  },
  {
    type: 'DATE',
    name: 'dataInicio',
    label: 'Data Início'
  },
  {
    type: 'DATE',
    name: 'dataFim',
    label: 'Data Fim'
  },
  {
    type: 'DATE',
    name: 'dataSorteio',
    label: 'Data Sorteio'
  }
] as Field[];

const ConcursoListPage: React.FC<Props> = ({ history }) => {
  const onDelete = (entity: Concurso) =>
    ConcursoService.remove(entity).catch(erro => {
      Alert.error({ title: 'Erro ao deletar um concurso' }, erro);
    });
  return (
    <ListPage<Concurso>
      title="Pesquisar Concurso"
      icon="search"
      baseUrl="/concurso"
      searchFields={field}
      onSearch={ConcursoService.filtrar}
      onDelete={onDelete}
      history={history}
      getId={(item: Concurso) => item.id!}
      columns={[
        ['Número Concurso', (item: Concurso) => item.id],
        ['Nome Concurso', (item: Concurso) => item.nomeConcurso],
        [
          'Início Concurso',
          (item: Concurso) => <FormattedDate value={item.dataInicio} />
        ],
        [
          'Fim Concurso',
          (item: Concurso) => <FormattedDate value={item.dataFim} />
        ],
        [
          'Data Sorteio',
          (item: Concurso) => <FormattedDate value={item.dataSorteio} />
        ],
        ['Situação', (item: Concurso) => item.statusDescricao]
      ]}
      hideButtons={['view']}
    />
  );
};

export default ConcursoListPage;
