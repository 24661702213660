import {
  Alert,
  Container,
  Loading,
  Panel,
  SearchFilter,
  SearchPagination,
  usePagedQuery
} from '@elotech/components';
import { Field } from '@elotech/components/src/type';
import { JobStatus } from 'iss-common/enums';
import React from 'react';

import { JobType } from '../../enums';
import { BatchService } from '../../service';
import { BatchInstanceDTO } from '../../types';
import TarefaBatchListPage from './TarefaBatchListPage';

const searchFieldsLote: Field[] = [
  {
    label: 'Situação',
    name: 'status',
    type: 'ENUM',
    options: Object.entries(JobStatus)
      .filter(
        ([codigo]) =>
          codigo === 'COMPLETED' ||
          codigo === 'STARTED' ||
          codigo === 'FAILED' ||
          codigo === 'STOPPED'
      )
      .map(([codigo, descricao]) => ({
        name: codigo,
        descricao
      }))
  },
  {
    label: 'Tarefa',
    name: 'tarefa',
    type: 'ENUM',
    options: Object.entries(JobType).map(([codigo, descricao]) => ({
      name: codigo,
      descricao
    }))
  },
  {
    label: 'Processo',
    name: 'instanceId',
    type: 'NUMBER'
  }
];

const GerenciadorTarefasListPage: React.FC = () => {
  const {
    loading,
    values,
    pagination,
    doSearch,
    doPagedSearch
  } = usePagedQuery<BatchInstanceDTO>({
    search: BatchService.findAll,
    onError: error => {
      Alert.error({ title: `Erro ao pesquisar os lotes` }, error);
    }
  });

  return (
    <Container
      breadcrumb
      titleRightComponent={
        <button
          onClick={() =>
            doPagedSearch({
              size: pagination!.size,
              page: pagination!.number
            })
          }
          title="Atualizar"
        >
          <i className="fa fa-sync fa-lg" />
        </button>
      }
    >
      <Loading loading={loading} />
      <Panel isTable>
        <SearchFilter
          fieldSelectSize={2}
          valueSelectSize={6}
          fields={searchFieldsLote}
          search={doSearch}
        />

        <TarefaBatchListPage tarefas={values} loading={loading} />

        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
    </Container>
  );
};

export default GerenciadorTarefasListPage;
