import axios, { AxiosPromise } from 'axios';

import { Certificado } from '../types/Certificado';
import { CONTEXT_PATH } from './contextPath';

export const recuperarInformacaoCertificado = (): AxiosPromise<Certificado> =>
  axios.get(`${CONTEXT_PATH}/certificado-validador`);

export const realizarUpdateCertificado = (
  certificado: any
): AxiosPromise<Certificado> =>
  axios.post(`${CONTEXT_PATH}/certificado-validador/atualizar`, certificado);
