import { Col, Row } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const ViewForm = props => {
  const { acessoWeb } = props;

  return (
    <div className="display-data border small mb-xs">
      <Row>
        <Col md={7}>
          <b className="dd-title">Nome de Usuário</b>
          <div className="dd-content" id="userName">
            {acessoWeb.nome}
          </div>
        </Col>
        <Col md={4}>
          <b className="dd-title">CPF</b>
          <div className="dd-content" id="cpf">
            {acessoWeb.cpf}
          </div>
        </Col>
        <Col md={5}>
          <b className="dd-title">Data/Hora Cadastro</b>
          <div className="dd-content" id="data">
            {acessoWeb.dataCadastro}
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <b className="dd-title">Telefone</b>
          <div className="dd-content" id="tel">
            {acessoWeb.telefone}
          </div>
        </Col>
        <Col md={6}>
          <b className="dd-title">Celular</b>
          <div className="dd-content" id="cel">
            {acessoWeb.celular}
          </div>
        </Col>
        <Col md={7}>
          <b className="dd-title">Email</b>
          <div className="dd-content" id="email">
            {acessoWeb.email}
          </div>
        </Col>
      </Row>
    </div>
  );
};

ViewForm.propTypes = {
  acessoWeb: PropTypes.object.isRequired
};

export default ViewForm;
