import { DisplayData, SectionTitle, formatUtils } from '@elotech/components';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { Denuncia } from '../../../types/Denuncia';

type Props = {
  denuncia: Denuncia;
};

const DenunciadoSection: React.FC<Props> = ({ denuncia }) => {
  if (!denuncia.prestador || !denuncia.prestador.pessoa) {
    return null;
  }

  return (
    <>
      <SectionTitle>Contribuinte Denunciado</SectionTitle>

      <div className="display-data border small mb-xs">
        <Row>
          <Col>
            <DisplayData title="CNPJ">
              {formatUtils.formatCpfCnpj(denuncia.prestador.pessoa.cnpjCpf)}
            </DisplayData>
          </Col>
        </Row>
        <Row>
          <Col>
            <DisplayData title="Nome Fantasia">
              {denuncia.prestador.pessoa.nomeFantasia}
            </DisplayData>
          </Col>
        </Row>
        <Row>
          <Col>
            <DisplayData title="Razão Social">
              {denuncia.prestador.pessoa.nome}
            </DisplayData>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DenunciadoSection;
