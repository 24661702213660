import { Entidade, RoleUsuarioDTO } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';

const permissoesByUsuario = (
  entidade: Entidade | undefined
): AxiosPromise<RoleUsuarioDTO> =>
  axios.get(`${CONTEXT_PATH}/usuario/permissoes`, {
    params: {
      entidade: entidade?.id
    }
  });

export default {
  permissoesByUsuario
};
