import {
  Alert,
  Container,
  FAB,
  Hint,
  Loading,
  NotificationActions
} from '@elotech/components';
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { PrestacaoContasEleicoesService, withService } from '../../../service';
import ArquivosPrestacaoContasList from './ArquivosPrestacaoContasList';
import GerarArquivosPrestacaoContas from './GerarArquivosPrestacaoContas';

export class PrestacaoContasListPage extends Component {
  static propTypes = {
    prestacaoContasEleicoesService: PropTypes.object.isRequired
  };

  state = {
    loading: false,
    arquivosPrestacaoContas: [],

    gerarArquivosInputValue: {
      numeroRemessaCorrecao: '',
      numeroLoteCorrecao: ''
    }
  };

  componentDidMount() {
    this.serviceSearch('');
  }

  serviceSearch = (searchParams, page) => {
    this.setState({ loading: true });

    this.props.prestacaoContasEleicoesService
      .consultarPrestacoesContas(searchParams, page)
      .then(this.onConsultarPrestacoesContasSuccess)
      .catch(this.onConsultarPrestacoesContasError);
  };

  onConsultarPrestacoesContasSuccess = response => {
    this.setState({
      arquivosPrestacaoContas: response.data,
      loading: false
    });
  };

  onConsultarPrestacoesContasError = error => {
    this.setState({ loading: false });
    Alert.error(
      {
        title:
          'Ocorreu uma falha ao carregar os arquivos de prestação de contas.'
      },
      error
    );
  };

  onChangeInputValue = event => {
    const { name, value } = event.target;
    this.setState(state => {
      const { gerarArquivosInputValue } = state;
      return {
        gerarArquivosInputValue: { ...gerarArquivosInputValue, [name]: value }
      };
    });
  };

  gerarArquivoPrestacao = () => {
    const { gerarArquivosInputValue } = this.state;
    this.setState({ loading: true });
    this.props.prestacaoContasEleicoesService
      .gerarPrestacao(gerarArquivosInputValue)
      .then(this.onGerarPrestacaoSuccess)
      .catch(this.onGerarPrestacaoError);
  };

  onGerarPrestacaoSuccess = response => {
    this.setState({
      loading: false
    });

    if (!response.data) {
      this.props.showNotification({
        level: 'info',
        message:
          'Nenhuma nota fiscal foi encontrada para a geração do arquivo de prestação de contas'
      });
    } else {
      this.serviceSearch('');
    }
  };

  onGerarPrestacaoError = error => {
    this.setState({ loading: false });
    Alert.error(
      {
        title: 'Ocorreu uma falha ao gerar o arquivo de prestação de contas.'
      },
      error
    );
  };

  onDownloadArquivo = arquivo => {
    if (arquivo.substituida === 'S') {
      this.props.showNotification({
        level: 'error',
        message: 'Não é permitido download de arquivo que foi substituído.'
      });
    } else {
      this.setState({ loading: true });
      this.props.prestacaoContasEleicoesService
        .downloadArquivo(arquivo.id)
        .then(this.onDownloadArquivoSuccess)
        .catch(this.onDownloadArquivoError);
    }
  };

  onDownloadArquivoSuccess = response => {
    this.setState({
      loading: false
    });

    const file = new Blob([response.data], { type: 'application/txt' });
    const fileURL = URL.createObjectURL(file);
    FileSaver.saveAs(fileURL, file.name);
  };

  onDownloadArquivoError = error => {
    this.setState({ loading: false });
    Alert.info(
      {
        title:
          'Ocorreu uma falha ao fazer o download arquivo de prestação de contas.'
      },
      error
    );
  };

  render() {
    const {
      arquivosPrestacaoContas,
      gerarArquivosInputValue,
      loading
    } = this.state;
    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-prestacao-contas"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        <div className="panel table table-responsive">
          <div className="panel-body">
            <ArquivosPrestacaoContasList
              arquivosPrestacaoContas={arquivosPrestacaoContas}
              onDownloadArquivo={this.onDownloadArquivo}
            />
          </div>
        </div>
        <GerarArquivosPrestacaoContas
          onChangeInputValue={this.onChangeInputValue}
          gerarArquivosInputValue={gerarArquivosInputValue}
        />
        <div className="btn-save">
          <FAB
            icon="sync"
            iconColor="white"
            title="Gerar Arquivos"
            onClick={this.gerarArquivoPrestacao}
          />
        </div>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const ComponentWithService = withService({
  prestacaoContasEleicoesService: PrestacaoContasEleicoesService
})(PrestacaoContasListPage);

export default connect(null, mapDispatchToProps)(ComponentWithService);
