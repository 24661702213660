import { log } from 'util';

import {
  Alert,
  BasicInput,
  Button,
  Col,
  Container,
  Loading,
  NotificationActions,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormattedDate } from 'react-intl';
import { Notification } from 'react-notification-system';
import { connect } from 'react-redux';

import { CertificadoService } from '../../service';
import { Certificado } from '../../types/Certificado';
import UploadCertificado from './UploadCertificado';

const getBase64 = (file: File) => {
  if (file.size === 0) return;

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      //@ts-ignore
      let encoded = reader!.result!.replace(/^data:(.*;base64,)?/, '');
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = error => reject(error);
  });
};

type Props = {
  showNotification(notification: Notification): void;
};

const CertificadoFormPage: React.FC<Props> = ({ showNotification }) => {
  const [loading, setLoading] = useState(true);
  const [certificado, setCertificado] = useState<Certificado | undefined>();
  const [arquivo, setArquivo] = useState<File>();
  const loadCamposCertificado = (certificado: Certificado) => {
    setCertificado(certificado);
    setLoading(false);
  };

  const onSalvarNovoCertificado = async (values: any, opts: any) => {
    setLoading(true);
    let certificadoUpload = values;

    if (!arquivo || !(arquivo.type === 'application/x-pkcs12')) {
      showNotification({
        level: 'error',
        message: 'Favor selecionar um arquivo certificado válido (pfx).'
      });
      setLoading(false);
      return;
    } else {
      certificadoUpload.arquivo =
        arquivo !== undefined ? await getBase64(arquivo) : '';
    }
    CertificadoService.realizarUpdateCertificado(certificadoUpload)
      .then(response => {
        showNotification({
          level: 'success',
          message: 'Certificado alterado com sucesso.'
        });
        opts.resetForm({});
        setArquivo(undefined);
        setCertificado(response.data);
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Certificado'
          },
          'Não foi possível alterar informações do certificado. ' +
            error.response.data.message
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onSelectArquivoPFX = (e: any) => {
    setArquivo(e.target.files[0]);
  };

  const validationSchema = () => {
    return Yup.object().shape({
      senhaArquivo: Yup.string()
        .label('Senha Arquivo')
        .required(),
      senhaPrivada: Yup.string()
        .label('Senha Chave')
        .required(),
      alias: Yup.string()
        .label('Alias')
        .required()
    });
  };

  useEffect(() => {
    CertificadoService.recuperarInformacaoCertificado()
      .then((response: AxiosResponse<Certificado>) => {
        loadCamposCertificado(response.data);
      })
      .catch(error => {
        log(error);
        Alert.error(
          {
            title: 'Certificado'
          },
          'Não foi possível recuperar as informações do certificado.'
        );
        setLoading(false);
      });
  }, []);

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <div className={'display-data border'}>
        <Row>
          <Col md={12}>
            <b className={'dd-title'}>Proprietário</b>
            <div className={`dd-content`}>{certificado?.proprietario}</div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <b className={'dd-title'}>Fornecedor</b>
            <div className={`dd-content`}>{certificado?.fornecedor}</div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <b className={'dd-title'}>Data Validade</b>
            <div className={`dd-content`}>
              <FormattedDate value={certificado?.dataValidade} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <b className={'dd-title'}>Versão</b>
            <div className={`dd-content`}>{certificado?.versao}</div>
          </Col>
        </Row>
      </div>
      <SectionTitle>Alterar Certificado</SectionTitle>
      <Col md={12}>
        <Row>
          <Formik
            enableReinitialize
            initialValues={{}}
            onSubmit={onSalvarNovoCertificado}
            validationSchema={validationSchema}
            render={(formProps: FormikProps<any>) => (
              <>
                <Row>
                  <BasicInput
                    name="senhaArquivo"
                    label="Senha Arquivo"
                    size={2}
                  />
                  <BasicInput
                    name="senhaPrivada"
                    label="Senha Chave"
                    size={2}
                  />
                  <BasicInput name="alias" label="Aliás" size={2} />
                  <Col md={3}>
                    <UploadCertificado
                      name="certificado"
                      label="Certificado"
                      uploadArquivo={arquivo}
                      onChangeArquivo={onSelectArquivoPFX}
                    />
                  </Col>
                  <Col md={3}>
                    <div className="form-group">
                      <label className="label" />
                      <Button
                        data-test-id="submit-form-verificar"
                        onClick={formProps.submitForm}
                      >
                        Alterar Certificado
                      </Button>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          />
        </Row>
      </Col>
    </Container>
  );
};

const connectedComponent = connect(null, {
  showNotification: NotificationActions.showNotification
})(CertificadoFormPage);

export { connectedComponent as default, CertificadoFormPage };
