import { Bloqueio } from '../../../types/Bloqueio';

export type Tipo = {
  tipo: string;
  label: string;
};

export const Tipos: Tipo[] = [
  { tipo: 'nfseWeb', label: 'Emissão de NFS-e' },
  { tipo: 'somenteTomador', label: 'Somente Tomador' },
  { tipo: 'substitutoTributario', label: 'DMS Tomados e Prestados' },
  { tipo: 'dmsBanco', label: 'Bancos' },
  {
    tipo: 'lancamentoServicoPrestado',
    label: 'Lançamento de serviço prestado'
  },
  { tipo: 'lancamentoServicoTomado', label: 'Lançamento de serviço tomado' }
];

export const getLabel = (tipo?: string) =>
  ((tipo?: Tipo) => tipo && tipo.label)(Tipos.find(t => t.tipo === tipo));

export const tiposBloqueio = Tipos.map((tipo: Tipo) => tipo.tipo);

export const emptyBloqueio: Bloqueio = {
  id: undefined,
  tipo: '',
  bloqueado: false,
  observacao: '',
  data: '',
  fiscal: ''
};

/***
 * Retorna uma lista de bloqueios na mesma ordem de Tipos, mesclando
 * com os bloqueios informados no parâmetro "bloqueios"
 */
export const inflateBloqueios = (bloqueios: Bloqueio[] = []): Bloqueio[] => {
  const res = tiposBloqueio.map<Bloqueio>(
    (tipo: string) =>
      bloqueios.find((bloqueio: Bloqueio) => bloqueio.tipo === tipo) || {
        ...emptyBloqueio,
        tipo
      }
  );

  return res;
};
