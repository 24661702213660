import React from 'react';

const withService = services => Component => {
  const name = Component.displayName || Component.name || 'Component';

  return class extends React.Component {
    static displayName = `withService(${name})`;

    render() {
      return <Component {...this.props} {...services} />;
    }
  };
};

export default withService;
