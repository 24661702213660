import { FormattedDate, formatUtils } from '@elotech/components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';

const StyledQuickView = styled.div`
  &.quick-view {
    width: 520px;
  }
`;

class DetailQuickView extends Component {
  divRef = {};

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);
    document.addEventListener('keydown', this.handleEscapeButton);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
    document.removeEventListener('keydown', this.handleEscapeButton);
  }

  handleClick = event => {
    if (this.divRef && !this.divRef.contains(event.target)) {
      this.props.onClose();
    }
  };

  handleEscapeButton = event => {
    if (event.keyCode === 27) {
      this.props.onClose();
    }
  };

  render() {
    const { opened, onClose, data } = this.props;

    const quickClasses = classNames('quick-view', 'fixed', {
      open: opened
    });

    return (
      <StyledQuickView
        className={quickClasses}
        ref={ref => (this.divRef = ref)}
      >
        <i
          className="fas fa-times no-print"
          data-test-id="buttonClose"
          onClick={onClose}
        />
        <div className="quick-header">Detalhes</div>
        <Row>
          <Col xs={12} md={12}>
            <div className="form-group">
              <label className="label">Nome Solicitante</label>
              {data?.nomeSolicitante}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <div className="form-group">
              <label className="label">CPF/CNPJ</label>
              {data?.cnpjCpf && formatUtils.formatCpfCnpj(data.cnpjCpf)}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <div className="form-group">
              <label className="label">Status DMS</label>
              {data?.statusDms}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <div className="form-group">
              <label className="label">Data Solicitação</label>
              {data?.dataSolicitacao && (
                <FormattedDate value={data.dataSolicitacao} timeZone={'UTC'} />
              )}
            </div>
          </Col>
        </Row>
      </StyledQuickView>
    );
  }
}

DetailQuickView.propTypes = {
  opened: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.object
};

export default DetailQuickView;
