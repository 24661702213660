import ImportacaoCnpjListPage from '../pages/prestacao-de-contas-eleicao/importacao-candidatos/ImportacaoCandidatosListPage';
import PrestacaoContasListPage from '../pages/prestacao-de-contas-eleicao/prestacao-de-contas/PrestacaoContasListPage';
import { Roles } from '../utils/Roles';

export const routesPrestacaoContasEleicao = [
  {
    path: '/prestacao-de-contas-eleicao/importacao-dos-candidatos',
    component: ImportacaoCnpjListPage,
    icon: 'file-import',
    title: 'Importação dos Candidatos',
    role: Roles.importacao_candidatos_read.name
  },
  {
    path: '/prestacao-de-contas-eleicao/prestacao-contas',
    component: PrestacaoContasListPage,
    icon: 'dollar-sign',
    title: 'Gerar Prestação de Contas',
    role: Roles.prestacao_contas_read.name
  }
];

export default routesPrestacaoContasEleicao;
