import {
  Alert,
  Button,
  Checkbox,
  Col,
  Container,
  FormikAutocomplete,
  FormikAutocompleteMultiple,
  FormikInputCpfCnpj,
  FormikInputDate,
  FormikInputNumber,
  FormikSelect,
  Hint,
  Loading,
  Row,
  Yup,
  formatUtils
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';
import { Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';

import {
  CnaeService,
  ContratoSocialPessoaService,
  EntidadeService,
  RelatorioPGDASService
} from '../../service';
import { loadCnpj } from '../../service/CadastroGeralService';
import RegimeFiscalService from '../../service/RegimeFiscalService';
import { ContratoSocialPessoa, EntidadePrincipal } from '../../types';
import { CadastroGeral } from '../../types/CadastroGeral';
import { Cnae } from '../../types/Cnae';
import { RegimeFiscalType } from '../../types/RegimeFiscal';
import { RelatorioDmsDasVo } from '../../types/RelatorioDmsDasVo';

const initialValues: RelatorioDmsDasVo = {
  tipoRelatorio: '1',
  somenteDiferencaDIMP: false,
  somenteDiferencaPGDAS: false,
  tipoArquivo: 'PDF'
};

const URL_HELP = '';

const validationSchema = Yup.object().shape({
  dataInicial: Yup.date()
    .required()
    .label('Data inicial'),
  dataFinal: Yup.date()
    .required()
    .label('Data final')
    .test(
      'dataFinalMaiorQueInicial',
      'A data final não pode ser menor do que a data inicial',
      function(value: Date) {
        const { dataInicial } = this.parent;
        return dataInicial <= value;
      }
    ),
  tipoRelatorio: Yup.string()
    .required()
    .label('Tipo relatório'),
  tipoAgrupamento: Yup.string()
    .label('Tipo Agrupamento')
    .test(
      'tipoAgrupamentoObrigatorio',
      'O Tipo de Agrupamento é obrigatório',
      function(value: any) {
        const { tipoRelatorio } = this.parent;
        return (
          !tipoRelatorio ||
          tipoRelatorio === '1' ||
          tipoRelatorio === '3' ||
          (tipoRelatorio === '2' && value)
        );
      }
    ),
  diferencaMinimaPGDAS: Yup.number()
    .label('Diferença Mínima PGDAS')
    .test(
      'diferencaMinimaPGDASObrigatoria',
      'A Diferença Mínima PGDAS é obrigatória',
      function(value: number) {
        const { somenteDiferencaPGDAS } = this.parent;
        return (
          !somenteDiferencaPGDAS ||
          (somenteDiferencaPGDAS && value !== undefined)
        );
      }
    ),
  diferencaMinimaDIMP: Yup.number()
    .label('Diferença Mínima DIMP')
    .test(
      'diferencaMinimaDIMPObrigatoria',
      'A Diferença Mínima DIMP é obrigatória',
      function(value: number) {
        const { somenteDiferencaDIMP } = this.parent;
        return (
          !somenteDiferencaDIMP || (somenteDiferencaDIMP && value !== undefined)
        );
      }
    )
});

type Option = {
  label: string;
  value: string;
};

const optionsDefault: Option[] = [
  { label: 'Sim', value: 'S' },
  { label: 'Não', value: 'N' }
];

const optionsTipoRelatorio: Option[] = [
  { label: 'DMS X PGDAS', value: '1' },
  { label: 'DMS X DIMP', value: '2' },
  { label: 'DMS X PGDAS X DIMP', value: '3' }
];

const optionsTipoAgrupamento: Option[] = [
  { label: 'Por Contribuinte', value: '1' },
  { label: 'Por Instituição Financeira', value: '2' }
];

const RelatorioNfseDasPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [regimeFiscal, setRegimeFiscal] = useState<RegimeFiscalType[]>([]);
  const [socios, setSocios] = useState<ContratoSocialPessoa[]>([]);
  const [somenteDiferencaPGDAS, setSomenteDiferencaPGDAS] = useState(false);
  const [somenteDiferencaDIMP, setSomenteDiferencaDIMP] = useState(false);
  const [isTipoRelatorioDIMP, setIsTipoRelatorioDIMP] = useState(false);

  const [disabledCpfCnpj, setDisabledCpfCnpj] = useState(false);
  const [disabledSocio, setDisabledSocio] = useState(true);

  const [relatorioDmsDasVo] = useState<RelatorioDmsDasVo>(initialValues);

  const [entidadePrincipal, setEntidadePrincipal] = useState<
    EntidadePrincipal
  >();

  const getFileURL = (response: AxiosResponse<any>, type: string): string => {
    const file = new Blob([response.data], { type });
    return URL.createObjectURL(file);
  };

  const onFilter = async (values: RelatorioDmsDasVo) => {
    setLoading(true);
    await RelatorioPGDASService.carregarRelatorioDmsxPGDASGeral(values)
      .then((response: AxiosResponse<any>) => {
        const { tipoArquivo } = values;

        if (tipoArquivo === 'PDF') {
          window.open(getFileURL(response, 'application/pdf'));
          return;
        }

        const fileURL = getFileURL(response, 'application/vnd.ms-excel');
        FileSaver.saveAs(
          fileURL,
          'relatorio_diferencas_pgdas_x_dms_x_dimp_exportacao.xls'
        );
      })
      .catch(e => {
        Alert.error({ title: 'Erro ao gerar relatório.' }, e);
      })
      .finally(() => setLoading(false));
  };

  const loadRegimeFiscal = () => {
    return RegimeFiscalService.carregarRegimeFiscal()
      .then((result: AxiosResponse<RegimeFiscalType[]>) => {
        setRegimeFiscal(result.data);
      })
      .catch((error: any) => {
        Alert.error({ title: `Erro ao carregar regimes fiscais` }, error);
      });
  };

  const loadEntidadePrincipal = () => {
    setLoading(true);
    return EntidadeService.loadEntidadePrincipal()
      .then((result: AxiosResponse<EntidadePrincipal>) => {
        setEntidadePrincipal(result.data);
      })
      .catch((error: any) => {
        Alert.error({ title: `Erro ao carregar a entidade principal` }, error);
      })
      .finally(() => setLoading(false));
  };

  const loadSociosByEmpresaNoMunicipio = (idEmpresa: number) => {
    setLoading(true);
    return ContratoSocialPessoaService.carregarContratoSocialPessoa(idEmpresa)
      .then((result: AxiosResponse<ContratoSocialPessoa[]>) => {
        setSocios(result.data);
      })
      .catch((error: any) => {
        Alert.error({ title: `Erro ao carregar os sócios` }, error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([loadRegimeFiscal()]).then(() => setLoading(false));
  }, []);

  const onSelectedContribuinte = (
    form: FormikProps<RelatorioDmsDasVo>,
    contribuinte: CadastroGeral
  ) => {
    setDisabledCpfCnpj(false);
    form.setFieldValue('cpfCnpj', undefined);
    setDisabledSocio(true);
    setSocios([]);

    if (contribuinte) {
      setDisabledCpfCnpj(true);
      form.setFieldValue(
        'cpfCnpj',
        formatUtils.formatCpfCnpj(contribuinte?.pessoa?.cnpjCpf)
      );

      setDisabledSocio(false);
      loadEntidadePrincipal();

      if (entidadePrincipal?.cidade?.id === contribuinte?.pessoa?.cidade?.id) {
        loadSociosByEmpresaNoMunicipio(contribuinte.cadastroGeral!);
      }
    }
  };

  const onChange = (
    field: string,
    formProps: FormikProps<RelatorioDmsDasVo>,
    state: boolean,
    setState: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    formProps.setFieldValue(field, !state);
    setState(!state);
  };

  return (
    <Container
      breadcrumb
      titleRightComponent={
        <a href={URL_HELP} target="_blank" rel="noopener noreferrer">
          <Hint
            classes={`inline clean module-color center-right fa-querion-circle`}
          >
            Ajuda?
          </Hint>
        </a>
      }
    >
      <Loading loading={loading} />
      <Col md={12}>
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={relatorioDmsDasVo}
          onSubmit={onFilter}
          render={(formProps: FormikProps<RelatorioDmsDasVo>) => (
            <>
              <Row>
                <FormikInputDate
                  label="Data inicial"
                  name="dataInicial"
                  size={3}
                />
                <FormikInputDate name="dataFinal" label="Data final" size={3} />
              </Row>
              <Row>
                <FormikAutocomplete<CadastroGeral>
                  data-test-id="contribuinte"
                  name="contribuinte"
                  placeholder="Pesquisar por cnpj ou nome da empresa"
                  label="Contribuinte"
                  onSearch={loadCnpj}
                  onItemSelected={onSelectedContribuinte}
                  size={6}
                  getOptionLabel={value =>
                    `${formatUtils.formatCpfCnpj(value?.pessoa?.cnpjCpf)} - ${
                      value.cadastroGeral
                    } - ${value?.pessoa?.nome}`
                  }
                  getOptionValue={value => value.id}
                />
                <FormikInputCpfCnpj
                  data-test-id="cpfCnpj"
                  name="cpfCnpj"
                  label="CPF/CNPJ"
                  size={3}
                  disabled={disabledCpfCnpj}
                />
              </Row>
              <Row>
                <FormikSelect
                  fast={false}
                  name="cpfCnpjSocio"
                  label="Sócio"
                  size={11}
                  disabled={disabledSocio}
                  options={socios}
                  getOptionLabel={(option: ContratoSocialPessoa) =>
                    `${formatUtils.formatCpfCnpj(option?.pessoa?.cnpjCpf)} - ${
                      option?.pessoa?.nome
                    }`
                  }
                  getOptionValue={(option: ContratoSocialPessoa) =>
                    option?.pessoa?.cnpjCpf || ''
                  }
                />
              </Row>
              <Row>
                <FormikAutocompleteMultiple<Cnae>
                  name="cnaes"
                  label="Cnaes"
                  onSearch={CnaeService.autoCompleteCnaesComServico}
                  getOptionLabel={value =>
                    `${value.codigo} - ${value.descricao}`
                  }
                  getOptionValue={value => value.codigo}
                  size={11}
                />
              </Row>
              <Row>
                <FormikSelect
                  fast={false}
                  name="regimeFiscal"
                  label="Regime tributário"
                  size={4}
                  options={regimeFiscal}
                  getOptionLabel={(option: RegimeFiscalType) =>
                    option.descricao
                  }
                  getOptionValue={(option: RegimeFiscalType) => option.id}
                />
                <FormikSelect
                  name="dmsEntreguePrestador"
                  label="DMS Entregue Prestador"
                  size={2}
                  noValue={'A'}
                  options={optionsDefault}
                  getOptionLabel={(option: Option) => option.label}
                  getOptionValue={(option: Option) => option.value}
                />

                <FormikSelect
                  size={2}
                  options={optionsTipoRelatorio}
                  getOptionValue={(value: any) => value.value}
                  getOptionLabel={(value: any) => value.label}
                  onSelect={value => {
                    if (value?.value === '2') {
                      setIsTipoRelatorioDIMP(true);
                    } else {
                      setIsTipoRelatorioDIMP(false);
                      formProps.setFieldValue('tipoAgrupamento', undefined);
                    }
                  }}
                  label="Tipo Relatório"
                  name="tipoRelatorio"
                />
                {isTipoRelatorioDIMP && (
                  <FormikSelect
                    size={3}
                    options={optionsTipoAgrupamento}
                    getOptionValue={(value: any) => value.value}
                    getOptionLabel={(value: any) => value.label}
                    label="Tipo Agrupamento"
                    name="tipoAgrupamento"
                  />
                )}
              </Row>
              <Row>
                <Col md={3}>
                  <Checkbox
                    className="form-group"
                    name="somenteDiferencaPGDAS"
                    id="somenteDiferencaPGDAS"
                    checked={somenteDiferencaPGDAS}
                    onChange={() =>
                      onChange(
                        'somenteDiferencaPGDAS',
                        formProps,
                        somenteDiferencaPGDAS,
                        setSomenteDiferencaPGDAS
                      )
                    }
                    label="Somente com diferenças no PGDAS"
                    hintText="Compara se existe diferença entre o PGDAS e a DMS"
                  />
                </Col>
                {somenteDiferencaPGDAS && (
                  <FormikInputNumber
                    label="Valor mínimo diferença PGDAS"
                    name="diferencaMinimaPGDAS"
                    size={2}
                    prefix="R$"
                  />
                )}

                <Col md={3}>
                  <Checkbox
                    className="form-group"
                    name="somenteDiferencaDIMP"
                    id="somenteDiferencaDIMP"
                    checked={somenteDiferencaDIMP}
                    onChange={() =>
                      onChange(
                        'somenteDiferencaDIMP',
                        formProps,
                        somenteDiferencaDIMP,
                        setSomenteDiferencaDIMP
                      )
                    }
                    label="Somente com diferenças na DIMP"
                    hintText="Compara se existe diferença entre a DIMP e a DMS"
                  />
                </Col>
                {somenteDiferencaDIMP && (
                  <Col>
                    <FormikInputNumber
                      label="Valor mínimo diferença DIMP"
                      name="diferencaMinimaDIMP"
                      size={2}
                      prefix="R$"
                    />
                  </Col>
                )}
              </Row>

              <div className="form-group">
                <Button
                  className="inline"
                  iconPosition="left"
                  onClick={async () => {
                    await formProps.setFieldValue('tipoArquivo', 'PDF');
                    return await formProps.submitForm();
                  }}
                >
                  <i className="fa fa-search" />
                  EMITIR RELATÓRIO
                </Button>

                <Button
                  className="inline"
                  iconPosition="left"
                  onClick={async () => {
                    await formProps.setFieldValue('tipoArquivo', 'XLS');
                    return await formProps.submitForm();
                  }}
                >
                  <i className="fa fa-download" />
                  EXPORTAR (XLS) EM LISTA
                </Button>
              </div>
            </>
          )}
        />
      </Col>
    </Container>
  );
};

export default RelatorioNfseDasPage;
