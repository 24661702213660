import { Alert } from '@elotech/components';
import { useEffect } from 'react';

import { CertificadoService } from '../service';

const ValidadorCertificado: React.FC = () => {
  useEffect(() => {
    CertificadoService.recuperarInformacaoCertificado().then(Response => {
      if (Response.data.avisoVencimentoCertificado) {
        Alert.info({
          title: 'Vencimento Certificado',
          html:
            'Seu certificado digital vence em <span style="color:red">' +
            Response.data.quantidadeDiasParaVencimento +
            '</span> dias. Por favor, realize a aquisição de um novo certificado digital e informe à equipe de Suporte da Elotech.'
        });
      }
    });
  }, []);
  return null;
};

export { ValidadorCertificado as default };
