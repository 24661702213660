import {
  BasicInput,
  Button,
  Col,
  Container,
  FormikAutocomplete,
  FormikSelect,
  Loading,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import { Formik } from 'formik';
import { Alert } from 'iss-common/utils';
import moment from 'moment';
import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';

import { SituacaoParcelaService } from '../../service';
import { RelatorioDmsEntregueService } from '../../service';

const RelatorioDmsEntregueFormPage: React.FC = () => {
  const tipoDeclaracoes = [
    { codigo: 'N', descricao: 'Normal' },
    { codigo: 'R', descricao: 'Retificadora' },
    { codigo: 'S', descricao: 'Sem Movimento' },
    { codigo: 'M', descricao: 'Retificadora sem Movimento' }
  ];

  const tipoMovimentos = [
    { codigo: 'P', descricao: 'Prestador' },
    { codigo: 'T', descricao: 'Tomador' }
  ];

  const schema = Yup.object().shape({
    competenciaInicial: Yup.string()
      .required()
      .label('Competência inicial')
      .min(7)
      .test('dataValida', 'Competência inicial inválida!', value => {
        const data = moment(`01/${value}`, 'DD/MM/YYYY', true);
        return data.isValid();
      }),
    competenciaFinal: Yup.string()
      .label('Competência Final')
      .min(7)
      .notRequired()
      .test('dataFinalValida', 'Competência final inválida!', value => {
        if (value === undefined) {
          return true;
        }
        const data = moment(`01/${value}`, 'DD/MM/YYYY', true);
        return data.isValid();
      })
      .test(
        'dataFinalMenorQueInicial',
        'Competência final maior que competência inicial!',
        function(value) {
          if (value === undefined) {
            return true;
          }
          const dataInicial = moment(
            `01/${this.parent.competenciaInicial}`,
            'DD/MM/YYYY',
            true
          );
          const data = moment(`01/${value}`, 'DD/MM/YYYY', true);
          return data.isSame(dataInicial) || dataInicial.isBefore(data);
        }
      )
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: any) => {
    setLoading(true);
    await RelatorioDmsEntregueService.carregarRelatorio(values)
      .then((response: any) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);
      })
      .catch((error: any) => {
        Alert.error({ title: `Ocorreu um erro ao gerar o relatório` }, error);
      })
      .finally(() => setLoading(false));
  };

  const mask = (value: any) => {
    if (value.length < 15) {
      return [
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/
      ];
    } else {
      return [
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/
      ];
    }
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <SectionTitle>Filtro</SectionTitle>
      <Formik
        initialValues={{}}
        validationSchema={schema}
        onSubmit={onSubmit}
        render={form => (
          <>
            <Row>
              <BasicInput
                size={4}
                label="Competência Inicial"
                name="competenciaInicial"
                render={({ field }) => (
                  <MaskedInput
                    {...field}
                    type="text"
                    name="competenciaInicial"
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  />
                )}
              />
              <BasicInput
                size={4}
                label="Competência Final"
                name="competenciaFinal"
                render={({ field }) => (
                  <MaskedInput
                    {...field}
                    type="text"
                    name="competenciaFinal"
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  />
                )}
              />
              <FormikSelect
                size={4}
                options={tipoDeclaracoes}
                getOptionValue={(value: any) => value.codigo}
                getOptionLabel={(value: any) => value.descricao}
                label="Tipo Declaração"
                name="tipoDeclaracaoSigla"
              />
            </Row>
            <Row>
              <FormikAutocomplete<any>
                size={4}
                name="situacaoParcela"
                placeholder="Pesquisar situação parcela"
                label="Situação Parcela"
                onSearch={SituacaoParcelaService.autoComplete}
                getOptionLabel={(value: any) =>
                  `${value.id} - ${value.descricao}`
                }
                getOptionValue={value => value.id}
              />

              <BasicInput
                size={4}
                label="CPF/CNPJ"
                name="cnpj"
                render={({ field }) => (
                  <MaskedInput
                    {...field}
                    type="text"
                    guide={false}
                    mask={mask}
                  />
                )}
              />
              <FormikSelect
                size={4}
                options={tipoMovimentos}
                getOptionValue={(value: any) => value.codigo}
                getOptionLabel={(value: any) => value.descricao}
                label="Tipo Movimento"
                name="tipoMovimentoSigla"
              />
            </Row>
            <Row>
              <BasicInput
                size={4}
                name="inscricaoMunicipal"
                label="Inscrição Municipal"
              />
            </Row>
            <Row>
              <Col md={12}>
                <div className="form-group">
                  <Button onClick={form.submitForm} type="submit">
                    Imprimir
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      />
    </Container>
  );
};

export default RelatorioDmsEntregueFormPage;
