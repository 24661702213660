import {
  Alert,
  Container,
  DisplayDataGrid,
  DisplayDataItem,
  ErrorPage,
  FAB,
  FormikAutocomplete,
  FormikInputDate,
  FormikInputNumber,
  FormikSelect,
  Loading,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { tipoMulta } from '../../../enums/TipoMulta';
import { ControleDeclaracaoMensalNaoEntregueService } from '../../../service';
import GuiaRecolhimentoReceitaService from '../../../service/GuiaRecolhimentoReceitaService';
import GuiaRecolhimentoService from '../../../service/GuiaRecolhimentoService';
import IndiceCorrecaoService from '../../../service/IndiceCorrecaoService';
import {
  DeclaracaoMensalNaoEntregueCadastro,
  DeclaracaoMensalNaoEntregueControle,
  EnumValue
} from '../../../types';
import { ControleDmsNaoEntregueMultaDTO } from '../../../types/ControleDmsNaoEntregueMultaDTO';
import { IndiceCorrecao } from '../../../types/IndiceCorrecao';
import { ReceitaVo } from '../../../types/ReceitaVo';
import { getFiltrotext } from './FiltroUtil';

const validationSchema = Yup.object().shape({
  vencimento: Yup.date()
    .required()
    .label('Vencimento'),
  idIndiceCorrecao: Yup.string().test(
    'indiceCorrecaoVariavelnvm ',
    'Informe o índice de correção',
    function(value: string) {
      return !(this.parent.tipo === 'VARIAVEL' && !value);
    }
  ),
  tipo: Yup.string()
    .required()
    .label('Tipo'),
  valor: Yup.number()
    .required()
    .positive()
    .label('Valor'),
  guiaRecolhimento: Yup.object()
    .required()
    .label('Guia Recolhimento'),
  receita: Yup.object()
    .required()
    .label('Receita')
});

const ControleDeclaracaoMensalNaoEntregueMultaFormPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation<any>();
  const [indicesCorrecao, setIndicesCorrecao] = useState<IndiceCorrecao[]>([]);

  const [selecionados, setSelecionados] = useState<
    DeclaracaoMensalNaoEntregueCadastro[]
  >();
  const [controleDmsNaoEntregues, setControleDmsNaoEntregues] = useState<
    DeclaracaoMensalNaoEntregueControle
  >();
  const [quantidadeSelecionado, setQuantidadeSelecionado] = useState<number>();
  const [todosSelecionados, setTodosSelecionados] = useState<boolean>();

  useEffect(() => {
    setSelecionados(location.state?.selecionados);
    setControleDmsNaoEntregues(location.state?.controleDmsNaoEntregues);
    setQuantidadeSelecionado(location.state?.quantidadeSelecionado);
    setTodosSelecionados(location.state?.todosSelecionados);
  }, [location.state]);

  useEffect(() => {
    IndiceCorrecaoService.load('').then(({ data }) => {
      setIndicesCorrecao(data.content);
    });
  }, []);

  const onSubmit = (value: ControleDmsNaoEntregueMultaDTO) => {
    setLoading(true);
    value = {
      ...value,
      todasSelecionadas: todosSelecionados,
      selecionadas: selecionados,
      controle: controleDmsNaoEntregues
    };
    ControleDeclaracaoMensalNaoEntregueService.gerarMulta(value)
      .then(_ => {
        Alert.success({
          title: 'Iniciado com sucesso a geração da multa de DMS não entregue.',
          text:
            'Será enviada uma notificação assim que o processamento finalizar.'
        }).then(() => {
          history.replace(
            `/rotinas/controle-dms-nao-entregues/${controleDmsNaoEntregues?.id}/resumo`
          );
        });
      })
      .catch(error => {
        Alert.error(
          { title: 'Não foi possível gerar a multa de DMS não entregue.' },
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isNumeric = (value: string) => {
    return /^\d+$/.test(value);
  };

  const formataTexto = (texto: string) => {
    if (isNumeric(texto)) {
      return `(descricao=='*${texto}*' or guiaRecolhimento ==${parseInt(
        texto
      )})`;
    } else {
      return `descricao=='*${texto}*'`;
    }
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      {selecionados && controleDmsNaoEntregues ? (
        <>
          <SectionTitle marginTop="0px">Dados Gerais</SectionTitle>
          <DisplayDataGrid className="mb-xs">
            <Row>
              <DisplayDataItem md={6} title="Filtro Utilizado">
                {getFiltrotext(controleDmsNaoEntregues.filtro)}
              </DisplayDataItem>
              <DisplayDataItem md={6} title="Cadastros Selecionados">
                {`${quantidadeSelecionado} cadastros`}
              </DisplayDataItem>
            </Row>
          </DisplayDataGrid>

          <Formik
            enableReinitialize
            initialValues={{}}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            render={(
              formProps: FormikProps<ControleDmsNaoEntregueMultaDTO>
            ) => (
              <>
                <Row>
                  <FormikSelect<EnumValue>
                    name="tipo"
                    label="Tipos de Multa"
                    options={tipoMulta}
                    getOptionLabel={option => option.descricao}
                    getOptionValue={option => option.codigo}
                    size={3}
                    onSelect={value => {
                      if (value.codigo === 'FIXA') {
                        formProps.setFieldValue('idIndiceCorrecao', undefined);
                        formProps.setFieldTouched('idIndiceCorrecao');
                      }
                    }}
                  />
                  <FormikSelect<IndiceCorrecao>
                    name="idIndiceCorrecao"
                    label="Índice de correção"
                    options={indicesCorrecao}
                    getOptionLabel={option =>
                      `${option.id} - ${option.descricao}`
                    }
                    getOptionValue={option => option.id}
                    size={3}
                    fast={false}
                    disabled={formProps.values.tipo === 'FIXA'}
                  />
                  <FormikInputNumber
                    name="valor"
                    label="Valor da Multa"
                    fast={false}
                    size={3}
                  />
                  <FormikInputDate
                    label="Vencimento"
                    name="vencimento"
                    size={3}
                  />
                </Row>
                <Row>
                  <FormikAutocomplete
                    name="guiaRecolhimento"
                    label="Guia de Recolhimento"
                    size={6}
                    onSearch={texto => {
                      return GuiaRecolhimentoService.load(
                        `exercicio==${new Date().getFullYear()} and ${formataTexto(
                          texto
                        )}`
                      );
                    }}
                    fast={false}
                    onItemSelected={() => {
                      if (formProps.values.receita) {
                        formProps.setFieldValue('receita', undefined);
                      }
                    }}
                  />

                  <FormikAutocomplete<ReceitaVo>
                    name="receita"
                    label="Receita"
                    size={6}
                    onSearch={texto => {
                      return GuiaRecolhimentoReceitaService.buscaGuiaReceitaVo(
                        `guiaRecolhimento.id==${formProps.values.guiaRecolhimento?.id} and (receita.descricao=='*${texto}*' or receita.receita =='*${texto}*')`
                      );
                    }}
                    getOptionLabel={value =>
                      `${value.receita} - ${value.descricaoReceita}`
                    }
                    fast={false}
                    disabled={!formProps.values.guiaRecolhimento}
                  />
                </Row>
                <div className="btn-save">
                  <FAB
                    data-testid="btn-save"
                    icon="check"
                    title="Iniciar Geração da Multa"
                    onClick={formProps.submitForm}
                    type={'submit'}
                  />
                </div>
              </>
            )}
          />
        </>
      ) : (
        <ErrorPage
          status={400}
          message="Nenhum cadastro selecionado para a geração de multa"
        />
      )}
    </Container>
  );
};

export default ControleDeclaracaoMensalNaoEntregueMultaFormPage;
