import {
  ActionButton,
  ActionsGroup,
  Alert,
  Container,
  Field,
  FormattedDateTime,
  Hint,
  Loading,
  PagedResponse,
  SearchFilter,
  SearchPagination,
  Table,
  formatUtils
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { History } from 'history';
import React, { useEffect, useRef, useState } from 'react';

import { SolicitacaoRpsService } from '../../../service';
import { SolicitacaoRPS } from '../../../types/SolicitacaoRPS';
import SituacaoSolicitacao from './SituacaoSolicitacao';
import { PENDENTE, SituacoesSolicitacaoRps } from './SolicitacaoRpsTipos';

type Props = {
  history: Pick<History, 'push'>;
};

const searchFields: Field[] = [
  {
    label: 'Número de Inscrição Municipal',
    name: 'cadastroGeral.cadastroGeral',
    type: 'NUMBER'
  },
  {
    label: 'Razão Social',
    name: 'cadastroGeral.pessoa.nome',
    type: 'STRING'
  },
  {
    label: 'CPF/CNPJ Contribuinte',
    name: 'cadastroGeral.pessoa.cnpjCpf',
    type: 'STRING'
  },
  {
    label: 'Situação',
    name: 'situacao',
    type: 'ENUM',
    options: SituacoesSolicitacaoRps.map(situacao => ({
      name: situacao.situacao,
      descricao: situacao.label
    }))
  }
];

const SolicitacaoRPSListPage: React.FC<Props> = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [solicitacoes, setSolicitacoes] = useState<SolicitacaoRPS[]>([]);
  const [pagination, setPagination] = useState<any>(undefined);
  const callOnRender = useRef(false);

  useEffect(() => {
    onSearch(`situacao==${PENDENTE}`);
  }, []);

  const onSearch = (searchParams: string, page?: number) => {
    if (callOnRender.current) {
      setLoading(true);
      SolicitacaoRpsService.loadSolicitacoes(searchParams, page)
        .then((response: AxiosResponse<PagedResponse<SolicitacaoRPS>>) => {
          const { content, ...pagination } = response.data;
          setSolicitacoes(content);
          setPagination(pagination);
        })
        .catch(error => {
          Alert.error(
            {
              title: 'Não foi possível carregar as solicitações.'
            },
            error
          );
        })
        .finally(() => setLoading(false));
    }
    callOnRender.current = true;
  };

  const onEdit = (item: SolicitacaoRPS) => {
    history.push(`/acessos-e-permissoes/solicitacoes-rps/analisar/${item.id}`);
  };

  return (
    <Container
      breadcrumb
      titleRightComponent={
        <a href="." target="_blank" rel="noopener noreferrer">
          <Hint
            classes={`inline clean module-color center-right fa-question-circle`}
          >
            Ajuda?
          </Hint>
        </a>
      }
    >
      <Loading loading={loading} />
      <div className="panel table table-responsive">
        <div className="panel-body">
          <SearchFilter fields={searchFields} search={onSearch} />
          <Table<SolicitacaoRPS>
            values={solicitacoes}
            keyExtractor={item => `${item.id}`}
          >
            <Table.Column<SolicitacaoRPS> header="ID" value={item => item.id} />
            <Table.Column<SolicitacaoRPS>
              data-test-id="table-row"
              header="Inscrição Municipal"
              value={item => item.cadastroGeral!.cadastroGeral}
            />
            <Table.Column<SolicitacaoRPS>
              header="CPF/CNPJ"
              value={item =>
                formatUtils.formatCpfCnpj(item.cadastroGeral!.pessoa!.cnpjCpf)
              }
            />
            <Table.Column<SolicitacaoRPS>
              header="Nome/Razão Social"
              value={item => item.cadastroGeral!.pessoa!.nome}
            />
            <Table.Column<SolicitacaoRPS>
              header="Data da Solicitação"
              value={item => <FormattedDateTime value={item.data} />}
            />
            <Table.Column<SolicitacaoRPS>
              header="Quantidade"
              value={item => item.quantidade}
            />
            <Table.Column<SolicitacaoRPS>
              header="Situação"
              value={item => <SituacaoSolicitacao solicitacao={item} />}
            />

            <Table.Column<SolicitacaoRPS>
              data-test-id="buttons-col"
              header=""
              value={item => (
                <ActionsGroup>
                  <ActionButton
                    data-test-id="analyze-button"
                    key="analyse-button"
                    icon="eye"
                    label="Ver Solicitação"
                    onClick={() => onEdit(item)}
                  />
                </ActionsGroup>
              )}
            />
          </Table>
          {pagination && (
            <SearchPagination
              page={pagination}
              searchWithPage={(page: any) => onSearch('', page)}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default SolicitacaoRPSListPage;
