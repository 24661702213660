import {
  Alert,
  Container,
  FAB,
  Hint,
  Loading,
  SearchFilter
} from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import swal from 'sweetalert2';

import { ConfigVencimentoDMSService, withService } from '../../../service';
import VencimentoDividaMensalList from './VencimentoDividaMensalList';

export class VencimentoDividaMensalListPage extends Component {
  static propTypes = {
    configVencimentoDMSService: PropTypes.object.isRequired
  };

  state = {
    dividas: {},
    loading: false,
    pageConsulta: 0,
    filter: [
      {
        anoCompetencia: '',
        mesCompetencia: '',
        guiaRecolhimento: '',
        dataVencimento: ''
      }
    ]
  };

  getConfigVencimentoDMSSuccess = response => {
    this.setState({
      dividas: response.data,
      loading: false
    });
  };

  getConfigVencimentoDMSError = error => {
    this.setState({ loading: false });
    Alert.error(
      {
        title: 'Ocorreu uma falha ao carregar as Configurações de Vencimento.'
      },
      error
    );
  };

  onDeleteSuccess = () => {
    swal('Excluido', 'O Vencimento da Dívida foi excluído.', 'success');

    this.searchWithPage(this.state.pageConsulta);
  };

  onDeleteErrror = error => {
    Alert.error({ title: 'Não foi excluir o vencimento da dívida.' }, error);
  };

  searchWithPage = page => {
    this.setState({ pageConsulta: page });
    this.serviceSearch(this.state.searchParams, page);
  };

  serviceSearch = (searchParams, page) => {
    this.setState({ loading: true });

    this.props.configVencimentoDMSService
      .carregarConfiguracaoVencimentosDMS(searchParams, page)
      .then(this.getConfigVencimentoDMSSuccess)
      .catch(this.getConfigVencimentoDMSError);
  };

  fields = [
    {
      label: 'Exercício',
      name: 'anoCompetencia',
      type: 'NUMBER'
    },
    {
      label: 'Mês',
      name: 'mesCompetencia',
      type: 'NUMBER'
    },
    {
      label: 'Dívida',
      name: 'guiaRecolhimento.descricao',
      type: 'STRING'
    }
  ];

  onCreate = () => {
    this.props.history.push('/configuracoes/vencimentos-das-dividas-dms/novo');
  };

  onEdit = id => {
    this.props.history.push(
      `/configuracoes/vencimentos-das-dividas-dms/editar/${id}`
    );
  };

  exclude = id =>
    swal({
      title: 'Confirmar exclusão?',
      text: 'Esta ação não poderá ser revertida.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim'
    }).then(result => {
      if (result.value) {
        this.props.configVencimentoDMSService
          .removerConfiguracaoVencimentoDMS(id)
          .then(this.onDeleteSuccess)
          .catch(this.onDeleteErrror);
      }
    });

  render() {
    const { dividas, loading } = this.state;
    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-configuracao-vencimento-da-divida"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        <div className="panel table table-responsive">
          <div className="panel-body">
            <SearchFilter fields={this.fields} search={this.serviceSearch} />
            <VencimentoDividaMensalList
              dividas={dividas}
              edit={this.onEdit}
              exclude={this.exclude}
              searchWithPage={this.searchWithPage}
            />
          </div>
        </div>
        <div className="btn-save">
          <FAB
            data-test-id="buttonSave"
            icon="plus"
            iconColor="white"
            title="Adicionar Novo"
            onClick={this.onCreate}
          />
        </div>
      </Container>
    );
  }
}

export default withService({
  configVencimentoDMSService: ConfigVencimentoDMSService
})(VencimentoDividaMensalListPage);
