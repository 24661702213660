import { FilterUtils, PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { Denuncia } from '../types/Denuncia';
import { NotaFiscal } from '../types/NotaFiscal';
import { RpsManual } from '../types/SolicitacaoRPS';
import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'dataCadastro,desc' };

const search = (params: string) => (params ? `search=${params}` : '');

export const loadDenuncias = (
  searchParams: string,
  page?: any
): AxiosPromise<PagedResponse<Denuncia>> =>
  axios.get(`${CONTEXT_PATH}/denuncias?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const loadDenuncia = (id: number): AxiosPromise<Denuncia> =>
  axios.get(`${CONTEXT_PATH}/denuncias/${id}`);

export const saveDenuncia = (denuncia: Denuncia): AxiosPromise<Denuncia> =>
  denuncia.id === undefined
    ? axios.post(`${CONTEXT_PATH}/denuncias/`, denuncia)
    : axios.put(`${CONTEXT_PATH}/denuncias/${denuncia.id}`, denuncia);

export const notificarDenunciante = (
  denuncia: Denuncia
): AxiosPromise<Denuncia> =>
  axios.post(
    `${CONTEXT_PATH}/denuncias/${denuncia.id}/notificar-denunciante`,
    denuncia
  );

export const loadRpsManual = (idDenuncia: number): AxiosPromise<RpsManual> =>
  axios.get(`${CONTEXT_PATH}/denuncias/${idDenuncia}/rps`);

export const loadNotaFiscal = (idDenuncia: number): AxiosPromise<NotaFiscal> =>
  axios.get(`${CONTEXT_PATH}/denuncias/${idDenuncia}/nota-fiscal`);

export const tiposDenunciasAutoComplete = (
  search: string
): AxiosPromise<PagedResponse<any>> =>
  axios.get(`${CONTEXT_PATH}/tipos-denuncias/`, {
    params: {
      search: FilterUtils.buildDefaultFilter()(search),
      fields: 'id, descricao',
      sort: 'descricao'
    }
  });

export const qtdDenunciasNovas = (): AxiosPromise<number> =>
  axios.get(`${CONTEXT_PATH}/denuncias/qtd-denuncias-novas`);
