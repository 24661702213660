import { Chip, ObjectUtils } from '@elotech/components';
import React from 'react';

type Props = {
  value: boolean;
};

const BooleanChip: React.FC<Props> = ({ value }) => (
  <Chip
    value={ObjectUtils.booleanToSimNao(value)}
    color={value ? 'positive' : 'negative'}
  />
);

export default BooleanChip;
