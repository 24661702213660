import {
  BasicInput,
  Button,
  Col,
  Container,
  FormikAutocomplete,
  FormikInputDate,
  FormikInputInteger,
  FormikSelect,
  Hint,
  Loading,
  NotificationActions,
  Row,
  SectionTitle,
  Yup,
  formatUtils
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';
import { Formik, FormikProps } from 'formik';
import { History } from 'history';
import { Alert } from 'iss-common/utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { CidadeService } from '../../service';
import NaturezaOperacaoService from '../../service/NaturezaOperacaoService';
import NotaFiscalService from '../../service/NotaFiscalService';
import { prestadorAutoComplete } from '../../service/PrestadorService';
import RegimeFiscalService from '../../service/RegimeFiscalService';
import { loadServicos } from '../../service/ServicoService';
import { Cidade } from '../../types/Cidade';
import { NaturezaOperacao } from '../../types/NaturezaOperacao';
import { PrestadorType } from '../../types/Prestador';
import { RegimeFiscalType } from '../../types/RegimeFiscal';
import { Servico } from '../../types/Servico';

type Props = {
  username: string;
  history: Pick<History, 'replace'>;
};

type Option = {
  label: string;
  value: string;
};

type SituacaoNotaType = {
  label: string;
  value: number;
};

const optionsDefault: Option[] = [
  { label: 'Sim', value: 'S' },
  { label: 'Não', value: 'N' },
  { label: 'Ambos', value: 'A' }
];

const optionTipoMovimento: Option[] = [
  { label: 'Prestado', value: 'P' },
  { label: 'Tomado', value: 'T' }
];

const optionTipoRelatorio: Option[] = [
  { label: 'Analítico', value: 'A' },
  { label: 'Sintético', value: 'S' },
  { label: 'Maiores Emissores', value: 'E' }
];

const optionSituacaoNota: SituacaoNotaType[] = [
  { label: 'Todas', value: -1 },
  { label: 'Normal', value: 0 },
  { label: 'Cancelada', value: 1 },
  { label: 'Extraviada', value: 2 },
  { label: 'Substituída', value: 3 },
  { label: 'Aguardando pagamento', value: 4 }
];

const initRegimesFiscais: RegimeFiscalType[] = [];
const initNaturezaOperacao: NaturezaOperacao[] = [];

const validationSchema = Yup.object().shape({
  dataInicial: Yup.date()
    .required()
    .label('Data inicial'),
  dataFinal: Yup.date()
    .required()
    .label('Data final')
    .test(
      'dataFinalMaiorQueInicial',
      'A data final não pode ser menor do que a data inicial',
      function(value: Date) {
        const { dataInicial } = this.parent;
        return dataInicial <= value;
      }
    ),
  prestador: Yup.object()
    .label('Prestador')
    .test(
      'prestadorXcnpjCpfPrestador',
      'Informe somente o prestador ou o CNPJ/CPF do Prestador',
      function(value: string) {
        return !(!!value && !!this.parent.cnpjCpfPrestador);
      }
    ),
  cnpjCpfPrestador: Yup.string()
    .label('CNPJ/CPF do Prestador')
    .test('prestadorCnpjCpf', 'CNPJ/CPF do Prestador inválido', function(
      value: string
    ) {
      return !(!!value && value.length !== 11 && value.length !== 14);
    }),
  cnpjCpfTomador: Yup.string()
    .label('CNPJ/CPF do Tomador')
    .test('tomadorCnpjCpf', 'CNPJ/CPF do Tomador inválido', function(
      value: string
    ) {
      return !(!!value && value.length !== 11 && value.length !== 14);
    }),
  quantidadeContribuintes: Yup.number()
    .label('Quantidade de Contribuintes')
    .test(
      'quantidadeContribuintes',
      'Quantidade de Contribuintes deve ser informada',
      function(value) {
        const { tipoRelatorio } = this.parent;
        if (tipoRelatorio === 'E' && (!value || value <= 0)) {
          return false;
        }
        return true;
      }
    ),
  tipoRelatorio: Yup.string()
    .required()
    .label('Tipo relatório')
});

const URL_HELP = '';

const RelatorioNfseEmitidasFormPage: React.FC<Props> = () => {
  const [regimeFiscal, setRegimeFiscal] = useState(initRegimesFiscais);
  const [naturezaOperacao, setNaturezaOperacao] = useState(
    initNaturezaOperacao
  );
  const [loading, setLoading] = useState(false);

  const [
    exibeQuantidadeContribuintes,
    setExibeQuantidadeContribuintes
  ] = useState(false);

  const getFileURL = (response: AxiosResponse<any>, type: string): string => {
    const file = new Blob([response.data], { type });
    return URL.createObjectURL(file);
  };

  const onFilter = async (values: any) => {
    const natOp = naturezaOperacao.filter(
      natOp => natOp.id === parseFloat(values.idNaturezaOperacao)
    );
    values.natOp = natOp.length > 0 ? natOp[0] : null;

    setLoading(true);

    await NotaFiscalService.carregaNfseEmitidas(values)
      .then((response: AxiosResponse<any>) => {
        const { tipoArquivo } = values;

        if (tipoArquivo === 'pdf') {
          window.open(getFileURL(response, 'application/pdf'));
          return;
        }

        const fileURL = getFileURL(response, 'application/vnd.ms-excel');

        if (tipoArquivo === 'expxls') {
          FileSaver.saveAs(fileURL, 'relatorio_nfse_exportacao.xls');
          return;
        }

        FileSaver.saveAs(fileURL, 'relatorio_nfse.xls');
      })
      .catch((error: any) => {
        Alert.error({ title: `Ocorreu um erro ao gerar o relatório` }, error);
      })
      .finally(() => setLoading(false));
  };

  const loadRegimeFiscal = () => {
    return RegimeFiscalService.carregarRegimeFiscal()
      .then((result: AxiosResponse<RegimeFiscalType[]>) => {
        setRegimeFiscal(result.data);
      })
      .catch((error: any) => {
        Alert.error({ title: `Erro ao carregar regimes fiscais` }, error);
      });
  };

  const loadNaturezaOperacao = () => {
    return NaturezaOperacaoService.carregarNaturezas()
      .then((result: AxiosResponse<NaturezaOperacao[]>) => {
        setNaturezaOperacao(result.data);
      })
      .catch((error: any) => {
        Alert.error({ title: `Erro ao carregar regimes fiscais` }, error);
      });
  };

  const onChangeTipoRelatorio = (
    tipoRelatorio: Option,
    formProps: FormikProps<any>
  ) => {
    formProps.setFieldValue('tipoRelatorio', tipoRelatorio?.value, true);
    setExibeQuantidadeContribuintes(false);

    if (tipoRelatorio?.value === 'E') {
      formProps.setFieldValue('quantidadeContribuintes', 10, true);
      setExibeQuantidadeContribuintes(true);
      return;
    }

    formProps.setFieldValue('quantidadeContribuintes', undefined, true);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([loadRegimeFiscal(), loadNaturezaOperacao()]).then(() =>
      setLoading(false)
    );
  }, []);

  return (
    <Container
      breadcrumb
      titleRightComponent={
        <a href={URL_HELP} target="_blank" rel="noopener noreferrer">
          <Hint
            classes={`inline clean module-color center-right fa-querion-circle`}
          >
            Ajuda?
          </Hint>
        </a>
      }
    >
      <SectionTitle>Filtro</SectionTitle>
      <Loading loading={loading} />
      <Col md={12}>
        <Row>
          <Formik
            enableReinitialize
            initialValues={{
              simplesNacional: 'A',
              dmsEntreguePrestador: 'A',
              dmsEntregueTomador: 'A',
              retencao: 'A',
              situacaoNota: -1,
              homologacao: 'N',
              tipoMovimento: 'P',
              tipoRelatorio: 'A',
              regimeFiscal: undefined,
              dataInicial: '',
              dataFinal: '',
              prestador: undefined,
              tomador: undefined,
              atividade: '',
              tipoArquivo: 'pdf',
              quantidadeContribuintes: undefined
            }}
            onSubmit={onFilter}
            validationSchema={() => validationSchema}
            render={(formProps: FormikProps<any>) => (
              <>
                <Row>
                  <FormikSelect
                    name="tipoMovimento"
                    label="Tipo Serviço"
                    size={4}
                    options={optionTipoMovimento}
                    getOptionLabel={(option: Option) => option.label}
                    getOptionValue={(option: Option) => option.value}
                  />
                  <FormikInputDate
                    label="Data inicial"
                    name="dataInicial"
                    size={4}
                  />
                  <FormikInputDate
                    name="dataFinal"
                    label="Data final"
                    size={4}
                  />
                </Row>
                <Row>
                  <FormikAutocomplete<PrestadorType>
                    data-test-id="prestador"
                    name="prestador"
                    placeholder="Pesquisar por prestador"
                    label="Prestador"
                    onSearch={prestadorAutoComplete}
                    size={6}
                    getOptionLabel={value =>
                      `${formatUtils.formatCpfCnpj(value.pessoa.cnpjCpf)} - ${
                        value.pessoa.nome
                      } -  ${value.cadastroGeral}`
                    }
                    getOptionValue={value => value.id}
                  />
                  <BasicInput
                    data-test-id="cnpjCpf"
                    name="cnpjCpfPrestador"
                    maxLength={14}
                    label="CNPJ/CPF do Prestador"
                    size={3}
                  />
                  <BasicInput
                    data-test-id="cnpjCpfTomador"
                    name="cnpjCpfTomador"
                    label="CNPJ/CPF do Tomador"
                    maxLength={14}
                    size={3}
                  />
                </Row>
                <Row>
                  <FormikAutocomplete<Servico>
                    data-test-id="atividade"
                    name="atividade"
                    placeholder="Pesquisar por atividade"
                    label="Atividade"
                    onSearch={loadServicos}
                    size={3}
                    getOptionLabel={value => `${value.descricao}`}
                    getOptionValue={value => value}
                  />
                  <FormikSelect
                    fast={false}
                    name="regimeFiscal"
                    label="Regime tributário"
                    size={3}
                    options={regimeFiscal}
                    getOptionLabel={(option: RegimeFiscalType) =>
                      option.descricao
                    }
                    getOptionValue={(option: RegimeFiscalType) => option.id}
                  />
                  <FormikSelect
                    fast={false}
                    name="idNaturezaOperacao"
                    label="Natureza Operação"
                    size={3}
                    options={naturezaOperacao}
                    getOptionLabel={(option: NaturezaOperacao) =>
                      option.descricao
                    }
                    getOptionValue={(option: NaturezaOperacao) => option.id}
                  />
                </Row>
                <Row>
                  <FormikSelect<Option>
                    name="simplesNacional"
                    label="Simples Nacional"
                    size={3}
                    noValue={'A'}
                    options={optionsDefault}
                    getOptionLabel={(option: Option) => option.label}
                    getOptionValue={(option: Option) => option.value}
                  />
                  <FormikSelect
                    name="dmsEntreguePrestador"
                    label="DMS Entregue Prestador"
                    size={3}
                    noValue={'A'}
                    options={optionsDefault}
                    getOptionLabel={(option: Option) => option.label}
                    getOptionValue={(option: Option) => option.value}
                  />
                  <FormikSelect
                    name="dmsEntregueTomador"
                    label="DMS Entregue Tomador"
                    size={3}
                    noValue={'A'}
                    options={optionsDefault}
                    getOptionLabel={(option: Option) => option.label}
                    getOptionValue={(option: Option) => option.value}
                  />
                  <FormikSelect
                    name="retencao"
                    label="Retenção"
                    size={3}
                    noValue={'A'}
                    options={optionsDefault}
                    getOptionLabel={(option: Option) => option.label}
                    getOptionValue={(option: Option) => option.value}
                  />
                </Row>
                <Row>
                  <FormikSelect
                    name="situacaoNota"
                    label="Situação NF"
                    size={2}
                    options={optionSituacaoNota}
                    getOptionLabel={(option: SituacaoNotaType) => option.label}
                    getOptionValue={(option: SituacaoNotaType) => option.value}
                  />
                  <FormikSelect
                    name="homologacao"
                    label="Homologação"
                    size={2}
                    noValue={'A'}
                    options={optionsDefault}
                    getOptionLabel={(option: Option) => option.label}
                    getOptionValue={(option: Option) => option.value}
                  />
                  <FormikSelect
                    name="tipoRelatorio"
                    label="Tipo relatório"
                    size={2}
                    options={optionTipoRelatorio}
                    getOptionLabel={(option: Option) => option.label}
                    getOptionValue={(option: Option) => option.value}
                    onSelect={(value: Option) =>
                      onChangeTipoRelatorio(value, formProps)
                    }
                  />

                  <FormikAutocomplete<Cidade>
                    data-test-id="auto-complete-cidade"
                    name="cidadePrestacao"
                    label="Cidade - UF"
                    onSearch={CidadeService.autoComplete}
                    getOptionLabel={(cidade: Cidade) =>
                      `${cidade.descricao} - ${cidade.unidadeFederacao?.descricao}`
                    }
                    getOptionValue={(cidade: Cidade) => cidade.id}
                    size={6}
                  />
                  {exibeQuantidadeContribuintes && (
                    <FormikInputInteger
                      name="quantidadeContribuintes"
                      label="Quantidade Contribuintes"
                      fast={false}
                    />
                  )}
                </Row>

                <div className="form-group">
                  <Button
                    className="inline"
                    iconPosition="left"
                    onClick={async () => {
                      await formProps.setFieldValue('tipoArquivo', 'pdf');
                      return await formProps.submitForm();
                    }}
                  >
                    <i className="fa fa-search" />
                    BUSCAR
                  </Button>
                  <Button
                    className="inline"
                    iconPosition="left"
                    onClick={async () => {
                      await formProps.setFieldValue('tipoArquivo', 'xls');
                      return await formProps.submitForm();
                    }}
                  >
                    <i className="fa fa-file-download" />
                    BAIXAR (XLS)
                  </Button>
                  <Button
                    className="inline"
                    iconPosition="left"
                    onClick={async () => {
                      await formProps.setFieldValue('tipoArquivo', 'expxls');
                      return await formProps.submitForm();
                    }}
                  >
                    <i className="fa fa-download" />
                    EXPORTAR (XLS) EM LISTA
                  </Button>
                </div>
              </>
            )}
          />
        </Row>
      </Col>
    </Container>
  );
};

const ConnectedComponent = connect(
  (state: any) => ({ username: state.user.profile.username }),
  {
    showNotification: NotificationActions.showNotification
  }
)(RelatorioNfseEmitidasFormPage);

export { ConnectedComponent as default, RelatorioNfseEmitidasFormPage };
