import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const search = (params: any) => (params ? `search=descricao==*${params}*` : '');

export const autoComplete = (searchParams: string) =>
  axios.get(
    `${CONTEXT_PATH}/situacao-parcela/autocomplete?${search(searchParams)}`
  );
