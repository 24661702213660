import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const search = (params: any) => (params ? `search=${params}` : '');

export const loadTipoRepresentante = (searchParams: string) =>
  axios.get(
    `${CONTEXT_PATH}/tipo-representante?${search(
      searchParams && `descricao==*${searchParams}*`
    )}`
  );

export const findTipoRepresentanteById = (id: any) =>
  axios.get(`${CONTEXT_PATH}/tipo-representante/${id}`);
