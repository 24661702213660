import { FilterUtils } from '@elotech/components';
import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const filterAutocomplete = (search: any) => {
  if (isNaN(search)) {
    return FilterUtils.buildFilter(['pessoa.nome'])(search);
  }

  return FilterUtils.buildDefaultFilter(
    'cadastroGeral',
    'pessoa.cnpjCpf'
  )(search);
};

export const autocomplete = (search: string = '') =>
  axios.get(`${CONTEXT_PATH}/cadastro-geral/tomadores`, {
    params: {
      search: filterAutocomplete(search),
      sort: 'pessoa.nome'
    }
  });
