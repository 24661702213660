import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };

const search = params => (params ? `search=${params}` : '');

export const loadImportacaoCnpj = (searchParams, page) =>
  axios.get(
    `${CONTEXT_PATH}/candidato-eleicao/consultarCandidatos?${search(
      searchParams
    )}`,
    {
      params: { ...defaultSort, ...page }
    }
  );

export const importArquivoCnpj = uploadArquivo =>
  axios.post(
    `${CONTEXT_PATH}/candidato-eleicao/importarCnpjCandidato`,
    uploadArquivo
  );

export const consultarPrestacoesContas = () =>
  axios.get(`${CONTEXT_PATH}/candidato-eleicao/consultarPrestacoesContas`);

export const gerarPrestacao = gerarArquivosInputValue =>
  axios.post(
    `${CONTEXT_PATH}/candidato-eleicao/gerarPrestacao`,
    gerarArquivosInputValue
  );

export const downloadArquivo = id =>
  axios.get(`${CONTEXT_PATH}/candidato-eleicao/downloadContasCandidato/${id}`, {
    responseType: 'blob'
  });
