import { PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { Denuncia } from '../types/Denuncia';
import { Duvida } from '../types/Duvida';
import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'dataCadastro,desc' };

const search = (params: string) => (params ? `search=${params}` : '');

export const loadDuvidas = (
  searchParams: string,
  page?: any
): AxiosPromise<PagedResponse<Duvida>> =>
  axios.get(`${CONTEXT_PATH}/duvida?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const loadDuvida = (id: number): AxiosPromise<Duvida> =>
  axios.get(`${CONTEXT_PATH}/duvida/${id}`);

export const saveDuvidas = (duvida: Duvida): AxiosPromise<Duvida> =>
  duvida.id === undefined
    ? axios.post(`${CONTEXT_PATH}/duvida/`, duvida)
    : axios.put(`${CONTEXT_PATH}/duvida/${duvida.id}`, duvida);

export const notificarRequerente = (duvida: Duvida): AxiosPromise<Denuncia> =>
  axios.post(
    `${CONTEXT_PATH}/duvida/${duvida.id}/notificar-requerente`,
    duvida
  );

export const qtdDuvidasNovas = (): AxiosPromise<number> =>
  axios.get(`${CONTEXT_PATH}/duvida/qtd-duvidas-novas`);
