import { EnumValue, SearchFieldEnumOption } from '../types';

export const NFSeNacionalTipoNSU = {
  MEI: 'MEI',
  DISTRIBUICAO: 'Distribuição'
};

export type NFSeNacionalTipoNSUValue = keyof typeof NFSeNacionalTipoNSU;

export const NFSeNacionalTipoNSUEnum: EnumValue[] = [
  { codigo: 'MEI', descricao: 'MEI' },
  { codigo: 'DISTRIBUICAO', descricao: 'Distribuição' }
];

export const NFSeNacionalTipoNSUOptions: SearchFieldEnumOption[] = NFSeNacionalTipoNSUEnum.map(
  (item: EnumValue) => ({
    name: item.codigo.toString(),
    descricao: item.descricao
  })
);

export const NFSeNacionalTipoDocumento = {
  DPS: 'Declaração de Prestação de Serviço',
  PEDIDO_REGISTRO_EVENTO: 'Pedido de Registro de Evento',
  NFSE: 'Nota Fiscal de Serviços Eletrônica',
  EVENTO: 'Evento'
};

export type NFSeNacionalTipoDocumentoValue = keyof typeof NFSeNacionalTipoDocumento;

export const NFSeNacionalTipoEvento = {
  CANCELAMENTO: 'Cancelamento',
  SOLICITACAO_CANCELAMENTO_ANALISE_FISCAL:
    'Solicitação de Cancelamento para Análise Fiscal',
  CANCELAMENTO_POR_SUBSTITUICAO: 'Cancelamento por Substituição',
  CANCELAMENTO_DEFERIDO_ANALISE_FISCAL:
    'Cancelamento - Deferido por Análise Fiscal',
  CANCELAMENTO_INDEFERIDO_ANALISE_FISCAL:
    'Cancelamento - Indeferido por Análise Fiscal',
  CONFIRMACAO_PRESTADOR: 'Confirmação - Prestador',
  REJEICAO_PRESTADOR: 'Rejeição - Prestador',
  CONFIRMACAO_TOMADOR: 'Confirmação - Tomador',
  REJEICAO_TOMADOR: 'Rejeição - Tomador',
  CONFIRMACAO_INTERMEDIARIO: 'Confirmação - Intermediário',
  REJEICAO_INTERMEDIARIO: 'Rejeição - Intermediário',
  CONFIRMACAO_TACITA: 'Confirmação Tácita',
  ANULACAO_REJEICAO: 'Anulação/Rejeição',
  CANCELAMENTO_POR_OFICIO: 'Cancelamento por Ofício',
  BLOQUEIO_POR_OFICIO: 'Bloqueio por Ofício',
  DESBLOQUEIO_POR_OFICIO: 'Desbloqueio por Ofício',
  INCLUSAO_NFSE_DAN: 'Inclusão NFSe/DAN',
  TRIBUTOS_NFSE_RECOLHIDOS: 'Tributos Recolhidos NFSe'
};

export type NFSeNacionalTipoEventoValue = keyof typeof NFSeNacionalTipoEvento;

export const NFSeNacionalStatusImportacao = {
  NAO_IMPORTADA: 'Não importada',
  IMPORTADA: 'Importada com sucesso',
  ERRO: 'Erro'
};

export type NFSeNacionalStatusImportacaoValue = keyof typeof NFSeNacionalStatusImportacao;

export const NFSeNacionalStatusEnum: EnumValue[] = [
  { codigo: 'NAO_IMPORTADA', descricao: 'Não importada' },
  { codigo: 'IMPORTADA', descricao: 'Importada com sucesso' },
  { codigo: 'ERRO', descricao: 'Erro' }
];

export const NFSeNacionalStatusOptions: SearchFieldEnumOption[] = NFSeNacionalStatusEnum.map(
  (item: EnumValue) => ({
    name: item.codigo.toString(),
    descricao: item.descricao
  })
);
