import { DisplayData, SectionTitle, formatUtils } from '@elotech/components';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { Denuncia } from '../../../types/Denuncia';

type Props = {
  denuncia: Denuncia;
};

const DenuncianteSection: React.FC<Props> = ({ denuncia }) => {
  const isDenunciaAnonima = (): boolean => {
    return (
      !denuncia.cpf && !denuncia.nome && !denuncia.email && !denuncia.telefone
    );
  };

  return (
    <>
      <SectionTitle>Denunciante</SectionTitle>

      {isDenunciaAnonima() ? (
        <>Denunciante Anônimo</>
      ) : (
        <div className="display-data border small mb-xs">
          <Row>
            <Col md={3}>
              <DisplayData title="CPF">
                {formatUtils.formatCpfCnpj(denuncia.cpf)}
              </DisplayData>
            </Col>
            <Col md={9}>
              <DisplayData title="Nome">{denuncia.nome}</DisplayData>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <DisplayData title="Email">{denuncia.email}</DisplayData>
            </Col>
            <Col md={9}>
              <DisplayData title="Telefone">{denuncia.telefone}</DisplayData>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default DenuncianteSection;
