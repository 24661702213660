export const isEmptyObject = (object: object): boolean =>
  Object.keys(object).length === 0;

const extractAttribute = (attributes: any, attributeName: string) =>
  (attributes[attributeName] && attributes[attributeName][0]) || '';

export const createUserObjectFromProfileAndUserExistsData = (
  userProfile: any,
  userProps: any
) => {
  const cpfCnpj = userProps.userName.match(/^(\d{11}|\d{14}$)/)
    ? userProps.userName
    : '';
  const partialUser = {
    ...userProps,
    cpfCnpj: cpfCnpj,
    nome: userProps.name
  };

  if (isEmptyObject(userProfile.attributes)) {
    return partialUser;
  }

  return {
    cpfCnpj:
      extractAttribute(userProfile.attributes, 'cpfCnpj') ||
      userProfile.username.match(/^(\d{11}|\d{14}$)/)
        ? userProfile.username
        : partialUser.cpfCnpj || '',
    nome:
      extractAttribute(userProfile.attributes, 'nome') ||
      userProfile.name ||
      partialUser.nome ||
      '',
    nomeFantasia:
      extractAttribute(userProfile.attributes, 'nomeFantasia') || '',
    email:
      extractAttribute(userProfile.attributes, 'email') ||
      partialUser.email ||
      '',
    tipoPessoa:
      extractAttribute(userProfile.attributes, 'tipoPessoa').toUpperCase() ||
      'FISICA',
    telefone:
      extractAttribute(userProfile.attributes, 'celular') ||
      extractAttribute(userProfile.attributes, 'telefone'),
    cep: extractAttribute(userProfile.attributes, 'cep'),
    logradouro: extractAttribute(userProfile.attributes, 'logradouro'),
    numero: extractAttribute(userProfile.attributes, 'numeroEndereco'),
    complemento: extractAttribute(userProfile.attributes, 'complemento'),
    bairro: extractAttribute(userProfile.attributes, 'bairro'),
    cidade: extractAttribute(userProfile.attributes, 'cidade'),
    uf: extractAttribute(userProfile.attributes, 'estado')
  };
};
