import { Alert, Container, Loading, SearchFilter } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { SubstituicaoNotaFiscalService, withService } from '../../../service';
import SubstituicaoNfseList from './SubstituicaoNfseList';

export class SubstituicaoNfseListPage extends Component {
  static propTypes = {
    substituicaoNotaFiscalService: PropTypes.object.isRequired
  };

  state = {
    historicoSolicitacoesSubstituicaoNota: {},
    loading: false,
    pageConsulta: 0
  };

  fields = [
    {
      label: 'Situação',
      name: 'status',
      type: 'ENUM',
      options: [
        {
          name: 'AGUARDANDO_FISCAL',
          descricao: 'Aguardando Fiscal'
        },
        {
          name: 'AUTORIZADO',
          descricao: 'Autorizado'
        },
        {
          name: 'AGUARDANDO_TOMADOR',
          descricao: 'Aguardando Tomador'
        },
        {
          name: 'AGUARDANDO_PRESTADOR',
          descricao: 'Aguardando Prestador'
        },
        {
          name: 'REJEITADO',
          descricao: 'Rejeitado'
        }
      ]
    },
    {
      label: 'Nome Solicitante',
      name: 'solicitacaoSubstituicao.cadastroSolicitante.pessoa.nome',
      type: 'STRING'
    },
    {
      label: 'Cnpj Solicitante',
      name: 'solicitacaoSubstituicao.cadastroSolicitante.pessoa.cnpjCpf',
      type: 'STRING'
    },
    {
      label: 'Nº Nota Fiscal',
      name: 'solicitacaoSubstituicao.notaFiscal.numeroNotaFiscal',
      type: 'NUMBER'
    }
  ];

  searchWithPage = page => {
    this.setState({ pageConsulta: page });
    this.serviceSearch(this.state.searchParams, page);
  };

  serviceSearch = (searchParams, page) => {
    this.setState({
      loading: true,
      searchParams
    });

    this.props.substituicaoNotaFiscalService
      .loadSubstituicoesNotasFiscais(searchParams, page)
      .then(this.getSubstituicoesNotasFiscaisSuccess)
      .catch(this.getSubstituicoesNotasFiscaisError);
  };

  getSubstituicoesNotasFiscaisSuccess = response => {
    this.setState({
      historicoSolicitacoesSubstituicaoNota: response.data,
      loading: false
    });
  };

  getSubstituicoesNotasFiscaisError = error => {
    this.setState({ loading: false });
    Alert.error(
      { title: 'Não foi possível carregar as solicitações de substituição.' },
      error
    );
  };

  analisarSolicitacaoSubstituicao = id => {
    this.props.history.push(
      `/acessos-e-permissoes/substituicao-nfse/analisar/${id}`
    );
  };

  render() {
    const { historicoSolicitacoesSubstituicaoNota, loading } = this.state;
    return (
      <Container breadcrumb>
        <Loading loading={loading} />
        <div className="panel table table-responsive">
          <div className="panel-body">
            <SearchFilter fields={this.fields} search={this.serviceSearch} />
            <SubstituicaoNfseList
              historicoSolicitacoesSubstituicaoNota={
                historicoSolicitacoesSubstituicaoNota
              }
              searchWithPage={this.searchWithPage}
              analisarSolicitacaoSubstituicao={
                this.analisarSolicitacaoSubstituicao
              }
            />
          </div>
        </div>
      </Container>
    );
  }
}

const ComponentWithService = withService({
  substituicaoNotaFiscalService: SubstituicaoNotaFiscalService
})(SubstituicaoNfseListPage);

export default ComponentWithService;
