import { EnumValue } from '../types';

export const tipoRelatorioVariacaoValores: EnumValue[] = [
  { codigo: 'D', descricao: 'Detalhado' },
  { codigo: 'R', descricao: 'Resumido' }
];

export const tiposMovimento: EnumValue[] = [
  { codigo: 'P', descricao: 'Prestador' },
  { codigo: 'T', descricao: 'Tomador' }
];
