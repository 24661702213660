import {
  ActionButton,
  FormattedDate,
  SearchPagination,
  formatUtils
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const CancelamentoDMSList = ({
  solicitacoesCancelamentoDms,
  analyzeButton,
  searchWithPage
}) => {
  return (
    <div>
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <thead>
            <tr>
              <th>Solicitante</th>
              <th>CPF/CNPJ</th>
              <th>Nº Solicitação</th>
              <th>Situação</th>
              <th>Data</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {solicitacoesCancelamentoDms &&
              solicitacoesCancelamentoDms.content &&
              solicitacoesCancelamentoDms.content.map(cancelamentoDms => {
                return (
                  <tr key={cancelamentoDms.id}>
                    <td>{cancelamentoDms.nomeSolicitante}</td>
                    <td>
                      {formatUtils.formatCpfCnpj(cancelamentoDms.cnpjCpf)}
                    </td>
                    <td>{cancelamentoDms.numeroDms}</td>
                    <td>{cancelamentoDms.status}</td>
                    <td>
                      <FormattedDate
                        value={cancelamentoDms.dataSolicitacao}
                        timeZone={'UTC'}
                      />
                    </td>
                    <td>
                      <div className="btn-actions">
                        <div className="btn-actions-inner">
                          <ActionButton
                            data-test-id={`buttonAnalisarSolicitacao${cancelamentoDms.id}`}
                            key="edit-button"
                            icon="search"
                            label="Analisar"
                            onClick={() => analyzeButton(cancelamentoDms.id)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            {(!solicitacoesCancelamentoDms ||
              solicitacoesCancelamentoDms.totalElements === 0) && (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan="7">
                  Nenhum registro encontrado.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {solicitacoesCancelamentoDms && (
        <SearchPagination
          page={solicitacoesCancelamentoDms}
          searchWithPage={searchWithPage}
        />
      )}
    </div>
  );
};

CancelamentoDMSList.propTypes = {
  solicitacoesCancelamentoDms: PropTypes.object.isRequired,
  searchWithPage: PropTypes.func,
  analyzeButton: PropTypes.func
};
export default CancelamentoDMSList;
