import {
  Alert,
  Button,
  Checkbox,
  Col,
  Row,
  SectionTitle
} from '@elotech/components';
import React from 'react';

const ConfiguracaoEmailForm = ({
  parametrosConfiguracaoEmail,
  onChangeParametros,
  onToggleConfiguracaoEmail,
  showFormConfiguracaoEmail,
  servicoEnviarEmail,
  loading
}) => {
  let emailDestinatario = '';

  const onClickTestarEmail = () => {
    let emailTeste = {
      emailRemetente: parametrosConfiguracaoEmail.USUARIOSMTP.valor,
      emailDestinatario: emailDestinatario
    };
    loading(true);
    servicoEnviarEmail
      .enviarEmail(emailTeste)
      .then(response => {
        if (response.status === 200) {
          Alert.success({ title: 'Email enviado com sucesso!' });
        }
      })
      .catch(error => {
        Alert.error({ title: 'Não foi possível enviar o email' }, error);
      })
      .finally(() => {
        loading(false);
      });
  };

  const onChangeEmailTeste = email => {
    emailDestinatario = email.target.value;
  };

  return (
    <>
      <SectionTitle hasButton={true}>
        Configurações de Email
        <button
          className="dropdown-arrow"
          data-test-id="button"
          onClick={onToggleConfiguracaoEmail}
        >
          <i
            className={
              showFormConfiguracaoEmail
                ? 'fa fa-chevron-up'
                : 'fa fa-chevron-down'
            }
          />
        </button>
      </SectionTitle>
      {showFormConfiguracaoEmail ? (
        <>
          <Row>
            <Col sm={4} md={4}>
              <div className="form-group">
                <label htmlFor="SERVIDORSMTP">Servidor SMTP</label>
                <input
                  type="text"
                  name="SERVIDORSMTP"
                  value={parametrosConfiguracaoEmail.SERVIDORSMTP.valor}
                  onChange={onChangeParametros}
                />
              </div>
            </Col>
            <Col sm={3} md={3}>
              <div className="form-group">
                <label htmlFor="PORTASMTP">Porta</label>
                <input
                  type="number"
                  name="PORTASMTP"
                  value={parametrosConfiguracaoEmail.PORTASMTP.valor}
                  onChange={onChangeParametros}
                />
              </div>
            </Col>
            <Col sm={3} md={3}>
              <div className="form-group">
                <label htmlFor="">Protocolo de envio</label>
                <select
                  name="SMTPPROTOCOL"
                  value={parametrosConfiguracaoEmail.SMTPPROTOCOL.valor}
                  onChange={onChangeParametros}
                >
                  <option value="smtp">SMTP</option>
                  <option value="smtps">SMTPS</option>
                </select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={5} md={4}>
              <Checkbox
                className="form-group"
                name="REQUERAUTENTICACAO"
                id="REQUERAUTENTICACAO"
                checked={
                  parametrosConfiguracaoEmail.REQUERAUTENTICACAO.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Servidor requer autenticação"
              />
            </Col>
            <Col sm={5} md={4}>
              <Checkbox
                className="form-group"
                name="SSLSMTP"
                id="SSLSMTP"
                checked={parametrosConfiguracaoEmail.SSLSMTP.valor === 'S'}
                onChange={onChangeParametros}
                label="Servidor requer conexão segura (SSL)"
              />
            </Col>
          </Row>

          <Row>
            <Col sm={5} md={5}>
              <div className="form-group">
                <label htmlFor="USUARIOSMTP">Usuário</label>
                <input
                  type="text"
                  name="USUARIOSMTP"
                  value={parametrosConfiguracaoEmail.USUARIOSMTP.valor}
                  onChange={onChangeParametros}
                />
              </div>
            </Col>
            <Col sm={5} md={5}>
              <div className="form-group">
                <label htmlFor="SENHASMTP">Senha</label>
                <input
                  type="password"
                  name="SENHASMTP"
                  value={parametrosConfiguracaoEmail.SENHASMTP.valor}
                  onChange={onChangeParametros}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={5} md={5}>
              <div className="form-group">
                <label htmlFor="EMAILTESTE">Destinatário para Testes</label>
                <input
                  type="text"
                  name="EMAILTESTE"
                  onChange={onChangeEmailTeste}
                />
              </div>
            </Col>
            <Col sm={5} md={5}>
              <div className="form-group">
                <label htmlFor="" className="label" />
                <Button
                  data-test-id="button-email-teste"
                  iconPosition="left"
                  color="module-color"
                  onClick={onClickTestarEmail}
                >
                  <i className="fa fa-vial" />
                  Testar envio
                </Button>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default ConfiguracaoEmailForm;
