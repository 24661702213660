import {
  ActionButton,
  ActionsGroup,
  Alert,
  Chip,
  Container,
  Field,
  FormattedDateTime,
  Loading,
  SearchFilter,
  SearchPagination,
  Table,
  formatUtils,
  usePagedQuery
} from '@elotech/components';
import { History } from 'history';
import React from 'react';

import { DuvidaService } from '../../service';
import { Duvida } from '../../types/Duvida';
import { SituacoesDuvida, getLabelSituacao } from './SituacaoDuvida';

type Props = {
  history: Pick<History, 'push'>;
};

const searchFields: Field[] = [
  {
    label: 'Id',
    name: 'id',
    type: 'NUMBER'
  },
  {
    label: 'CPF',
    name: 'cpf',
    type: 'STRING'
  },
  {
    label: 'Nome',
    name: 'nome',
    type: 'STRING'
  },
  {
    label: 'Data da dúvida',
    name: 'dataCadastro',
    type: 'DATE'
  },
  {
    label: 'Situação',
    name: 'situacao',
    type: 'ENUM',
    options: SituacoesDuvida.map(situacao => ({
      name: situacao.situacao,
      descricao: situacao.label
    }))
  }
];

const DuvidaListPage: React.FC<Props> = ({ history }) => {
  const {
    loading,
    values,
    pagination,
    doSearch,
    doPagedSearch
  } = usePagedQuery<Duvida>({
    search: DuvidaService.loadDuvidas,
    onError: error => {
      Alert.error({ title: 'Erro ao buscar as dúvidas.' }, error);
    }
  });

  const onEdit = (item: Duvida) => {
    history.push(`/duvidas/analisar/${item.id}`);
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <div className="panel table table-responsive">
        <div className="panel-body">
          <SearchFilter fields={searchFields} search={doSearch} />
          <Table<Duvida> values={values} keyExtractor={item => `${item.id}`}>
            <Table.Column<Duvida> header="Id" value={item => item.id} />
            <Table.Column<Duvida>
              header="CPF/CNPJ do requerente"
              value={item => formatUtils.formatCpfCnpj(item.cpf)}
            />

            <Table.Column<Duvida>
              header="Nome do requerente"
              value={item => item.nome}
            />

            <Table.Column<Duvida>
              header="Data de envio"
              value={item => <FormattedDateTime value={item.dataCadastro} />}
            />

            <Table.Column<Duvida>
              header="Situação"
              value={item => (
                <Chip inline value={getLabelSituacao(item!.situacao)} />
              )}
            />

            <Table.Column<Duvida>
              data-test-id="buttons-col"
              header=""
              value={item => (
                <ActionsGroup>
                  <ActionButton
                    key="analyse-button"
                    icon="eye"
                    label="Ver dúvida"
                    onClick={() => onEdit(item)}
                  />
                </ActionsGroup>
              )}
            />
          </Table>
          {pagination && (
            <SearchPagination
              page={pagination}
              searchWithPage={doPagedSearch}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default DuvidaListPage;
