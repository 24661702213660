import {
  Container,
  FAB,
  Hint,
  Loading,
  NotificationActions,
  SectionTitle
} from '@elotech/components';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  DocumentoService,
  GrupoDocumentoService,
  withService
} from '../../../service';
import AnexoPublicoNewEditForm from './AnexoPublicoNewEditForm';
import VersoesDocumentoList from './VersoesDocumentoList';
import VersoesDocumentoNewForm from './VersoesDocumentoNewForm';

export class AnexoPublicoNewEditFormPage extends Component {
  state = {
    loading: false,

    cadastroDocumento: {
      id: '',
      numero: '',
      ano: '',
      documentoGrupo: '',
      titulo: '',
      descricao: '',
      link: '',
      versoes: []
    },

    versoesDocumento: {
      id: '',
      versao: '',
      dataVersao: '',
      notasVersao: '',
      nomeArquivo: '',
      arquivoBase64: ''
    },

    showForm: false,

    error: {
      documentoGrupo: false,
      titulo: false,
      descricao: false
    },

    errorVersoesDocumento: {
      versao: false,
      dataVersao: false,
      arquivoBase64: false,
      arquivoErrorMessage: false
    }
  };

  loadGrupoDocumento = search => {
    const { grupoDocumentoService } = this.props;
    return grupoDocumentoService.loadGrupoDocumento(search);
  };

  loadCadastroDocumentoSuccess = response => {
    this.setState({
      cadastroDocumento: response.data,
      loading: false
    });
  };

  loadCadastroDocumentoError = () => {
    this.setState({ loading: false });

    this.props.showNotification({
      level: 'error',
      message: 'Não foi possível carregar o Cadastro de Documentos'
    });
  };

  onSelect = (name, value) => {
    this.setState(state => {
      return {
        cadastroDocumento: {
          ...state.cadastroDocumento,
          [name]: value
        },
        error: {
          ...state.error,
          [name]: false
        }
      };
    });
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.setState({ loading: true });
      this.props.documentoService
        .loadDocumentosCadastradosById(id)
        .then(this.loadCadastroDocumentoSuccess)
        .catch(this.loadCadastroDocumentoError);
    }
  }

  validators = {
    titulo: value => value,
    descricao: value => value,
    documentoGrupo: value => value
  };

  validatorsVersoesDocumento = {
    versao: value => value,
    dataVersao: value => value,
    arquivoBase64: value => value
  };

  hasErrorsVersoesDocumento = () => {
    const { versoesDocumento } = this.state;

    const errors = Object.keys(versoesDocumento).filter(field => {
      return (
        this.validatorsVersoesDocumento[field] &&
        !this.validatorsVersoesDocumento[field](versoesDocumento[field])
      );
    });

    const objErro = errors.reduce((total, current) => {
      total[current] = true;
      return total;
    }, {});

    this.setState({ errorVersoesDocumento: objErro });

    return errors.length > 0;
  };

  hasErrors = () => {
    const { cadastroDocumento } = this.state;

    const errors = Object.keys(cadastroDocumento).filter(field => {
      return (
        this.validators[field] &&
        !this.validators[field](cadastroDocumento[field])
      );
    });

    const objErro = errors.reduce((total, current) => {
      total[current] = true;
      return total;
    }, {});

    this.setState({ error: objErro });

    return errors.length > 0;
  };

  onChangeInputValue = event => {
    const { name, value } = event.target;

    this.setState(state => {
      const { cadastroDocumento, error } = state;
      return {
        cadastroDocumento: { ...cadastroDocumento, [name]: value },
        error: {
          ...error,
          [name]: this.validators[name] && !this.validators[name](value)
        }
      };
    });
  };

  onChangeInputValueVersoesDocumento = event => {
    const { name, value } = event.target;

    this.setState(state => {
      const { versoesDocumento, errorVersoesDocumento } = state;
      return {
        versoesDocumento: { ...versoesDocumento, [name]: value },
        errorVersoesDocumento: {
          ...errorVersoesDocumento,
          [name]:
            this.validatorsVersoesDocumento[name] &&
            !this.validatorsVersoesDocumento[name](value)
        }
      };
    });
  };

  onVersoesDocumentoNovo = () => {
    this.setState({ showForm: true });
  };

  onEditVersoesDocumento = index => {
    this.setState(state => {
      const { cadastroDocumento } = state;

      let versoesDocumentoEdicao = cadastroDocumento.versoes[index];
      versoesDocumentoEdicao = {
        ...versoesDocumentoEdicao,
        indexEdit: index
      };

      return {
        versoesDocumento: versoesDocumentoEdicao,
        showForm: true
      };
    });
  };

  onExcluirVersoesDocumento = index => {
    this.setState(state => {
      const { cadastroDocumento } = state;
      const versoes = cadastroDocumento.versoes.filter(
        (item, i) => index !== i
      );
      return {
        cadastroDocumento: {
          ...cadastroDocumento,
          versoes
        }
      };
    });
  };

  salvarDocumentoSuccess = () => {
    this.setState({ loading: false });
    this.props.showNotification({
      level: 'success',
      message: 'Documento salvo com sucesso'
    });
    this.props.history.replace('/anexos-publicos/anexo-publico');
  };

  salvarDocumentoError = () => {
    this.setState({ loading: false });
    this.props.showNotification({
      level: 'error',
      message: 'Não foi possível salvar o Documento.'
    });
  };

  onSave = () => {
    const { cadastroDocumento } = this.state;
    if (this.hasErrors()) {
      this.props.showNotification({
        level: 'error',
        message: 'Campos Obrigatórios'
      });
    } else {
      this.setState({ loading: true });
      this.props.documentoService
        .save(cadastroDocumento)
        .then(this.salvarDocumentoSuccess)
        .catch(this.salvarDocumentoError);
    }
  };

  onSaveVersoesDocumento = () => {
    if (!this.hasErrorsVersoesDocumento()) {
      this.setState(state => {
        const { versoesDocumento, cadastroDocumento, showForm } = state;

        let versoes;
        if (versoesDocumento.indexEdit >= 0) {
          cadastroDocumento.versoes[
            versoesDocumento.indexEdit
          ] = versoesDocumento;
          versoes = cadastroDocumento.versoes;
        } else {
          versoes = cadastroDocumento.versoes.concat(versoesDocumento);
        }
        return {
          cadastroDocumento: {
            ...cadastroDocumento,
            versoes
          },
          showForm: !showForm
        };
      });
    }
  };

  onCancel = () => {
    this.props.history.replace('/anexos-publicos/anexo-publico');
  };

  onCancelVersoesDocumento = () => {
    this.setState({
      showForm: false,
      versoesDocumento: '',
      errorVersoesDocumento: false
    });
  };

  getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.replace(/^data:(.*;base64,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = error => reject(error);
    });
  };

  salvarVersoesDocumentoError = () => {
    this.setState({ loading: false });

    this.props.showNotification({
      level: 'error',
      message: 'Campos obrigatórios.'
    });
  };

  onUploadFile = event => {
    const { arquivoErrorMessage } = this.state;
    const { files } = event.target;
    const isValid = !(files && files[0] && files[0].size > 52428800);

    const errorObjValid = isValid
      ? { arquivoErrorMessage: undefined }
      : { arquivoErrorMessage: 'Arquivo maior que 50Mb' };

    if (!isValid) {
      this.setState(state => ({
        errorVersoesDocumento: {
          ...state.errorVersoesDocumento,
          ...errorObjValid
        }
      }));
      return;
    } else {
      this.setState(state => ({
        errorVersoesDocumento: {
          ...state.errorVersoesDocumento,
          arquivoErrorMessage
        }
      }));
    }
    this.getBase64(files[0])
      .then(data => {
        this.setState(state => {
          const { versoesDocumento, error } = state;
          return {
            versoesDocumento: {
              ...versoesDocumento,
              arquivoBase64: data,
              ...errorObjValid,
              nomeArquivo: files[0].name
            },

            error: { ...error, arquivoErrorMessage: arquivoErrorMessage }
          };
        });
      })
      .catch(this.salvarVersoesDocumentoError);
  };

  optionLabel = option => option.titulo;

  render() {
    const {
      loading,
      cadastroDocumento,
      versoesDocumento,
      error,
      errorVersoesDocumento,
      showForm
    } = this.state;
    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-cadastro-documento"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />

        <SectionTitle marginTop="0">Documentos</SectionTitle>
        <AnexoPublicoNewEditForm
          cadastroDocumento={cadastroDocumento}
          onChange={this.onChangeInputValue}
          error={error}
          loadGrupoDocumento={this.loadGrupoDocumento}
          onSelect={this.onSelect}
          optionLabel={this.optionLabel}
        />
        <SectionTitle>Versões de Documentos</SectionTitle>
        {showForm ? (
          <VersoesDocumentoNewForm
            versoesDocumento={versoesDocumento}
            onCancel={this.onCancelVersoesDocumento}
            onSave={this.onSaveVersoesDocumento}
            error={errorVersoesDocumento}
            onChange={this.onChangeInputValueVersoesDocumento}
            onUploadFile={this.onUploadFile}
          />
        ) : (
          <VersoesDocumentoList
            versoesDocumentoData={cadastroDocumento.versoes}
            edit={this.onEditVersoesDocumento}
            onToggle={this.onVersoesDocumentoNovo}
            exclude={this.onExcluirVersoesDocumento}
          />
        )}
        <div className="btn-save">
          <FAB
            data-test-id="buttonSave"
            disabled={showForm ? 'disabled' : ''}
            typeButton="positive"
            iconColor="white"
            title="Salvar"
            onClick={this.onSave}
          />
          <FAB
            data-test-id="buttonCancel"
            typeButton="negative"
            iconColor="white"
            title="Cancelar"
            onClick={this.onCancel}
          />
        </div>
      </Container>
    );
  }
}
const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const ComponentWithService = withService({
  documentoService: DocumentoService,
  grupoDocumentoService: GrupoDocumentoService
})(AnexoPublicoNewEditFormPage);

export default connect(null, mapDispatchToProps)(ComponentWithService);
