import { SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import EmpresaNewForm from './EmpresaNewForm';
import EmpresasListRow from './EmpresasListRow';

const EmpresasForm = ({
  incentivoFiscal,
  empresa,
  errorEmpresa,
  showFormEmpresa,
  onSaveEmpresa,
  onCancelEmpresa,
  onNewEmpresa,
  onRemoveEmpresa,
  onSelectEmpresa,
  loadCadastrosMobiliarios,
  optionLabel
}) => {
  return (
    <Fragment>
      <SectionTitle hasButton={true}>
        Empresas
        {!showFormEmpresa && (
          <button
            data-test-id="buttonNovo"
            className="btn module-color"
            onClick={onNewEmpresa}
          >
            + Novo
          </button>
        )}
      </SectionTitle>
      {showFormEmpresa ? (
        <EmpresaNewForm
          empresa={empresa}
          errorEmpresa={errorEmpresa}
          onSaveEmpresa={onSaveEmpresa}
          onCancelEmpresa={onCancelEmpresa}
          onSelectEmpresa={onSelectEmpresa}
          loadCadastrosMobiliarios={loadCadastrosMobiliarios}
          optionLabel={optionLabel}
        />
      ) : (
        incentivoFiscal.empresas &&
        incentivoFiscal.empresas.map((empresa, index) => {
          return (
            <EmpresasListRow
              index={index}
              empresa={empresa}
              onRemoveEmpresa={onRemoveEmpresa}
            />
          );
        })
      )}
    </Fragment>
  );
};

EmpresasForm.propTypes = {
  incentivoFiscal: PropTypes.object.isRequired,
  empresa: PropTypes.object.isRequired,
  errorEmpresa: PropTypes.object,
  showFormEmpresa: PropTypes.bool,
  onSaveEmpresa: PropTypes.func.isRequired,
  onCancelEmpresa: PropTypes.func.isRequired,
  onNewEmpresa: PropTypes.func.isRequired,
  onRemoveEmpresa: PropTypes.func.isRequired,
  onSelectEmpresa: PropTypes.func.isRequired,
  loadCadastrosMobiliarios: PropTypes.func.isRequired,
  filter: PropTypes.func.isRequired,
  optionLabel: PropTypes.func.isRequired,
  showButtonNovo: PropTypes.func.isRequired
};

export default EmpresasForm;
