import axios, { AxiosPromise } from 'axios';

import { ViradaExercicio } from './../types/ViradaExercicio.d';
import { CONTEXT_PATH } from './contextPath';

export const verificarConfiguracaoExercicioEDivida = (
  viradaExercicio: ViradaExercicio
): AxiosPromise<ViradaExercicio> =>
  axios.post(`${CONTEXT_PATH}/virada-exercio/verificar`, viradaExercicio);

export const atualizarViradaExercicio = (
  viradaExercicio: ViradaExercicio
): AxiosPromise<void> =>
  axios.post(`${CONTEXT_PATH}/virada-exercio/atualizar`, viradaExercicio);
