import {
  ActionButton,
  ActionsGroup,
  Alert,
  DateUtils,
  FormattedDateTime,
  Panel,
  Table,
  TableChild
} from '@elotech/components';
import { BatchStatusChip } from 'iss-common/components';
import React, { useEffect, useState } from 'react';

import { BatchService } from '../../service';
import { BatchInstanceDTO, HistoricoBatchDTO } from '../../types';

type Props = {
  tarefa: BatchInstanceDTO;
};

const HistoricoExecucaoList: React.FC<Props> = props => {
  const [loading, setLoading] = useState<boolean>(false);
  const [historico, setHistorico] = useState<HistoricoBatchDTO[]>([]);
  const [idsExecutionError, setIdExecutionError] = useState<number[]>([]);

  const getExecutionFromInstanceId = (instanceId: number) => {
    setLoading(true);
    BatchService.getExecutionFromInstanceId(instanceId)
      .then(result => setHistorico(result.data))
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao carregar histórico de execução.'
          },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getExecutionFromInstanceId(props.tarefa.instanceId);
  }, [props.tarefa.instanceId]);

  const onExecutionError = (id: number) => {
    if (idsExecutionError.includes(id)) {
      return setIdExecutionError(
        idsExecutionError.filter(idExecution => idExecution !== id)
      );
    }

    return setIdExecutionError([...idsExecutionError, id]);
  };

  const renderInnerComponent = (item: HistoricoBatchDTO) => {
    if (!idsExecutionError.includes(item.id)) {
      return;
    }

    return (
      <TableChild>
        <textarea title="Mensagem de Erro" value={item.mensagemErro} readOnly />
      </TableChild>
    );
  };

  return (
    <TableChild>
      <Panel isTable>
        <Table
          values={historico}
          loading={loading}
          renderInnerComponent={renderInnerComponent}
        >
          <Table.Column<HistoricoBatchDTO>
            header="Job"
            value={item => item.id}
          />
          <Table.Column<HistoricoBatchDTO>
            header="Início"
            value={item => <FormattedDateTime value={item.dataInicio} />}
          />
          <Table.Column<HistoricoBatchDTO>
            header="Fim"
            value={item => <FormattedDateTime value={item.dataFim} />}
          />
          <Table.Column<HistoricoBatchDTO>
            header="Duração"
            value={item => {
              return (
                item.dataInicio &&
                item.dataFim &&
                DateUtils.getDiferencaDatasFormatada(
                  item.dataInicio,
                  item.dataFim
                )
              );
            }}
          />
          <Table.Column<HistoricoBatchDTO>
            header="Situação"
            headerClassName="center "
            value={item => <BatchStatusChip jobStatus={item.status} />}
          />
          <Table.Column<HistoricoBatchDTO>
            header=""
            value={item => (
              <ActionsGroup>
                {item.status === 'FAILED' && (
                  <ActionButton
                    key="executionErrorView"
                    icon="exclamation"
                    label="Visualizar Erros"
                    onClick={() => {
                      onExecutionError(item.id);
                    }}
                  />
                )}
              </ActionsGroup>
            )}
          />
        </Table>
      </Panel>
    </TableChild>
  );
};

export default HistoricoExecucaoList;
