import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Hint,
  InputInteger,
  Masks,
  Row,
  SectionTitle
} from '@elotech/components';
import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import MaskedInput from 'react-text-mask';

import {
  reactQuillFormats,
  reactQuillModules
} from '../../template-email/ConfiguracaoReactQuill';
import MessageBox from './MessageBox';

type Props = {
  dadosGerais: any;
  tipoImagemLogomarca: any;
  onChangeParametros: any;
  onToggleDadosGerais: any;
  showFormDadosGerais: any;
  onToggleMessageBoxMensagemApresetacao: any;
  onToggleMessageBoxMensagemAlerta: any;
  onToggleMessageBoxNotaExplicativaCartaCorrecao: any;
  showMessageBoxMensagemApresentacao: any;
  showMessageBoxMensagemAlerta: any;
  onCancelMensagemApresentacao: any;
  onCancelMensagemAlerta: any;
  onCancelNotaExplicativaCartaCorrecao: any;
  showMessageBoxNotaExplicativaCartaCorrecao: any;
  onSaveMessageBoxMensagemApresentacao: any;
  onSaveMessageBoxMensagemAlerta: any;
  onSaveMessageBoxNotaExplicativaCartaCorrecao: any;
  onSelectLogomarca: any;
  loadLogomarca: any;
};

const DadosGeraisForm: React.FC<Props> = ({
  dadosGerais,
  tipoImagemLogomarca,
  onChangeParametros,
  onToggleDadosGerais,
  showFormDadosGerais,
  onToggleMessageBoxMensagemApresetacao,
  onToggleMessageBoxMensagemAlerta,
  onToggleMessageBoxNotaExplicativaCartaCorrecao,
  showMessageBoxMensagemApresentacao,
  showMessageBoxMensagemAlerta,
  onCancelMensagemApresentacao,
  onCancelMensagemAlerta,
  onCancelNotaExplicativaCartaCorrecao,
  showMessageBoxNotaExplicativaCartaCorrecao,
  onSaveMessageBoxMensagemApresentacao,
  onSaveMessageBoxMensagemAlerta,
  onSaveMessageBoxNotaExplicativaCartaCorrecao,
  onSelectLogomarca,
  loadLogomarca
}) => {
  const reactQuillRef = useRef<ReactQuill>(null);
  return (
    <>
      <SectionTitle marginTop="0" hasButton={true}>
        Dados Gerais
        <button data-test-id="button" onClick={onToggleDadosGerais}>
          <i
            className={
              showFormDadosGerais ? 'fa fa-chevron-up' : 'fa fa-chevron-down'
            }
          />
        </button>
      </SectionTitle>
      {showFormDadosGerais ? (
        <div className="form">
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">Nome da Prefeitura</label>
                <input
                  type="text"
                  name="NOMEPREFEITURA"
                  key="NOMEPREFEITURA"
                  value={dadosGerais.NOMEPREFEITURA.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">Nome da Secretaria da Fazenda</label>
                <input
                  type="text"
                  name="NOMESECRETARIAFAZENDA"
                  key="NOMESECRETARIAFAZENDA"
                  value={dadosGerais.NOMESECRETARIAFAZENDA.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">Nome Setor Tributário</label>
                <input
                  type="text"
                  name="NOMESETORTRIBUTARIO"
                  key="NOMESETORTRIBUTARIO"
                  value={dadosGerais.NOMESETORTRIBUTARIO.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">Logomarca</label>
                <AutoComplete
                  data-test-id="logomarca"
                  name="TIPOIMAGEMLOGOMARCA"
                  className="form-group"
                  value={tipoImagemLogomarca}
                  onSearch={loadLogomarca}
                  onSelect={onSelectLogomarca}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">Email Setor Tributário</label>
                <input
                  type="text"
                  name="EMAIL_TRIBUTARIO"
                  key="EMAIL_TRIBUTARIO"
                  value={dadosGerais.EMAIL_TRIBUTARIO.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">Telefone Setor Tributário</label>
                <MaskedInput
                  type="text"
                  name="TELEFONE_TRIBUTARIO"
                  key="TELEFONE_TRIBUTARIO"
                  onChange={onChangeParametros}
                  mask={Masks.MASK_TELEFONE}
                  defaultValue={dadosGerais.TELEFONE_TRIBUTARIO.valor || ''}
                  maxLength={17}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">
                  URL Web Service AISE
                  <Hint classes="inline clean module-color top-left fa-exclamation-circle mobile xs">
                    Apache 2.4 (Débitos, Boletos)
                  </Hint>
                </label>
                <input
                  type="text"
                  name="URLWEBSERVICE"
                  key="URLWEBSERVICE"
                  value={dadosGerais.URLWEBSERVICE.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">Contribuinte ativo temporariamente</label>
                <Hint classes="inline clean module-color top-left fa-exclamation-circle mobile xs">
                  Quantidade de dias
                </Hint>
                <InputInteger
                  name="DIASCONTRIBUINTEATIVOTEMP"
                  key="DIASCONTRIBUINTEATIVOTEMP"
                  value={dadosGerais.DIASCONTRIBUINTEATIVOTEMP.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">
                  Quantidade de dias para o aviso do vencimento do certificado
                </label>
                <Hint classes="inline clean module-color top-right fa-exclamation-circle mobile xs">
                  Quantidade de dias para o aviso do vencimento do certificado
                </Hint>
                <InputInteger
                  name="DIASAVISOVALIDADECERTIFICADO"
                  key="DIASAVISOVALIDADECERTIFICADO"
                  value={dadosGerais.DIASAVISOVALIDADECERTIFICADO.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">Mensagem de apresentação</label>
                <input
                  value={dadosGerais.TEXTOAPRESENTACAO.valor || ''}
                  name="TEXTOAPRESENTACAO"
                  key="TEXTOAPRESENTACAO"
                  type="text"
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
              {showMessageBoxMensagemApresentacao ? (
                <MessageBox
                  value={dadosGerais.TEXTOAPRESENTACAO.valor || ''}
                  name="TEXTOAPRESENTACAO"
                  onCancel={onCancelMensagemApresentacao}
                  onSave={onSaveMessageBoxMensagemApresentacao}
                  onChange={onChangeParametros}
                />
              ) : (
                ''
              )}
            </Col>
            <Col md={2}>
              <label htmlFor="" className="label" />
              <Button
                data-test-id="button-message-apresentacao"
                className="mt-xs"
                iconPosition="left"
                color="module-color"
                onClick={onToggleMessageBoxMensagemApresetacao}
              >
                <i className="fa fa-envelope" />
                Mensagem
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Checkbox
                className="form-group"
                name="EXIBEAVISO"
                id="EXIBEAVISO"
                checked={dadosGerais.EXIBEAVISO.valor === 'S'}
                onChange={onChangeParametros}
                label="Deseja exibir mensagem alerta"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">Mensagem de alerta</label>
                <input
                  name="TEXTOALERTA"
                  value={dadosGerais.TEXTOALERTA.valor || ''}
                  type="text"
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
              {showMessageBoxMensagemAlerta ? (
                <>
                  <br />
                  <ReactQuill
                    theme={'snow'}
                    ref={reactQuillRef}
                    modules={reactQuillModules}
                    formats={reactQuillFormats}
                    value={dadosGerais.TEXTOALERTA.valor}
                    onChange={(any, Delta, Sources, editor) => {
                      dadosGerais.TEXTOALERTA.valor = editor.getHTML();
                    }}
                  />
                  <div className="form-group">
                    <Button
                      data-test-id="button-save"
                      className="inline"
                      color="module-color"
                      onClick={onSaveMessageBoxMensagemAlerta}
                      iconPosition="left"
                    >
                      <i className="fa fa-check" />
                      Salvar
                    </Button>
                    <Button
                      data-test-id="button-cancel"
                      className="inline"
                      color="neutral"
                      onClick={onCancelMensagemAlerta}
                      iconPosition="left"
                    >
                      <i className="fa fa-times" />
                      Cancelar
                    </Button>
                  </div>
                </>
              ) : (
                ''
              )}
            </Col>
            <Col md={2}>
              <label htmlFor="" className="label" />
              <Button
                data-test-id="button-mensage-alerta"
                className="mt-xs"
                iconPosition="left"
                color="module-color"
                onClick={onToggleMessageBoxMensagemAlerta}
              >
                <i className="fa fa-envelope" />
                Mensagem
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">Nota explicativa carta de correção</label>
                <input
                  name="NOTAEXPLICATIVACARTACORRECAO"
                  value={dadosGerais.NOTAEXPLICATIVACARTACORRECAO.valor || ''}
                  onChange={onChangeParametros}
                  type="text"
                />
              </div>
              {showMessageBoxNotaExplicativaCartaCorrecao ? (
                <MessageBox
                  value={dadosGerais.NOTAEXPLICATIVACARTACORRECAO.valor || ''}
                  name="NOTAEXPLICATIVACARTACORRECAO"
                  onCancel={onCancelNotaExplicativaCartaCorrecao}
                  onSave={onSaveMessageBoxNotaExplicativaCartaCorrecao}
                  onChange={onChangeParametros}
                />
              ) : (
                ''
              )}
            </Col>
            <Col md={2}>
              <label htmlFor="" className="label" />
              <Button
                data-test-id="button-nota-explicativa"
                className="mt-xs"
                iconPosition="left"
                color="module-color"
                onClick={onToggleMessageBoxNotaExplicativaCartaCorrecao}
              >
                <i className="fa fa-envelope" />
                Mensagem
              </Button>
            </Col>
          </Row>
          <SectionTitle>Servidor Único</SectionTitle>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">URL do servidor</label>
                <input
                  name="URLSERVIDORUNICO"
                  value={dadosGerais.URLSERVIDORUNICO.valor || ''}
                  onChange={onChangeParametros}
                  key="URLSERVIDORUNICO"
                  type="text"
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label htmlFor="">Tempo limite de conexão (ms)</label>
                <Hint
                  classes={`inline clean module-color top-left fa-exclamation-circle mobile xs`}
                >
                  (ms) Milissegundos
                </Hint>
                <InputInteger
                  name="CONNECTIONTIMEOUTUNICO"
                  key="CONNECTIONTIMEOUTUNICO"
                  value={dadosGerais.CONNECTIONTIMEOUTUNICO.valor || ''}
                  onChange={onChangeParametros}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label htmlFor="">Tempo limite de resposta (ms)</label>
                <Hint
                  classes={`inline clean module-color top-right fa-exclamation-circle mobile xs`}
                >
                  (ms) Milissegundos
                </Hint>
                <InputInteger
                  name="SOCKETTIMEOUTSERVIDORUNICO"
                  key="SOCKETTIMEOUTSERVIDORUNICO"
                  value={dadosGerais.SOCKETTIMEOUTSERVIDORUNICO.valor || ''}
                  onChange={onChangeParametros}
                />
              </div>
            </Col>
            <Col md={6}>
              <Checkbox
                className="form-group"
                name="ISONPREMISEUNICO"
                id="ISONPREMISEUNICO"
                checked={dadosGerais.ISONPREMISEUNICO?.valor === 'S'}
                onChange={onChangeParametros}
                label="Único roda instalado no servidor do cliente (on-Premise)"
              />
            </Col>
          </Row>
          <SectionTitle>Servidor de Licenças</SectionTitle>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">URL do servidor</label>
                <input
                  name="URLSERVIDORLICENCA"
                  value={dadosGerais.URLSERVIDORLICENCA.valor || ''}
                  onChange={onChangeParametros}
                  key="URLSERVIDORLICENCA"
                  type="text"
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label htmlFor="">Tempo limite de conexão (ms)</label>
                <Hint
                  classes={`inline clean module-color top-left fa-exclamation-circle mobile xs`}
                >
                  (ms) Milissegundos
                </Hint>
                <InputInteger
                  name="CONNECTIONTIMEOUTLICENCA"
                  key="CONNECTIONTIMEOUTLICENCA"
                  value={dadosGerais.CONNECTIONTIMEOUTLICENCA.valor || ''}
                  onChange={onChangeParametros}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label htmlFor="">Tempo limite de resposta (ms)</label>
                <Hint
                  classes={`inline clean module-color top-right fa-exclamation-circle mobile xs`}
                >
                  (ms) Milissegundos
                </Hint>
                <InputInteger
                  name="SOCKETTIMEOUTSERVIDORLICENCA"
                  key="SOCKETTIMEOUTSERVIDORLICENCA"
                  value={dadosGerais.SOCKETTIMEOUTSERVIDORLICENCA.valor || ''}
                  onChange={onChangeParametros}
                />
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default DadosGeraisForm;
