import { Header, Icon } from '@elotech/components';
import React, { useContext } from 'react';

import { ControleIntegracao } from '../types/ControleIntegracao';
import { BadgeValuesConsumer } from './BadgeValuesProvider';

export const FEATURE_NAME = 'controleIntegracao';

const getDescricaoStatus = (status: ControleIntegracao) => {
  if (!status?.status) {
    return '';
  }

  switch (status?.status) {
    case 'ATIVO':
      return 'Integração online';
    case 'ATRASADO':
      return 'Integração com atraso';
    case 'PARADO':
      return 'Integração OFFLINE!';
    case 'ERRO':
      return 'Integração com erros!';
    default:
      return '';
  }
};

export const ControleIntegracaoControl: React.FC = () => {
  const { toggleFeature } = useContext(Header.HeaderContext);

  return (
    <BadgeValuesConsumer>
      {({ getBadgeValue }) => {
        const status: ControleIntegracao = getBadgeValue('controleIntegracao');

        return (
          <li
            data-test-id="status"
            title={getDescricaoStatus(status)}
            onClick={() => toggleFeature(FEATURE_NAME)}
          >
            <Icon
              data-test-id="icon"
              icon="cloud-upload-alt"
              color={
                status?.status === 'PARADO'
                  ? '#df382c'
                  : status?.status === 'ERRO'
                  ? '#d39e00'
                  : status?.status === 'ATIVO' || status?.status === 'ATRASADO'
                  ? '#348c30'
                  : undefined
              }
            />
            <small className="hidden-xs">Integração</small>
          </li>
        );
      }}
    </BadgeValuesConsumer>
  );
};
