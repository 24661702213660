import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const loadReport = (search: any) =>
  axios.post(`${CONTEXT_PATH}/relatorio-limite-faturamento-mei/`, search, {
    responseType: 'blob'
  });

export default {
  loadReport
};
