import {
  AutoComplete,
  Checkbox,
  Col,
  ErrorText,
  Hint,
  InputInteger,
  TextArea
} from '@elotech/components';
import React from 'react';

type TipoParametro =
  | 'text'
  | 'checkbox'
  | 'autocomplete'
  | 'select'
  | 'integer'
  | 'textarea';

type ParametroProps<T> = {
  id?: string;
  type: TipoParametro;
  className?: string;
  name: string;
  value?: any;
  size?: number;
  label?: string;
  error?: boolean;
  errorMessage?: string;
  placeHolder?: string;
  isDisabled?: boolean;
  onSearch?: any;
  onChange?: (event: React.ChangeEvent<any>) => void;
  onSelect?: any;
  htmlFor?: any;
  hintClass?: string;
  hintIcon?: string;
  hintText?: string;
};

const Parametro = <T extends object>({
  type = 'text',
  className,
  name,
  value,
  size = 2,
  label,
  error,
  placeHolder,
  errorMessage,
  onSearch,
  isDisabled = false,
  hintClass = 'top-left',
  hintIcon = 'exclamation',
  hintText,
  htmlFor,
  id,
  ...props
}: ParametroProps<T>) => {
  const renderComponent = (type: TipoParametro) => {
    if (type === 'checkbox') {
      return (
        <Checkbox
          name={name}
          className="form-group"
          checked={value}
          id={id}
          label={label}
          onChange={props.onChange}
          {...props}
        />
      );
    } else if (type === 'autocomplete')
      return (
        <React.Fragment>
          <AutoComplete
            data-test-id="autocomplete"
            name={name}
            className={`form-group ${error ? 'error' : ''}`}
            placeholder={placeHolder}
            value={!value && value === undefined ? '' : value}
            onSearch={onSearch}
            isDisabled={isDisabled}
            onSelect={props.onSelect}
          />
          {error && <ErrorText>{errorMessage}</ErrorText>}
        </React.Fragment>
      );
    else if (type === 'select') {
      return (
        <React.Fragment>
          <select
            data-test-id="selectField"
            name="filter-fields"
            style={{ height: 28 }}
            onChange={props.onChange}
          >
            {value.map((option: any, index: number) => (
              <option key={index} value={option.name}>
                {option.label}
              </option>
            ))}
          </select>
        </React.Fragment>
      );
    } else if (type === 'integer') {
      return (
        <React.Fragment>
          <InputInteger
            className={className}
            name={name}
            value={value || ''}
            placeholder={placeHolder}
            onChange={props.onChange}
            autoComplete="off"
            {...props}
          />
        </React.Fragment>
      );
    } else if (type === 'textarea') {
      return (
        <React.Fragment>
          <TextArea
            className={className}
            name={name}
            value={value || ''}
            placeholder={placeHolder}
            onChange={props.onChange}
            {...props}
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <input
            type={type}
            className={className}
            name={name}
            value={value || ''}
            placeholder={placeHolder}
            onChange={props.onChange}
            autoComplete="off"
          />
        </React.Fragment>
      );
    }
  };

  return (
    <div className="form-group">
      <Col md={size}>
        {type !== 'checkbox' ? <label htmlFor={htmlFor}>{label}</label> : ''}
        {hintText ? (
          <Hint
            classes={`inline clean module-color ${hintClass} fa-${hintIcon}-circle mobile xs`}
          >
            {hintText}
          </Hint>
        ) : (
          ''
        )}

        {renderComponent(type)}
      </Col>
    </div>
  );
};

export default Parametro;
