import { Col, ErrorText, Row } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const errorMessages = {
  size: 'Tamanho do arquivo inválido.',
  type: 'Somente arquivo .pdf.'
};

const Arquivo = ({
  disabled,
  arquivo,
  tiposImagens,
  errorArquivo,
  onUploadFile,
  onChangeTipoImagem
}) => {
  return (
    <Fragment>
      <Row>
        <Col md={4}>
          <div className="form-group">
            <label htmlFor="tipoIncentivo">Tipo de Imagem</label>
            <select
              disabled={disabled}
              id="tipoImagem"
              name="tipoImagem"
              value={arquivo.tipoImagem}
              onChange={onChangeTipoImagem}
            >
              {tiposImagens &&
                tiposImagens.map(tipo => (
                  <option key={tipo.id} value={tipo.id}>
                    {tipo.descricao}
                  </option>
                ))}
            </select>
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <label forHtml="arquivo">Arquivo</label>
            <div className="file-uploader">
              <input
                disabled={disabled}
                name="arquivoBase64"
                type="file"
                id="upload-file-input"
                className={`file-uploader-input ${
                  errorArquivo.size || errorArquivo.type ? 'error' : ''
                }`}
                title="Clique ou arraste para anexar"
                onChange={onUploadFile}
              />
              <label
                for="upload-file-input"
                className="input"
                data-title={
                  arquivo.nomeDocumento && arquivo.nomeDocumento.length > 0
                    ? arquivo.nomeDocumento
                    : 'Clique ou arraste para anexar'
                }
              />
              <label for="upload-file-input" className="file-uploader-icon" />
            </div>
            {errorArquivo.size && <ErrorText>{errorMessages.size}</ErrorText>}
            {errorArquivo.type && <ErrorText>{errorMessages.type}</ErrorText>}
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

Arquivo.propTypes = {
  arquivo: PropTypes.object.isRequired,
  onUploadFile: PropTypes.func.isRequired
};
export default Arquivo;
