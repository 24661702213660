import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import CryptoJS from 'crypto-js';

import { CONTEXT_PATH } from './contextPath';

export const URL_VISUALIZAR_NFSE = `${CONTEXT_PATH}/cancelamento-nota-fiscal/url-imprimir-nota`;

const headers: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/pdf'
  }
};

const getUrlImprimirNota = (): AxiosPromise<string> =>
  axios.get(`${CONTEXT_PATH}/cancelamento-nota-fiscal/url-imprimir-nota`);

export const visualizarNotaFiscal = async (idNotaFiscal: number) => {
  const response = await getUrlImprimirNota();

  const chave = `{"id":${idNotaFiscal}}`;

  let b64 = CryptoJS.AES.encrypt(chave.toString(), '3l0tech#').toString();
  let e64 = CryptoJS.enc.Base64.parse(b64);
  let path = '';

  const url = response.data.replace(/\/+$/gm, '');

  if (!url.includes('.iss.elotech.com.br') && !url.includes('localhost')) {
    path = '/iss';
  }

  window.open(
    `${url}${path}/show-pdf/imprimir-nota-fiscal-pdf/${e64.toString(
      CryptoJS.enc.Hex
    )}`
  );
};

const carregaNfseEmitidas = (search: any) =>
  axios.post(`${CONTEXT_PATH}/relatorio-nota-fiscal/nfse-emitidas/`, search, {
    responseType: 'blob'
  });

const viewPdfNfseCancelamento = (idNotaFiscal: number): AxiosPromise<any> =>
  axios.post(
    `${CONTEXT_PATH}/cancelamento-nota-fiscal/pdf-nfse-cancelamento/${idNotaFiscal}`,
    {},
    {
      headers,
      responseType: 'blob'
    }
  );

const viewPdfNfseSubstituicao = (idNotaFiscal: number): AxiosPromise<any> =>
  axios.post(
    `${CONTEXT_PATH}/substituicao-nota-fiscal/pdf-nfse-substituicao/${idNotaFiscal}`,
    {},
    {
      headers,
      responseType: 'blob'
    }
  );

export default {
  carregaNfseEmitidas,
  viewPdfNfseCancelamento,
  viewPdfNfseSubstituicao
};
