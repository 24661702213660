export const JobStatus = {
  COMPLETED: 'Finalizado',
  STARTING: 'Iniciando',
  STARTED: 'Processando',
  STOPPING: 'Parando',
  STOPPED: 'Parado',
  FAILED: 'Erro',
  ABANDONED: 'Abandonado',
  UNKNOWN: 'Desconhecido'
};

export type JobStatusValue = keyof typeof JobStatus;
