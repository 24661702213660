import {
  Alert,
  Container,
  FAB,
  Hint,
  Loading,
  SearchFilter
} from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import swal from 'sweetalert2';

import { IncentivoFiscalService, withService } from '../../../service';
import IncentivosFiscaisList from './IncentivosFiscaisList';

export class IncentivosFiscaisListPage extends Component {
  static propTypes = {
    incentivoFiscalService: PropTypes.object.isRequired
  };

  state = {
    incentivosFiscais: {},
    loading: false,
    pageConsulta: 0
  };

  fields = [
    {
      label: 'Código',
      name: 'id',
      type: 'NUMBER'
    },
    {
      label: 'Descrição',
      name: 'descricao',
      type: 'STRING'
    },
    {
      label: 'Insc. Municipal',
      name: 'cadastroGeral.cadastroGeral',
      type: 'NUMBER'
    },
    {
      label: 'Cnpj',
      name: 'cadastroGeral.pessoa.cnpjCpf',
      type: 'STRING'
    },
    {
      label: 'Desc. Lei',
      name: 'lei.descricao',
      type: 'STRING'
    }
  ];

  searchWithPage = page => {
    this.setState({ pageConsulta: page });
    this.serviceSearch(this.state.searchParams, page);
  };

  serviceSearch = (searchParams, page) => {
    this.setState({
      loading: true,
      searchParams
    });

    this.props.incentivoFiscalService
      .loadIncentivosFiscais(searchParams, page)
      .then(this.getIncentivosFiscaisSuccess)
      .catch(this.getIncentivosFiscaisError);
  };

  getIncentivosFiscaisSuccess = response => {
    this.setState({
      incentivosFiscais: response.data,
      loading: false
    });
  };

  getIncentivosFiscaisError = error => {
    this.setState({ loading: false });
    Alert.error(
      { title: 'Não foi possível carregar os incentivos fiscais.' },
      error
    );
  };

  new = () => {
    this.props.history.push('/acessos-e-permissoes/incentivos-fiscais/novo');
  };

  edit = id => {
    this.props.history.push(
      `/acessos-e-permissoes/incentivos-fiscais/editar/${id}`
    );
  };

  onDeleteSuccess = success => {
    this.setState({ loading: false });
    swal('Excluido', 'Incentivo fiscal excluido!', 'success');

    this.searchWithPage(this.state.pageConsulta);
  };

  onDeleteErrror = error => {
    this.setState({ loading: false });
    Alert.error(
      { title: 'Não foi possível excluir o incentivo fiscal.' },
      error
    );
  };

  exclude = id =>
    swal({
      title: 'Excluir?',
      text: 'Deseja excluir o incentivo fiscal?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim'
    }).then(result => {
      if (result.value) {
        this.setState({ loading: true });
        this.props.incentivoFiscalService
          .removerIncentivoFiscal(id)
          .then(this.onDeleteSuccess)
          .catch(this.onDeleteErrror);
        swal('Excluido', '', 'success');
      }
    });

  render() {
    const { loading, incentivosFiscais } = this.state;
    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-incentivo-fiscal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        <div className="panel table table-responsive">
          <div className="panel-body">
            <SearchFilter fields={this.fields} search={this.serviceSearch} />
            <IncentivosFiscaisList
              incentivosFiscais={incentivosFiscais}
              searchWithPage={this.searchWithPage}
              edit={this.edit}
              exclude={this.exclude}
            />
          </div>
        </div>
        <div className="btn-save">
          <FAB
            icon="plus"
            iconColor="white"
            title="Adicionar Novo"
            onClick={this.new}
          />
        </div>
      </Container>
    );
  }
}

const ComponentWithService = withService({
  incentivoFiscalService: IncentivoFiscalService
})(IncentivosFiscaisListPage);

export default ComponentWithService;
