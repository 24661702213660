import { FormattedDateTime, Icon } from '@elotech/components';
import React from 'react';

import { RpsManual } from '../../../types/SolicitacaoRPS';
import { InfoBlock } from './InfoBlock';
import {
  CANCELADO,
  LIBERADO,
  UTILIZADO,
  getLabelSituacaoRpsManual
} from './SolicitacaoRpsTipos';

type Props = {
  rpsManual: RpsManual;
};

const SituacaoRpsManual: React.FC<Props> = ({ rpsManual }) => (
  <InfoBlock>
    <div style={{ marginRight: '5px' }}>
      {rpsManual.situacao === LIBERADO && <Icon icon="clock" warning />}
      {rpsManual.situacao === UTILIZADO && <Icon icon="thumbs-up" positive />}
      {rpsManual.situacao === CANCELADO && (
        <Icon icon="thumbs-down" danger />
      )}{' '}
    </div>
    <div>
      {getLabelSituacaoRpsManual(rpsManual.situacao)}
      {rpsManual.situacao === CANCELADO && (
        <div>
          Por {rpsManual.acessoWeb!.nome} em{' '}
          <FormattedDateTime value={rpsManual.data} />
          <div>Motivo: {rpsManual.motivo}</div>
        </div>
      )}
      {rpsManual.situacao === UTILIZADO && (
        <div>
          NFS-e {rpsManual.notaFiscal!.numeroNotaFiscal} {' de '}
          <FormattedDateTime value={rpsManual.notaFiscal!.dataEmissao} />
        </div>
      )}
    </div>
  </InfoBlock>
);

export default SituacaoRpsManual;
