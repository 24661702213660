import { ActionButton, ActionsGroup, DisplayData } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const ObrasListRow = ({ obras, index, onEditObra, onRemoveObra }) => {
  return (
    <div key={index}>
      <div className="display-data border small mt-xs has-btn-actions">
        <div className="row" tabIndex="0" autoFocus>
          <div className="col-sm-3">
            <DisplayData title="Código">{obras.codigoObra}</DisplayData>
          </div>
          <div className="col-sm-2">
            <DisplayData title="ART">{obras.art}</DisplayData>
          </div>
          <ActionsGroup>
            <Fragment>
              <ActionButton
                data-test-id={`buttonEdit-${index}`}
                key="edit-button"
                icon="pencil-alt"
                label="Editar"
                onClick={() => onEditObra(index)}
              />
              <ActionButton
                data-test-id={`buttonDelete-${index}`}
                key="delete-button"
                icon="trash-alt"
                label="Excluir"
                onClick={() => onRemoveObra(index)}
              />
            </Fragment>
          </ActionsGroup>
        </div>
      </div>
    </div>
  );
};

ObrasListRow.propTypes = {
  obras: PropTypes.object.isRequired,
  onEditObra: PropTypes.func.isRequired,
  onRemoveObra: PropTypes.func.isRequired
};
export default ObrasListRow;
