import { PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import {
  RpsManual,
  SituacaoAtualSolicitante,
  SolicitacaoRPS
} from '../types/SolicitacaoRPS';
import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'data,desc' };

const search = (params: string) => (params ? `search=${params}` : '');

export const loadSolicitacoes = (
  searchParams: string,
  page?: any
): AxiosPromise<PagedResponse<SolicitacaoRPS>> =>
  axios.get(`${CONTEXT_PATH}/solicitacoes-rps?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const loadSolicitacao = (id: number): AxiosPromise<SolicitacaoRPS> =>
  axios.get(`${CONTEXT_PATH}/solicitacoes-rps/${id}`);

export const loadRpsManual = (
  searchParams: string,
  page?: any
): AxiosPromise<PagedResponse<RpsManual>> =>
  axios.get(`${CONTEXT_PATH}/rps-manual?${search(searchParams)}`, {
    params: { sort: 'numeroRps,desc', ...page }
  });

export const situacaoAtualSolicitante = (
  id: number
): AxiosPromise<SituacaoAtualSolicitante> =>
  axios.get(`${CONTEXT_PATH}/rps-manual/situacao-atual-solicitante/${id}`);

export const aprovar = (
  id: number,
  usuario: string
): AxiosPromise<SolicitacaoRPS> =>
  axios.post(`${CONTEXT_PATH}/solicitacoes-rps/aprovar/${id}`, undefined, {
    headers: {
      usuario
    }
  });

export const rejeitar = (
  id: number,
  usuario: string,
  justificativa: string
): AxiosPromise<SolicitacaoRPS> =>
  axios.post(`${CONTEXT_PATH}/solicitacoes-rps/rejeitar/${id}`, undefined, {
    headers: {
      usuario,
      justificativa
    }
  });
