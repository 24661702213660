import { FilterUtils } from '@elotech/components';
import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = {
  sort: 'anoCompetencia,mesCompetencia,id,desc'
};

const search = params => (params ? `search=${params}` : '');

export const carregarConfiguracaoVencimentosDMS = (searchParams, page) =>
  axios.get(
    `${CONTEXT_PATH}/dms-vencimento-divida-config?${search(searchParams)}`,
    {
      params: { ...defaultSort, ...page }
    }
  );

export const removerConfiguracaoVencimentoDMS = id =>
  axios.delete(`${CONTEXT_PATH}/dms-vencimento-divida-config/remover/${id}`);

export const save = divida => {
  if (divida.id > 0) {
    return axios.put(
      `${CONTEXT_PATH}/dms-vencimento-divida-config/${divida.id}`,
      divida
    );
  }
  return axios.post(`${CONTEXT_PATH}/dms-vencimento-divida-config`, divida);
};

export const carregarConfigVencimentoPorID = id =>
  axios.get(`${CONTEXT_PATH}/dms-vencimento-divida-config/${id}`);

export const loadGuiasRecolhimento = (
  searchParams,
  exercicio,
  fields = 'descricao, exercicio'
) =>
  axios.get(
    `${CONTEXT_PATH}/dms-vencimento-divida-config/guias-recolhimento?search=${FilterUtils.buildDefaultFilter()(
      searchParams
    )} and exercicio==${exercicio}`,
    undefined,
    fields
  );
