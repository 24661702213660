import { FilterUtils, PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { DeclaracaoGuiaAvulso } from '../types/DeclaracaoGuiaAvulso';
import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id,desc' };
const search = (params: any) => (params ? `search=${params}` : '');
export const loadDeclaracoes = (
  searchParams: string,
  page?: any
): AxiosPromise<PagedResponse<DeclaracaoGuiaAvulso>> =>
  axios.get(`${CONTEXT_PATH}/guia-eventual?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const acessoWebAutoComplete = (
  searchParams: string
): AxiosPromise<PagedResponse<any>> =>
  axios.get(`${CONTEXT_PATH}/guia-eventual/acessoWebAutoComplete`, {
    params: {
      search: FilterUtils.buildNameFilter('id', 'nome')(searchParams),
      fields: 'nome'
    }
  });

export const deletar = (id?: number): AxiosPromise<DeclaracaoGuiaAvulso> =>
  axios.delete(`${CONTEXT_PATH}/${id}`);

export const download = (id?: number) =>
  axios.get(`${CONTEXT_PATH}/guia-eventual/${id}/download-anexo`, {
    responseType: 'blob'
  });

export const findById = (
  id?: number | string
): AxiosPromise<DeclaracaoGuiaAvulso> =>
  axios.get(`${CONTEXT_PATH}/guia-eventual/${id}`);
