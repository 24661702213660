import {
  Col,
  ErrorText,
  Row,
  SectionTitle,
  formatUtils
} from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const errorMessages = {
  razaoSocial: 'Campo obrigatório!.',
  nomeFantasia: 'Campo obrigatório!.'
};

const DadosEmpresariais = ({ disabled, historicoAgendamento, error }) => {
  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <div className="form-group">
            <label htmlFor="de">Razão Social</label>
            <input
              readOnly
              disabled={disabled}
              className={error.razaoSocial ? 'error' : ''}
              type="text"
              name="razaoSocial"
              defaultValue={historicoAgendamento.razaoSocial}
            />
            {error.razaoSocial && (
              <ErrorText>{errorMessages.razaoSocial}</ErrorText>
            )}
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <label htmlFor="de">Nome Fantasia</label>
            <input
              readOnly
              disabled={disabled}
              className={error.nomeFantasia ? 'error' : ''}
              type="text"
              name="nomeFantasia"
              defaultValue={historicoAgendamento.nomeFantasia}
            />
            {error.nomeFantasia && (
              <ErrorText>{errorMessages.nomeFantasia}</ErrorText>
            )}
          </div>
        </Col>
      </Row>
      <br />
      <div className="display-data border small mb-xs">
        <Row>
          <Col md={3}>
            <b className="dd-title">Tipo Pessoa</b>
            <div className="dd-content" id="tipoPessoa">
              {historicoAgendamento.tipoPessoa}
            </div>
          </Col>
          <Col md={3}>
            <b className="dd-title">Insc Municipal</b>
            <div className="dd-content" id="inscMunicipal">
              {historicoAgendamento.inscricaoMunicipal}
            </div>
          </Col>
          <Col md={3}>
            <b className="dd-title">Insc Estadual</b>
            <div className="dd-content" id="inscEstadual">
              {historicoAgendamento.inscricaoEstadual}
            </div>
          </Col>
          <Col md={3}>
            <b className="dd-title">CPF/CNPJ</b>
            <div className="dd-content" id="cnpj">
              {formatUtils.formatCpfCnpj(historicoAgendamento.cnpjCpf)}
            </div>
          </Col>
        </Row>
      </div>

      {historicoAgendamento.febraban && historicoAgendamento.febraban.id && (
        <div className="display-data border small mb-xs">
          <Row>
            <Col md={3}>
              <b className="dd-title">Código Febraban</b>
              <div className="dd-content" id="febrabanCodigoCompensacao">
                {historicoAgendamento.febraban.codigoCompensacao}
              </div>
            </Col>
            <Col md={5}>
              <b className="dd-title">Descrição Febraban</b>
              <div className="dd-content" id="inscMunicipal">
                {historicoAgendamento.febraban.nomeInstituicao}
              </div>
            </Col>
          </Row>
        </div>
      )}

      <SectionTitle>Auditor</SectionTitle>
      <div className="display-data border small mb-xs">
        <Row>
          <Col md={6}>
            <b className="dd-title">Nome</b>
            <div className="dd-content" id="inscEstadual">
              {historicoAgendamento.nomeAuditor}
            </div>
          </Col>
          <Col md={6}>
            <b className="dd-title">CPF</b>
            <div className="dd-content" id="inscEstadual">
              {historicoAgendamento.cpfAuditor}
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

DadosEmpresariais.propTypes = {
  historicoAgendamento: PropTypes.object.isRequired
};

export default DadosEmpresariais;
