import { Yup } from '@elotech/components';

export const validationSchema = Yup.object().shape({
  codigoCompensacao: Yup.string()
    .required()
    .min(3, 'Cod. Compensação deve conter no mínimo 3 dígitos.')
    .max(5, 'Cod. Compensação deve conter no máximo 5 dígitos.')
    .label('Cod. Compensação'),

  nomeInstituicao: Yup.string()
    .required()
    .max(60)
    .label('Instituição')
});
