import { ActionButton, SearchPagination } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const DividaDmsList = ({
  configuracaoDivida,
  edit,
  exclude,
  searchWithPage
}) => {
  return (
    <>
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>Exercício</th>
              <th>Tipo Declaração</th>
              <th style={{ textAlign: 'center' }}>Tipo Movimento</th>
              <th>Guia Recolhimento</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {configuracaoDivida &&
              configuracaoDivida.content &&
              configuracaoDivida.content.map(configDivida => {
                return (
                  <tr key={configDivida.id}>
                    <td style={{ textAlign: 'center' }}>
                      {configDivida.exercicio}
                    </td>
                    <td>
                      {configDivida.tipoDeclaracao === 'S'
                        ? 'Sem Movimento'
                        : ''}
                      {configDivida.tipoDeclaracao === 'M'
                        ? 'Retificadora Sem Movimento'
                        : ''}
                      {configDivida.tipoDeclaracao === 'N' ? 'Normal' : ''}
                      {configDivida.tipoDeclaracao === 'A'
                        ? 'Declaração Avulsa'
                        : ''}
                      {configDivida.tipoDeclaracao === 'F'
                        ? 'Fiscalização Iss'
                        : ''}
                      {configDivida.tipoDeclaracao === 'R'
                        ? 'Retificadora'
                        : ''}
                      {configDivida.tipoDeclaracao === 'C'
                        ? 'Construção Civil'
                        : ''}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {configDivida.tipoMovimento === 'T' ? 'Tomador' : ''}
                      {configDivida.tipoMovimento === 'P' ? 'Prestador' : ''}
                    </td>
                    <td>
                      {configDivida.guiaRecolhimento.guiaRecolhimento +
                        ' - ' +
                        configDivida.guiaRecolhimento.descricao}
                    </td>
                    <td>
                      <div className="btn-actions">
                        <div className="btn-actions-inner">
                          <ActionButton
                            data-test-id={`buttonEdit${configDivida.id}`}
                            key="edit-button"
                            icon="pencil-alt"
                            label="Editar"
                            onClick={() => edit(configDivida.id)}
                          />
                          <ActionButton
                            data-test-id={`buttonDelete${configDivida.id}`}
                            key="delete-button"
                            icon="trash-alt"
                            label="Excluir"
                            onClick={() => exclude(configDivida.id)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            {(!configuracaoDivida ||
              configuracaoDivida.totalElements === 0) && (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan="7">
                  Sem registros
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {configuracaoDivida && (
        <SearchPagination
          page={configuracaoDivida}
          searchWithPage={searchWithPage}
        />
      )}
    </>
  );
};

DividaDmsList.propTypes = {
  configuracaoDivida: PropTypes.object.isRequired,
  edit: PropTypes.func,
  exclude: PropTypes.func,
  searchWithPage: PropTypes.func
};

export default DividaDmsList;
