import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id,nome' };

const search = params => (params ? `search=${params}` : '');

export const loadAcessosWeb = (searchParams, page) =>
  axios.get(`${CONTEXT_PATH}/acessoweb?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const bloquearOrDesbloquear = id =>
  axios.patch(`${CONTEXT_PATH}/acessoweb/bloquear-desbloquear/${id}`);

export const loadAcessoWebById = id =>
  axios.get(`${CONTEXT_PATH}/acessoweb/${id}`);

export const trocarSenha = acessoWeb =>
  axios.put(
    `${CONTEXT_PATH}/acessoweb/trocar-senha/${acessoWeb.id}`,
    acessoWeb
  );

export const atualizarAcessoWeb = acessoWeb =>
  axios.put(`${CONTEXT_PATH}/acessoweb/atualizar/${acessoWeb.id}`, acessoWeb);

export const loadAcessoWebWithAcessoEmpresas = id =>
  axios.get(`${CONTEXT_PATH}/acessoweb/acesso-empresas/${id}`);

export const removerAcessoWeEmpresa = id =>
  axios.delete(`${CONTEXT_PATH}/acessoweb/remover-acesso-empresa/${id}`);
