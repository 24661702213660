import {
  BasicInput,
  Button,
  Col,
  Container,
  FormikSelect,
  Loading,
  Masks,
  Row,
  SectionTitle
} from '@elotech/components';
import { Formik } from 'formik';
import { Alert } from 'iss-common/utils';
import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';

import RelatorioLimiteFaturamentoMeiService from '../../service/RelatorioLimiteFaturamentoMeiService';

type Option = {
  label: string;
  value: string;
};

const optionTipoRelatorio: Option[] = [
  { label: 'Analítico', value: 'A' },
  { label: 'Sintético', value: 'S' }
];

const RelatorioLimiteFaturamentoMeiFormPage: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: any) => {
    setLoading(true);
    await RelatorioLimiteFaturamentoMeiService.loadReport(values)
      .then((response: any) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);
      })
      .catch((error: any) => {
        Alert.error({ title: 'Não foi possível gerar o relatório' }, error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <SectionTitle>Filtro</SectionTitle>
      <Formik
        initialValues={{
          tipoRelatorio: 'A',
          cnpj: ''
        }}
        onSubmit={onSubmit}
        render={form => (
          <>
            <Row>
              <BasicInput
                size={4}
                label="CPF/CNPJ"
                name="cnpj"
                render={({ field }) => (
                  <MaskedInput
                    {...field}
                    type="text"
                    guide={false}
                    mask={value =>
                      value.length < 15 ? Masks.MASK_CPF : Masks.MASK_CNPJ
                    }
                    maxLength={19}
                  />
                )}
              />
              <FormikSelect
                name="tipoRelatorio"
                label="Tipo Relatório"
                size={2}
                options={optionTipoRelatorio}
                getOptionLabel={(option: Option) => option.label}
                getOptionValue={(option: Option) => option.value}
              />
            </Row>
            <Row>
              <Col md={12}>
                <div className="form-group">
                  <Button onClick={form.submitForm} type="submit">
                    EMITIR RELATÓRIO
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      />
    </Container>
  );
};

export default RelatorioLimiteFaturamentoMeiFormPage;
