import { SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import ObraNewEditForm from './ObraNewEditForm';
import ObrasListRow from './ObrasListRow';

const ObrasForm = ({
  incentivoFiscal,
  obra,
  errorObra,
  showFormObra,
  onChangeObra,
  onSaveObra,
  onCancelObra,
  onNewObra,
  onEditObra,
  onRemoveObra
}) => {
  return (
    <Fragment>
      <SectionTitle hasButton={true}>
        Obras
        {!showFormObra && (
          <button
            data-test-id="buttonNovo"
            className="btn module-color"
            onClick={onNewObra}
          >
            + Novo
          </button>
        )}
      </SectionTitle>
      {showFormObra ? (
        <ObraNewEditForm
          obra={obra}
          errorObra={errorObra}
          onChangeObra={onChangeObra}
          onSaveObra={onSaveObra}
          onCancelObra={onCancelObra}
        />
      ) : (
        incentivoFiscal.obras &&
        incentivoFiscal.obras.map((obras, index) => {
          return (
            <ObrasListRow
              index={index}
              obras={obras}
              onEditObra={onEditObra}
              onRemoveObra={onRemoveObra}
            />
          );
        })
      )}
    </Fragment>
  );
};

ObrasForm.propTypes = {
  incentivoFiscal: PropTypes.object.isRequired,
  obra: PropTypes.object.isRequired,
  errorObra: PropTypes.object,
  showFormObra: PropTypes.bool,
  onChangeObra: PropTypes.func.isRequired,
  onSaveObra: PropTypes.func.isRequired,
  onCancelObra: PropTypes.func.isRequired,
  onNewObra: PropTypes.func.isRequired,
  onEditObra: PropTypes.func.isRequired,
  onRemoveObra: PropTypes.func.isRequired
};

export default ObrasForm;
