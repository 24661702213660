import { Chip } from '@elotech/components';
import React from 'react';

import { JobStatus, JobStatusValue } from '../enums';

type Props = { jobStatus?: JobStatusValue };

const getSituacaoColorChip = (
  situacaoJob?: JobStatusValue
): 'module-color' | 'negative' | 'positive' | 'warning' | 'neutral' => {
  switch (situacaoJob) {
    case 'COMPLETED':
      return 'positive';
    case 'FAILED':
    case 'STOPPED':
    case 'ABANDONED':
    case 'UNKNOWN':
      return 'negative';
    case undefined:
      return 'neutral';
    default:
      return 'module-color';
  }
};

const BatchStatusChip: React.FC<Props> = ({ jobStatus }) => {
  return (
    <Chip
      className="center"
      value={JobStatus[jobStatus!] || 'Não executado'}
      color={getSituacaoColorChip(jobStatus)}
    />
  );
};

export default BatchStatusChip;
