import { Container, Loading, NotificationActions } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  AcessoWebService,
  CidadeService,
  UnidadeFederacaoService,
  withService
} from '../../../service';
import { validateEmail } from '../../../utils/ValidationEmail';
import UsuariosIssqnForm from './UsuariosIssqnForm';

export class UsuariosIssqnFormPage extends Component {
  static propTypes = {
    acessoWebService: PropTypes.object.isRequired,
    unidadeFederacaoService: PropTypes.object.isRequired,
    cidadeService: PropTypes.object.isRequired
  };

  validators = {
    id: value => value,
    nome: value => value,
    cpf: value => value,
    cep: value => value,
    endereco: value => value,
    numero: value => value,
    bairro: value => value,
    unidadeFederacao: value => value,
    cidade: value => value,
    telefone: value => value,
    email: value => value,
    dataNascimento: value => value
  };

  state = {
    dadosAcessos: {
      id: '',
      administrador: '',
      nome: '',
      cpf: '',
      cep: '',
      endereco: '',
      numero: '',
      bairro: '',
      unidadeFederacao: '',
      cidade: '',
      complemento: '',
      telefone: '',
      ramal: '',
      celular: '',
      email: '',
      dataNascimento: ''
    },

    error: {
      acesso: false,
      administrador: false,
      nome: false,
      cpf: false,
      cep: false,
      endereco: false,
      numero: false,
      bairro: false,
      unidadeFederacao: false,
      cidade: false,
      complemento: false,
      telefone: false,
      ramal: false,
      celular: false,
      email: false,
      emailInvalido: false,
      dataNascimento: false
    },
    loading: false,
    unidadesFederacao: [],
    cidades: []
  };

  componentDidMount() {
    const { id } = this.props.match.params;

    if (id) {
      this.setState({ loading: true });
      this.props.acessoWebService
        .loadAcessoWebById(id)
        .then(this.loadAcessoWebSuccess)
        .catch(this.loadAcessoWebError);
    }
  }

  loadAcessoWebSuccess = response => {
    this.setState(
      {
        dadosAcessos: response.data
      },
      () => {
        this.props.unidadeFederacaoService
          .loadAll()
          .then(this.loadUnidadeFederacaoSuccess)
          .catch(this.loadUnidadeFederacaoError);
      }
    );
  };

  loadAcessoWebError = error => {
    this.setState({ loading: false });

    this.props.showNotification({
      level: 'error',
      message: 'Não foi possível carregar o acesso web.'
    });
  };

  loadUnidadeFederacaoSuccess = response => {
    this.setState(
      {
        unidadesFederacao: response.data
      },
      () => {
        const { unidadeFederacao } = this.state.dadosAcessos;

        this.loadCidadesByUf(unidadeFederacao);
      }
    );
  };

  loadUnidadeFederacaoError = error => {
    this.props.showNotification({
      level: 'error',
      message: 'Não foi possível carregar as unidades de federação.'
    });

    this.setState({
      loading: false
    });
  };

  loadCidadesSuccess = response => {
    this.setState({
      cidades: response.data,
      loading: false
    });
  };

  onSaveAcessoWebSuccess = response => {
    this.props.showNotification({
      level: 'success',
      message: 'Dados atualizados com sucesso'
    });
    this.props.history.replace('/acessos-e-permissoes/usuarios-issqn');
  };

  onSaveAcessoWebError = error => {
    this.props.showNotification({
      level: 'error',
      message: 'Não foi possível atualizar o acesso web.'
    });
  };

  loadCidadesError = error => {
    this.props.showNotification({
      level: 'error',
      message: 'Não foi possível carregar as cidades.'
    });

    this.setState({
      loading: false
    });
  };

  hasErrors = () => {
    const { dadosAcessos } = this.state;

    const errorsCamposObrigatorios = Object.keys(dadosAcessos).filter(field => {
      return (
        this.validators[field] && !this.validators[field](dadosAcessos[field])
      );
    });
    const objErro = errorsCamposObrigatorios.reduce((total, current) => {
      total[current] = true;
      return total;
    }, {});

    objErro.emailInvalido = !validateEmail(dadosAcessos['email']);

    this.setState({ error: objErro });
    return errorsCamposObrigatorios.length > 0 || objErro.emailInvalido;
  };

  onSave = () => {
    if (this.hasErrors()) {
      this.props.showNotification({
        level: 'error',
        message: 'Campos obrigatórios ou inválidos!'
      });
    } else {
      const { dadosAcessos } = this.state;
      this.props.acessoWebService
        .atualizarAcessoWeb(dadosAcessos)
        .then(this.onSaveAcessoWebSuccess)
        .catch(this.onSaveAcessoWebError);
    }
  };

  onChangeInputValue = event => {
    const { name, value } = event.target;
    this.onChangeInput({ name, value });
  };

  onChangeAcessoAdmin = event => {
    const { name, value, checked, type } = event.target;
    this.onChangeInput({ name, value, checked, type });
  };

  onChangeInputEmail = event => {
    const { name, value } = event.target;
    this.onChangeInput({ name, value });

    this.setState(state => {
      const { error } = state;
      let emailValidacao = !validateEmail(value);

      return {
        error: {
          ...error,
          emailInvalido: emailValidacao
        }
      };
    });
  };

  onChangeFieldMask = event => {
    const { name, value = '' } = event.target;
    const newValue = value.replace(/\D/g, '');
    const obj = {
      name: name,
      value: newValue
    };

    this.onChangeInput(obj);
  };

  onChangeInput = obj => {
    const { name, value = '', checked = '', type = '' } = obj;
    this.setState(state => {
      const { dadosAcessos, error } = state;
      let novoValor = type === 'checkbox' ? checked : value;

      if (novoValor === true) {
        novoValor = 'S';
      } else if (novoValor === false) {
        novoValor = 'N';
      }

      return {
        dadosAcessos: { ...dadosAcessos, [name]: novoValor },
        error: {
          ...error,
          [name]: this.validators[name] && !this.validators[name](novoValor)
        }
      };
    });
  };

  onChangeSelectEstado = event => {
    const { value } = event.target;

    this.onChangeInputValue(event);

    this.loadCidadesByUf(value);
  };

  onChangeSelectCidade = event => {
    const { name, value } = event.target;
    const { cidades } = this.state;

    const cidadeSelected = cidades.find(cidade => cidade.id === Number(value));

    this.setState(state => {
      const { dadosAcessos, error } = state;
      return {
        dadosAcessos: { ...dadosAcessos, [name]: cidadeSelected },
        error: { ...error, [name]: !this.validators[name](value) }
      };
    });
  };

  loadCidadesByUf = uf => {
    if (uf) {
      this.setState({ loading: true });
      this.props.cidadeService
        .loadByUnidadeFederacao(uf)
        .then(this.loadCidadesSuccess)
        .catch(this.loadCidadesError);
    } else {
      this.setState(state => {
        const { dadosAcessos, error } = state;
        return {
          dadosAcessos: { ...dadosAcessos, cidade: '' },
          error: { ...error, cidade: !this.validators['cidade'](null) }
        };
      });
    }
  };

  onBack = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      dadosAcessos,
      error,
      unidadesFederacao,
      cidades,
      loading
    } = this.state;

    return (
      <Container breadcrumb>
        <Loading loading={loading} />
        <UsuariosIssqnForm
          onSave={this.onSave}
          dadosAcessos={dadosAcessos}
          error={error}
          unidadesFederacao={unidadesFederacao}
          cidades={cidades}
          onChangeSelectEstado={this.onChangeSelectEstado}
          onChangeSelectCidade={this.onChangeSelectCidade}
          onChangeInputValue={this.onChangeInputValue}
          onBack={this.onBack}
          onChangeFieldMask={this.onChangeFieldMask}
          onChangeInputEmail={this.onChangeInputEmail}
          onChangeAcessoAdmin={this.onChangeAcessoAdmin}
        />
      </Container>
    );
  }
}
const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

UsuariosIssqnFormPage.propTypes = {
  dadosAcessos: PropTypes.object
};

const ComponentWithService = withService({
  acessoWebService: AcessoWebService,
  unidadeFederacaoService: UnidadeFederacaoService,
  cidadeService: CidadeService
})(UsuariosIssqnFormPage);

export default connect(null, mapDispatchToProps)(ComponentWithService);
