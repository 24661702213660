import { PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { ConcursoPessoaExclusao } from '../types/ConcursoPessoaExclusao';
import { CONTEXT_PATH } from './contextPath';

export const filtrar = (
  search: string,
  page?: any
): AxiosPromise<PagedResponse<ConcursoPessoaExclusao>> =>
  axios.get(`${CONTEXT_PATH}/concursos/pessoa-exclusao/filter`, {
    params: {
      search,
      ...page
    }
  });

export const findById = (id: string): AxiosPromise<ConcursoPessoaExclusao> =>
  axios.get(`${CONTEXT_PATH}/concursos/pessoa-exclusao/${id}`);

export const remove = (id: number) =>
  axios.delete(`${CONTEXT_PATH}/concursos/pessoa-exclusao/${id}`);

export const save = (
  ConcursoPessoaExclusao: ConcursoPessoaExclusao
): AxiosPromise<ConcursoPessoaExclusao> =>
  axios.post(
    `${CONTEXT_PATH}/concursos/pessoa-exclusao`,
    ConcursoPessoaExclusao
  );

export default { filtrar, findById, save, remove };
