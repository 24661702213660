import CertificadoFormPage from '../pages/certificado/CertificadoForm';
import DividaDmsFormPage from '../pages/configuracoes/divida-DMS/DividaDmsFormPage';
import DividaDmsListPage from '../pages/configuracoes/divida-DMS/DividaDmsListPage';
import ConfigEmailMensagemPage from '../pages/configuracoes/email-mensagem/EmailMensagemPage';
import TextareaMensagem from '../pages/configuracoes/email-mensagem/TextareaMensagem';
import ModeloRelatorioFormPage from '../pages/configuracoes/modelo-relatorio/ModeloRelatorioFormPage';
import ModeloRelatorioListPage from '../pages/configuracoes/modelo-relatorio/ModeloRelatorioListPage';
import ParametrosPage from '../pages/configuracoes/parametros/ParametrosPage';
import MensagemTemplateEmail from '../pages/configuracoes/template-email/MensagemTemplateEmail';
import TemplateEmailListPage from '../pages/configuracoes/template-email/TemplateEmailListPage';
import VencimentoDividaMensalFormPage from '../pages/configuracoes/vencimento-divida-DMS/VencimentoDividaMensalFormPage';
import VencimentoDividaMensalListPage from '../pages/configuracoes/vencimento-divida-DMS/VencimentoDividaMensalListPage';
import { Roles } from '../utils/Roles';

export const routesConfiguracoes = [
  {
    path: '/configuracoes/template-email',
    component: TemplateEmailListPage,
    icon: 'envelope',
    title: 'Template Email',
    role: Roles.template_mensagem_email_read.name
  },
  {
    path: '/configuracoes/certificado',
    component: CertificadoFormPage,
    icon: 'file-alt',
    title: 'Certificado',
    role: Roles.certificado_read.name
  },
  {
    path: '/configuracoes/mensagem-template-email/editar/:id',
    component: MensagemTemplateEmail,
    icon: 'pencil-alt',
    title: 'Editar',
    parent: '/configuracoes/template-email',
    role: Roles.template_mensagem_email_write.name
  },
  {
    path: '/configuracoes/email-mensagem',
    component: ConfigEmailMensagemPage,
    icon: 'envelope',
    title: 'Email/Mensagem'
  },
  {
    path: '/configuracoes/email-mensagem/mensagem',
    component: TextareaMensagem,
    icon: 'file',
    title: 'Novo',
    parent: '/configuracoes/email-mensagem'
  },
  {
    path: '/configuracoes/parametros',
    component: ParametrosPage,
    icon: 'wrench',
    title: 'Parâmetros',
    role: Roles.parametros_read.name
  },
  {
    path: '/configuracoes/divida-dms',
    component: DividaDmsListPage,
    icon: 'hand-holding-usd',
    title: 'Dívida - DMS',
    role: Roles.divida_dms_read.name
  },
  {
    path: '/configuracoes/divida-dms/novo',
    component: DividaDmsFormPage,
    icon: 'file',
    title: 'Novo',
    parent: '/configuracoes/divida-dms',
    role: Roles.divida_dms_write.name
  },
  {
    path: '/configuracoes/divida-dms/editar/:id',
    component: DividaDmsFormPage,
    icon: 'pencil-alt',
    title: 'Editar',
    parent: '/configuracoes/divida-dms',
    role: Roles.divida_dms_write.name
  },
  {
    path: '/configuracoes/vencimentos-das-dividas-dms/novo',
    component: VencimentoDividaMensalFormPage,
    icon: 'file',
    title: 'Novo',
    parent: '/configuracoes/vencimentos-das-dividas-dms'
  },
  {
    path: '/configuracoes/vencimentos-das-dividas-dms/editar/:id',
    component: VencimentoDividaMensalFormPage,
    icon: 'pencil-alt',
    title: 'Editar',
    parent: '/configuracoes/vencimentos-das-dividas-dms'
  },
  {
    path: '/configuracoes/vencimentos-das-dividas-dms',
    component: VencimentoDividaMensalListPage,
    icon: 'calendar-alt',
    title: 'Vencimento da Dívida - DMS',
    role: Roles.vencimento_dividas_dms_read.name
  },
  {
    path: '/configuracoes/modelo-de-relatorio',
    component: ModeloRelatorioListPage,
    icon: 'file-alt',
    title: 'Modelo de Relatório',
    role: Roles.modelo_relatorio_read.name
  },
  {
    path: '/configuracoes/modelo-de-relatorio/novo',
    component: ModeloRelatorioFormPage,
    icon: 'file',
    title: 'Novo',
    parent: '/configuracoes/modelo-de-relatorio',
    role: Roles.modelo_relatorio_write.name
  },
  {
    path: '/configuracoes/modelo-de-relatorio/editar/:id',
    component: ModeloRelatorioFormPage,
    icon: 'pencil-alt',
    title: 'Editar',
    parent: '/configuracoes/modelo-de-relatorio',
    role: Roles.modelo_relatorio_write.name
  }
];

export default routesConfiguracoes;
