import {
  ActionButton,
  SearchPagination,
  formatUtils
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const IncentivosFiscaisList = props => {
  const { incentivosFiscais, edit, exclude, searchWithPage } = props;
  return (
    <div>
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <thead>
            <tr>
              <th>Código</th>
              <th>Descrição</th>
              <th>Tipo/Insc. Mun</th>
              <th>Nome</th>
              <th>CNPJ</th>
              <th>Desc. Lei</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {incentivosFiscais &&
              incentivosFiscais.content &&
              incentivosFiscais.content.map(incentivoFiscal => {
                return (
                  <tr key={incentivoFiscal.id}>
                    <td>{incentivoFiscal.id}</td>
                    <td>{incentivoFiscal.descricao}</td>
                    <td>
                      {incentivoFiscal.cadastroGeral.tipoCadastro} /{' '}
                      {incentivoFiscal.cadastroGeral.cadastroGeral}`
                    </td>
                    <td>{incentivoFiscal.cadastroGeral.pessoa.nome}</td>
                    <td>
                      {formatUtils.formatCpfCnpj(
                        incentivoFiscal.cadastroGeral.pessoa.cnpjCpf
                      )}
                    </td>
                    <td>{incentivoFiscal.lei.descricao}</td>
                    <td>
                      <div className="btn-actions">
                        <div className="btn-actions-inner">
                          <ActionButton
                            data-test-id={`buttonEdit${incentivoFiscal.id}`}
                            key="edit-button"
                            icon="pencil-alt"
                            label="Editar"
                            onClick={() => edit(incentivoFiscal.id)}
                          />
                          <ActionButton
                            data-test-id={`buttonDelete${incentivoFiscal.id}`}
                            key="delete-button"
                            icon="trash-alt"
                            label="Excluir"
                            onClick={() => exclude(incentivoFiscal.id)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            {(!incentivosFiscais || incentivosFiscais.totalElements === 0) && (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan="7">
                  Nenhum registro encontrado.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {incentivosFiscais && (
        <SearchPagination
          page={incentivosFiscais}
          searchWithPage={searchWithPage}
        />
      )}
    </div>
  );
};

IncentivosFiscaisList.propTypes = {
  incentivosFiscais: PropTypes.object.isRequired,
  edit: PropTypes.func,
  exclude: PropTypes.func,
  searchWithPage: PropTypes.func
};

export default IncentivosFiscaisList;
