import { ActionButton, SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import AtividadesListRow from './AtividadesListRow';
import AutoCompleteAdicionarCnae from './AutoCompleteAdicionarCnae';

const Atividades = ({
  disabled,
  historicoAgendamento,
  cnaeAutoComplete,
  loadCnaes,
  onSelectCnae,
  filterCnae,
  optionLabelCnae,
  onClearAutoComplete,
  onAdicionarCnae,
  atribuirCnaePrincipal,
  autorizarOuNaoCnae,
  toggleExpandedAllServices,
  authorizesAllCnaes,
  expandedAllServices,
  excluirCnae
}) => {
  return (
    <Fragment>
      <SectionTitle>CNAE</SectionTitle>
      <AutoCompleteAdicionarCnae
        disabled={disabled}
        cnaeAutoComplete={cnaeAutoComplete}
        loadCnaes={loadCnaes}
        onSelectCnae={onSelectCnae}
        filterCnae={filterCnae}
        optionLabelCnae={optionLabelCnae}
        onClearAutoComplete={onClearAutoComplete}
        onAdicionarCnae={onAdicionarCnae}
      />
      <br />

      <div className="panel table table-responsive">
        <div className="panel-body">
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <thead>
                <tr>
                  <th>CNAE</th>
                  <th>Descrição</th>
                  <th style={{ textAlign: 'center' }}>Situação</th>
                  <th />
                  <th>
                    <div className="btn-actions header-actions">
                      <div className="btn-actions-inner">
                        <ActionButton
                          data-test-id="authorizesAllCnaes"
                          key="authorizesAllCnaes-button"
                          icon="thumbs-up"
                          label="Autorizar todos CNAEs"
                          onClick={authorizesAllCnaes}
                        />
                        <ActionButton
                          data-test-id="allServices"
                          key="allServices-button"
                          icon="folder"
                          label="Mostrar todos Serviços"
                          onClick={toggleExpandedAllServices}
                        />
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {historicoAgendamento &&
                  historicoAgendamento.cnaes &&
                  historicoAgendamento.cnaes.map((cnae, index) => (
                    <AtividadesListRow
                      disabled={disabled}
                      key={cnae.id}
                      index={index}
                      atividade={cnae}
                      atribuirCnaePrincipal={atribuirCnaePrincipal}
                      autorizarOuNaoCnae={autorizarOuNaoCnae}
                      expandedAllServices={expandedAllServices}
                      excluirCnae={excluirCnae}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Atividades.propTypes = {
  historicoAgendamento: PropTypes.object.isRequired,
  cnaeAutoComplete: PropTypes.object.isRequired,
  loadCnaes: PropTypes.func.isRequired,
  onSelectCnae: PropTypes.func.isRequired,
  filterCnae: PropTypes.func.isRequired,
  optionLabelCnae: PropTypes.func.isRequired,
  onClearAutoComplete: PropTypes.func.isRequired,
  onAdicionarCnae: PropTypes.func.isRequired,
  atribuirCnaePrincipal: PropTypes.func.isRequired,
  autorizarOuNaoCnae: PropTypes.func.isRequired,
  toggleExpandedAllServices: PropTypes.func.isRequired,
  expandedAllServices: PropTypes.bool
};

export default Atividades;
