import {
  Alert,
  Container,
  DisplayDataGrid,
  DisplayDataItem,
  FAB,
  FabSpeedDial,
  FormattedDate,
  Loading,
  Row,
  SectionTitle
} from '@elotech/components';
import { BatchStatusChip } from 'iss-common/components';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { ControleDeclaracaoMensalNaoEntregueService } from '../../../service';
import {
  DeclaracaoMensalNaoEntregueCadastro,
  DeclaracaoMensalNaoEntregueControle
} from '../../../types';
import ContribuinteDeclaracaoMensalNaoEntregueListPage from '../contribuinte-dms-nao-entregue/ContribuinteDeclaracaoMensalNaoEntregueListPage';
import { getFiltrotext } from './FiltroUtil';

const ControleDeclaracaoMensalNaoEntregueViewPage: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [controleDmsNaoEntregues, setControleDmsNaoEntregues] = useState<
    DeclaracaoMensalNaoEntregueControle
  >();
  const [selecionadosList, setSelecionadosList] = useState<
    DeclaracaoMensalNaoEntregueCadastro[]
  >([]);
  const [todosRegistrosSelecionados, setTodosRegistrosSelecionados] = useState<
    boolean
  >(false);
  const [quantidadeTotalPesquisa, setQuantidadeTotalPesquisa] = useState<
    number
  >(0);

  useEffect(() => {
    setLoading(true);
    ControleDeclaracaoMensalNaoEntregueService.findById(+id)
      .then(response => setControleDmsNaoEntregues(response.data))
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao buscar os dados do controle de DMS não entregues.'
          },
          error
        );
      })
      .finally(() => setLoading(false));
  }, [id]);

  const emitirRelatorio = (id: number) => {
    if (!todosRegistrosSelecionados && selecionadosList.length === 0) {
      Alert.warning({
        title: 'Deve ter pelo menos um cadastro marcado para gerar o relatório'
      });
      return;
    }
    setLoading(true);
    var registrosRelatorio = {
      selecionadas: selecionadosList,
      todasSelecionadas: todosRegistrosSelecionados
    };
    ControleDeclaracaoMensalNaoEntregueService.generateRelatorio(
      id,
      registrosRelatorio
    )
      .then(response => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);
      })
      .catch((error: any) => {
        Alert.error({ title: `Ocorreu um erro ao gerar o relatório` }, error);
      })
      .finally(() => setLoading(false));
  };

  const onChangeDmsNaoEntregueSelecionados = (
    newProteto: DeclaracaoMensalNaoEntregueCadastro[]
  ) => {
    setSelecionadosList(newProteto);
  };

  const gerarMulta = () => {
    if (!todosRegistrosSelecionados && selecionadosList.length === 0) {
      Alert.warning({
        title: 'Deve ter pelo menos um cadastro marcado para gerar multa'
      });
      return;
    }
    history.push({
      pathname: `/rotinas/controle-dms-nao-entregues/${id}/resumo/multa`,
      state: {
        selecionados: selecionadosList,
        todosSelecionados: todosRegistrosSelecionados,
        controleDmsNaoEntregues: controleDmsNaoEntregues,
        quantidadeSelecionado: todosRegistrosSelecionados
          ? quantidadeTotalPesquisa
          : selecionadosList.length
      }
    });
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      {controleDmsNaoEntregues && (
        <>
          <SectionTitle marginTop="0px">Dados Gerais</SectionTitle>
          <DisplayDataGrid className="mb-xs">
            <Row>
              <DisplayDataItem md={5} title="Usuário">
                {controleDmsNaoEntregues.usuario}
              </DisplayDataItem>
              <DisplayDataItem md={3} title="Data da Geração">
                <FormattedDate value={controleDmsNaoEntregues.dataGeracao} />
              </DisplayDataItem>
              <DisplayDataItem md={4} title="Situação de Processamento">
                <BatchStatusChip
                  jobStatus={
                    controleDmsNaoEntregues.batchJobExecutionControle?.status
                  }
                />
              </DisplayDataItem>
            </Row>
            <Row>
              <DisplayDataItem md={12} title="Filtro Utilizado">
                {getFiltrotext(controleDmsNaoEntregues.filtro)}
              </DisplayDataItem>
            </Row>
          </DisplayDataGrid>
          <ContribuinteDeclaracaoMensalNaoEntregueListPage
            controleDmsNaoEntregues={controleDmsNaoEntregues}
            selecionados={selecionadosList}
            onChangeSelecionados={onChangeDmsNaoEntregueSelecionados}
            todosRegistrosSelecionados={todosRegistrosSelecionados}
            setTodosRegistrosSelecionados={setTodosRegistrosSelecionados}
            setQuantidadeTotalPesquisa={setQuantidadeTotalPesquisa}
          />
          {controleDmsNaoEntregues.batchJobExecutionControle?.status ===
            'COMPLETED' && (
            <FabSpeedDial icon="ellipsis-v" title="Ações">
              <FAB
                data-test-id="btn-gerar-multa"
                icon="dollar-sign"
                title="Gerar Multa"
                onClick={gerarMulta}
              />
              <FAB
                data-test-id="btn-save"
                icon="file-pdf"
                title="Emitir Relatório"
                onClick={() => emitirRelatorio(controleDmsNaoEntregues.id!)}
              />
            </FabSpeedDial>
          )}
        </>
      )}
    </Container>
  );
};

export default ControleDeclaracaoMensalNaoEntregueViewPage;
