import {
  Button,
  Col,
  DisplayData,
  FormattedDateTime,
  Icon,
  Row,
  SectionTitle
} from '@elotech/components';
import { Alert } from 'iss-common/utils';
import React from 'react';

import {
  SituacaoAtualSolicitante,
  SolicitacaoRPS
} from '../../../types/SolicitacaoRPS';
import SituacaoSolicitacao from './SituacaoSolicitacao';
import { PENDENTE } from './SolicitacaoRpsTipos';

type Props = {
  solicitacao: SolicitacaoRPS;
  situacaoAtualSolicitante?: SituacaoAtualSolicitante;
  onAprovar(solicitacao: SolicitacaoRPS): void;
  onRejeitar(solicitacao: SolicitacaoRPS, justificativa: string): void;
};

const SolicitacaoRpsDetailForm: React.FC<Props> = ({
  solicitacao,
  onAprovar,
  onRejeitar
}) => {
  return (
    <>
      <SectionTitle>Dados da Solicitação de RPS</SectionTitle>

      <div className="display-data border small mb-xs">
        <Row>
          <Col md={4}>
            <DisplayData title="Solicitante">
              {solicitacao.cadastroGeral!.pessoa!.nome}
            </DisplayData>
          </Col>
          <Col md={4}>
            <DisplayData title="Inscrição Municipal">
              {solicitacao.cadastroGeral!.cadastroGeral}
            </DisplayData>
          </Col>
          <Col md={4}>
            <DisplayData title="CPF/CNPJ">
              {solicitacao.cadastroGeral!.pessoa!.cnpjCpf}
            </DisplayData>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <DisplayData title="Data">
              <FormattedDateTime value={solicitacao.data} />
            </DisplayData>
          </Col>
          <Col md={4}>
            <DisplayData title="Quantidade">
              {solicitacao.quantidade}
            </DisplayData>
          </Col>
          <Col md={4}>
            <DisplayData title="Situação" data-cy="situacaoRPS">
              <SituacaoSolicitacao solicitacao={solicitacao} />
            </DisplayData>
          </Col>
        </Row>
      </div>

      {solicitacao.situacao === PENDENTE && (
        <>
          <Button
            data-test-id="aprove-button"
            className="inline"
            key="aprove-button"
            color="positive"
            onClick={() => onAprovar(solicitacao)}
          >
            <Icon icon="check" /> Aprovar
          </Button>
          <Button
            data-test-id="reject-button"
            className="inline"
            color="negative"
            key="reject-button"
            onClick={() =>
              getReason().then(
                (justificativa: string) =>
                  justificativa && onRejeitar(solicitacao, justificativa)
              )
            }
          >
            <Icon icon="times" /> Rejeitar
          </Button>
        </>
      )}
    </>
  );
};

const getReason = (): Promise<string> =>
  Alert.question({
    title: 'Qual a justificativa da rejeição?',
    input: 'textarea',
    inputPlaceholder: 'Descreva a justificativa',
    inputValidator: (value: string) =>
      !value ? 'A justificativa é obrigatória' : null,
    confirmButtonText: 'Rejeitar',
    cancelButtonText: 'Cancelar'
  }).then((result: any) => result.value);

export default SolicitacaoRpsDetailForm;
