import {
  Alert,
  FormattedDateTime,
  Loading,
  PagedResponse,
  SearchPagination,
  Switch,
  Table
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { SolicitacaoRpsService } from '../../../service';
import { RpsManual, SolicitacaoRPS } from '../../../types/SolicitacaoRPS';
import SituacaoRpsManual from './SituacaoRpsManual';

type Props = {
  solicitacao: SolicitacaoRPS;
  listarTodos: boolean;
  onChangeListarTodos(valor: boolean): void;
};

const RpsList: React.FC<Props> = ({
  solicitacao,
  listarTodos,
  onChangeListarTodos
}) => {
  const [listaRps, setListaRps] = useState<RpsManual[] | undefined>(undefined);
  const [pagination, setPagination] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);

  const onSearch = useCallback(
    (page?: number) => {
      const searchList = [`cadastroGeral.id==${solicitacao.cadastroGeral!.id}`];

      listarTodos || searchList.push(`solicitacaoRps.id==${solicitacao.id}`);

      const searchStr = searchList.join(' and ');

      setLoading(true);

      SolicitacaoRpsService.loadRpsManual(searchStr, page)
        .then((response: AxiosResponse<PagedResponse<RpsManual>>) => {
          const { content, ...pagination } = response.data;
          setListaRps(content);
          setPagination(pagination);
        })
        .catch(error => {
          Alert.error(
            {
              title: 'Não foi possível carregar a lista de RPS.'
            },
            error
          );
        })
        .finally(() => setLoading(false));
    },
    [listarTodos, solicitacao.cadastroGeral, solicitacao.id]
  );

  useEffect(() => {
    onSearch();
  }, [onSearch]);

  return (
    <>
      <div style={{ marginBottom: '5px' }}>
        <Switch
          data-test-id="switch"
          caption="Mostrar para todas as solicitações"
          active={listarTodos}
          onChange={(name: string, value: boolean) =>
            onChangeListarTodos(value)
          }
          name="todosRPS"
        />
      </div>

      <div className="panel table table-responsive">
        <div className="panel-body">
          <Loading loading={loading} />

          <Table<RpsManual>
            values={listaRps || []}
            keyExtractor={item => `${item.id}`}
          >
            <Table.Column<RpsManual>
              header="Número"
              value={(item: RpsManual) => item.numeroRps}
            />
            <Table.Column<RpsManual>
              header="Situação"
              value={(item: RpsManual) => (
                <SituacaoRpsManual rpsManual={item} />
              )}
            />
            <Table.Column<RpsManual>
              header="Solicitação"
              value={(item: RpsManual) => (
                <>
                  <Link
                    to={`/acessos-e-permissoes/solicitacoes-rps/analisar/${
                      item.solicitacaoRps!.id
                    }`}
                  >
                    {item.solicitacaoRps!.id}
                  </Link>
                  {' de '}
                  <FormattedDateTime value={item.solicitacaoRps!.data} />
                </>
              )}
            />
          </Table>
          {pagination && (
            <SearchPagination
              page={pagination}
              searchWithPage={(page: any) => onSearch(page)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default RpsList;
