import {
  BasicInput,
  DataPage,
  FormikAutocomplete,
  FormikTextArea,
  Row,
  Yup
} from '@elotech/components';
import { History } from 'history';
import React from 'react';
import { match } from 'react-router';

import ConcursoPessoaExclusaoService from '../../../service/ConcursoPessoaExclusaoService';
import ConcursoService from '../../../service/ConcursoService';
import { Concurso } from '../../../types/Concurso';
import { ConcursoPessoaExclusao } from '../../../types/ConcursoPessoaExclusao';

type Props = {
  match: match<{ id: string }>;
  history: Pick<History, 'replace'>;
};

const PessoaExclusaoFormPage: React.FC<Props> = ({ match, history }) => {
  const validationSchema = Yup.object().shape({
    concurso: Yup.object()
      .required()
      .label('Concurso'),
    nome: Yup.string()
      .required()
      .label('Nome'),
    cnpjCpf: Yup.string()
      .required()
      .label('CNPJ/CPF')
      .min(11)
      .max(14),
    motivo: Yup.string()
      .required()
      .label('Motivo')
  });

  const bloqueiaEdicao: boolean = match.params.id !== 'novo';

  console.log(` id  = ${match.params.id}`);

  return (
    <DataPage<ConcursoPessoaExclusao>
      match={match}
      history={history}
      name="Pessoa exclusão"
      load={ConcursoPessoaExclusaoService.findById}
      icon="trophy"
      validationSchema={validationSchema}
      title="Exclusão de CNPJ/CPF de concurso"
      redirectUrl="/concurso/pessoa-exclusao/consulta"
      onSave={ConcursoPessoaExclusaoService.save}
      render={form => {
        return (
          <>
            <Row>
              <FormikAutocomplete<Concurso>
                name="concurso"
                disabled={bloqueiaEdicao}
                placeholder="Pesquisar concurso vigente"
                label="Concurso"
                onSearch={ConcursoService.concursoAutoComplete}
                getOptionLabel={(value: Concurso) =>
                  `${value.id} - ${value.nomeConcurso}`
                }
                getOptionValue={value => value.id}
              />

              <BasicInput name="nome" type="text" label="Nome" />

              <BasicInput
                name="cnpjCpf"
                type="text"
                label="Cnpj/Cpf"
                disabled={bloqueiaEdicao}
              />
            </Row>
            <Row>
              <FormikTextArea
                size={6}
                name="motivo"
                type="text"
                label="Motivo"
              />
            </Row>
          </>
        );
      }}
    />
  );
};

export default PessoaExclusaoFormPage;
