import ConcursoFormPage from '../pages/concurso/cadastro/ConcursoFormPage';
import ConcursoListPage from '../pages/concurso/cadastro/ConcursoListPage';
import PessoaExclusaoFormPage from '../pages/concurso/concurso-pessoa-exclusao/ConcursoPessoaExclusaoFormPage';
import PessoaExclusaoListPage from '../pages/concurso/concurso-pessoa-exclusao/ConcursoPessoaExclusaoListPage';
import { Roles } from '../utils/Roles';

export const routesConcurso = [
  {
    path: '/concurso/consulta',
    component: ConcursoListPage,
    icon: 'search',
    title: 'Pesquisar Concurso',
    role: Roles.concurso_read.name
  },
  {
    path: '/concurso/:id',
    component: ConcursoFormPage,
    icon: 'check',
    title: 'Concurso',
    parent: '/concurso',
    role: Roles.concurso_write.name
  },
  {
    path: '/concurso/pessoa-exclusao/consulta',
    component: PessoaExclusaoListPage,
    icon: 'search',
    title: 'Pesquisar Pessoas Excluídas de Concursos',
    role: Roles.exclusao_participante_read.name
  },
  {
    path: '/concurso/pessoa-exclusao/:id',
    component: PessoaExclusaoFormPage,
    icon: 'check',
    title: 'Exclusão de CNPJ/CPF de concurso',
    parent: '/concurso/pessoa-exclusao',
    role: Roles.exclusao_participante_write.name
  }
];

export default routesConcurso;
