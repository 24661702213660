import { FilterUtils } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { PrestadorType } from '../types/Prestador';
import { CONTEXT_PATH } from './contextPath';

const filterAutocomplete = (search: any) => {
  if (isNaN(search)) {
    return FilterUtils.buildFilter(['pessoa.cnpjCpf', 'pessoa.nome'])(search);
  }

  return FilterUtils.buildDefaultFilter(
    'cadastroGeral',
    'pessoa.cnpjCpf'
  )(search);
};

export const prestadorAutoComplete = (
  search: string = ''
): AxiosPromise<PrestadorType[]> =>
  axios.get(`${CONTEXT_PATH}/cadastro-geral/prestadores`, {
    params: {
      search: filterAutocomplete(search),
      sort: 'pessoa.nome'
    }
  });
