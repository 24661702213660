import { PageRequest, PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { DeclaracaoMensalNaoEntregueContribuinte } from '../types';
import { CONTEXT_PATH } from './contextPath';

const defaultSort = {
  sort: 'anoCompetencia,mesCompetencia,desc'
};

const findContribuintesControleDmsNaoEntreguesCadastros = (
  search: string,
  page?: PageRequest
) =>
  axios.get(`${CONTEXT_PATH}/dms-nao-entregue-contribuinte/search-cadastros`, {
    params: {
      search,
      ...page
    }
  });

export const searchCadastros = (
  controleDmsNaoEntregueId: number,
  search: string,
  page?: PageRequest
): AxiosPromise<PagedResponse<DeclaracaoMensalNaoEntregueContribuinte>> => {
  const controleDmsNaoEntregueSearch = `(idControle==${controleDmsNaoEntregueId})`;

  return findContribuintesControleDmsNaoEntreguesCadastros(
    search
      ? [search, controleDmsNaoEntregueSearch].join(' and ')
      : controleDmsNaoEntregueSearch,
    page
  );
};

const findContribuintesControleDmsNaoEntregues = (
  search: string,
  page?: PageRequest
) =>
  axios.get(`${CONTEXT_PATH}/dms-nao-entregue-contribuinte/search`, {
    params: {
      ...defaultSort,
      search,
      ...page
    }
  });

export const searchCompetenciaByCadastro = (
  idControle: number,
  cadastroGeral: number,
  search?: string,
  page?: PageRequest
): AxiosPromise<PagedResponse<DeclaracaoMensalNaoEntregueContribuinte>> => {
  const filterSearch = `(controle.id==${idControle}) and (cadastroGeral.tipoCadastro==2) and (cadastroGeral.cadastroGeral==${cadastroGeral})`;
  const finalSearch = search
    ? [search, filterSearch].join(' and ')
    : filterSearch;

  return findContribuintesControleDmsNaoEntregues(finalSearch, page);
};
