import { FilterUtils } from '@elotech/components';
import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

export const loadByUnidadeFederacao = unidadeFederacao =>
  axios.get(`${CONTEXT_PATH}/cidade/unidade-federacao/${unidadeFederacao}`);

export const autoComplete = (search, page) =>
  axios.get(`${CONTEXT_PATH}/cidade`, {
    params: {
      search:
        FilterUtils.buildFilter(['descricao', 'unidadeFederacao.descricao'])(
          search
        ) + ' and ativa==S',
      ...page
    }
  });
