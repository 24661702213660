import { Col, ErrorText, InputDate, Row } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const errorMessages = {
  versao: 'Campo obrigatório!',
  dataVersao: 'Campo obrigatório!',
  arquivoBase64: 'Campo obrigatório!'
};

const VersoesDocumentoNewForm = ({
  versoesDocumento,
  error,
  onChange,
  onUploadFile,
  onSave,
  onCancel
}) => {
  return (
    <>
      <div className="panel-body">
        <Row>
          <Col sm={5} md={3}>
            <div className="form-group">
              <label htmlFor="versao">Versão</label>
              <input
                id="versao"
                className={error.versao ? 'error' : ''}
                type="text"
                name="versao"
                value={versoesDocumento.versao}
                onChange={onChange}
              />
              {error.versao && <ErrorText>{errorMessages.versao}</ErrorText>}
            </div>
          </Col>
          <Col md={3}>
            <div className="form-group">
              <label htmlFor="dataVersao">Data Cadastro</label>
              <InputDate
                data-test-id="dataVersao"
                className={error.dataVersao ? 'error' : ''}
                name="dataVersao"
                value={versoesDocumento.dataVersao}
                onChange={onChange}
              />
              <i aria-hidden="true" className="fa fa-calendar-alt input-icon" />
              {error.dataVersao && (
                <ErrorText>{errorMessages.dataVersao}</ErrorText>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={5} md={8}>
            <div className="form-group">
              <label htmlFor="notasdaVersao">Notas da Versão</label>
              <textarea
                id="notasVersao"
                name="notasVersao"
                onChange={onChange}
                value={versoesDocumento.notasVersao || ''}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <div className="form-group">
              <label htmlFor="arquivo">Arquivo</label>
              <div className="file-uploader">
                <input
                  data-max-size="50000000"
                  type="file"
                  name="arquivoBase64"
                  className={`file-uploader-input ${
                    error.arquivoBase64 ? 'error' : ''
                  }`}
                  id="upload-file-input"
                  data-title={
                    versoesDocumento.nomeArquivo &&
                    versoesDocumento.nomeArquivo.length > 0
                      ? versoesDocumento.nomeArquivo
                      : 'Clique ou arraste para anexar'
                  }
                  onChange={onUploadFile}
                />
                <em className="file-uploader-icon" />
                {error.arquivoBase64 && (
                  <ErrorText>{errorMessages.arquivoBase64}</ErrorText>
                )}
                {error.arquivoErrorMessage && (
                  <ErrorText>{error.arquivoErrorMessage}</ErrorText>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <div className="form-group">
          <button
            data-test-id="buttonSave"
            className="btn positive inline mb-xs"
            name="buttonSave"
            onClick={onSave}
          >
            Salvar
          </button>
          <button
            data-test-id="buttonCancel"
            className="btn negative inline mb-xs"
            name="buttonCancel"
            onClick={onCancel}
          >
            Cancelar
          </button>
        </div>
      </div>
    </>
  );
};

VersoesDocumentoNewForm.propTypes = {
  versoesDocumento: PropTypes.object.isRequired,
  onUploadFile: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  error: PropTypes.object
};

export default VersoesDocumentoNewForm;
