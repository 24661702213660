import {
  Checkbox,
  Col,
  InputInteger,
  Row,
  SectionTitle
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const EmailFields = ({ configEmailInput, onChange }) => {
  return (
    <>
      <SectionTitle>Informações do Usuário</SectionTitle>
      <form className="form">
        <Row>
          <Col sm={5} md={5}>
            <div className="form-group">
              <label htmlFor="nome">Nome</label>
              <input
                type="text"
                name="nome"
                value={configEmailInput.nome}
                onChange={onChange}
              />
            </div>
          </Col>
          <Col sm={5} md={5}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                value={configEmailInput.email}
                onChange={onChange}
              />
            </div>
          </Col>
        </Row>
        <SectionTitle>Informações do Servidor</SectionTitle>
        <Row>
          <Col sm={5} md={3}>
            <Checkbox
              className="form-group"
              name="servidorAutenticacao"
              id="servidorAutenticacao"
              checked={configEmailInput.servidorAutenticacao}
              onChange={onChange}
              label="Servidor requer autenticação"
              disabled={false}
            />
          </Col>
          <Col sm={5} md={4}>
            <Checkbox
              className="form-group"
              name="servidorConexaoSegura"
              id="servidorConexaoSegura"
              checked={configEmailInput.servidorConexaoSegura}
              onChange={onChange}
              label="Servidor requer conexao segura (SSL)"
              disabled={false}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={5} md={7}>
            <div className="form-group">
              <label htmlFor="servidorSmtp">Servidor SMTP</label>
              <input
                type="text"
                name="servidorSMTP"
                value={configEmailInput.servidorSMTP}
                onChange={onChange}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="form-group">
              <label htmlFor="portatcp">Porta TCP</label>
              <InputInteger
                name="portaTCP"
                value={configEmailInput.portaTCP}
                onChange={onChange}
              />
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={5} md={3}>
            <Checkbox
              className="form-group"
              name="pop3Autenticacao"
              id="pop3Autenticacao"
              checked={configEmailInput.pop3Autenticacao}
              onChange={onChange}
              label="Utilizar POP3 para autenticação"
              disabled={false}
            />
          </Col>
          <Col sm={5} md={4}>
            <Checkbox
              className="form-group"
              name="pop3ConexaoSegura"
              id="pop3ConexaoSegura"
              checked={configEmailInput.pop3ConexaoSegura}
              onChange={onChange}
              label="POP3 requer conexao segura (SSL)"
              disabled={false}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={5} md={7}>
            <div className="form-group">
              <label htmlFor="servidorPop3">Servidor POP3</label>
              <input
                type="text"
                name="servidorPOP3"
                value={configEmailInput.servidorPOP3}
                onChange={onChange}
              />
            </div>
          </Col>
          <Col md={3}>
            <div className="form-group">
              <label htmlFor="portatcp">Porta TCP</label>
              <InputInteger
                name="portaTCP2"
                value={configEmailInput.portaTCP2}
                onChange={onChange}
              />
            </div>
          </Col>
        </Row>
        <SectionTitle>Informações de Logon</SectionTitle>
        <Row>
          <Col sm={5} md={5}>
            <div className="form-group">
              <label htmlFor="usuario">Usuário</label>
              <input
                type="text"
                name="usuario"
                value={configEmailInput.usuario}
                onChange={onChange}
              />
            </div>
          </Col>
          <Col sm={5} md={5}>
            <div className="form-group">
              <label htmlFor="senha">Senha</label>
              <input
                type="password"
                name="senha"
                value={configEmailInput.senha}
                onChange={onChange}
              />
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

EmailFields.propTypes = {
  configEmailInput: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default EmailFields;
