import {
  Container,
  Hint,
  Loading,
  NotificationActions
} from '@elotech/components';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ConfiguracaoDividaService, withService } from '../../../service';
import DividaDmsForm from './DividaDmsForm';

export class DividaDmsFormPage extends Component {
  state = {
    loading: false,
    configuracaoDividaInput: {
      id: '',
      exercicio: '',
      tipoDeclaracao: '',
      tipoMovimento: '',
      guiaRecolhimento: '',
      desabilitaEnvioSubdivida: false
    },

    error: {
      exercicio: false,
      guiaRecolhimento: false,
      tipoDeclaracao: false,
      tipoMovimento: false
    }
  };

  validators = {
    exercicio: value => value,
    tipoDeclaracao: value => value,
    tipoMovimento: value => value,
    guiaRecolhimento: value => value
  };

  hasErrors = () => {
    const { configuracaoDividaInput } = this.state;

    const errorsCamposObrigatorios = Object.keys(
      configuracaoDividaInput
    ).filter(field => {
      return (
        this.validators[field] &&
        !this.validators[field](configuracaoDividaInput[field])
      );
    });
    const objErro = errorsCamposObrigatorios.reduce((total, current) => {
      total[current] = true;
      return total;
    }, {});

    this.setState({ error: objErro });
    return errorsCamposObrigatorios.length > 0;
  };

  onSave = () => {
    const { configuracaoDividaInput } = this.state;
    if (this.hasErrors()) {
      this.props.showNotification({
        level: 'error',
        message: 'Campos obrigatórios não preenchidos.'
      });
    } else {
      this.setState({ loading: true });
      this.props.configuracaoDividaService
        .save(configuracaoDividaInput)
        .then(this.salvarConfiguracaoDividaSuccess)
        .catch(this.salvarConfiguracaoDividaError);
    }
  };

  salvarConfiguracaoDividaSuccess = () => {
    this.setState({ loading: false });

    this.props.showNotification({
      level: 'success',
      message: 'Configuração de Dívida salvo com sucesso.'
    });

    this.props.history.replace('/configuracoes/divida-dms');
  };

  salvarConfiguracaoDividaError = () => {
    this.setState({ loading: false });

    this.props.showNotification({
      level: 'error',
      message: 'Falha ao salvar a Configuração de Dívida'
    });
  };

  componentDidMount() {
    const idConfiguracaoDivida = this.props.match.params['id'];

    if (idConfiguracaoDivida) {
      this.setState({ loading: true });

      this.props.configuracaoDividaService
        .loadConfiguracaoDividaById(idConfiguracaoDivida)
        .then(this.loadConfiguracaoDividaSuccess)
        .catch(this.loadConfiguracaoDividaError);
    }
  }

  loadConfiguracaoDividaSuccess = response => {
    this.setState({
      configuracaoDividaInput: response.data,
      loading: false
    });
  };

  loadConfiguracaoDividaError = () => {
    this.setState({ loading: false });

    this.props.showNotification({
      level: 'error',
      message: 'Não foi possível carregar a Configuração de Dívida.'
    });
  };

  onSelect = (name, value) => {
    this.setState(state => {
      return {
        configuracaoDividaInput: {
          ...state.configuracaoDividaInput,
          [name]: value
        },
        errors: {
          ...state.errors,
          [name]: false
        }
      };
    });
  };

  onChangeInputValue = event => {
    const { name, value } = event.target;

    this.setState(state => {
      const { configuracaoDividaInput, error } = state;
      return {
        configuracaoDividaInput: { ...configuracaoDividaInput, [name]: value },
        error: { ...error, [name]: !this.validators[name](value) }
      };
    });
  };

  onChangeCheckbox = event => {
    const { name, checked } = event.target;

    this.setState(state => {
      const { configuracaoDividaInput, error } = state;
      return {
        configuracaoDividaInput: {
          ...configuracaoDividaInput,
          [name]: checked
        },
        error: { ...error }
      };
    });
  };

  onChangeExercicio = event => {
    this.onChangeInputValue(event);
    this.onSelect('guiaRecolhimento', '');
  };

  render() {
    const { configuracaoDividaInput, error, loading } = this.state;
    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/42175/oxy-issadmin-configuracao-de-divida"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        <DividaDmsForm
          configuracaoDividaInput={configuracaoDividaInput}
          error={error}
          onSave={this.onSave}
          onChange={this.onChangeInputValue}
          onChangeExercicio={this.onChangeExercicio}
          onSelect={this.onSelect}
          loadGuiasRecolhimento={
            this.props.configuracaoDividaService.loadGuiasRecolhimento
          }
          onChangeCheckbox={this.onChangeCheckbox}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const ComponentWithService = withService({
  configuracaoDividaService: ConfiguracaoDividaService
})(DividaDmsFormPage);

export default connect(null, mapDispatchToProps)(ComponentWithService);
