import {
  BasicInput,
  Button,
  Col,
  Panel,
  Row,
  Switch,
  TableChild,
  Yup
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import React from 'react';

import { ConcursoCupomParticipante } from '../../../types/ConcursoCupomParticipante';

type Props = {
  cupom: ConcursoCupomParticipante;
  onSave(values: ConcursoCupomParticipante): void;
};

type FormValue = ConcursoCupomParticipante;

const validationSchema = Yup.object().shape({
  premiado: Yup.string()
    .label('Premiado')
    .required(),
  observacao: Yup.string().when('premiado', {
    is: 'true',
    then: Yup.string()
      .label('Observação')
      .required()
  })
});

const ConcursoCupomPremiadoForm: React.FC<Props> = ({ cupom, onSave }) => {
  if (cupom === undefined) {
    return <React.Fragment />;
  }

  return (
    <TableChild>
      <Panel>
        <Formik<FormValue>
          initialValues={cupom}
          onSubmit={onSave}
          validationSchema={() => validationSchema}
          render={(form: FormikProps<FormValue>) => (
            <>
              <Row>
                <BasicInput size={4} name="observacao" label="Observação" />
                <Col xs={1}>
                  <div className="form-group ml-xs">
                    <label className="label">Premiado</label>
                    <Switch
                      data-test-id="switch-sorteado"
                      active={form.values.premiado}
                      onChange={(name: string, value: boolean) => {
                        form.setFieldValue('premiado', value, true);
                      }}
                      name="premiado"
                    />
                  </div>
                </Col>
                <Col md={7}>
                  <div className="form-group ml-xs">
                    <label className="label" />
                    <Button
                      id="salvar-cupom"
                      className={'inline'}
                      onClick={form.submitForm}
                    >
                      Salvar
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}
        />
      </Panel>
    </TableChild>
  );
};

export default ConcursoCupomPremiadoForm;
