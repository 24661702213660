import {
  Col,
  Container,
  FAB,
  FabSpeedDial,
  Loading,
  NotificationActions,
  NotificationEmitter,
  Row
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { Formik, FormikActions, FormikProps } from 'formik';
import { History } from 'history';
import { Alert } from 'iss-common/utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router';

import { resetBadgeValues } from '../../menus/BadgeValuesProvider';
import { DenunciaService } from '../../service';
import { Denuncia } from '../../types/Denuncia';
import DenunciadoSection from './sections/DenunciadoSection';
import DenuncianteSection from './sections/DenuncianteSection';
import DenunciaSection from './sections/DenunciaSection';
import DocumentoSection from './sections/DocumentoSection';
import ObservacaoSection from './sections/ObservacaoSection';
import RespostaSection from './sections/RespostaSection';
import { CONCLUIDO, EM_ANALISE, NOVA } from './SituacaoDenuncia';

type Props = {
  match: match<{ id: string }>;
  history: Pick<History, 'replace'>;
  showNotification: NotificationEmitter;
};

type DenunciaPageValues = {
  id?: number;
  situacao?: string;
  observacao?: string;
  resposta?: string;
  novaSituacao?: string;
};

const initialValues: DenunciaPageValues = {
  id: undefined,
  situacao: '',
  observacao: '',
  resposta: '',
  novaSituacao: ''
};

const DenunciaFormPage: React.FC<Props> = ({
  match,
  history,
  showNotification
}) => {
  const [denuncia, setDenuncia] = useState<Denuncia | undefined>(undefined);
  const [values, setValues] = useState<DenunciaPageValues>(initialValues);
  const [loading, setLoading] = useState(false);

  const loadDenuncia = (id: number) => {
    if (id) {
      setLoading(true);

      return DenunciaService.loadDenuncia(id)
        .then((response: AxiosResponse<Denuncia>) => {
          setDenuncia(response.data);

          const denuncia = response.data;

          if (denuncia) {
            setValues({
              id: denuncia.id,
              resposta: denuncia.resposta,
              situacao: denuncia.situacao,
              observacao: denuncia.observacao
            });
          }
        })
        .catch((error: any) => {
          Alert.error({ title: 'Não foi possível carregar a denúncia' }, error);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    loadDenuncia(+match.params.id);
  }, [match.params.id]);

  const onSave = async (
    values: DenunciaPageValues,
    actions: FormikActions<DenunciaPageValues>
  ) => {
    const { novaSituacao, ...resto } = values;

    const obj: Denuncia = {
      ...denuncia,
      ...resto
    };

    obj.situacao = novaSituacao || (denuncia && denuncia.situacao);

    if (obj.situacao === CONCLUIDO && !obj.resposta) {
      actions.setFieldError('resposta', 'Campo obrigatório');
      return;
    }

    try {
      setLoading(true);

      await DenunciaService.saveDenuncia(obj);
      resetBadgeValues();

      setLoading(false);

      const notificarDenunciante =
        obj.situacao === CONCLUIDO &&
        (await Alert.question({
          title:
            'Denúncia salva com sucesso.<br/><br/>Deseja enviar o parecer ao denunciante?'
        }).then((result: any) => result.value));

      if (notificarDenunciante) {
        setLoading(true);
        await DenunciaService.notificarDenunciante(obj);
        setLoading(false);
      }

      showNotification({
        level: 'success',
        message: 'Operação realizada com sucesso.'
      });

      if (novaSituacao !== EM_ANALISE) {
        history.replace(
          `/denuncias?filters==codigo_Igual=${encodeURIComponent(
            denuncia!.codigo || 0
          )}`
        );
      } else {
        loadDenuncia(+match.params.id);
      }
    } catch (error) {
      setLoading(false);
      Alert.error({ title: 'Ocorreu um erro ao salvar as alterações' }, error);
    }
  };

  // O async/await é necessário para o submitForm ser chamado,
  // apesar de setFieldValue não ser assíncrono.
  // Parece um bug no Formik.
  const callSave = async (
    formProps: FormikProps<DenunciaPageValues>,
    novaSituacao?: string
  ) => {
    await formProps.setFieldValue('novaSituacao', novaSituacao);
    return await formProps.submitForm();
  };

  return (
    <>
      <Container title={'Denúncia'} icon="volume-up">
        <Loading loading={loading} />
        <Formik enableReinitialize initialValues={values} onSubmit={onSave}>
          {(formProps: FormikProps<DenunciaPageValues>) => (
            <>
              {denuncia && (
                <>
                  <DenunciaSection denuncia={denuncia} />
                  <Row>
                    <Col md={denuncia.numeroRPS ? 4 : 12}>
                      <DenunciadoSection denuncia={denuncia} />
                    </Col>
                    {denuncia.numeroRPS && (
                      <Col md={8}>
                        <DocumentoSection denuncia={denuncia} />
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col md={12}>
                      <DenuncianteSection denuncia={denuncia} />
                    </Col>
                  </Row>
                  {denuncia.situacao !== NOVA && (
                    <Row data-test-id="row-resposta-obs">
                      <Col md={6}>
                        <ObservacaoSection denuncia={denuncia} />
                      </Col>
                      <Col md={6}>
                        <RespostaSection denuncia={denuncia} />
                      </Col>
                    </Row>
                  )}
                </>
              )}
              <FabSpeedDial icon="ellipsis-v" title="Ações">
                {denuncia && denuncia.situacao === NOVA && (
                  <FAB
                    data-test-id="btn-analise"
                    icon="file-invoice-dollar"
                    onClick={() => callSave(formProps, EM_ANALISE)}
                    title="Iniciar análise da denúncia"
                  />
                )}
                {denuncia && denuncia.situacao !== NOVA && (
                  <FAB
                    data-test-id="btn-concluir"
                    icon="check-double"
                    onClick={() => callSave(formProps, CONCLUIDO)}
                    title="Salvar & Concluir"
                    disabled={denuncia && denuncia.situacao === CONCLUIDO}
                  />
                )}
                {denuncia && denuncia.situacao !== NOVA && (
                  <FAB
                    data-test-id="btn-salvar"
                    icon="check"
                    onClick={() => callSave(formProps)}
                    title="Salvar"
                    disabled={denuncia && denuncia.situacao === CONCLUIDO}
                  />
                )}
              </FabSpeedDial>
            </>
          )}
        </Formik>
      </Container>
    </>
  );
};

const ConnectedComponent = connect(null, {
  showNotification: NotificationActions.showNotification
})(DenunciaFormPage);

export { ConnectedComponent as default, DenunciaFormPage };
