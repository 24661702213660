import {
  BasicInput,
  Button,
  Checkbox,
  Col,
  Container,
  FormikAutocomplete,
  FormikInputDate,
  Loading,
  Row,
  Yup
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import { Alert } from 'iss-common/utils';
import React, { useState } from 'react';

import {
  PessoaService,
  RelatorioEmpresasLiberadasEmissaoNfseService
} from '../../service';
import { Pessoa } from '../../types';
import { RelatorioEmpresasLiberadasEmissaoNfseDTO } from '../../types/RelatorioEmpresasLiberadasEmissaoNfseDTO';

const initialValues: RelatorioEmpresasLiberadasEmissaoNfseDTO = {};

const RelatorioEmpresasLiberadasEmissaoNfseFormPage: React.FC = () => {
  const [somenteNaoEmitidoNota, setSomenteNaoEmitidoNota] = useState<boolean>(
    false
  );

  const schema = Yup.object().shape({
    dataCredenciamentoInicial: Yup.string()
      .required()
      .label('Data credenciamento inicial')
      .min(7)
      .notRequired(),
    dataCredenciamentoFinal: Yup.string()
      .label('Data credenciamento final')
      .min(7)
      .notRequired()
      .test(
        'dataFinalMenorQueInicial',
        'Data de credenciamento final maior que data de credenciamento inicial!',
        function(value) {
          if (value === undefined) {
            return true;
          }
          const { dataCredenciamentoInicial } = this.parent;
          return dataCredenciamentoInicial <= value;
        }
      )
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: any) => {
    setLoading(true);
    await RelatorioEmpresasLiberadasEmissaoNfseService.carregarRelatorioEmpresasLiberadasEmissaoNfse(
      values
    )
      .then((response: any) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);
      })
      .catch((error: any) => {
        Alert.error({ title: `Ocorreu um erro ao gerar o relatório` }, error);
      })
      .finally(() => setLoading(false));
  };

  const onChangeDeclaracao = (
    formProps: FormikProps<RelatorioEmpresasLiberadasEmissaoNfseDTO>,
    state: boolean
  ) => {
    formProps.setFieldValue('somenteNaoEmitidoNota', !state);
    setSomenteNaoEmitidoNota(!state);
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
        render={(
          form: FormikProps<RelatorioEmpresasLiberadasEmissaoNfseDTO>
        ) => (
          <>
            <Row>
              <FormikAutocomplete
                data-test-id="autocomplete-pessoa"
                name="pessoa"
                placeholder="Pesquisar por Cnpj/Cpf ou Nome"
                label="Pessoa/Empresa (CPF/CNPJ)"
                onSearch={PessoaService.search}
                size={3}
                getOptionLabel={(value: Pessoa) =>
                  `${value?.cnpjCpf} - ${value.nome}`
                }
                getOptionValue={(value: Pessoa) => value.id}
              />
              <BasicInput
                size={2}
                label="Inscrição Municipal"
                name="inscricaoMunicipal"
              />
              <FormikInputDate
                size={2}
                label="Data credenciamento inicial"
                name="dataCredenciamentoInicial"
              />
              <FormikInputDate
                size={2}
                label="Data credenciamento final"
                name="dataCredenciamentoFinal"
              />
            </Row>
            <Row>
              <Col md={3}>
                <Checkbox
                  className="form-group"
                  name="somenteNaoEmitidoNota"
                  id="somenteNaoEmitidoNota"
                  checked={somenteNaoEmitidoNota}
                  onChange={() =>
                    onChangeDeclaracao(form, somenteNaoEmitidoNota)
                  }
                  label="Somente empresas que não emitiram nota"
                ></Checkbox>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="form-group">
                  <Button onClick={form.submitForm} type="submit">
                    Imprimir
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      />
    </Container>
  );
};

export default RelatorioEmpresasLiberadasEmissaoNfseFormPage;
