import {
  Checkbox,
  Col,
  InputInteger,
  Row,
  SectionTitle
} from '@elotech/components';
import React from 'react';

const DesIfForm = ({
  onChangeParametros,
  dadosDesIf,
  onToggleDesIf,
  showFormDesIf
}) => {
  return (
    <>
      <SectionTitle hasButton={true}>
        DES-IF
        <button
          className="dropdown-arrow"
          data-test-id="button"
          onClick={onToggleDesIf}
        >
          <i
            className={
              showFormDesIf ? 'fa fa-chevron-up' : 'fa fa-chevron-down'
            }
          />
        </button>
      </SectionTitle>
      {showFormDesIf && (
        <>
          <Row>
            <Col md={4}>
              <div className="form-group">
                <label htmlFor="">Tipo de consolidação</label>
                <select
                  name="TIPO_CONSOLIDACAO_DESIF"
                  key="TIPO_CONSOLIDACAO_DESIF"
                  value={dadosDesIf.TIPO_CONSOLIDACAO_DESIF?.valor || ''}
                  onChange={onChangeParametros}
                >
                  <option value="" />
                  <option value="1">Instituição e alíquota</option>
                  <option value="2">
                    Instituição, alíquota e código de tributação DES-IF
                  </option>
                  <option value="3">Dependência e alíquota</option>
                  <option value="4">
                    Dependência, alíquota e código de tributação DES-IF
                  </option>
                </select>
              </div>
            </Col>

            <Col md={4}>
              <div className="form-group">
                <label htmlFor="">Tipo de arredondamento adotado</label>
                <select
                  name="TIPO_ARREDONDAMENTO_DESIF"
                  key="TIPO_ARREDONDAMENTO_DESIF"
                  value={dadosDesIf.TIPO_ARREDONDAMENTO_DESIF?.valor || ''}
                  onChange={onChangeParametros}
                >
                  <option value="" />
                  <option value="1">Arredondado</option>
                  <option value="2">Truncado</option>
                </select>
              </div>
            </Col>

            <Col md={4}>
              <div className="form-group">
                <label htmlFor="PRAZO_MAX_COMPENSAR_0440_DESIF">
                  Prazo para a compensação de créditos (em anos)
                </label>
                <InputInteger
                  name="PRAZO_MAX_COMPENSAR_0440_DESIF"
                  value={dadosDesIf.PRAZO_MAX_COMPENSAR_0440_DESIF?.valor || ''}
                  onChange={onChangeParametros}
                  allowNegative={false}
                  maxLength={4}
                  minLength={4}
                  min={2000}
                  max={2100}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="PERMITE_IMPOSTO_RETIDO_DESIF"
                id="PERMITE_IMPOSTO_RETIDO_DESIF"
                checked={dadosDesIf.PERMITE_IMPOSTO_RETIDO_DESIF?.valor === 'S'}
                onChange={onChangeParametros}
                label="Declarar imposto próprio retido por subtítulo"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="INFORMA_CONTAS_DESPESAS_DESIF"
                id="INFORMA_CONTAS_DESPESAS_DESIF"
                checked={
                  dadosDesIf.INFORMA_CONTAS_DESPESAS_DESIF?.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Informar contas de despesas"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="EXIGE_DETALHAMENTO_RATEIO_DESIF"
                id="EXIGE_DETALHAMENTO_RATEIO_DESIF"
                checked={
                  dadosDesIf.EXIGE_DETALHAMENTO_RATEIO_DESIF?.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Exigir detalhamento do rateio"
              />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="EXIGE_DETALHAMENTO_ESTORNOS_DESIF"
                id="EXIGE_DETALHAMENTO_ESTORNOS_DESIF"
                checked={
                  dadosDesIf.EXIGE_DETALHAMENTO_ESTORNOS_DESIF?.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Exigir detalhamento dos estornos"
              />
            </Col>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="PERMITE_CODIGO_INTERNO_DESIF"
                id="PERMITE_CODIGO_INTERNO_DESIF"
                checked={dadosDesIf.PERMITE_CODIGO_INTERNO_DESIF?.valor === 'S'}
                onChange={onChangeParametros}
                label="Declarar código interno"
              />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="PERMITE_MAXVALOR_COMPENSAR_0440_DESIF"
                id="PERMITE_MAXVALOR_COMPENSAR_0440_DESIF"
                checked={
                  dadosDesIf.PERMITE_MAXVALOR_COMPENSAR_0440_DESIF?.valor ===
                  'S'
                }
                onChange={onChangeParametros}
                label="Declarar valor a compensar por indébito fiscal"
              />
            </Col>
          </Row>
          {dadosDesIf.PERMITE_MAXVALOR_COMPENSAR_0440_DESIF?.valor === 'S' ? (
            <Row>
              <Col sm={3} md={3}>
                <div className="form-group">
                  <label htmlFor="MAXVALOR_COMPENSAR_0440_DESIF">
                    Valor máximo em R$
                  </label>
                  <InputInteger
                    name="MAXVALOR_COMPENSAR_0440_DESIF"
                    value={
                      dadosDesIf.MAXVALOR_COMPENSAR_0440_DESIF?.valor || ''
                    }
                    onChange={onChangeParametros}
                    required={true}
                    allowNegative={false}
                    decimalScale={2}
                    maxLength={10}
                    prefix={'R$'}
                    errormessage={'aaaa'}
                    decimalSeparator={','}
                  />
                </div>
              </Col>

              <Col sm={3} md={3}>
                <div className="form-group">
                  <label htmlFor="MAXPERCDEVIDO_COMPENSAR_0440_DESIF">
                    Percentual máximo do ISSQN
                  </label>
                  <InputInteger
                    name="MAXPERCDEVIDO_COMPENSAR_0440_DESIF"
                    value={
                      dadosDesIf.MAXPERCDEVIDO_COMPENSAR_0440_DESIF?.valor || ''
                    }
                    onChange={onChangeParametros}
                    required={true}
                    allowNegative={false}
                    decimalScale={0}
                    maxLength={3}
                    suffix={'%'}
                  />
                </div>
              </Col>

              <Col sm={3} md={3}>
                <div className="form-group">
                  <label htmlFor="MAXPERCPAGAR_COMPENSAR_0440_DESIF">
                    Percentual máximo do ISSQN a pagar
                  </label>
                  <InputInteger
                    name="MAXPERCPAGAR_COMPENSAR_0440_DESIF"
                    value={
                      dadosDesIf.MAXPERCPAGAR_COMPENSAR_0440_DESIF?.valor || ''
                    }
                    onChange={onChangeParametros}
                    required={true}
                    allowNegative={false}
                    decimalScale={0}
                    maxLength={3}
                    suffix={'%'}
                  />
                </div>
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="PERMITE_INCENTIVO_0440_DESIF"
                id="PERMITE_INCENTIVO_0440_DESIF"
                checked={dadosDesIf.PERMITE_INCENTIVO_0440_DESIF?.valor === 'S'}
                onChange={onChangeParametros}
                label="Declarar incentivo por tipo de consolidação"
              />
            </Col>
          </Row>
          {dadosDesIf.PERMITE_INCENTIVO_0440_DESIF?.valor === 'S' ? (
            <Row>
              <Col sm={3} md={3}>
                <div className="form-group">
                  <label htmlFor="PERCENTUAL_MAXIMO_INCENTIVO_0440_DESIF">
                    Percentual máximo (0.00 a 100.00)
                  </label>
                  <InputInteger
                    name="PERCENTUAL_MAXIMO_INCENTIVO_0440_DESIF"
                    value={
                      dadosDesIf.PERCENTUAL_MAXIMO_INCENTIVO_0440_DESIF
                        ?.valor || ''
                    }
                    onChange={onChangeParametros}
                    required={true}
                    allowNegative={false}
                    decimalScale={2}
                    maxLength={3}
                    suffix={'%'}
                  />
                </div>
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col md={4}>
              <Checkbox
                className="form-group"
                name="PERMITE_INCENTIVO_0430_DESIF"
                id="PERMITE_INCENTIVO_0430_DESIF"
                checked={dadosDesIf.PERMITE_INCENTIVO_0430_DESIF?.valor === 'S'}
                onChange={onChangeParametros}
                label="Declarar incentivo por subtítulo"
              />
            </Col>
          </Row>
          {dadosDesIf.PERMITE_INCENTIVO_0430_DESIF?.valor === 'S' ? (
            <Row>
              <Col sm={3} md={3}>
                <div className="form-group">
                  <label htmlFor="PERCENTUAL_MAXIMO_INCENTIVO_0430_DESIF">
                    Percentual máximo (0.00 a 100.00)
                  </label>
                  <InputInteger
                    name="PERCENTUAL_MAXIMO_INCENTIVO_0430_DESIF"
                    value={
                      dadosDesIf.PERCENTUAL_MAXIMO_INCENTIVO_0430_DESIF
                        ?.valor || ''
                    }
                    onChange={onChangeParametros}
                    required={true}
                    allowNegative={false}
                    decimalScale={2}
                    maxLength={3}
                    suffix={'%'}
                  />
                </div>
              </Col>
            </Row>
          ) : null}
        </>
      )}
    </>
  );
};
export default DesIfForm;
