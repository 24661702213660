import { FilterUtils } from '@elotech/components';
import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };

export const findById = id => axios.get(`${CONTEXT_PATH}/cadastro-geral/${id}`);

const filterAutocomplete = search => {
  return FilterUtils.buildNumberAndStringFilter(
    ['cadastroGeral'],
    ['pessoa.cnpjCpf', 'pessoa.nome']
  )(search);
};

export const loadCadastroMobiliarios = (search, page = 50) =>
  axios.get(`${CONTEXT_PATH}/cadastro-geral/mobiliarios`, {
    params: {
      search: filterAutocomplete(search),
      ...defaultSort,
      ...page
    }
  });

export const loadCnpj = (search, page = 50) => {
  return axios.get(`${CONTEXT_PATH}/cadastro-geral/mobiliarios`, {
    params: {
      search: filterAutocomplete(search),
      ...defaultSort,
      ...page
    }
  });
};
