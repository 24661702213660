import {
  ActionButton,
  ActionsGroup,
  Alert,
  Container,
  Field,
  FormattedDate,
  Loading,
  Panel,
  SearchFilter,
  SearchPagination,
  Table,
  usePagedQuery
} from '@elotech/components';
import { History } from 'history';
import React from 'react';

import { VisualizacaoGuiasEventuaisService } from '../../../service';
import { AcessoWeb } from '../../../types/AcessoWeb';
import { DeclaracaoGuiaAvulso } from '../../../types/DeclaracaoGuiaAvulso';

type Props = {
  history: Pick<History, 'push'>;
};

const searchFields: Field[] = [
  {
    label: 'Tipo Movimento',
    name: 'tipoMovimento',
    type: 'ENUM',
    options: [
      {
        descricao: 'Tomado',
        name: 'T'
      },
      {
        descricao: 'Prestado',
        name: 'P'
      }
    ]
  },
  {
    label: 'Data de Emissão',
    name: 'emissao',
    type: 'DATE'
  },
  {
    label: 'Acesso Web',
    name: 'acessoWeb.id',
    type: 'AUTOCOMPLETE',
    getOptionValue: (value: AcessoWeb) => value.id,
    getOptionLabel: (value: AcessoWeb) => value.nome!,
    loadOptions: searchParameter =>
      Promise.resolve(
        VisualizacaoGuiasEventuaisService.acessoWebAutoComplete(searchParameter)
      )
  },
  {
    label: 'Razão Social',
    name: 'notaFiscal.razaoSocial',
    type: 'STRING'
  },
  {
    label: 'CPF/CNPJ',
    name: 'notaFiscal.cnpj',
    type: 'STRING'
  },
  {
    label: 'Nº Cadastro',
    name: 'cadastroGeral.cadastroGeral',
    type: 'NUMBER'
  }
];

const onDownloadArquivo = (id: any, fileName: string) => {
  VisualizacaoGuiasEventuaisService.download(id)
    .then(response => onDownloadArquivoSuccess(response, fileName))
    .catch(onDownloadArquivoError);
};

const onDownloadArquivoSuccess = (response: any, fileName: string) => {
  const file = new Blob([response.data], { type: response.data.type });
  const fileURL = URL.createObjectURL(file);
  saveAs(fileURL, fileName);
};
const onDownloadArquivoError = (error: any) => {
  Alert.info(
    {
      title: 'Ocorreu uma falha ao fazer o download do relatório.'
    },
    error
  );
};

export const VisualizacaoGuiasEventuaisListPage: React.FC<Props> = () => {
  const {
    doSearch,
    doPagedSearch,
    values,
    pagination,
    loading
  } = usePagedQuery({
    search: VisualizacaoGuiasEventuaisService.loadDeclaracoes
  });
  return (
    <Container breadcrumb>
      <Panel isTable>
        <Loading loading={loading} />
        <SearchFilter fields={searchFields} search={doSearch} />
        <Table values={values}>
          <Table.Column
            header="Nº Identificação"
            value={(guia: DeclaracaoGuiaAvulso) => guia.id}
          ></Table.Column>
          <Table.Column
            header="Data de Emissão"
            value={(guia: DeclaracaoGuiaAvulso) => (
              <FormattedDate value={guia.dataEmissao} />
            )}
          ></Table.Column>
          <Table.Column
            header="Número Documento"
            value={(guia: DeclaracaoGuiaAvulso) => guia.numeroDocumento}
          ></Table.Column>
          <Table.Column
            header="Tomador"
            value={(guia: DeclaracaoGuiaAvulso) => guia.tomador}
          ></Table.Column>
          <Table.Column
            header="Prestador"
            value={(guia: DeclaracaoGuiaAvulso) => guia.prestador}
          ></Table.Column>
          <Table.Column
            header="Nº Cadastro"
            value={(guia: DeclaracaoGuiaAvulso) => guia.cadastroGeral}
          ></Table.Column>
          <Table.Column
            header="Tipo de Movimento"
            value={(guia: DeclaracaoGuiaAvulso) =>
              guia.tipoMovimento === 'P' ? 'Prestado' : 'Tomado'
            }
          ></Table.Column>
          <Table.Column
            header=""
            value={(guia: DeclaracaoGuiaAvulso) => (
              <ActionsGroup>
                <ActionButton
                  key="edit-button"
                  icon="edit"
                  label="Editar"
                  path={`/acessos-e-permissoes/visualizacao-guias-eventuais/${guia.id}/visualizar`}
                ></ActionButton>
                <ActionButton
                  key="download-button"
                  icon="download"
                  label="Download"
                  onClick={() =>
                    onDownloadArquivo(guia.id, guia.nomeArquivoAnexo)
                  }
                ></ActionButton>
              </ActionsGroup>
            )}
          ></Table.Column>
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
    </Container>
  );
};

export default VisualizacaoGuiasEventuaisListPage;
