import { Col, Row, SectionTitle } from '@elotech/components';
import React from 'react';

import Parametro from '../component/Parametro';

type Props = {
  dados: any;
  onToggle: any;
  showForm: any;
  onChangeParametros: any;
};

const NfseNacionalForm: React.FC<Props> = ({
  dados,
  onToggle,
  showForm,
  onChangeParametros
}) => {
  return (
    <>
      <SectionTitle hasButton={true}>
        NFS-e (Padrão Nacional)
        <button
          className="dropdown-arrow"
          data-test-id="button"
          onClick={onToggle}
        >
          <i className={showForm ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} />
        </button>
      </SectionTitle>
      {showForm ? (
        <>
          <Row>
            <Col md={12}>
              <Parametro
                type="checkbox"
                id="IMPORTARNFSENACIONAL"
                name="IMPORTARNFSENACIONAL"
                value={dados.IMPORTARNFSENACIONAL.valor === 'S'}
                onChange={onChangeParametros}
                size={12}
                label="Habilitar importação de NFS-e - Sefin Nacional"
              />
              <Parametro
                type="checkbox"
                id="NFSENACIONALHOMOLOGACAO"
                name="NFSENACIONALHOMOLOGACAO"
                value={dados.NFSENACIONALHOMOLOGACAO.valor === 'S'}
                onChange={onChangeParametros}
                size={12}
                label="Importar notas do ambiente de Homologação (Produção Restrita)"
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Parametro
                type="integer"
                id="NFSENACIONALNSUMUNICIPIO"
                name="NFSENACIONALNSUMUNICIPIO"
                value={dados.NFSENACIONALNSUMUNICIPIO.valor || ''}
                onChange={onChangeParametros}
                size={12}
                label="Último NSU - DISTRIBUICAO"
              />
              <Parametro
                type="integer"
                id="NFSENACIONALNSUMUNICIPIOMEI"
                name="NFSENACIONALNSUMUNICIPIOMEI"
                value={dados.NFSENACIONALNSUMUNICIPIOMEI.valor || ''}
                onChange={onChangeParametros}
                size={12}
                label="Último NSU - MEI"
              />
            </Col>
          </Row>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default NfseNacionalForm;
