import {
  Alert,
  Container,
  FAB,
  Hint,
  Loading,
  NotificationActions,
  formatUtils
} from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  CadastroGeralService,
  ConfiguracaoParametrosService,
  IncentivoFiscalService,
  LeiService,
  ServicoService,
  withService
} from '../../../service';
import DadosGeraisForm from './form-dados-gerais/DadosGeraisForm';
import EmpresasForm from './form-empresas/EmpresasForm';
import ObrasForm from './form-obras/ObrasForm';
import ServicosForm from './form-servicos/ServicosForm';

const DESCONTO_BASE_CALCULO = 'BC';
const INCIDENCIA_TODOS_MUNICIPIOS = 'TM';
const INCENTIVO_CONTRIBUINTE_PRESTADOR = 'P';
const TIPO_INCENTIVO_REDUCAO = 'RE';
const TIPO_INCENTIVO_DEDUCAO = 'DE';
const PARAMETRO_GERAL_PERCENTUAL_DEDUCAO = 'PERCENTUALMAXDEDUCAONFSE';
let parametroGeralDeducaoPermitida;

export class IncentivosFiscaisNewEditPage extends Component {
  static propTypes = {
    leiService: PropTypes.object.isRequired,
    cadastroGeralService: PropTypes.object.isRequired,
    servicoService: PropTypes.object.isRequired,
    incentivoFiscalService: PropTypes.object.isRequired,
    configuracaoParametroGeralService: PropTypes.object.isRequired
  };

  state = {
    showFormServico: false,
    showFormEmpresa: false,
    incentivoFiscal: {
      id: '',
      descricao: '',
      lei: '',
      cadastroGeral: '',
      dataInicial: '',
      dataFinal: '',
      tipoIncentivo: 'RE',
      percentualDeducao: parametroGeralDeducaoPermitida,
      imprimeIncentivoNota: false,
      descServicosEspecificos: false,
      percentualDesconto: '',
      tipoIncentivoContribuinte: INCENTIVO_CONTRIBUINTE_PRESTADOR,
      tipoIncidencia: INCIDENCIA_TODOS_MUNICIPIOS,
      servicos: [],
      obras: [],
      empresas: []
    },
    error: {
      descricao: false,
      lei: false,
      cadastroGeral: false,
      dataInicial: false,
      tipoIncentivo: false,
      percentualDeducao: false,
      percentualDesconto: false,
      percentualDescontoMaxValue: false
    },

    servico: {
      id: '',
      servico: '',
      tipoCalculo: DESCONTO_BASE_CALCULO,
      percentualDesconto: '',
      aliquota: ''
    },
    errorServico: {
      servico: false,
      percentualDesconto: false,
      percentualDescontoMaxValue: false,
      aliquota: false
    },

    obra: {
      id: '',
      codigoObra: '',
      art: ''
    },
    errorObra: {
      codigoObra: false,
      art: false
    },

    grupoIncentivoEmpresa: {
      id: '',
      cadastroGeral: ''
    },
    errorGrupoIncentivoEmpresa: {
      cadastroGeral: false
    },

    loading: false
  };

  componentDidMount() {
    const { id } = this.props.match.params;

    if (id) {
      this.setState({ loading: true });
      this.props.incentivoFiscalService
        .loadIncentivoFiscalById(id)
        .then(this.loadIncentivoFiscalSuccess)
        .catch(this.loadIncentivoFiscalError);
    }

    this.props.configuracaoParametroGeralService
      .findByParamName(PARAMETRO_GERAL_PERCENTUAL_DEDUCAO)
      .then(this.loadParametroGeralDeducaoPermitidaSuccess)
      .catch(this.loadParametroGeralDeducaoPermitidaError);
  }

  loadParametroGeralDeducaoPermitidaSuccess = response => {
    parametroGeralDeducaoPermitida = response.data;
  };

  loadParametroGeralDeducaoPermitidaError = () => {
    this.props.showNotification({
      level: 'error',
      message: 'Não foi possível carregar o parâmetro geral de dedução.'
    });
  };

  loadIncentivoFiscalSuccess = response => {
    this.setState({ loading: false });
    this.setState({ incentivoFiscal: response.data });
  };

  loadIncentivoFiscalError = () => {
    this.setState({ loading: false });

    this.props.showNotification({
      level: 'error',
      message: 'Não foi possível carregar o incentivo fiscal.'
    });
  };

  validators = {
    descricao: value => value,
    lei: value => value,
    cadastroGeral: value => value,
    dataInicial: value => value,
    tipoIncentivo: value => value,
    percentualDesconto: value =>
      this.state.incentivoFiscal.descServicosEspecificos ||
      this.state.incentivoFiscal.tipoIncentivo !== TIPO_INCENTIVO_REDUCAO ||
      value,
    percentualDeducao: value =>
      this.state.incentivoFiscal.tipoIncentivo !== TIPO_INCENTIVO_DEDUCAO ||
      value,
    percentualDescontoMaxValue: value => !value || value <= 100,
    percentualDeducaoMaxValue: value => !value || value <= 100,
    percentualDeducaoMinValue: value =>
      !value || value < parametroGeralDeducaoPermitida
  };

  validatorsServico = {
    servico: value => value,
    aliquota: value =>
      this.state.servico.percentualDesconto ||
      this.state.incentivoFiscal.tipoIncentivo !== TIPO_INCENTIVO_REDUCAO ||
      value,
    percentualDesconto: value =>
      this.state.servico.aliquota ||
      this.state.incentivoFiscal.tipoIncentivo !== TIPO_INCENTIVO_REDUCAO ||
      value,
    percentualDescontoMaxValue: value => !value || value <= 100,
    aliquotaMinValue: value => !value || value >= 0
  };

  validatorsObra = {
    codigoObra: value => value,
    art: value => value
  };

  validatorsEmpresa = {
    cadastroGeral: value => value
  };

  validDadosServicos = servicos => {
    const servicosValidos = servicos.filter(servico => {
      const { incentivoFiscal } = this.state;
      if (incentivoFiscal.tipoIncentivo === TIPO_INCENTIVO_REDUCAO) {
        return (
          servico.tipoCalculo !== '' &&
          (servico.percentualDesconto !== '' || servico.aliquota !== '')
        );
      } else {
        return true;
      }
    });

    return servicosValidos.length === servicos.length;
  };

  validCamposObrigatorios = dados => {
    const errorsCamposObrigatorios = Object.keys(dados).filter(field => {
      return this.validators[field] && !this.validators[field](dados[field]);
    });
    const objErro = errorsCamposObrigatorios.reduce((total, current) => {
      total[current] = true;
      return total;
    }, {});

    return objErro;
  };

  validFieldsServicos = () => {
    const { incentivoFiscal } = this.state;

    const nenhumDescontoTodosServicos = incentivoFiscal.descServicosEspecificos;

    const naoContemServicos =
      incentivoFiscal.servicos === undefined ||
      !incentivoFiscal.servicos.length > 0;

    let servicosValidados = false;
    if (!naoContemServicos) {
      servicosValidados = !this.validDadosServicos(incentivoFiscal.servicos);
    }

    const temErro =
      (naoContemServicos && nenhumDescontoTodosServicos) || servicosValidados;

    return temErro;
  };

  hasErrorsDadosGerais = () => {
    const { incentivoFiscal } = this.state;

    const errors = Object.keys(incentivoFiscal).filter(field => {
      return (
        this.validators[field] &&
        !this.validators[field](incentivoFiscal[field])
      );
    });

    const objErro = errors.reduce((total, current) => {
      total[current] = true;
      return total;
    }, {});

    this.setState({ error: objErro });

    const limitPercentualDesconto =
      incentivoFiscal.percentualDesconto !== undefined &&
      !this.validators.percentualDescontoMaxValue(
        incentivoFiscal.percentualDesconto
      );

    const erroPercentualDescontoMax =
      limitPercentualDesconto &&
      !incentivoFiscal.descServicosEspecificos &&
      incentivoFiscal.tipoIncentivo === TIPO_INCENTIVO_REDUCAO;
    if (erroPercentualDescontoMax) {
      this.setState(state => {
        const { error } = state;
        return {
          error: {
            ...error,
            percentualDescontoMaxValue: true
          }
        };
      });
    }

    return errors.length > 0 || erroPercentualDescontoMax;
  };

  onSelect = (name, value) => {
    this.setState(state => {
      return this.atualizarStateIncentivoFiscal(state, name, value);
    });
  };

  onSelectServico = (name, value) => {
    this.setState(state => {
      return this.atualizarStateServico(state, name, value);
    });
  };

  onSelectEmpresa = (name, value) => {
    this.setState(state => {
      return this.atualizarStateGrupoEmpresa(state, name, value);
    });
  };

  onChangeDescontoServicosEspecificos = event => {
    const { incentivoFiscal } = this.state;

    if (incentivoFiscal.servicos.length === 0) {
      const { name, value, checked, type } = event.target;

      this.setState(
        state => {
          return this.atualizarStateIncentivoFiscal(
            state,
            name,
            value,
            checked,
            type
          );
        },
        () => {
          this.setState(state => {
            const { incentivoFiscal, error } = state;
            return {
              error: {
                ...error,
                percentualDesconto: !this.validators.percentualDesconto(
                  incentivoFiscal.percentualDesconto
                )
              }
            };
          });
        }
      );
    } else {
      this.props.showNotification({
        level: 'error',
        message:
          'Remova os incentivos dados a cada serviço antes de conceder desconto a todos.'
      });
    }
  };

  onChangePercentualDescontoTodosServicos = event => {
    const { name, value, checked, type } = event.target;
    this.setState(
      state => {
        return this.atualizarStateIncentivoFiscal(
          state,
          name,
          value,
          checked,
          type
        );
      },
      () => {
        this.setState(state => {
          const { incentivoFiscal, error } = state;
          return {
            error: {
              ...error,
              percentualDescontoMaxValue: !this.validators.percentualDescontoMaxValue(
                incentivoFiscal.percentualDesconto
              )
            }
          };
        });
      }
    );
  };

  onChangePercentualDeducao = event => {
    const { name, value, checked, type } = event.target;

    this.setState(
      state => {
        return this.atualizarStateIncentivoFiscal(
          state,
          name,
          value,
          checked,
          type
        );
      },
      () => {
        this.setState(state => {
          const { incentivoFiscal, error } = state;
          return {
            error: {
              ...error,
              percentualDeducaoMaxValue: !this.validators.percentualDeducaoMaxValue(
                incentivoFiscal.percentualDeducao
              ),
              percentualDeducaoMinValue: this.validators.percentualDeducaoMinValue(
                incentivoFiscal.percentualDeducao
              )
            }
          };
        });
      }
    );
  };

  onChangeInputValue = event => {
    const { name, value, checked, type } = event.target;
    this.setState(state => {
      return this.atualizarStateIncentivoFiscal(
        state,
        name,
        value,
        checked,
        type
      );
    });
  };

  onChangeInputDescontoBaseCalculoServico = event => {
    const { name, value } = event.target;

    this.setState(
      state => {
        return this.atualizarStateServico(state, name, value);
      },
      () => {
        this.setState(state => {
          const nameAliquota = 'aliquota';
          const valor = state.servico[nameAliquota];
          const namePercentualMax = 'percentualDescontoMaxValue';
          const valorPercentual = state.servico['percentualDesconto'];

          const { errorServico } = state;
          return {
            errorServico: {
              ...errorServico,
              [nameAliquota]:
                this.validatorsServico[nameAliquota] &&
                !this.validatorsServico[nameAliquota](valor),
              [namePercentualMax]:
                this.validatorsServico[namePercentualMax] &&
                !this.validatorsServico[namePercentualMax](valorPercentual)
            }
          };
        });
      }
    );
  };

  onChangeInputAliquotaServico = event => {
    const { name, value } = event.target;

    this.setState(
      state => {
        return this.atualizarStateServico(state, name, value);
      },
      () => {
        this.setState(state => {
          const namePercentualDesconto = 'percentualDesconto';
          const valor = state.servico[namePercentualDesconto];
          const nameAliquotaMin = 'aliquotaMinValue';
          const valorAliquota = state.servico['aliquota'];

          const { errorServico } = state;
          return {
            errorServico: {
              ...errorServico,
              [namePercentualDesconto]:
                this.validatorsServico[namePercentualDesconto] &&
                !this.validatorsServico[namePercentualDesconto](valor),
              [nameAliquotaMin]:
                this.validatorsServico[nameAliquotaMin] &&
                !this.validatorsServico[nameAliquotaMin](valorAliquota)
            }
          };
        });
      }
    );
  };

  onChangeInputServico = event => {
    const { name, value, checked, type } = event.target;

    this.setState(state => {
      return this.atualizarStateServico(state, name, value, checked, type);
    });
  };

  onChangeInputObra = event => {
    const { name, value } = event.target;
    this.setState(state => {
      const { obra, errorObra } = state;

      return {
        obra: { ...obra, [name]: value },
        errorObra: {
          ...errorObra,
          [name]: this.validatorsObra[name] && !this.validatorsObra[name](value)
        }
      };
    });
  };

  onSaveServico = () => {
    if (!this.hasErrorsServico()) {
      this.setState(state => {
        const { servico, incentivoFiscal, showFormServico } = state;

        let servicos;
        if (servico.indexEdit >= 0) {
          incentivoFiscal.servicos[servico.indexEdit] = servico;
          servicos = incentivoFiscal.servicos;
        } else {
          servicos = incentivoFiscal.servicos.concat(servico);
        }

        return {
          incentivoFiscal: {
            ...incentivoFiscal,
            servicos
          },
          showFormServico: !showFormServico,
          servico: {
            id: '',
            servico: '',
            tipoCalculo: DESCONTO_BASE_CALCULO,
            percentualDesconto: '',
            aliquota: ''
          }
        };
      });
    }
  };

  onSaveObra = () => {
    if (!this.hasErrorsObra()) {
      this.setState(state => {
        const { obra, incentivoFiscal, showFormObra } = state;

        let obras;
        if (obra.indexEdit >= 0) {
          incentivoFiscal.obras[obra.indexEdit] = obra;
          obras = incentivoFiscal.obras;
        } else {
          obras = incentivoFiscal.obras.concat(obra);
        }

        return {
          incentivoFiscal: {
            ...incentivoFiscal,
            obras
          },
          showFormObra: !showFormObra,
          obra: {
            id: '',
            codigoObra: '',
            art: ''
          }
        };
      });
    }
  };

  onSaveEmpresa = () => {
    if (!this.hasErrorsEmpresa() && !this.empresaJaVinculada()) {
      this.setState(state => {
        const {
          grupoIncentivoEmpresa,
          incentivoFiscal,
          showFormEmpresa
        } = state;
        const empresas = incentivoFiscal.empresas.concat(grupoIncentivoEmpresa);

        return {
          incentivoFiscal: {
            ...incentivoFiscal,
            empresas
          },
          showFormEmpresa: !showFormEmpresa
        };
      });
    }

    this.setState({
      grupoIncentivoEmpresa: {
        id: '',
        cadastroGeral: ''
      }
    });
  };

  onCancelServico = () => {
    this.setState(state => {
      const { showFormServico } = state;
      return {
        showFormServico: !showFormServico,
        servico: {
          id: '',
          servico: '',
          tipoCalculo: DESCONTO_BASE_CALCULO,
          percentualDesconto: '',
          aliquota: ''
        }
      };
    });
  };

  onCancelEmpresa = () => {
    this.setState({
      showFormEmpresa: false,
      grupoIncentivoEmpresa: {
        id: '',
        cadastroGeral: ''
      }
    });
  };

  onCancelObra = () => {
    this.setState({
      showFormObra: false,
      obra: {
        id: '',
        codigoObra: '',
        art: ''
      }
    });
  };

  onEditObra = index => {
    this.setState(state => {
      const { incentivoFiscal } = state;

      let obraEdicao = incentivoFiscal.obras[index];
      obraEdicao = {
        ...obraEdicao,
        indexEdit: index
      };

      return {
        obra: obraEdicao,
        showFormObra: true
      };
    });
  };

  onEditServico = index => {
    this.setState(state => {
      const { incentivoFiscal } = state;

      let servicoEdicao = incentivoFiscal.servicos[index];

      if (!servicoEdicao.aliquota) {
        servicoEdicao.aliquota = '';
      }
      if (!servicoEdicao.percentualDesconto) {
        servicoEdicao.percentualDesconto = '';
      }

      servicoEdicao = {
        ...servicoEdicao,
        indexEdit: index
      };

      return {
        servico: servicoEdicao,
        showFormServico: true
      };
    });
  };

  onRemoveObra = index => {
    this.setState(state => {
      const { incentivoFiscal } = state;
      const obras = incentivoFiscal.obras.filter((item, i) => index !== i);
      return {
        incentivoFiscal: {
          ...incentivoFiscal,
          obras
        }
      };
    });
  };

  onRemoveEmpresa = index => {
    this.setState(state => {
      const { incentivoFiscal } = state;
      const empresas = incentivoFiscal.empresas.filter(
        (item, i) => index !== i
      );
      return {
        incentivoFiscal: {
          ...incentivoFiscal,
          empresas
        }
      };
    });
  };

  onExcluirServico = index => {
    this.setState(state => {
      const { incentivoFiscal } = state;
      const servicos = incentivoFiscal.servicos.filter(
        (item, i) => index !== i
      );
      return {
        incentivoFiscal: {
          ...incentivoFiscal,
          servicos
        }
      };
    });
  };

  atualizarStateIncentivoFiscal = (
    state,
    name,
    value,
    checked = '',
    type = ''
  ) => {
    const { incentivoFiscal, error } = state;
    const novoValor = type === 'checkbox' ? checked : value;

    return {
      incentivoFiscal: { ...incentivoFiscal, [name]: novoValor },
      error: {
        ...error,
        [name]: this.validators[name] && !this.validators[name](novoValor)
      }
    };
  };

  atualizarStateServico = (state, name, value, checked = '', type = '') => {
    const { servico, errorServico } = state;
    const novoValor = type === 'checkbox' ? checked : value;
    return {
      servico: { ...servico, [name]: novoValor },
      errorServico: {
        ...errorServico,
        [name]:
          this.validatorsServico[name] &&
          !this.validatorsServico[name](novoValor)
      }
    };
  };

  atualizarStateGrupoEmpresa = (state, name, value) => {
    const { grupoIncentivoEmpresa, errorGrupoIncentivoEmpresa } = state;
    return {
      grupoIncentivoEmpresa: { ...grupoIncentivoEmpresa, [name]: value },
      errorGrupoIncentivoEmpresa: {
        ...errorGrupoIncentivoEmpresa,
        [name]:
          this.validatorsEmpresa[name] && !this.validatorsEmpresa[name](value)
      }
    };
  };

  hasErrorsObra = () => {
    const { obra } = this.state;

    const errorsCamposObrigatorios = Object.keys(obra).filter(field => {
      return (
        this.validatorsObra[field] && !this.validatorsObra[field](obra[field])
      );
    });
    const objErro = errorsCamposObrigatorios.reduce((total, current) => {
      total[current] = true;
      return total;
    }, {});

    this.setState({ errorObra: objErro });
    return errorsCamposObrigatorios.length > 0;
  };

  hasErrorsEmpresa = () => {
    const { grupoIncentivoEmpresa } = this.state;

    const errorsCamposObrigatorios = Object.keys(grupoIncentivoEmpresa).filter(
      field => {
        return (
          this.validatorsEmpresa[field] &&
          !this.validatorsEmpresa[field](grupoIncentivoEmpresa[field])
        );
      }
    );

    const objErro = errorsCamposObrigatorios.reduce((total, current) => {
      total[current] = true;
      return total;
    }, {});

    this.setState({ errorGrupoIncentivoEmpresa: objErro });
    return errorsCamposObrigatorios.length > 0;
  };

  empresaJaVinculada = () => {
    const { grupoIncentivoEmpresa, incentivoFiscal } = this.state;
    const empresaJaCadastrada = incentivoFiscal.empresas.filter(
      empresa =>
        empresa.cadastroGeral.id === grupoIncentivoEmpresa.cadastroGeral.id
    );

    if (empresaJaCadastrada.length > 0) {
      this.props.showNotification({
        level: 'error',
        message: 'Empresa já vinculada.'
      });
      return true;
    }
    return false;
  };

  hasErrorsServico = () => {
    const { servico } = this.state;

    const errorsCamposObrigatorios = Object.keys(servico).filter(field => {
      return (
        this.validatorsServico[field] &&
        !this.validatorsServico[field](servico[field])
      );
    });
    const objErro = errorsCamposObrigatorios.reduce((total, current) => {
      total[current] = true;
      return total;
    }, {});

    this.setState({ errorServico: objErro });

    const erroPercentualDescontoMax =
      servico.percentualDesconto !== undefined &&
      !this.validatorsServico.percentualDescontoMaxValue(
        servico.percentualDesconto
      );

    if (erroPercentualDescontoMax) {
      this.setState(state => {
        const { errorServico } = state;
        return {
          errorServico: {
            ...errorServico,
            percentualDescontoMaxValue: true
          }
        };
      });
    }

    const erroAliquotaMin =
      servico.aliquota !== undefined &&
      !this.validatorsServico.aliquotaMinValue(servico.aliquota);

    if (erroAliquotaMin) {
      this.setState(state => {
        const { errorServico } = state;
        return {
          errorServico: {
            ...errorServico,
            aliquotaMinValue: true
          }
        };
      });
    }

    return (
      errorsCamposObrigatorios.length > 0 ||
      erroPercentualDescontoMax ||
      erroAliquotaMin
    );
  };

  onSaveIncentivoFiscalSuccess = () => {
    this.props.showNotification({
      level: 'success',
      message: 'Salvo com sucesso'
    });

    this.props.history.replace('/acessos-e-permissoes/incentivos-fiscais');
  };

  onSaveIncentivoFiscalError = error => {
    this.setState({ loading: false });

    Alert.error(
      { title: 'Não foi possível salvar o incentivo fiscal.' },
      error
    );
  };

  onSave = () => {
    const { incentivoFiscal } = this.state;

    if (incentivoFiscal.percentualDeducao < parametroGeralDeducaoPermitida) {
      this.props.showNotification({
        level: 'error',
        message: `Percentual de dedução não pode ser menor que ${parametroGeralDeducaoPermitida}% já configurado no parametro geral da instituição.`
      });
      return;
    }

    if (
      this.hasErrorsDadosGerais(
        this.getCamposDadosGeraisValidacao(incentivoFiscal)
      )
    ) {
      this.props.showNotification({
        level: 'error',
        message: 'Verifique os erros.'
      });
    } else if (this.validFieldsServicos()) {
      this.props.showNotification({
        level: 'error',
        message: 'Nenhum serviço informado ou erro nos dados do serviço.'
      });
    } else {
      const { incentivoFiscal } = this.state;
      this.setState({ loading: true });
      this.props.incentivoFiscalService
        .save(incentivoFiscal)
        .then(this.onSaveIncentivoFiscalSuccess)
        .catch(this.onSaveIncentivoFiscalError);
    }
  };

  optionLabel = option => {
    if (option.pessoa) {
      return (
        formatUtils.formatCpfCnpj(option.pessoa.cnpjCpf) +
        ' - ' +
        option.pessoa.nome +
        ' - ' +
        option.tipoCadastro +
        ' - ' +
        option.cadastroGeral
      );
    }
    return '';
  };

  getCamposDadosGeraisValidacao = incentivoFiscal => {
    const {
      id,
      descricao,
      lei,
      cadastroGeral,
      dataInicial,
      dataFinal,
      tipoIncentivo,
      imprimeIncentivoNota,
      descServicosEspecificos,
      tipoIncentivoContribuinte,
      tipoIncidencia
    } = incentivoFiscal;

    const dadosGerais = {
      id,
      descricao,
      lei,
      cadastroGeral,
      dataInicial,
      dataFinal,
      tipoIncentivo,
      imprimeIncentivoNota,
      descServicosEspecificos,
      tipoIncidencia,
      tipoIncentivoContribuinte
    };
    return dadosGerais;
  };

  getCamposServicos = incentivoFiscal => {
    const { percentualDesconto } = incentivoFiscal;

    const dadosServicos = { percentualDesconto };

    return dadosServicos;
  };

  newServico = () => {
    this.setState({ showFormServico: true });
  };

  onNewObra = () => {
    this.setState({ showFormObra: true });
  };

  onNewEmpresa = () => {
    this.setState({ showFormEmpresa: true });
  };

  cancelarCadastroIncentivoFiscal = () => {
    this.props.history.goBack();
  };

  validarDeducao = (valor, parametroGeralDeducaoPermitida) => {
    return valor < parametroGeralDeducaoPermitida;
  };

  handleBlur = event => {
    const valor = event.target.value;
    const isValid = this.validarDeducao(valor, parametroGeralDeducaoPermitida);
    if (isValid) {
      this.props.showNotification({
        level: 'error',
        message: `Percentual de dedução não pode ser menor que ${parametroGeralDeducaoPermitida}% já configurado no parametro geral da instituição.`
      });
    }
  };

  render() {
    const {
      error,
      errorServico,
      incentivoFiscal,
      showFormServico,
      showFormObra,
      servico,
      obra,
      errorObra,
      loading,
      grupoIncentivoEmpresa,
      errorGrupoIncentivoEmpresa,
      showFormEmpresa
    } = this.state;
    return (
      <Container
        breadcrumb
        title="Incentivos Fiscais"
        icon="donate"
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-incentivo-fiscal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />

        <DadosGeraisForm
          incentivoFiscal={incentivoFiscal}
          onChangeInputValue={this.onChangeInputValue}
          loadLeis={this.props.leiService.loadLeis}
          loadCadastrosMobiliarios={
            this.props.cadastroGeralService.loadCadastroMobiliarios
          }
          onSelect={this.onSelect}
          optionLabel={this.optionLabel}
          error={error}
          onChangePercentualDescontoTodosServicos={
            this.onChangePercentualDescontoTodosServicos
          }
          onChangePercentualDeducao={this.onChangePercentualDeducao}
          handleBlur={this.handleBlur}
          onChange={this.onChange}
          onChangeDescontoServicosEspecificos={
            this.onChangeDescontoServicosEspecificos
          }
        />

        <ServicosForm
          incentivoFiscal={incentivoFiscal}
          servico={servico}
          errorServico={errorServico}
          showFormServico={showFormServico}
          onChangeServico={this.onChangeInputServico}
          onChangeBaseCalculoServico={
            this.onChangeInputDescontoBaseCalculoServico
          }
          onChangeAliquotaServico={this.onChangeInputAliquotaServico}
          onSelect={this.onSelectServico}
          onSaveServico={this.onSaveServico}
          onCancelServico={this.onCancelServico}
          newServico={this.newServico}
          loadServicos={this.props.servicoService.loadServicos}
          onExcluirServico={this.onExcluirServico}
          onEditServico={this.onEditServico}
        />

        <ObrasForm
          incentivoFiscal={incentivoFiscal}
          obra={obra}
          errorObra={errorObra}
          showFormObra={showFormObra}
          onChangeObra={this.onChangeInputObra}
          onSaveObra={this.onSaveObra}
          onCancelObra={this.onCancelObra}
          onNewObra={this.onNewObra}
          onEditObra={this.onEditObra}
          onRemoveObra={this.onRemoveObra}
        />

        <EmpresasForm
          incentivoFiscal={incentivoFiscal}
          empresa={grupoIncentivoEmpresa}
          errorEmpresa={errorGrupoIncentivoEmpresa}
          showFormEmpresa={showFormEmpresa}
          onSaveEmpresa={this.onSaveEmpresa}
          onCancelEmpresa={this.onCancelEmpresa}
          onSelectEmpresa={this.onSelectEmpresa}
          loadCadastrosMobiliarios={
            this.props.cadastroGeralService.loadCadastroMobiliarios
          }
          optionLabel={this.optionLabel}
          onNewEmpresa={this.onNewEmpresa}
          onRemoveEmpresa={this.onRemoveEmpresa}
        />

        <div className="btn-save">
          <FAB
            data-test-id="buttonSave"
            typeButton="module-color"
            icon="check"
            title="Salvar"
            onClick={() => this.onSave(incentivoFiscal)}
          />
        </div>
      </Container>
    );
  }
}

const ComponentWithService = withService({
  leiService: LeiService,
  cadastroGeralService: CadastroGeralService,
  servicoService: ServicoService,
  incentivoFiscalService: IncentivoFiscalService,
  configuracaoParametroGeralService: ConfiguracaoParametrosService
})(IncentivosFiscaisNewEditPage);

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

export default connect(null, mapDispatchToProps)(ComponentWithService);
