import {
  ActionButton,
  ActionsGroup,
  Alert,
  Chip,
  Container,
  FormattedDateTime,
  Loading,
  Panel,
  SearchFilter,
  SearchPagination,
  Table,
  usePagedQuery
} from '@elotech/components';
import { Field } from '@elotech/components/src/type';
import React from 'react';

import {
  NFSeNacionalStatusOptions,
  NFSeNacionalTipoNSUOptions
} from '../../../enums';
import { NfseNacionalImportacaoService } from '../../../service';
import { NfseNacionalImportacao } from '../../../types';

const fields: Field[] = [
  {
    label: 'NSU',
    name: 'nsu',
    type: 'NUMBER'
  },
  {
    label: 'Tipo NSU',
    name: 'tipoNSU',
    type: 'ENUM',
    options: NFSeNacionalTipoNSUOptions
  },
  {
    label: 'Chave de Acesso',
    name: 'chaveAcesso',
    type: 'STRING'
  },
  {
    label: 'Status',
    name: 'status',
    type: 'ENUM',
    options: NFSeNacionalStatusOptions
  }
];

const ImportacaoNfseNacionalListPage: React.FC = () => {
  const {
    loading,
    values,
    pagination,
    doSearch,
    doPagedSearch
  } = usePagedQuery<NfseNacionalImportacao>({
    search: NfseNacionalImportacaoService.searchNfseImportacoes,
    onError: error => {
      Alert.error(
        {
          title: `Erro ao buscar os dados das importações`
        },
        error
      );
    }
  });

  const downloadXml = (nfse: NfseNacionalImportacao) => {
    const blob = new Blob(
      [decodeURIComponent(escape(window.atob(nfse.arquivoXml)))],
      {
        type: 'application/xml;charset=utf-8;'
      }
    );

    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
  };

  const copyToClipboard = (text: string) => {
    var input = document.createElement('textarea');
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
  };

  const copyStackTrace = (nfse: NfseNacionalImportacao) => {
    copyToClipboard(nfse.stackTraceErro ?? '');
  };

  const showError = (nfse: NfseNacionalImportacao) => {
    Alert.error({
      html: `${nfse.mensagenErro ?? 'erro não definido'}`
    });
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <Panel isTable>
        <SearchFilter fields={fields} search={doSearch} />
        <Table
          values={values}
          keyExtractor={item => item.id}
          highlightError={item => item.status === 'ERRO'}
        >
          <Table.Column<NfseNacionalImportacao>
            header="ID"
            value={item => item.id}
          />
          <Table.Column<NfseNacionalImportacao>
            header="NSU"
            value={item => item.nsu}
          />
          <Table.Column<NfseNacionalImportacao>
            header="Tipo NSU"
            value={item => item.tipoNSU}
          />
          <Table.Column<NfseNacionalImportacao>
            header="Chave de Acesso"
            value={item => item.chaveAcesso}
          />
          <Table.Column<NfseNacionalImportacao>
            align="center"
            header="Data"
            value={item => <FormattedDateTime value={item.dataHoraGeracao} />}
          />
          <Table.Column<NfseNacionalImportacao>
            header="Tipo de Documento"
            value={item => item.tipoDocumento}
          />
          <Table.Column<NfseNacionalImportacao>
            header="Tipo de Evento"
            value={item => item.tipoEvento}
          />
          <Table.Column<NfseNacionalImportacao>
            header="Cadatro Prestador"
            align="center"
            value={item => (
              <Chip
                className="center"
                value={item.cadastroPrestador ? 'Possui' : 'Não Possui'}
                color={item.cadastroPrestador ? 'neutral' : 'negative'}
              />
            )}
          />
          <Table.Column<NfseNacionalImportacao>
            header="Cadatro Tomador"
            align="center"
            value={item => (
              <Chip
                className="center"
                value={item.cadastroTomador ? 'Possui' : 'Não Possui'}
                color={item.cadastroTomador ? 'neutral' : 'negative'}
              />
            )}
          />
          <Table.Column<NfseNacionalImportacao>
            header="Status"
            value={item => item.status}
          />
          <Table.Column<NfseNacionalImportacao>
            header=""
            value={item => (
              <ActionsGroup>
                {!!item.mensagenErro && (
                  <ActionButton
                    data-test-id={`teste1`}
                    key="view-error-button"
                    icon="exclamation-circle"
                    label="Visualizar Erro"
                    onClick={() => showError(item)}
                  />
                )}
                {!!item.stackTraceErro && (
                  <ActionButton
                    data-test-id={`teste2`}
                    key="copy-button"
                    icon="copy"
                    label="Copiar stackTrace"
                    onClick={() => copyStackTrace(item)}
                  />
                )}
                <ActionButton
                  data-test-id={`teste3`}
                  key="download-button"
                  icon="download"
                  label="Baixar XML"
                  onClick={() => downloadXml(item)}
                />
              </ActionsGroup>
            )}
          />
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
      {/* <div className="btn-save">
        <FAB
          data-test-id="btn-new"
          icon="plus"
          title="Adicionar Novo"
          onClick={this.onNew}
        />
      </div> */}
    </Container>
  );
};

export default ImportacaoNfseNacionalListPage;
