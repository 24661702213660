import { Panel, SectionTitle, Table } from '@elotech/components';
import React from 'react';

import { DeclaracaoMensalDividaConfig } from '../../../types/DeclaracaoMensalDividaConfig';
import { GuiaRecolhimento } from '../../../types/GuiaRecolhimento';

type Props = {
  dmsDividasConfig: DeclaracaoMensalDividaConfig[];
};

const tipoDeclaracao = (valor: string) => {
  switch (valor) {
    case 'N':
      return 'Normal';
    case 'R':
      return 'Retificadora';
    case 'S':
      return 'Sem Movimento';
    case 'M':
      return 'Retificadora Sem Movimento';
    case 'A':
      return 'Declaração Avulsa';
    case 'F':
      return 'Fiscalização ISS';
    case 'C':
      return 'Construção Civil';
    default:
      return 'Não encontrado';
  }
};

const tipoMovimento = (valor: string) => {
  switch (valor) {
    case 'P':
      return 'Prestado';
    case 'T':
      return 'Tomado';
    default:
      return 'Não encontrado';
  }
};

const descricaoGuiaRecolhimento = (valor: GuiaRecolhimento) => {
  return `${valor.guiaRecolhimento} - ${valor.descricao}`;
};

const descricaoStatus = (valor: number) => {
  if (valor) {
    return 'Cadastrado';
  }
  return 'Não Cadastrado';
};

const DeclaracaoMensalDividaConfigList: React.FC<Props> = ({
  dmsDividasConfig
}) => {
  return (
    <>
      <SectionTitle>Configuração Dívida DMS</SectionTitle>

      <Panel isTable>
        <Table
          values={dmsDividasConfig}
          keyExtractor={(item: DeclaracaoMensalDividaConfig) =>
            item.tipoDeclaracao + item.tipoMovimento + item.guiaRecolhimento
          }
        >
          <Table.Column<DeclaracaoMensalDividaConfig>
            header="Exercício"
            value={value => `${value.exercicio}`}
          />
          <Table.Column<DeclaracaoMensalDividaConfig>
            header="Tipo Declaração"
            value={value => tipoDeclaracao(value.tipoDeclaracao)}
          />
          <Table.Column<DeclaracaoMensalDividaConfig>
            header="Tipo Movimento"
            value={value => tipoMovimento(value.tipoMovimento)}
          />
          <Table.Column<DeclaracaoMensalDividaConfig>
            header="Guia Recolhimento"
            value={value => descricaoGuiaRecolhimento(value.guiaRecolhimento)}
          />
          <Table.Column<DeclaracaoMensalDividaConfig>
            header="Status"
            value={value => descricaoStatus(value.id)}
          />
        </Table>
      </Panel>
    </>
  );
};

export default DeclaracaoMensalDividaConfigList;
