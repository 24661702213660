import {
  ActionButton,
  FormattedDate,
  SearchPagination
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const VencimentoDividaMensalList = ({
  dividas,
  edit,
  exclude,
  searchWithPage
}) => {
  return (
    <div>
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>Ano</th>
              <th style={{ textAlign: 'center' }}>Mês</th>
              <th>Guia de Recolhimento</th>
              <th style={{ textAlign: 'center' }}>Data de Vencimento</th>
              <th>Tipo Empresa</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {dividas &&
              dividas.content &&
              dividas.content.map(divida => {
                return (
                  <tr key={divida.id}>
                    <td style={{ textAlign: 'center' }}>
                      {divida.anoCompetencia}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {divida.mesCompetencia}
                    </td>
                    <td>
                      {divida.guiaRecolhimento.guiaRecolhimento +
                        ' - ' +
                        divida.guiaRecolhimento.descricao}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <FormattedDate
                        value={divida.dataVencimento}
                        timeZone={'UTC'}
                      />
                    </td>
                    <td>
                      {divida.declaracaoMensalTipoEmpresaVencimento === 'DPT'
                        ? 'DEMAIS PRESTADORES E TOMADORES'
                        : ''}
                      {divida.declaracaoMensalTipoEmpresaVencimento === 'IF'
                        ? 'INSTITUIÇÃO FINANCEIRA'
                        : ''}
                    </td>
                    <td>
                      <div className="btn-actions">
                        <div className="btn-actions-inner">
                          <ActionButton
                            data-test-id="buttonEdit"
                            key="edit-button"
                            icon="pencil-alt"
                            label="Editar"
                            onClick={() => edit(divida.id)}
                          />
                          <ActionButton
                            data-test-id="buttonDelete"
                            key="delete-button"
                            icon="trash-alt"
                            label="Excluir"
                            onClick={() => exclude(divida.id)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            {(!dividas || dividas.totalElements === 0) && (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan="7">
                  Sem registros
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {dividas && (
        <SearchPagination page={dividas} searchWithPage={searchWithPage} />
      )}
    </div>
  );
};

VencimentoDividaMensalList.propTypes = {
  dividas: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
  exclude: PropTypes.func.isRequired,
  searchWithPage: PropTypes.func.isRequired
};
export default VencimentoDividaMensalList;
