import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };

const search = params => (params ? `search=${params}` : '');

export const loadCancelamentosDms = (searchParams, page) =>
  axios.get(`${CONTEXT_PATH}/cancelamento-guia?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const loadSolicitacaoCancelamentoById = id =>
  axios.get(`${CONTEXT_PATH}/cancelamento-guia/${id}`);

export const rejeitarSolicitacaoCancelamento = solicitacao =>
  axios.put(`${CONTEXT_PATH}/cancelamento-guia/rejeitar`, solicitacao);

export const aprovarSolicitacaoCancelamento = solicitacao =>
  axios.put(`${CONTEXT_PATH}/cancelamento-guia/aprovar`, solicitacao);
