import { FilterUtils } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from './contextPath';
import { Entity } from './utils';

export const defaultFilterAutocomplete = (
  fieldCodigo: string,
  fieldDescricao?: string
) => (search: string) =>
  search && FilterUtils.buildDefaultFilter(fieldCodigo, fieldDescricao)(search);

export const ReadonlyService = <T extends Entity>(
  basePath: string,
  defaultSort?: object,
  filterAutocomplete?: (search: string) => string
) => {
  const sort = defaultSort || { sort: 'id' };

  const parsedPath = basePath.startsWith('/')
    ? basePath.substring(1)
    : basePath;
  const path = `${CONTEXT_PATH}/${parsedPath}`;

  const search = (params: string) =>
    !!filterAutocomplete ? filterAutocomplete(params) : params;

  const list = (searchParams: string, page?: any) =>
    axios.get(`${path}`, {
      params: { search: search(searchParams), ...sort, ...page }
    });

  const findById = (id: number | string): AxiosPromise<T> =>
    axios.get(`${path}/${id}`);

  return {
    search: list,
    load: list,
    findById
  };
};
