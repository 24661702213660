import styled, { css } from 'styled-components';

export const InfoBlock = styled.div<{ active?: boolean }>`
  line-height: 140%;
  display: flex;
  ${(props: any) =>
    props.active &&
    css`
      border-left: 1px solid #d8d8d8;
      padding-left: 5px;
    `};
`;
