import IncentivosFiscaisListPage from 'iss-admin/src/pages/acessos-permissoes/incentivos-fiscais/IncentivosFiscaisListPage';

import BloqueioOperacoesFormPage from '../pages/acessos-permissoes/bloqueio-operacoes/BloqueioOperacoesFormPage';
import BloqueioOperacoesListPage from '../pages/acessos-permissoes/bloqueio-operacoes/BloqueioOperacoesListPage';
import CadastroFebrabanFormPage from '../pages/acessos-permissoes/cadastro-febraban/CadastroFebrabanFormPage';
import CadastroFebrabanListPage from '../pages/acessos-permissoes/cadastro-febraban/CadastroFebrabanListPage';
import AnaliseCancelamentoDMSPage from '../pages/acessos-permissoes/cancelamento-dms/AnaliseCancelamentoDMSFormPage';
import CancelamentoDMSListPage from '../pages/acessos-permissoes/cancelamento-dms/CancelamentoDMSListPage';
import CancelamentoGuiaDataPage from '../pages/acessos-permissoes/cancelamento-guia/CancelamentoGuiaDataPage';
import CancelamentoGuiaListPage from '../pages/acessos-permissoes/cancelamento-guia/CancelamentoGuiaListPage';
import AnaliseCancelamentoFormPage from '../pages/acessos-permissoes/cancelamento-nfse/AnaliseCancelamentoFormPage';
import CancelamentoNfseListPage from '../pages/acessos-permissoes/cancelamento-nfse/CancelamentoNfseListPage';
import ConsultarAgendamentoCredenciarPage from '../pages/acessos-permissoes/credenciamento/ConsultarAgendamentoCredenciarPage';
import ConsultarAgendamentoListPage from '../pages/acessos-permissoes/credenciamento/ConsultarAgendamentoListPage';
import CredenciarFormPage from '../pages/acessos-permissoes/credenciamento/CredenciarFormPage';
import IncentivosFiscaisNewEditPage from '../pages/acessos-permissoes/incentivos-fiscais/IncentivosFiscaisNewEditPage';
import SolicitacaoRPSFormPage from '../pages/acessos-permissoes/solicitacao-rps/SolicitacaoRpsFormPage';
import SolicitacaoRPSListPage from '../pages/acessos-permissoes/solicitacao-rps/SolicitacaoRpsListPage';
import AnaliseSubstituicaoFormPage from '../pages/acessos-permissoes/substituicao-nfse/AnaliseSubstituicaoFormPage';
import SubstituicaoNfseListPage from '../pages/acessos-permissoes/substituicao-nfse/SubstituicaoNfseListPage';
import ChangePasswordFormPage from '../pages/acessos-permissoes/usuarios-issqn/ChangePasswordFormPage';
import UsuariosIssqnFormPage from '../pages/acessos-permissoes/usuarios-issqn/UsuariosIssqnFormPage';
import UsuariosIssqnListPage from '../pages/acessos-permissoes/usuarios-issqn/UsuariosIssqnListPage';
import ViewListPage from '../pages/acessos-permissoes/usuarios-issqn/ViewListPage';
import VisualizacaoGuiasEventuaisFormPage from '../pages/acessos-permissoes/visualizacao-guias-eventuais/VisualizacaoGuiasEventuaisFormPage';
import VisualizacaoGuiasEventuaisListPage from '../pages/acessos-permissoes/visualizacao-guias-eventuais/VisualizacaoGuiasEventuaisListPage';
import { Roles } from '../utils/Roles';

export const routesAcessosPermissoes = [
  {
    path: '/acessos-e-permissoes/credenciamento',
    component: ConsultarAgendamentoListPage,
    icon: 'medal',
    title: 'Credenciamento',
    role: Roles.credenciamento_read.name
  },
  {
    path: '/acessos-e-permissoes/credenciamento/atualizacao-cadastral/:id',
    component: ConsultarAgendamentoCredenciarPage,
    icon: 'pencil-alt',
    title: 'Editar',
    parent: '/acessos-e-permissoes/credenciamento'
  },
  {
    path: '/acessos-e-permissoes/credenciamento/credenciar/:id',
    component: CredenciarFormPage,
    icon: 'medal',
    title: 'Credenciar',
    parent: '/acessos-e-permissoes/credenciamento'
  },
  {
    path: '/acessos-e-permissoes/usuarios-issqn',
    component: UsuariosIssqnListPage,
    icon: 'desktop',
    title: 'Usuários ISSQN',
    role: Roles.usuarios_issqn_read.name
  },
  {
    path: '/acessos-e-permissoes/usuarios-issqn/view/:id',
    component: ViewListPage,
    icon: 'desktop',
    title: 'Resumo',
    parent: '/acessos-e-permissoes/usuarios-issqn',
    role: Roles.usuarios_issqn_read.name
  },
  {
    path: '/acessos-e-permissoes/usuarios-issqn/editar/:id',
    component: UsuariosIssqnFormPage,
    icon: 'pencil-alt',
    title: 'Editar',
    parent: '/acessos-e-permissoes/usuarios-issqn',
    role: Roles.usuarios_issqn_write.name
  },
  {
    path: '/acessos-e-permissoes/usuarios-issqn/alterar-senha/:id',
    component: ChangePasswordFormPage,
    icon: 'pencil-alt',
    title: 'Editar',
    parent: '/acessos-e-permissoes/usuarios-issqn'
  },
  {
    path: '/acessos-e-permissoes/incentivos-fiscais',
    component: IncentivosFiscaisListPage,
    icon: 'donate',
    title: 'Incentivos Fiscais',
    role: Roles.incentivos_fiscais_read.name
  },
  {
    path: '/acessos-e-permissoes/incentivos-fiscais/editar/:id',
    component: IncentivosFiscaisNewEditPage,
    icon: 'pencil-alt',
    title: 'Editar',
    parent: '/acessos-e-permissoes/incentivos-fiscais',
    role: Roles.incentivos_fiscais_write.name
  },
  {
    path: '/acessos-e-permissoes/incentivos-fiscais/Novo',
    component: IncentivosFiscaisNewEditPage,
    icon: 'file',
    title: 'Novo',
    parent: '/acessos-e-permissoes/incentivos-fiscais',
    role: Roles.incentivos_fiscais_write.name
  },
  {
    path: '/acessos-e-permissoes/cancelamento-nfse',
    component: CancelamentoNfseListPage,
    icon: 'file-excel',
    title: 'Cancelamento de NFSe',
    role: Roles.cancelamento_nfse_read.name
  },
  {
    path: '/acessos-e-permissoes/cancelamento-nfse/analisar/:id',
    component: AnaliseCancelamentoFormPage,
    icon: 'search',
    title: 'Análise',
    parent: '/acessos-e-permissoes/cancelamento-nfse'
  },
  {
    path: '/acessos-e-permissoes/substituicao-nfse',
    component: SubstituicaoNfseListPage,
    icon: 'exchange-alt',
    title: 'Substituição de NFSe',
    role: Roles.substituicao_nfse_read.name
  },
  {
    path: '/acessos-e-permissoes/substituicao-nfse/analisar/:id',
    component: AnaliseSubstituicaoFormPage,
    icon: 'search',
    title: 'Análise',
    parent: '/acessos-e-permissoes/substituicao-nfse'
  },
  {
    path: '/acessos-e-permissoes/cancelamento-dms',
    component: CancelamentoDMSListPage,
    icon: 'ban',
    title: 'Cancelamento de DMS',
    role: Roles.cancelamento_dms_read.name
  },
  {
    path: '/acessos-e-permissoes/cancelamento-dms/analisar/:id',
    component: AnaliseCancelamentoDMSPage,
    icon: 'search',
    title: 'Análise',
    parent: '/acessos-e-permissoes/cancelamento-dms'
  },
  {
    path: '/acessos-e-permissoes/cancelamento-guia',
    component: CancelamentoGuiaListPage,
    icon: 'ban',
    title: 'Cancelamento de Guia',
    role: Roles.cancelamento_guia_read.name
  },
  {
    path: '/acessos-e-permissoes/cancelamento-guia/:id/visualizar',
    component: CancelamentoGuiaDataPage,
    icon: 'ban',
    title: 'Resumo',
    parent: '/acessos-e-permissoes/cancelamento-guia',
    role: Roles.cancelamento_guia_read.name
  },
  {
    path: '/acessos-e-permissoes/bloqueio-operacoes',
    component: BloqueioOperacoesListPage,
    icon: 'lock',
    title: 'Bloqueio de Operações',
    role: Roles.bloqueio_operacoes_read.name
  },
  {
    path: '/acessos-e-permissoes/bloqueio-operacoes/:id',
    component: BloqueioOperacoesFormPage,
    icon: 'pencil-alt',
    title: 'Editar',
    parent: '/acessos-e-permissoes/bloqueio-operacoes',
    role: Roles.bloqueio_operacoes_write.name
  },
  {
    path: '/acessos-e-permissoes/cadastro-febraban',
    component: CadastroFebrabanListPage,
    icon: 'university',
    title: 'Cadastro FEBRABAN',
    role: Roles.cadastro_febraban_read.name
  },
  {
    path: '/acessos-e-permissoes/cadastro-febraban/novo',
    component: CadastroFebrabanFormPage,
    icon: 'file',
    title: 'Novo',
    parent: '/acessos-e-permissoes/cadastro-febraban',
    role: Roles.cadastro_febraban_write.name
  },
  {
    path: '/acessos-e-permissoes/cadastro-febraban/editar/:id',
    component: CadastroFebrabanFormPage,
    icon: 'pencil-alt',
    title: 'Editar',
    parent: '/acessos-e-permissoes/cadastro-febraban',
    role: Roles.cadastro_febraban_write.name
  },
  {
    path: '/acessos-e-permissoes/solicitacoes-rps/',
    component: SolicitacaoRPSListPage,
    icon: 'lock',
    title: 'Solicitações de RPS',
    role: Roles.solicitacoes_rps_read.name
  },
  {
    path: '/acessos-e-permissoes/solicitacoes-rps/analisar/:id',
    component: SolicitacaoRPSFormPage,
    icon: 'lock',
    title: 'Análise',
    parent: '/acessos-e-permissoes/solicitacoes-rps/'
  },
  {
    path: '/acessos-e-permissoes/visualizacao-guias-eventuais',
    component: VisualizacaoGuiasEventuaisListPage,
    icon: 'file-alt',
    title: 'Guias Eventuais',
    role: Roles.guias_eventuais_read.name
  },
  {
    path: '/acessos-e-permissoes/visualizacao-guias-eventuais/:id/visualizar',
    component: VisualizacaoGuiasEventuaisFormPage,
    icon: 'file-alt',
    title: 'Guia Eventual',
    parent: '/acessos-e-permissoes/visualizacao-guias-eventuais',
    role: Roles.guias_eventuais_read.name
  }
];

export default routesAcessosPermissoes;
