import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

export const registerSchedulerDMSVencida = () => {
  return axios.post(
    `${CONTEXT_PATH}/scheduled/agendar-notificacao-dms-vencida`
  );
};

export const registerSchedulerEntregaDmsAutomatico = () => {
  return axios.post(`${CONTEXT_PATH}/scheduled/agendar-entrega-dms-automatico`);
};
