import { Yup } from '@elotech/components';

export const validationSchema = Yup.object().shape({
  exercicioBase: Yup.string()
    .required()
    .max(4)
    .label('Exercício base')
    .test(
      'exerciciosIguais1',
      'Exercício base e exercício novo são iguais',
      function(value: string): boolean {
        const { exercicioNovo } = this.parent;
        return value !== exercicioNovo;
      }
    ),
  exercicioNovo: Yup.string()
    .required()
    .max(4)
    .label('Exercício novo')
    .test(
      'exerciciosIguais1',
      'Exercício base e exercício novo são iguais.',
      function(value: string): boolean {
        const { exercicioBase } = this.parent;
        return value !== exercicioBase;
      }
    )
    .test(
      'exercicioMenor',
      'Exercício novo é menor do que o exercício base.',
      function(value: string): boolean {
        const { exercicioBase } = this.parent;
        return value >= exercicioBase;
      }
    )
});
