import { Checkbox, Col, Row, SectionTitle } from '@elotech/components';
import React from 'react';

import Parametro from '../component/Parametro';

type Props = {
  dados: any;
  onToggle: any;
  showForm: any;
  onChangeParametros: any;
  tiposProcesso: Array<any>;
  tiposAtosProcessos: Array<any>;
  onChangeSolicitacaoBaixaTipoProcesso: any;
  onChangeSolicitacaoBaixaTipoProcessoAto: any;
};

const FiscalizacaoForm: React.FC<Props> = ({
  dados,
  onToggle,
  showForm,
  onChangeParametros,
  tiposProcesso,
  tiposAtosProcessos,
  onChangeSolicitacaoBaixaTipoProcesso,
  onChangeSolicitacaoBaixaTipoProcessoAto
}) => {
  return (
    <>
      <SectionTitle hasButton={true}>
        Fiscalização
        <button
          className="dropdown-arrow"
          data-test-id="button"
          onClick={onToggle}
        >
          <i className={showForm ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} />
        </button>
      </SectionTitle>
      {showForm ? (
        <Row>
          <Col md={6}>
            <Parametro
              type="checkbox"
              id="HABILITASOLICITACAOPROCESSO"
              name="HABILITASOLICITACAOPROCESSO"
              value={dados.HABILITASOLICITACAOPROCESSO.valor === 'S'}
              onChange={onChangeParametros}
              size={12}
              label="Habilitar Solicitação de Processos"
            />
            <Parametro
              type="checkbox"
              id="VINCULAR_USUARIO_VARIOS_LOCAIS_INTERNOS"
              name="VINCULAR_USUARIO_VARIOS_LOCAIS_INTERNOS"
              value={
                dados.VINCULAR_USUARIO_VARIOS_LOCAIS_INTERNOS.valor === 'S'
              }
              onChange={onChangeParametros}
              size={12}
              label="Deixar vincular um usuário a vários locais internos"
            />
            <Parametro
              type="checkbox"
              id="PERMITE_USUARIO_EDITAR_OUTROS_PROCESSOS_LOCAL_INTERNO"
              name="PERMITE_USUARIO_EDITAR_OUTROS_PROCESSOS_LOCAL_INTERNO"
              value={
                dados.PERMITE_USUARIO_EDITAR_OUTROS_PROCESSOS_LOCAL_INTERNO
                  .valor === 'S'
              }
              onChange={onChangeParametros}
              size={12}
              label="Permitir usuário tramitar no processo de outro fiscal do mesmo local interno"
            />
          </Col>
          <Col md={6}>
            <Row>
              <Checkbox
                data-test-id="button-habilita-entregar-dms"
                className="form-group"
                name="HABILITASOLICITACAOBAIXA"
                id="HABILITASOLICITACAOBAIXA"
                checked={dados.HABILITASOLICITACAOBAIXA.valor === 'S'}
                onChange={onChangeParametros}
                label="Visualizar Solicitações de Baixa - RedeSim"
              />
            </Row>
            <Row>
              <div className="form-group">
                <label htmlFor="">Solicitação de Baixa - Tipo Processo</label>
                <select
                  name="SOLICITACAOBAIXA_TIPO_PROCESSO"
                  value={dados.SOLICITACAOBAIXA_TIPO_PROCESSO.valor}
                  onChange={event => {
                    onChangeParametros(event);
                    onChangeSolicitacaoBaixaTipoProcesso(event.target?.value);
                  }}
                >
                  {tiposProcesso &&
                    tiposProcesso.map((tipo, index) => (
                      <option key={index} value={tipo.id}>
                        {tipo.id} - {tipo.descricao}
                      </option>
                    ))}
                </select>
              </div>
            </Row>
            <Row>
              <div className="form-group">
                <label htmlFor="">
                  Solicitação de Baixa - Tipo Ato Processo
                </label>
                <select
                  name="SOLICITACAOBAIXA_TIPO_ATO"
                  value={dados.SOLICITACAOBAIXA_TIPO_ATO.valor}
                  onChange={event => {
                    onChangeParametros(event);
                    onChangeSolicitacaoBaixaTipoProcessoAto(
                      event.target?.value
                    );
                  }}
                >
                  {tiposAtosProcessos &&
                    tiposAtosProcessos.map((tipo: any, index: number) => (
                      <option key={index} value={tipo.id}>
                        {tipo.id} - {tipo.tipoAto?.descricao}
                      </option>
                    ))}
                </select>
              </div>
            </Row>
          </Col>
        </Row>
      ) : (
        ''
      )}
    </>
  );
};

export default FiscalizacaoForm;
