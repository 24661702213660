import { Col, Row } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const ObservacoesTextArea = ({
  historicoAgendamento,
  onChangeObservacoes,
  disabled
}) => {
  return (
    <Fragment>
      <div className="form-group">
        <Row>
          <Col md={12}>
            <textarea
              disabled={disabled}
              name="observacoes"
              placeholder=""
              value={historicoAgendamento.observacoes}
              onChange={onChangeObservacoes}
            />
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

ObservacoesTextArea.propTypes = {
  historicoAgendamento: PropTypes.object.isRequired,
  onChangeObservacoes: PropTypes.func.isRequired
};
export default ObservacoesTextArea;
