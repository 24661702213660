import { Container, Loading, SectionTitle } from '@elotech/components';
import { AxiosResponse } from 'axios';
import { Alert } from 'iss-common/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router';

import { SolicitacaoRpsService } from '../../../service';
import {
  SituacaoAtualSolicitante,
  SolicitacaoRPS
} from '../../../types/SolicitacaoRPS';
import RpsList from './RpsList';
import SolicitacaoRpsDetailForm from './SolicitacaoRpsDetailForm';
import { PENDENTE } from './SolicitacaoRpsTipos';

type Props = {
  match: match<{ id: string }>;
  username: string;
};

const SolicitacaoRPSFormPage: React.FC<Props> = ({ match, username }) => {
  const [solicitacao, setSolicitacao] = useState<SolicitacaoRPS | undefined>(
    undefined
  );
  const [situacaoAtualSolicitante, setSituacaoAtualSolicitante] = useState<
    SituacaoAtualSolicitante | undefined
  >(undefined);
  const [listarTodosRPS, setListarTodosRPS] = useState<boolean | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const loadSolicitacoes = useCallback((id: number) => {
    if (!id) return;

    const loadSituacaoAtualSolicitante = (idCadastro: number) =>
      SolicitacaoRpsService.situacaoAtualSolicitante(idCadastro)
        .then((response: AxiosResponse<SituacaoAtualSolicitante>) => {
          setSituacaoAtualSolicitante(response.data);
        })
        .catch((error: any) => {
          Alert.error(
            {
              title: 'Não foi possível carregar a situacao atual do solicitante'
            },
            error
          );
        });

    setLoading(true);
    setError(false);

    return SolicitacaoRpsService.loadSolicitacao(id)
      .then((response: AxiosResponse<SolicitacaoRPS>) => {
        setSolicitacao(undefined); // necessário para atualizar a RpsList
        setSolicitacao(response.data);
        setListarTodosRPS(response.data.situacao === PENDENTE);
        loadSituacaoAtualSolicitante(response.data.cadastroGeral!.id!);
      })
      .catch((error: any) => {
        Alert.error(
          { title: 'Não foi possível carregar a solicitação' },
          error
        );
        setError(true);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    loadSolicitacoes(+match.params.id);
  }, [match.params.id, loadSolicitacoes]);

  const aprovar = (id?: number) => {
    Alert.question({ title: 'Confirma a aprovação desta solicitação?' }).then(
      (result: any) => {
        if (result.value) {
          setLoading(true);

          SolicitacaoRpsService.aprovar(id!, username)
            .then(() => loadSolicitacoes(+match.params.id))
            .catch((error: any) => {
              Alert.error(
                { title: 'Ocorreu um erro ao aprovar a solicitação' },
                error
              );
              setError(true);
            })
            .finally(() => setLoading(false));
        }
      }
    );
  };

  const rejeitar = (id: number, justificativa: string) => {
    setLoading(true);

    SolicitacaoRpsService.rejeitar(id, username, justificativa)
      .then(() => loadSolicitacoes(+match.params.id))
      .catch((error: any) => {
        Alert.error(
          { title: 'Ocorreu um erro ao rejeitar a solicitação' },
          error
        );
        setError(true);
      })
      .finally(() => setLoading(false));
  };

  const calcRpsDisponiveis = (situacao: SituacaoAtualSolicitante) => {
    const perc = (100 - situacao.liberados / situacao.total).toFixed(0);

    return `${perc}% disponível`;
  };

  const innerContent = () => (
    <>
      <Loading loading={loading} />
      {solicitacao && (
        <>
          <SolicitacaoRpsDetailForm
            solicitacao={solicitacao}
            onAprovar={(solicitacao: SolicitacaoRPS) => aprovar(solicitacao.id)}
            onRejeitar={(solicitacao: SolicitacaoRPS, justificativa: string) =>
              rejeitar(solicitacao.id!, justificativa)
            }
          />
          <SectionTitle>
            RPS aprovados para o contribuinte
            {listarTodosRPS || ' (nesta solicitação)'}
            {listarTodosRPS && situacaoAtualSolicitante && (
              <small>
                {' - '} {calcRpsDisponiveis(situacaoAtualSolicitante!)}
              </small>
            )}
          </SectionTitle>
          <RpsList
            solicitacao={solicitacao}
            listarTodos={listarTodosRPS!}
            onChangeListarTodos={v => setListarTodosRPS(v)}
          />
        </>
      )}
    </>
  );

  return (
    <Container breadcrumb>
      {!error && innerContent()}
      {!solicitacao && <div>Solicitação de RPS não encontrada</div>}
    </Container>
  );
};

const connectedComponent = connect(
  (state: any) => ({ username: state.user.profile.username }),
  undefined
)(SolicitacaoRPSFormPage);

export { connectedComponent as default, SolicitacaoRPSFormPage };
