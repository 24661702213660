import {
  Alert,
  BasicInput,
  Button,
  Col,
  Container,
  FormikInputIntegerMultiValue,
  Loading,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import { differenceInMonths } from 'date-fns';
import { Formik } from 'formik';
import { maskCompetencia, maskCpfCnpj } from 'iss-common/utils/MaskUtils';
import moment from 'moment';
import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';

import { RelatorioPGDASService } from '../../service';

const RelatorioPGDASReceitasOutrosMunicipiosFormPage: React.FC = () => {
  const validationSchema = Yup.object().shape({
    competenciaInicial: Yup.string()
      .required()
      .label('Competência inicial')
      .test('dataValida', 'Competência inicial inválida!', value => {
        const data = moment(`01/${value}`, 'DD/MM/YYYY', true);
        return data.isValid();
      }),
    competenciaFinal: Yup.string()
      .label('Competência Final')
      .required()
      .test('dataFinalValida', 'Competência final inválida!', ano => {
        const data = moment(`01/${ano}`, 'DD/MM/YYYY', true);
        return data.isValid();
      })
      .test(
        'dataFinalMenorQueInicial',
        'Competência inicial maior que final!',
        function(ano) {
          if (ano === undefined) {
            return true;
          }
          const dataInicial = moment(
            `01/${this.parent.competenciaInicial}`,
            'DD/MM/YYYY',
            true
          );
          const data = moment(`01/${ano}`, 'DD/MM/YYYY', true);
          return data.isSame(dataInicial) || dataInicial.isBefore(data);
        }
      )
      .test(
        'periodoBuscaExcedeUmAno',
        'O período da busca não pode exceder 12 meses',
        function(ano) {
          if (ano === undefined) {
            return true;
          }
          const dataInicial = moment(
            `01/${this.parent.competenciaInicial}`,
            'DD/MM/YYYY',
            true
          );
          const data = moment(`01/${ano}`, 'DD/MM/YYYY', true);
          const diferenca = differenceInMonths(
            dataInicial.format('DD/MM/YYYY'),
            data.format('DD/MM/YYYY')
          );
          return diferenca > -12;
        }
      )
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: any) => {
    setLoading(true);
    await RelatorioPGDASService.carregarRelatorioPGDASReceitasOutrosMunicipios(
      values
    )
      .then((response: any) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);
      })
      .catch((error: any) => {
        Alert.error({ title: `Ocorreu um erro ao gerar o relatório` }, error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <SectionTitle>Filtro</SectionTitle>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={{}}
        onSubmit={onSubmit}
        render={form => (
          <>
            <Row>
              <BasicInput
                size={4}
                label="Competência Inicial"
                name="competenciaInicial"
                render={({ field }) => (
                  <MaskedInput
                    {...field}
                    type="text"
                    name="competenciaInicial"
                    mask={maskCompetencia}
                  />
                )}
              />
              <BasicInput
                size={4}
                label="Competência Final"
                name="competenciaFinal"
                render={({ field }) => (
                  <MaskedInput
                    {...field}
                    type="text"
                    name="competenciaFinal"
                    mask={maskCompetencia}
                  />
                )}
              />
            </Row>
            <Row>
              <BasicInput
                size={4}
                label="CPF/CNPJ"
                name="cnpj"
                render={({ field }) => (
                  <MaskedInput
                    {...field}
                    type="text"
                    guide={false}
                    mask={maskCpfCnpj}
                  />
                )}
              />
              <FormikInputIntegerMultiValue
                id="cadastroGeral"
                name="cadastroGeral"
                label="Cadastro Geral"
                size={4}
              />
            </Row>

            <Row>
              <Col md={12}>
                <div className="form-group">
                  <Button onClick={form.submitForm} type="submit">
                    Imprimir
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      />
    </Container>
  );
};

export default RelatorioPGDASReceitasOutrosMunicipiosFormPage;
