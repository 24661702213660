import { Col, Row, SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const ConfigMensagemFields = ({ configMensagemInput, onChange, mensagem }) => {
  return (
    <form className="form">
      <SectionTitle>Emitir NFSe</SectionTitle>
      <Row>
        <Col md={7}>
          <div className="form-group">
            <label htmlFor="assunto">Assunto</label>
            <input
              type="text"
              name="emitirNfse"
              value={configMensagemInput.emitirNfse}
              onChange={onChange}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="form-group">
            <label className="label" />
            <button
              className="btn module-color inline icon-left"
              data-test-id="buttonMensagem1"
              onClick={mensagem}
            >
              <em className="fa fa-envelope" />
              Mensagem
            </button>
          </div>
        </Col>
      </Row>
      <SectionTitle>Substituir NFSe</SectionTitle>
      <Row>
        <Col md={7}>
          <div className="form-group">
            <label htmlFor="assunto">Assunto</label>
            <input
              type="text"
              name="substituirNfse"
              value={configMensagemInput.substituirNfse}
              onChange={onChange}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="form-group">
            <label className="label" />
            <button
              className="btn module-color inline icon-left"
              data-test-id="buttonMensagem"
              onClick={mensagem}
            >
              <em className="fa fa-envelope" />
              Mensagem
            </button>
          </div>
        </Col>
      </Row>
      <SectionTitle>Cancelar NFSe</SectionTitle>
      <Row>
        <Col md={7}>
          <div className="form-group">
            <label htmlFor="assunto">Assunto</label>
            <input
              type="text"
              name="cancelarNfse"
              value={configMensagemInput.cancelarNfse}
              onChange={onChange}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="form-group">
            <label className="label" />
            <button
              className="btn module-color inline icon-left"
              data-test-id="buttonMensagem"
              onClick={mensagem}
            >
              <em className="fa fa-envelope" />
              Mensagem
            </button>
          </div>
        </Col>
      </Row>
      <SectionTitle>Movimentação de cancelamento de NFSe</SectionTitle>
      <Row>
        <Col md={7}>
          <div className="form-group">
            <label htmlFor="assunto">Assunto</label>
            <input
              type="text"
              name="movimentacaoDeCancelamentoNfse"
              value={configMensagemInput.movimentacaoDeCancelamentoNfse}
              onChange={onChange}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="form-group">
            <label className="label" />
            <button
              className="btn module-color inline icon-left"
              data-test-id="buttonMensagem"
              onClick={mensagem}
            >
              <em className="fa fa-envelope" />
              Mensagem
            </button>
          </div>
        </Col>
      </Row>
      <SectionTitle>Envio de senha</SectionTitle>
      <Row>
        <Col md={7}>
          <div className="form-group">
            <label htmlFor="assunto">Assunto</label>
            <input
              type="text"
              name="envioSenha"
              value={configMensagemInput.envioSenha}
              onChange={onChange}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="form-group">
            <label className="label" />
            <button
              className="btn module-color inline icon-left"
              data-test-id="buttonMensagem"
              onClick={mensagem}
            >
              <em className="fa fa-envelope" />
              Mensagem
            </button>
          </div>
        </Col>
      </Row>
      <SectionTitle>Confirmação de Homologação</SectionTitle>
      <Row>
        <Col md={7}>
          <div className="form-group">
            <label htmlFor="assunto">Assunto</label>
            <input
              type="text"
              name="confirmacaoHomologacao"
              value={configMensagemInput.confirmacaoHomologacao}
              onChange={onChange}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="form-group">
            <label className="label" />
            <button
              className="btn module-color inline icon-left"
              data-test-id="buttonMensagem"
              onClick={mensagem}
            >
              <em className="fa fa-envelope" />
              Mensagem
            </button>
          </div>
        </Col>
      </Row>
      <SectionTitle>Confirmação de Homologação Temporária</SectionTitle>
      <Row>
        <Col md={7}>
          <div className="form-group">
            <label htmlFor="assunto">Assunto</label>
            <input
              type="text"
              name="confirmacaoHomologacaoTemporaria"
              value={configMensagemInput.confirmacaoHomologacaoTemporaria}
              onChange={onChange}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="form-group">
            <label className="label" />
            <button
              className="btn module-color inline icon-left"
              data-test-id="buttonMensagem"
              onClick={mensagem}
            >
              <em className="fa fa-envelope" />
              Mensagem
            </button>
          </div>
        </Col>
      </Row>
      <SectionTitle>Confirmação de Rejeição da Homolagação</SectionTitle>
      <Row>
        <Col md={7}>
          <div className="form-group">
            <label htmlFor="assunto">Assunto</label>
            <input
              type="text"
              name="confirmaçãoRejeicaoHomolagacao"
              value={configMensagemInput.confirmaçãoRejeicaoHomolagacao}
              onChange={onChange}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="form-group">
            <label className="label" />
            <button
              className="btn module-color inline icon-left"
              data-test-id="buttonMensagem"
              onClick={mensagem}
            >
              <em className="fa fa-envelope" />
              Mensagem
            </button>
          </div>
        </Col>
      </Row>
      <SectionTitle>Aprovação de Cancelamento de DMS</SectionTitle>
      <Row>
        <Col md={7}>
          <div className="form-group">
            <label htmlFor="assunto">Assunto</label>
            <input
              type="text"
              name="aprovacaoCancelamentoDMS"
              value={configMensagemInput.aprovacaoCancelamentoDMS}
              onChange={onChange}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="form-group">
            <label className="label" />
            <button
              className="btn module-color inline icon-left"
              data-test-id="buttonMensagem"
              onClick={mensagem}
            >
              <em className="fa fa-envelope" />
              Mensagem
            </button>
          </div>
        </Col>
      </Row>
      <SectionTitle>Rejeição de Cancelamento de DMS</SectionTitle>
      <Row>
        <Col md={7}>
          <div className="form-group">
            <label htmlFor="assunto">Assunto</label>
            <input
              type="text"
              name="rejeicaoCancelamentoDMS"
              value={configMensagemInput.rejeicaoCancelamentoDMS}
              onChange={onChange}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="form-group">
            <label className="label" />
            <button
              className="btn module-color inline icon-left"
              data-test-id="buttonMensagem"
              onClick={mensagem}
            >
              <em className="fa fa-envelope" />
              Mensagem
            </button>
          </div>
        </Col>
      </Row>
      <SectionTitle>Envio de Senha Web Service</SectionTitle>
      <Row>
        <Col md={7}>
          <div className="form-group">
            <label htmlFor="assunto">Assunto</label>
            <input
              type="text"
              name="envioSenhaWebService"
              value={configMensagemInput.envioSenhaWebService}
              onChange={onChange}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="form-group">
            <label className="label" />
            <button
              className="btn module-color inline icon-left"
              data-test-id="buttonMensagem"
              onClick={mensagem}
            >
              <em className="fa fa-envelope" />
              Mensagem
            </button>
          </div>
        </Col>
      </Row>
      <SectionTitle>Envio de Email de Recuperação de Senha </SectionTitle>
      <Row>
        <Col md={7}>
          <div className="form-group">
            <label htmlFor="assunto">Assunto</label>
            <input
              type="text"
              name="movimentacaoDeCancelamentoNfse"
              value={configMensagemInput.movimentacaoDeCancelamentoNfse}
              onChange={onChange}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="form-group">
            <label className="label" />
            <button
              className="btn module-color inline icon-left"
              data-test-id="buttonMensagem"
              onClick={mensagem}
            >
              <em className="fa fa-envelope" />
              Mensagem
            </button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

ConfigMensagemFields.propTypes = {
  configMensagemInput: PropTypes.object.isRequired,
  onChange: PropTypes.func
};

export default ConfigMensagemFields;
