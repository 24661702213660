export const PENDENTE = 'PENDENTE';
export const APROVADO = 'APROVADO';
export const REJEITADO = 'REJEITADO';
export const LIBERADO = 'LIBERADO';
export const UTILIZADO = 'UTILIZADO';
export const CANCELADO = 'CANCELADO';

export type Situacao = {
  situacao: string;
  label: string;
};

export const SituacoesSolicitacaoRps: Situacao[] = [
  { situacao: PENDENTE, label: 'Pendente' },
  { situacao: APROVADO, label: 'Aprovado' },
  { situacao: REJEITADO, label: 'Rejeitado' }
];

export const SituacoesRpsManual: Situacao[] = [
  { situacao: LIBERADO, label: 'Liberado' },
  { situacao: UTILIZADO, label: 'Utilizado' },
  { situacao: CANCELADO, label: 'Cancelado' }
];

const getLabelSituacao = <T extends Situacao>(list: T[], situacao?: string) =>
  ((situacao?: T) => situacao && situacao.label)(
    list.find(t => t.situacao === situacao)
  );

export const getLabelSituacaoSolicitacaoRps = (situacao?: string) =>
  getLabelSituacao(SituacoesSolicitacaoRps, situacao);

export const getLabelSituacaoRpsManual = (situacao?: string) =>
  getLabelSituacao(SituacoesRpsManual, situacao);
