import axios, { AxiosPromise } from 'axios';

import { Bloqueio, BloqueioCredenciamento } from '../types/Bloqueio';
import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };

const search = (params: string) => (params ? `search=${params}` : '');

export const loadBloqueiosPorCredenciamento = (
  credenciamento: number,
  tipos: string[],
  searchParams: string = '',
  page?: number
): AxiosPromise<BloqueioCredenciamento> =>
  axios.get(
    `${CONTEXT_PATH}/bloqueios/por-credenciamento/${credenciamento}/?${search(
      searchParams
    )}`,
    {
      params: {
        tipos: tipos.join(','),
        ...defaultSort,
        page
      }
    }
  );

export const post = (idCadastro?: number, bloqueio?: Bloqueio) =>
  axios.post(`${CONTEXT_PATH}/bloqueios/por-cadastro-geral/${idCadastro}/`, {
    ...bloqueio
  });
