import {
  Alert,
  Container,
  FAB,
  Hint,
  Loading,
  SearchFilter
} from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import swal from 'sweetalert2';

import { GrupoDocumentoService, withService } from '../../../service';
import GrupoAnexoPublicoList from './GrupoAnexoPublicoList';

export class GrupoAnexoPublicoListPage extends Component {
  static propTypes = {
    grupoDocumentoService: PropTypes.object.isRequired
  };

  state = {
    grupoDocumentos: {},
    loading: false,
    pageConsulta: 0,
    filter: [
      {
        id: '',
        titulo: ''
      }
    ],
    searchParams: ''
  };

  getGrupoDocumentosSuccess = response => {
    this.setState({
      grupoDocumentos: response.data,
      loading: false
    });
  };

  getGrupoDocumentosError = error => {
    this.setState({ loading: false });
    Alert.error(
      { title: 'Não foi possível carregar o grupo de documentos.' },
      error
    );
  };

  onDeleteSuccess = success => {
    this.setState({ loading: false });
    swal('Excluido', 'Documento foi excluido!', 'success');

    this.searchWithPage(this.state.pageConsulta);
  };

  onDeleteErrror = error => {
    this.setState({ loading: false });
    Alert.error(
      { title: 'Não foi possível excluir o grupo de documentos.' },
      error
    );
  };

  searchWithPage = page => {
    this.setState({ pageConsulta: page });
    this.serviceSearch(this.state.searchParams, page);
  };

  serviceSearch = (searchParams, page) => {
    this.setState({
      loading: true,
      searchParams
    });

    this.props.grupoDocumentoService
      .loadGrupoDocumento(searchParams, page)
      .then(this.getGrupoDocumentosSuccess)
      .catch(this.getGrupoDocumentosError);
  };

  fields = [
    {
      label: 'Código',
      name: 'id',
      type: 'NUMBER'
    },
    {
      label: 'Título',
      name: 'titulo',
      type: 'STRING'
    }
  ];

  createNew = () => {
    this.props.history.push('/anexos-publicos/grupo-anexo-publico/novo');
  };

  edit = id => {
    this.props.history.push(
      `/anexos-publicos/grupo-anexo-publico/editar/${id}`
    );
  };

  exclude = id => {
    swal({
      title: 'Excluir?',
      text: 'Deseja excluir documento?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim'
    }).then(result => {
      if (result.value) {
        this.setState({ loading: true });
        this.props.grupoDocumentoService
          .removerGrupoDocumento(id)
          .then(this.onDeleteSuccess)
          .catch(this.onDeleteErrror);
      }
    });
  };

  render() {
    const { grupoDocumentos, loading } = this.state;

    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-cadastro-grupo-documento"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        <div className="panel table table-responsive">
          <div className="panel-body">
            <SearchFilter fields={this.fields} search={this.serviceSearch} />
            <GrupoAnexoPublicoList
              documento={grupoDocumentos}
              edit={this.edit}
              exclude={this.exclude}
              searchWithPage={this.searchWithPage}
            />
          </div>
        </div>
        <div className="btn-save">
          <FAB
            data-test-id="buttonSave"
            icon="plus"
            iconColor="white"
            title="Adicionar Novo"
            onClick={this.createNew}
          />
        </div>
      </Container>
    );
  }
}

const ComponentWithService = withService({
  grupoDocumentoService: GrupoDocumentoService
})(GrupoAnexoPublicoListPage);

export default ComponentWithService;
