import CaixaPostalFormPage from '../pages/caixa-postal/CaixaPostalFormPage';
import CaixaPostalListPage from '../pages/caixa-postal/CaixaPostalListPage';
import { Roles } from '../utils/Roles';

export const routesCaixaPostal = [
  {
    path: '/caixa-postal',
    component: CaixaPostalListPage,
    icon: 'envelope',
    title: 'Caixa Postal',
    role: Roles.caixa_postal_read.name
  },
  {
    path: '/caixa-postal/editar/:id',
    component: CaixaPostalFormPage,
    icon: 'pencil-alt',
    title: 'Editar',
    parent: '/caixa-postal',
    role: Roles.caixa_postal_read.name
  }
];

export default routesCaixaPostal;
