import 'iss-common/polyfills';

import './IconsLibrary';

import {
  ActuatorService,
  ApplicationProvider,
  BreadCrumbProvider,
  Footer,
  KeycloakService,
  Loading,
  NotificationContainer,
  Onboarding,
  SidebarToggle,
  UrlUtils,
  getCurrentTheme
} from '@elotech/components';
import axios from 'axios';
import classnames from 'classnames';
import { AxiosUtils } from 'iss-common/utils';
import PromiseFinallyPolyfill from 'promise.prototype.finally';
import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import AppKeycloakProvider from './AppKeycloakProvider';
import keycloakConfig from './keycloak';
import AppHeader from './menus/AppHeader';
import AppSidebar from './menus/AppSidebar';
import { BadgeValuesProvider } from './menus/BadgeValuesProvider';
import Routes, { allRoutes } from './routes';
import EntidadeService from './service/EntidadeService';
import UsuarioService from './service/UsuarioService';
import createStore from './state';
import ThemeSelector from './theme/ThemeSelector';
import ValidadorCertificado from './utils/ValidadorCertificado';

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/pt');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/pt');
}

if (!global.Intl) {
  global.Intl = require('intl');
}

const store = createStore({});
KeycloakService.init(keycloakConfig);

axios.interceptors.request.use(async config => {
  const { ...newConfig } = config;
  if (
    newConfig.url.startsWith('/api') ||
    newConfig.url.startsWith('/gateway') ||
    newConfig.url.startsWith('/iss-admin') ||
    newConfig.url.startsWith('/painel-api')
  ) {
    const refreshed = await KeycloakService.refreshToken({});
    if (refreshed) {
      newConfig.headers.Authorization = `Bearer ${KeycloakService.getToken()}`;
    }
  }
  if (newConfig.url.startsWith('/gateway')) {
    newConfig.headers.cidade = UrlUtils.getTenantIdentifier();
  }

  AxiosUtils.configureBaseUrl(newConfig);

  return Promise.resolve(newConfig);
});

PromiseFinallyPolyfill.shim();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isIFrame:
        new URLSearchParams(window.location.search).get('iframe') === 'true'
    };
  }

  render() {
    const { isIFrame } = this.state;
    return (
      <Provider store={store}>
        <ThemeProvider theme={getCurrentTheme()}>
          <IntlProvider locale="pt" textComponent="span">
            <NotificationContainer>
              <BrowserRouter basename={process.env.PUBLIC_URL}>
                <BreadCrumbProvider routes={allRoutes}>
                  <AppKeycloakProvider keycloakService={KeycloakService}>
                    <ApplicationProvider
                      gatewayResource="/gateway"
                      onGetVersaoSobreSistema={ActuatorService.getInfo}
                      onGetEntidadePrincipal={
                        EntidadeService.loadEntidadePrincipal
                      }
                      getUserPermission={UsuarioService.permissoesByUsuario}
                    >
                      <ThemeSelector>
                        <Onboarding>
                          <SidebarToggle>
                            {({ toggledSidebar, userExists, fullScreen }) => {
                              return (
                                <div className="wrapper">
                                  <BadgeValuesProvider>
                                    <AppHeader
                                      showToggleButton
                                      showMenu={true}
                                    />
                                    <ValidadorCertificado></ValidadorCertificado>
                                    <AppSidebar showSidebar={true} />
                                    <section
                                      className={classnames({
                                        'main-content': true,
                                        'sidebar-toggle': toggledSidebar,
                                        'no-sidebar': isIFrame,
                                        'sidebar-toggle-md': true,
                                        fullScreen: fullScreen || isIFrame
                                      })}
                                    >
                                      {userExists === undefined ? (
                                        <Loading loading={true} />
                                      ) : (
                                        <div>
                                          <Routes />
                                        </div>
                                      )}
                                    </section>
                                  </BadgeValuesProvider>
                                  <Footer />
                                </div>
                              );
                            }}
                          </SidebarToggle>
                        </Onboarding>
                      </ThemeSelector>{' '}
                    </ApplicationProvider>
                  </AppKeycloakProvider>
                </BreadCrumbProvider>
              </BrowserRouter>
            </NotificationContainer>
          </IntlProvider>
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;
