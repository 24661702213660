import { PageRequest, PagedResponse, Sort } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import {
  ControleDmsNaoEntregueDTO,
  DeclaracaoMensalNaoEntregueControle
} from '../types';
import { ControleDmsNaoEntregueMultaDTO } from '../types/ControleDmsNaoEntregueMultaDTO';
import { CONTEXT_PATH } from './contextPath';

const defaultSort = {
  sort: 'id,desc'
};

export const search = (
  searchParams?: string,
  page?: PageRequest,
  sort?: Sort
): AxiosPromise<PagedResponse<DeclaracaoMensalNaoEntregueControle>> => {
  sort = sort || defaultSort;
  return axios.get(`${CONTEXT_PATH}/dms-nao-entregue-controle`, {
    params: {
      search: searchParams ? searchParams : undefined,
      ...sort,
      ...page,
      fields: 'id,dataGeracao,usuario.nome,batchJobExecutionControle,filtro'
    }
  });
};

export const findById = (
  id: number
): AxiosPromise<DeclaracaoMensalNaoEntregueControle> =>
  axios.get(`${CONTEXT_PATH}/dms-nao-entregue-controle/${id}`);

export const generateControleDmsNaoEntregues = (
  value: ControleDmsNaoEntregueDTO
): AxiosPromise<void> => {
  return axios.post(`${CONTEXT_PATH}/dms-nao-entregue-controle`, value);
};

export const gerarMulta = (
  value: ControleDmsNaoEntregueMultaDTO
): AxiosPromise<void> => {
  return axios.post(
    `${CONTEXT_PATH}/dms-nao-entregue-controle/${value.controle?.id}/multa`,
    value
  );
};

const idParams = (params: any) => `?id=${params!}`;

export const generateRelatorio = (id: number, registros: any) => {
  return axios.post(
    `${CONTEXT_PATH}/relatorio-dms-nao-entregue${idParams(id)}`,
    registros,
    {
      responseType: 'blob'
    }
  );
};
