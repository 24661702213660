import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

export const carregarRelatorioEmpresasLiberadasEmissaoNfse = (search: any) =>
  axios.post(
    `${CONTEXT_PATH}/relatorio-empresas-liberadas-emissao-nfse`,
    search,
    {
      responseType: 'blob'
    }
  );
