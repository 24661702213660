import {
  Alert,
  BasicInput,
  Col,
  Container,
  DragDropFiles,
  FAB,
  Hint,
  Loading,
  Panel,
  Row,
  Yup
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';

import { importarDocDimp } from '../../../service/ImportacaoArquivoDimpService';
import { ArquivoDimp } from '../../../types/ArquivoDimp';

type Props = {};

const initialValue: ArquivoDimp = { arquivo: [], competencia: '' };

function isCompetenciaValida(competencia: string) {
  const data = moment(`01/${competencia}`, 'DD/MM/YYYY', true);
  return data.isValid();
}

const validationSchema = Yup.object().shape({
  competencia: Yup.string()
    .required()
    .test('competenciaInvalida', 'Competência inválida', function(
      competenciaInicial: string
    ) {
      return (
        (!competenciaInicial &&
          (!!this.parent.cadastroGeral || this.parent.pessoa)) ||
        (competenciaInicial?.length > 0 &&
          isCompetenciaValida(competenciaInicial))
      );
    })
});

const ImportacaoArquivoDIMPFormPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (form: ArquivoDimp) => {
    form.arquivo.forEach(value => {
      setLoading(true);
      const formData = new FormData();
      formData.append('files', value);
      importarDocDimp(formData, form.competencia!)
        .then(() => {
          Alert.success({
            title: 'Importação Arquivo DIMP',
            text:
              'Uma notificação será enviada assim que a importação for finalizada'
          });
        })
        .catch(error => {
          Alert.error({ title: 'Erro ao importar arquivo DIMP' }, error);
        })
        .finally(() => setLoading(false));
    });
  };

  const addFile = (formprops: any, newFile: File) => {
    formprops?.setFieldValue('arquivo', newFile);
  };

  const onRemove = (formprops: any) => {
    formprops?.setFieldValue('arquivo', []);
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <Formik<ArquivoDimp>
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValue}
        validationSchema={validationSchema}
        render={(formProps: FormikProps<ArquivoDimp>) => (
          <>
            <Panel
              isForm
              title={
                <>
                  Importação Arquivo DIMP
                  <Hint
                    classes={`inline clean module-color center-left fa-question-circle mobile`}
                  >
                    Formatos aceitos: .zip, .rar
                  </Hint>
                </>
              }
            >
              <Row>
                <BasicInput
                  size={2}
                  label="Competência"
                  name="competencia"
                  render={({ field }) => (
                    <MaskedInput
                      {...field}
                      type="text"
                      name="competencia"
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    />
                  )}
                />
              </Row>
              <Row>
                <Col sm={12} className="form-group">
                  <DragDropFiles
                    data-testid="[drag-drop-arquivo]"
                    maxSize={1000000000}
                    files={formProps.values.arquivo}
                    onAddFile={file => addFile(formProps, file as File)}
                    onRemoveFile={() => onRemove(formProps)}
                    acceptedFiles={'.zip, .rar'}
                    multiple={false}
                  ></DragDropFiles>
                </Col>
              </Row>
            </Panel>
            {formProps.values.arquivo.length > 0 && (
              <div className="btn-save">
                <FAB
                  data-test-id="btn-save"
                  icon="check"
                  onClick={formProps.submitForm}
                  title="Importar"
                />
              </div>
            )}
          </>
        )}
      ></Formik>
    </Container>
  );
};

export default ImportacaoArquivoDIMPFormPage;
