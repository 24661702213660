import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from './contextPath';

export const credenciamentosPendentesAnalise = (): AxiosPromise<number> =>
  axios.get(`${CONTEXT_PATH}/dashboard/credenciamentos`);

export const cancelamentosNotaFiscal = (): AxiosPromise<number> =>
  axios.get(`${CONTEXT_PATH}/dashboard/sol-cancelamento-notas`);

export const substituicaoNotaFiscal = (): AxiosPromise<number> =>
  axios.get(`${CONTEXT_PATH}/dashboard/sol-substituicao-notas`);

export const cancelamentosDms = (): AxiosPromise<number> =>
  axios.get(`${CONTEXT_PATH}/dashboard/sol-cancelamento-dms`);

export const cancelamentosGuiaAvulsa = (): AxiosPromise<number> =>
  axios.get(`${CONTEXT_PATH}/dashboard/sol-cancelamento-guia`);
