import { ActionButton, SearchPagination } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedDate } from 'react-intl';

const AcessosCadastradosList = props => {
  const {
    acessos,
    onEdit,
    onView,
    changePassword,
    block,
    searchWithPage
  } = props;

  return (
    <>
      <div className="panel-table-outer ">
        <table className="panel-table striped fancy">
          <thead>
            <tr>
              <th>CPF</th>
              <th>Data Cad</th>
              <th>Nome</th>
              <th>Data Nascimento</th>
              <th>Situação</th>
              <th>Email</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {acessos &&
              acessos.content &&
              acessos.content.map((acesso, index) => {
                return (
                  <tr className="reduced" key={index}>
                    <td>{acesso.cpf}</td>
                    <td>
                      {acesso.dataCadastro ? (
                        <FormattedDate
                          value={acesso.dataCadastro}
                          timeZone={'UTC'}
                        />
                      ) : (
                        ''
                      )}
                    </td>
                    <td>{acesso.nome}</td>
                    <td>
                      {acesso.dataNascimento ? (
                        <FormattedDate
                          value={acesso.dataNascimento}
                          timeZone={'UTC'}
                        />
                      ) : (
                        ''
                      )}
                    </td>
                    <td>{acesso.situacao}</td>
                    <td>{acesso.email}</td>
                    <td>
                      <div className="btn-actions">
                        <div className="btn-actions-inner">
                          <ActionButton
                            data-test-id="buttonView"
                            key="view-button"
                            icon="eye"
                            label="Visualizar"
                            onClick={() => onView(acesso.id)}
                          />
                          <ActionButton
                            data-test-id="buttonEdit"
                            key="edit-button"
                            icon="pencil-alt"
                            label="Editar"
                            onClick={() => onEdit(acesso.id)}
                          />
                          <ActionButton
                            data-test-id="buttonChangePassword"
                            key="change-password-button"
                            icon="key"
                            label="Alterar Senha"
                            onClick={() => changePassword(acesso.id)}
                          />
                          <ActionButton
                            data-test-id="buttonBlock"
                            key="block-button"
                            icon={acesso.ativo === 'A' ? 'lock' : 'unlock'}
                            label={
                              acesso.ativo === 'A' ? 'Bloquear' : 'Desbloquear'
                            }
                            onClick={() => block(acesso)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            {(!acessos || acessos.totalElements === 0) && (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan="7">
                  Sem registros
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {acessos && (
        <SearchPagination page={acessos} searchWithPage={searchWithPage} />
      )}
    </>
  );
};

AcessosCadastradosList.propTypes = {
  acessos: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  block: PropTypes.func.isRequired
};

export default AcessosCadastradosList;
