import { PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CaixaPostal } from '../types/CaixaPostal';
import { CONTEXT_PATH } from './contextPath';

const search = (params: string) => (params ? `search=${params}` : '');

export const loadCaixaPostal = (
  searchParams: string,
  page?: any
): AxiosPromise<PagedResponse<CaixaPostal>> =>
  axios.get(`${CONTEXT_PATH}/caixa-postal?${search(searchParams)}`, {
    params: { ...page }
  });

export const loadMensagem = (id: number): AxiosPromise<CaixaPostal> =>
  axios.get(`${CONTEXT_PATH}/caixa-postal/${id}`);

export const saveMensagem = (
  mensagem: CaixaPostal
): AxiosPromise<CaixaPostal> =>
  axios.post(`${CONTEXT_PATH}/caixa-postal/`, mensagem);

export const iniciarAnalise = (
  mensagem: CaixaPostal
): AxiosPromise<CaixaPostal> =>
  axios.post(`${CONTEXT_PATH}/caixa-postal/iniciar-analise`, mensagem);

export const qtdMensagensNovas = (): AxiosPromise<number> =>
  axios.get(`${CONTEXT_PATH}/caixa-postal/qtd-mensagens-novas`);
