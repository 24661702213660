import { FilterUtils } from '@elotech/components';
import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id,exercicio' };

const search = params => (params ? `search=${params}` : '');

export const loadConfiguracaoDivida = (searchParams, page) =>
  axios.get(`${CONTEXT_PATH}/dms-divida-config?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const removerConfiguracaoDivida = id =>
  axios.delete(`${CONTEXT_PATH}/dms-divida-config/remover/${id}`);

export const loadConfiguracaoDividaById = id =>
  axios.get(`${CONTEXT_PATH}/dms-divida-config/${id}`);

export const save = configuracaoDivida => {
  if (configuracaoDivida.id > 0) {
    return axios.put(
      `${CONTEXT_PATH}/dms-divida-config/${configuracaoDivida.id}`,
      configuracaoDivida
    );
  }
  return axios.post(`${CONTEXT_PATH}/dms-divida-config`, configuracaoDivida);
};

export const loadGuiasRecolhimento = (
  searchParams,
  exercicio,
  fields = 'descricao, exercicio'
) =>
  axios.get(
    `${CONTEXT_PATH}/dms-divida-config/guias-recolhimento?search=${FilterUtils.buildDefaultFilter()(
      searchParams
    )} and exercicio==${exercicio}`,
    undefined,
    fields
  );
