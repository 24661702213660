import { ActionButton, FormattedDate, Table } from '@elotech/components';
import React from 'react';

const ArquivosPrestacaoContasList = ({
  arquivosPrestacaoContas,
  onDownloadArquivo
}) => {
  return (
    <Table
      values={arquivosPrestacaoContas || []}
      keyExtractor={item => item.id}
    >
      <Table.Column
        header="Data Processamento"
        value={item => (
          <FormattedDate value={item.dataProcessamento || ''} timeZone="UTC" />
        )}
      />

      <Table.Column header="Nº Remessa" value={item => item.numeroRemessa} />

      <Table.Column
        header="Nº Lote Remessa"
        value={item => item.numeroLoteRemessa}
      />

      <Table.Column
        header="Nº Correção"
        value={item => item.numeroRemessaCorrecao}
      />

      <Table.Column
        header="Nº Lote Remessa Correção"
        value={item => item.numeroLoteRemessaCorrecao}
      />

      <Table.Column
        value={item => (
          <ActionButton
            data-test-id="buttonDownload"
            key="download-button"
            icon="download"
            label="Baixar"
            onClick={() => onDownloadArquivo(item)}
          />
        )}
      />
    </Table>
  );
};

ArquivosPrestacaoContasList.propTypes = {};
export default ArquivosPrestacaoContasList;
