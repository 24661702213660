import { Yup } from '@elotech/components';

export const validationSchema = Yup.object().shape({
  rubrica: Yup.string()
    .required()
    .max(20, 'Código da Rubrica deve conter no máximo 20 dígitos.')
    .label('Rubrica'),

  descricao: Yup.string()
    .required()
    .max(100, 'Descrição deve conter no máximo 100 dígitos.')
    .label('Descrição')
});
