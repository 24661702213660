import {
  Alert,
  Container,
  Hint,
  Loading,
  SearchFilter
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import swal from 'sweetalert2';

import { AcessoWebService, withService } from '../../../service';
import UsuariosIssqnList from './UsuariosIssqnList';

export class UsuariosIssqnListPage extends React.Component {
  static propTypes = {
    acessoWebService: PropTypes.object.isRequired
  };

  state = {
    acessos: {},
    filter: [
      {
        nome: '',
        cpf: '',
        situacao: ''
      }
    ],
    loading: false,
    pageConsulta: 0,
    searchParams: ''
  };

  fields = [
    {
      label: 'Nome',
      name: 'nome',
      type: 'STRING'
    },
    {
      label: 'CPF Usuário',
      name: 'cpf',
      type: 'STRING'
    },
    {
      label: 'Situação',
      name: 'ativo',
      type: 'ENUM',
      options: [
        {
          name: 'TODAS',
          descricao: 'Todas'
        },
        {
          name: 'A',
          descricao: 'Ativo'
        },
        {
          name: 'I',
          descricao: 'Inativo'
        }
      ]
    }
  ];

  searchWithPage = page => {
    this.setState({ pageConsulta: page });
    this.serviceSearch(this.state.searchParams, page);
  };

  serviceSearch = (searchParams, page) => {
    this.setState({
      loading: true,
      searchParams
    });

    this.props.acessoWebService
      .loadAcessosWeb(searchParams, page)
      .then(this.getAcessosWebSuccess)
      .catch(this.getAcessosWebError);
  };

  getAcessosWebSuccess = response => {
    this.setState({
      acessos: response.data,
      loading: false
    });
  };

  getAcessosWebError = error => {
    this.setState({ loading: false });
    Alert.error(
      { title: 'Não foi possível carregar os acessos cadastrados.' },
      error
    );
  };

  onEdit = id => {
    this.props.history.push(
      `/acessos-e-permissoes/usuarios-issqn/editar/${id}`
    );
  };

  onView = id => {
    this.props.history.push(`/acessos-e-permissoes/usuarios-issqn/view/${id}`);
  };

  changePassword = id => {
    this.props.history.push(
      `/acessos-e-permissoes/usuarios-issqn/alterar-senha/${id}`
    );
  };

  capitalizeFirstLetter = valor =>
    valor.charAt(0).toUpperCase() + valor.slice(1);

  onBloquearSuccess = success => {
    this.setState({ loading: false });
    swal('Bloqueado', 'Acesso Bloqueado', 'success');

    this.searchWithPage(this.state.pageConsulta);
  };

  onDesbloquearSuccess = success => {
    this.setState({ loading: false });
    swal('Liberado', 'Acesso Liberado', 'success');

    this.searchWithPage(this.state.pageConsulta);
  };

  onBloquearOrDesbloquearError = error => {
    this.setState({ loading: false });
    Alert.error({ title: 'Não foi bloquear o acesso web.' }, error);

    this.searchWithPage(this.state.pageConsulta);
  };

  block = acesso => {
    let bloquear = acesso.ativo === 'A' ? 'bloquear' : 'desbloquear';
    swal({
      title: `${this.capitalizeFirstLetter(bloquear)} Acesso?`,
      text: `Você deseja ${bloquear} acesso de usuário?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim'
    }).then(result => {
      if (result.value) {
        this.setState({ loading: true });
        this.props.acessoWebService
          .bloquearOrDesbloquear(acesso.id)
          .then(() => {
            acesso.ativo === 'A'
              ? this.onBloquearSuccess()
              : this.onDesbloquearSuccess();
          })
          .catch(this.onBloquearOrDesbloquearError);
      }
    });
  };

  render() {
    const { loading } = this.state;
    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-usuarios-issqn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        <div className="panel table table-responsive">
          <div className="panel-body">
            <SearchFilter fields={this.fields} search={this.serviceSearch} />
            <UsuariosIssqnList
              acessos={this.state.acessos}
              onEdit={this.onEdit}
              onView={this.onView}
              changePassword={this.changePassword}
              block={this.block}
              searchWithPage={this.searchWithPage}
            />
          </div>
        </div>
      </Container>
    );
  }
}

const ComponentWithService = withService({
  acessoWebService: AcessoWebService
})(UsuariosIssqnListPage);

export default ComponentWithService;
