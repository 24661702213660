import RelatorioComparativoValorEstimadoValorPrestadoFormPage from '../pages/relatorios/RelatorioComparativoValorEstimadoValorPrestadoFormPage';
import RelatorioDmsEntregueFormPage from '../pages/relatorios/RelatorioDmsEntregueFormPage';
import RelatorioEmpresasLiberadasEmissaoNfseFormPage from '../pages/relatorios/RelatorioEmpresasLiberadasEmissaoNfseFormPage';
import RelatorioGerencialISSFormPage from '../pages/relatorios/RelatorioGerencialISSFormPage';
import RelatorioLimiteFaturamentoMeiFormPage from '../pages/relatorios/RelatorioLimiteFaturamentoMeiFormPage';
import RelatorioNfseEmitidasFormPage from '../pages/relatorios/RelatorioNfseEmitidasFormPage';
import RelatorioPGDASEmpresasNaoEstabelecidasFormPage from '../pages/relatorios/RelatorioPGDASEmpresasNaoEstabelecidasFormPage';
import RelatorioPGDASImuneIsentoFormPage from '../pages/relatorios/RelatorioPGDASImuneIsentoFormPage';
import RelatorioPGDASLocacaoBensMoveisFormPage from '../pages/relatorios/RelatorioPGDASLocacaoBensMoveisFormPage';
import RelatorioPGDASReceitasOutrosMunicipiosFormPage from '../pages/relatorios/RelatorioPGDASReceitasOutrosMunicipiosFormPage';
import RelatorioPGDASRegimeFixoFormPage from '../pages/relatorios/RelatorioPGDASRegimeFixoFormPage';
import RelatorioPGDASServicosExteriorFormPage from '../pages/relatorios/RelatorioPGDASServicosExteriorFormPage';
import RelatorioSimplesNacionalValoresDASFormPage from '../pages/relatorios/RelatorioSimplesNacionalValoresDASFormPage';
import RelatorioVariacaoValoresFormPage from '../pages/relatorios/RelatorioVariacaoValoresFormPage';
import { Roles } from '../utils/Roles';

export const routesRelatorios = [
  {
    path: '/relatorios/relatorio-nfse-emitidas',
    component: RelatorioNfseEmitidasFormPage,
    icon: 'file-alt',
    title: 'Relatório NFS-e emitidas',
    role: Roles.rel_nfse_emitidas_read.name
  },
  {
    path: '/relatorios/dms-pgdas-dimp',
    component: RelatorioSimplesNacionalValoresDASFormPage,
    icon: 'file-alt',
    title: 'Relatório comparativo DMS x PGDAS x DIMP',
    role: Roles.rel_dms_pgdas_geral_read.name
  },
  {
    path: '/relatorios/dms-entregue',
    component: RelatorioDmsEntregueFormPage,
    icon: 'file-alt',
    title: 'Relatório DMS Entregue',
    role: Roles.rel_dms_entregue_read.name
  },
  {
    path: '/relatorios/limite-faturamento-mei',
    component: RelatorioLimiteFaturamentoMeiFormPage,
    icon: 'file-alt',
    title: 'MEI - Empresas Acima do Limite',
    role: Roles.rel_limite_faturamento_mei_read.name
  },
  {
    path: '/relatorios/pgdas-regime-fixo',
    component: RelatorioPGDASRegimeFixoFormPage,
    icon: 'file-alt',
    title: 'Simples Nacional - Declarações de Regime Fixo',
    role: Roles.rel_pgdas_regime_fixo_read.name
  },
  {
    path: '/relatorios/pgdas-servicos-ao-exterior',
    component: RelatorioPGDASServicosExteriorFormPage,
    icon: 'file-alt',
    title: 'Simples Nacional - Serviços Prestados ao Exterior',
    role: Roles.rel_pgdas_servicos_exterior_read.name
  },
  {
    path: '/relatorios/pgdas-declaracoes-bens-moveis',
    component: RelatorioPGDASLocacaoBensMoveisFormPage,
    icon: 'file-alt',
    title: 'Simples Nacional - Declarações de Bens/Móveis',
    role: Roles.rel_pgdas_declaracoes_bens_moveis_read.name
  },
  {
    path: '/relatorios/pgdas-empresas-nao-estabelecidas',
    component: RelatorioPGDASEmpresasNaoEstabelecidasFormPage,
    icon: 'file-alt',
    title: 'Simples Nacional - Empresas não estabelecidas no Município',
    role: Roles.rel_pgdas_empresas_nao_estabelecidas_read.name
  },
  {
    path: '/relatorios/pgdas-declaracoes-isentas-imunes',
    component: RelatorioPGDASImuneIsentoFormPage,
    icon: 'file-alt',
    title: 'Simples Nacional - Declarações isentas, imunes ou red. alíquota',
    role: Roles.rel_pgdas_declaracoes_isentas_imunes_red_aliquota_read.name
  },
  {
    path: '/relatorios/pgdas-receitas-outros-municipios',
    component: RelatorioPGDASReceitasOutrosMunicipiosFormPage,
    icon: 'file-alt',
    title: 'Simples Nacional - Receitas declaradas para outros Municípios',
    role: Roles.rel_pgdas_receitas_outros_municipios_read.name
  },
  {
    path: '/relatorios/empresas-liberadas-emissao-nfse',
    component: RelatorioEmpresasLiberadasEmissaoNfseFormPage,
    icon: 'file-alt',
    title: 'Empresas liberadas emissão NFS-e',
    role: Roles.rel_empresas_liberadas_emissao_nfse_read.name
  },
  {
    path: '/relatorios/gerencial-iss',
    component: RelatorioGerencialISSFormPage,
    icon: 'file-alt',
    title: 'Gerencial ISS',
    role: Roles.rel_empresas_liberadas_emissao_nfse_read.name
  },
  {
    path: '/relatorios/valor-estimado-valor-prestado',
    component: RelatorioComparativoValorEstimadoValorPrestadoFormPage,
    icon: 'file-alt',
    title: 'Valor estimado X Valor prestado',
    role: Roles.rel_comparativo_valor_estimado_valor_prestado_read.name
  },
  {
    path: '/relatorios/variacao-valores',
    component: RelatorioVariacaoValoresFormPage,
    icon: 'file-percent',
    title: 'Variação de valores',
    role: Roles.rel_variacao_valores_read.name
  }
];

export default routesRelatorios;
