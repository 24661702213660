import {
  Col,
  Container,
  FAB,
  FabSpeedDial,
  FormikTextArea,
  Loading,
  NotificationActions,
  Row,
  SectionTitle
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { Formik, FormikActions, FormikProps } from 'formik';
import { History } from 'history';
import { Alert } from 'iss-common/utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router';

import { DuvidaService } from '../../service';
import { Duvida } from '../../types/Duvida';
import DuvidaSection from './DuvidaSection';
import { CONCLUIDO, EM_ANALISE, NOVA } from './SituacaoDuvida';

type Props = {
  match: match<{ id: string }>;
  history: Pick<History, 'replace'>;
};

const initialValues: Duvida = {};

const DuvidaFormPage: React.FC<Props> = ({ match, history }) => {
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const [duvida, setDuvida] = useState(initialValues);

  const load = (id: string) => {
    if (!id) {
      setLoading(false);
      return;
    }

    DuvidaService.loadDuvida(+id)
      .then((result: AxiosResponse<Duvida>) => {
        setDuvida(result.data);
      })
      .catch((error: any) => {
        setLoading(false);
        Alert.error({ title: `Erro ao carregar os dados da dúvida` }, error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    load(id);
  }, [id]);

  const onEdit = async (
    formProps: FormikProps<Duvida>,
    novaSituacao?: string
  ) => {
    await formProps.setFieldValue('situacao', novaSituacao);
  };

  const onSave = async (
    values: Duvida,
    formikActions?: FormikActions<Duvida>,
    situacao?: string
  ) => {
    if (situacao !== undefined) {
      values.situacao = situacao;
    }

    DuvidaService.saveDuvidas(values)
      .then(async () => {
        const notificarRequerente =
          values.situacao === CONCLUIDO &&
          (await Alert.question({
            title:
              'Dúvida salva com sucesso.<br/><br/>Deseja enviar a resposta ao requerente?'
          }).then((result: any) => result.value));

        if (notificarRequerente) {
          setLoading(true);
          await DuvidaService.notificarRequerente(values);

          if (values.situacao !== EM_ANALISE) {
            history.replace(
              `/duvidas?filters==id=${encodeURIComponent(duvida.id || 0)}`
            );
          } else {
            load(match.params.id);
          }
        }
      })
      .catch((error: any) => {
        Alert.error({ title: 'Erro ao salvar resposta' }, error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Container breadcrumb>
        <Loading loading={loading} />
        <Formik enableReinitialize initialValues={duvida} onSubmit={onSave}>
          {(formProps: FormikProps<Duvida>) => (
            <>
              {duvida && (
                <>
                  <DuvidaSection duvida={duvida} />
                  {formProps.values.situacao !== NOVA && (
                    <>
                      <Row>
                        <Col md={6}>
                          <SectionTitle>
                            Observações do responsável
                            <p className="text-muted">
                              <small>
                                Informações visíveis somente pelos fiscais
                              </small>
                            </p>
                          </SectionTitle>
                          <FormikTextArea
                            name="observacao"
                            data-test-id="observacao"
                            noLabel
                            size={12}
                            disabled={formProps.values.situacao !== EM_ANALISE}
                          />
                        </Col>
                        <Col md={6}>
                          <SectionTitle>
                            Resposta ao Requerente
                            <p className="text-muted">
                              <small>
                                Poderá ser enviada ao requerente ao concluir a
                                resposta
                              </small>
                            </p>
                          </SectionTitle>
                          <FormikTextArea
                            name="resposta"
                            data-test-id="resposta-field"
                            noLabel
                            size={12}
                            disabled={formProps.values.situacao === CONCLUIDO}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
              <FabSpeedDial icon="ellipsis-v" title="Ações">
                {duvida && formProps.values.situacao === NOVA && (
                  <FAB
                    data-test-id="btn-analise"
                    icon="file-invoice-dollar"
                    onClick={() => onEdit(formProps, EM_ANALISE)}
                    title="Iniciar análise da dúvida"
                  />
                )}
                {duvida && formProps.values.situacao !== NOVA && (
                  <FAB
                    data-test-id="btn-concluir"
                    icon="check-double"
                    onClick={() =>
                      onSave(formProps.values, undefined, CONCLUIDO)
                    }
                    title="Salvar & Concluir"
                    disabled={duvida && formProps.values.situacao === CONCLUIDO}
                  />
                )}
                {duvida && formProps.values.situacao !== NOVA && (
                  <FAB
                    data-test-id="btn-salvar"
                    icon="check"
                    onClick={() => onSave(formProps.values)}
                    title="Salvar"
                    disabled={duvida && formProps.values.situacao === CONCLUIDO}
                  />
                )}
              </FabSpeedDial>
            </>
          )}
        </Formik>
      </Container>
    </>
  );
};

const ConnectedComponent = connect(null, {
  showNotification: NotificationActions.showNotification
})(DuvidaFormPage);

export { ConnectedComponent as default, DuvidaFormPage };
