import {
  ForbiddenPage,
  NotFoundPage,
  ProtectedRoute
} from '@elotech/components';
import { RouteWithRedirect } from 'iss-common/components';
import { NotificacaoPage } from 'iss-common/components';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import GerenciadorTarefasListPage from '../pages/gerenciador-tarefas/GerenciadorTarefasListPage';
import Home from '../pages/home/Home';
import MyAccountPage from '../pages/user/MyAccountPage';
import routesAcessosPermissoes from '../routes/RoutesAcessosPermissoes';
import routesAnexosPublicos from '../routes/RoutesAnexosPublicos';
import routesConcurso from '../routes/RoutesConcurso';
import routesConfiguracoes from '../routes/RoutesConfiguracoes';
import routesDenuncias from '../routes/RoutesDenuncias';
import routesPrestacaoContasEleicao from '../routes/RoutesPrestacaoContasEleicao';
import routesRelatorios from '../routes/RoutesRelatorios';
import routesRotinas from '../routes/RoutesRotinas';
import { Roles } from '../utils/Roles';
import { routesCaixaPostal } from './RoutesCaixaPostal';
import routesDuvidas from './RoutesDuvidas';

export const rootRoutes = [
  {
    title: 'Gerenciador de Tarefas',
    icon: 'cogs',
    path: '/tarefas',
    component: GerenciadorTarefasListPage,
    role: Roles.gerenciador_tarefas_read.name
  },
  {
    title: 'Notificações',
    icon: 'envelope',
    path: '/notificacoes',
    component: NotificacaoPage
  },
  {
    title: 'Minha Conta',
    icon: 'envelope',
    path: '/minha-conta',
    component: MyAccountPage
  },
  {
    title: 'Sem Permissao',
    icon: 'envelope',
    path: '/sem-permissao',
    component: ForbiddenPage
  }
];

const renderRoutes = (routes, userExists) => {
  return routes.map(({ path, component, exact = true, role }, key) =>
    role ? (
      <ProtectedRoute
        role={role}
        exact={exact}
        path={path}
        component={component}
        key={key}
      />
    ) : (
      <RouteWithRedirect
        path={path}
        component={component}
        hasPermission={userExists}
        exact={exact}
        key={key}
      />
    )
  );
};

const Routes = ({ userExists }) => {
  return (
    <Switch>
      <RouteWithRedirect
        path="/"
        component={Home}
        hasPermission={userExists}
        exact
      />
      {renderRoutes(routesAcessosPermissoes, userExists)}
      {renderRoutes(routesAnexosPublicos, userExists)}
      {renderRoutes(routesConfiguracoes, userExists)}
      {renderRoutes(routesPrestacaoContasEleicao, userExists)}
      {renderRoutes(routesDenuncias, userExists)}
      {renderRoutes(routesDuvidas, userExists)}
      {renderRoutes(routesCaixaPostal, userExists)}
      {renderRoutes(routesRotinas, userExists)}
      {renderRoutes(routesRelatorios, userExists)}
      {renderRoutes(routesConcurso, userExists)}
      {renderRoutes(rootRoutes, userExists)}
      <Route component={NotFoundPage} />
    </Switch>
  );
};

const allRoutes = [
  ...routesAcessosPermissoes,
  ...routesAnexosPublicos,
  ...routesConfiguracoes,
  ...routesPrestacaoContasEleicao,
  ...routesDuvidas,
  ...routesDenuncias,
  ...routesCaixaPostal,
  ...routesRotinas,
  ...routesRelatorios,
  ...routesConcurso,
  ...rootRoutes
];

export { allRoutes };
export default Routes;
