import { Row, SectionTitle } from '@elotech/components';
import React from 'react';

import Parametro from '../component/Parametro';

const UnicoTributosWebForm = ({
  dadosUnicoTributosWeb,
  onToggleUnicoTributosWeb,
  showFormUnicoTributosWeb,
  onChangeParametros
}) => {
  return (
    <>
      <SectionTitle hasButton={true}>
        Único - Tributos Web
        <button
          className="dropdown-arrow"
          data-test-id="button"
          onClick={onToggleUnicoTributosWeb}
        >
          <i
            className={
              showFormUnicoTributosWeb
                ? 'fa fa-chevron-up'
                : 'fa fa-chevron-down'
            }
          />
        </button>
      </SectionTitle>
      {showFormUnicoTributosWeb ? (
        <>
          <Row>
            <Parametro //Componente que criei para facilitar criacao de novos parametros futuros
              type="text"
              name="USUARIOISSUNICO"
              value={dadosUnicoTributosWeb.USUARIOISSUNICO.valor || ''}
              onChange={onChangeParametros}
              size={6}
              label="Usuário"
            />
          </Row>
          <Row>
            <Parametro
              type="password"
              name="SENHAISSUNICO"
              value={dadosUnicoTributosWeb.SENHAISSUNICO.valor || ''}
              onChange={onChangeParametros}
              size={6}
              label="Senha"
            />
          </Row>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default UnicoTributosWebForm;
