import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {
  EnvironmentUtils,
  ErrorPage,
  KeycloakService,
  UserInfo
} from '@elotech/components';
import React from 'react';
import { match } from 'react-router';

Bugsnag.start({
  apiKey: '7575658ea10874168f8e1e88238af917',
  appVersion: process.env.REACT_APP_VERSION || '0.0.1',
  metadata: {
    app: process.env.REACT_APP_NAME
  },
  releaseStage: EnvironmentUtils.resolveCurrentEnvironment(),
  enabledReleaseStages: ['prod', 'dev', 'qa'],
  plugins: [new BugsnagPluginReact()],
  onError: event => {
    const userInfo = KeycloakService.getInstance().userInfo as UserInfo;
    if (userInfo) {
      event.setUser(
        userInfo.sub ?? 'anonymous',
        userInfo.email,
        `${userInfo.name} -  ${userInfo.username}`
      );
      event.addMetadata('user', {
        groups: userInfo.groups,
        username: userInfo.username
      });
    }
  }
});

export const BugsnagErrorBoundary = Bugsnag.getPlugin(
  'react'
)!.createErrorBoundary(React);

type ErrorBoundaryProps = {
  currentUser: {
    id?: string;
    nome?: string;
    email?: string;
  };
  match: match;
  fallbackComponent?: React.ComponentProps<
    typeof BugsnagErrorBoundary
  >['FallbackComponent'];
  children: React.ReactNode;
};

export const ErrorBoundary: React.FunctionComponent<ErrorBoundaryProps> = ({
  fallbackComponent: FallbackComponent = ErrorPage,
  children
}) => {
  return (
    <BugsnagErrorBoundary FallbackComponent={FallbackComponent}>
      {children}
    </BugsnagErrorBoundary>
  );
};
