import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

export const loadParametros = () => {
  return axios.get(`${CONTEXT_PATH}/parametro-geral`);
};

export const loadFluxosAutorizacaoNfse = () => {
  return axios.get(`${CONTEXT_PATH}/parametro-geral/fluxos-autorizacao-nfse`);
};

export const findByParamName = (paramName: string) => {
  return axios.get(`${CONTEXT_PATH}/parametro-geral/${paramName}`);
};

export const update = (alteracaoParametro: object) =>
  axios.put(`${CONTEXT_PATH}/parametro-geral/salvar`, alteracaoParametro);

export const enviarEmail = (from: any) => {
  return axios.post(`${CONTEXT_PATH}/parametro-geral/enviar-email-teste`, from);
};
