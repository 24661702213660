import {
  ActionButton,
  ActionsGroup,
  Alert,
  Container,
  FAB,
  Field,
  Hint,
  Loading,
  NotificationActions,
  Panel,
  SearchFilter,
  SearchPagination,
  Table
} from '@elotech/components';
import { History } from 'history';
import React from 'react';
import { Notification } from 'react-notification-system';
import { connect } from 'react-redux';

import { FebrabanService } from '../../../service';
import { Febraban } from '../../../types/Febraban';

type Props = {
  history: Pick<History, 'push'>;
  showNotification(notification: Notification): void;
};

type State = {
  loading: boolean;
  febrabanList: Febraban[];
  searchParams: string;
  pagination?: any;
};

const URL_HELP =
  'https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-cadastro-febraban';

const fields: Field[] = [
  {
    label: 'Cod. Compensação',
    name: 'codigoCompensacao',
    type: 'NUMBER'
  },
  {
    label: 'Instituição',
    name: 'nomeInstituicao',
    type: 'STRING'
  }
];

class CadastroFebrabanListPage extends React.Component<Props, State> {
  state: State = {
    loading: false,
    febrabanList: [],
    searchParams: '',
    pagination: undefined
  };

  onDelete = (id?: number) => {
    Alert.question({
      title: 'Deseja excluir Banco ?'
    }).then((result: any) => {
      if (result.value) {
        this.setState({ loading: true });
        FebrabanService.deleteFebraban(id)
          .then(this.onDeleteSuccess)
          .catch(this.onDeleteError);
      }
    });
  };

  onDeleteSuccess = () => {
    this.setState({ loading: false });
    Alert.success({
      title: 'Banco excluído com sucesso.'
    });
    this.searchWithPage();
  };
  onDeleteError = () => {
    this.setState({ loading: false });
    Alert.error({ title: 'Erro ao excluir Banco' });
  };

  searchWithPage = (page?: any) => {
    this.setState({ pagination: page });
    this.onSearch(this.state.searchParams, page);
  };

  onEdit = (id?: number) => {
    this.props.history.push(
      `/acessos-e-permissoes/cadastro-febraban/editar/${id}`
    );
  };

  onSearch = (searchParams: string = this.state.searchParams, page?: any) => {
    this.setState({ loading: true, searchParams }, () => {
      FebrabanService.loadFebraban(searchParams, page)
        .then(response => {
          const { content, ...pagination } = response.data;
          this.setState({ febrabanList: content, pagination });
        })
        .catch(error => {
          Alert.error(
            { title: 'Erro ao carregar Bancos cadastrados na FEBRABAN' },
            error
          );
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    });
  };

  keyExtractor = (banco: Febraban) => banco.id!;

  paginationSearch = (page: any) => this.onSearch(undefined, page);

  onNew = () => {
    this.props.history.push('/acessos-e-permissoes/cadastro-febraban/novo');
  };

  render() {
    const { loading, febrabanList, pagination } = this.state;
    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a href={URL_HELP} target="_blank" rel="noopener noreferrer">
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        <Panel isTable>
          <SearchFilter fields={fields} search={this.onSearch} />
          <Table
            values={febrabanList}
            keyExtractor={this.keyExtractor}
            reduced={false}
          >
            <Table.Column
              header="Cod. Compensação"
              value={(banco: Febraban) => banco.codigoCompensacao}
            />
            <Table.Column
              header="Instituição"
              value={(banco: Febraban) => banco.nomeInstituicao}
            />
            <Table.Column
              header="Endereço Eletrônico"
              value={(banco: Febraban) => banco.enderecoEletronico}
            />
            <Table.Column
              header=""
              value={(banco: Febraban) => (
                <ActionsGroup>
                  <ActionButton
                    data-test-id={`edit-button-test-${banco.id}`}
                    key="edit-button"
                    icon="pencil-alt"
                    label="Editar"
                    onClick={() => this.onEdit(banco.id)}
                  />
                  <ActionButton
                    data-test-id={`delete-button-test-${banco.id}`}
                    key="delete-button"
                    icon="trash-alt"
                    label="Excluir"
                    onClick={() => this.onDelete(banco.id)}
                  />
                </ActionsGroup>
              )}
            />
          </Table>
          {pagination && (
            <SearchPagination
              page={pagination}
              searchWithPage={this.paginationSearch}
            />
          )}
        </Panel>
        <div className="btn-save">
          <FAB
            data-test-id="btn-new"
            icon="plus"
            title="Adicionar Novo"
            onClick={this.onNew}
          />
        </div>
      </Container>
    );
  }
}

const ConnectedComponent = connect(null, {
  showNotification: NotificationActions.showNotification
})(CadastroFebrabanListPage);

export { ConnectedComponent as default, CadastroFebrabanListPage };
