import { ActionButton, FormattedDate } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const VersoesDocumentoList = ({
  versoesDocumentoData,
  edit,
  exclude,
  onToggle
}) => {
  return (
    <div className="panel table table-responsive">
      <div className="panel-body">
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <thead>
              <tr>
                <th>Versões</th>
                <th style={{ textAlign: 'center' }}> Data Versão</th>
                <th style={{ textAlign: 'center' }}> Arquivo</th>
                <th>
                  <div className="btn-actions header-actions">
                    <div className="btn-actions-inner">
                      <ActionButton
                        data-test-id="buttonNew"
                        key="new-button"
                        icon="plus"
                        label="Novo"
                        onClick={onToggle}
                      />
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {versoesDocumentoData.map((versoesDocumento, index) => {
                return (
                  <tr key={index}>
                    <td>{versoesDocumento.versao}</td>
                    <td style={{ textAlign: 'center' }}>
                      {versoesDocumento.dataVersao && (
                        <FormattedDate
                          value={versoesDocumento.dataVersao}
                          timeZone={'UTC'}
                        />
                      )}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {versoesDocumento.nomeArquivo}
                    </td>
                    <td>
                      <div className="btn-actions">
                        <div className="btn-actions-inner">
                          <ActionButton
                            data-test-id={`buttonEdit${index}`}
                            key="edit-button"
                            icon="pencil-alt"
                            label="Editar"
                            onClick={() => edit(index)}
                          />
                          <ActionButton
                            data-test-id={`buttonExclude${index}`}
                            key="exclude-button"
                            icon="trash-alt"
                            label="Excluir"
                            onClick={() => exclude(index)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

VersoesDocumentoList.propTypes = {
  versoesDocumentoData: PropTypes.array.isRequired,
  edit: PropTypes.func,
  exclude: PropTypes.func,
  onToggle: PropTypes.func
};

export default VersoesDocumentoList;
