import { Button, Col, Row } from '@elotech/components';
import React from 'react';
type Props = {
  value: string;
  name: string;
  className?: any | undefined;
  placeholder?: any | undefined;
  onCancel: any;
  onSave: any;
  onChange: any;
};
const MessageBox: React.FC<Props> = ({
  value,
  name,
  className,
  placeholder,
  onCancel,
  onSave,
  onChange
}) => {
  return (
    <>
      <Row>
        <Col md={12}>
          <div className="form-group">
            <textarea
              className={className}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="form-group">
            <Button
              data-test-id="button-save"
              className="inline"
              color="module-color"
              onClick={onSave}
              iconPosition="left"
            >
              <i className="fa fa-check" />
              Salvar
            </Button>
            <Button
              data-test-id="button-cancel"
              className="inline"
              color="neutral"
              onClick={onCancel}
              iconPosition="left"
            >
              <i className="fa fa-times" />
              Cancelar
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default MessageBox;
