import {
  ActionButton,
  ActionsGroup,
  Alert,
  Container,
  Hint,
  Loading,
  Panel,
  Table
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { History } from 'history';
import React, { useEffect, useState } from 'react';

import { TemplateEmailService } from '../../../service';
import { TemplateEmail } from '../../../types/TemplateEmail';

type Props = {
  history: Pick<History, 'push'>;
};

const URL_HELP =
  'https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-template-mensagem-email';

const TemplateMensagemEmailListPage: React.FC<Props> = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState<TemplateEmail[]>([]);

  useEffect(() => {
    findAllTemplateEmail();
  }, []);

  const findAllTemplateEmail = () => {
    setLoading(true);
    TemplateEmailService.findAllTemplateEmail()
      .then((response: AxiosResponse<TemplateEmail[]>) => {
        setTemplates(response.data);
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Não foi possível carregar os template de mensagem do email.'
          },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const onEdit = (id?: number) => {
    history.push(`/configuracoes/mensagem-template-email/editar/${id}`);
  };

  return (
    <Container
      breadcrumb
      titleRightComponent={
        <a href={URL_HELP} target="_blank" rel="noopener noreferrer">
          <Hint
            classes={`inline clean module-color center-right fa-question-circle`}
          >
            Ajuda?
          </Hint>
        </a>
      }
    >
      <Loading loading={loading} />

      <Panel isTable>
        <Table<TemplateEmail>
          values={templates}
          keyExtractor={item => `${item.id}`}
        >
          <Table.Column<TemplateEmail>
            data-test-id="table-row"
            header="Assuntos"
            value={item => item.assunto}
          />

          <Table.Column<TemplateEmail>
            data-test-id="buttons-col"
            header=""
            value={item => (
              <ActionsGroup>
                <ActionButton
                  data-test-id="edit-button"
                  key="edit-button"
                  icon="pencil-alt"
                  label="Editar Mensagem"
                  onClick={() => onEdit(item.id)}
                />
              </ActionsGroup>
            )}
          />
        </Table>
      </Panel>
    </Container>
  );
};

export default TemplateMensagemEmailListPage;
