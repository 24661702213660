import { formatUtils } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const AcessosList = ({ acessos }) => {
  return (
    <Fragment>
      <div className="panel table table-responsive">
        <div className="panel-body">
          <div className="panel-table-outer ">
            <table className="panel-table striped fancy">
              <thead>
                <tr>
                  <th>CPF</th>
                  <th>Nome</th>
                </tr>
              </thead>
              <tbody>
                {acessos.map((acesso, index) => {
                  return (
                    <tr key={index}>
                      <td>{formatUtils.formatCpfCnpj(acesso.cpf)}</td>
                      <td>{acesso.nome}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

AcessosList.propTypes = {
  acessos: PropTypes.array
};

export default AcessosList;
