import {
  Alert,
  Container,
  Hint,
  Loading,
  SearchFilter
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

import { CancelamentoDmsService, withService } from '../../../service';
import CancelamentoDMSList from './CancelamentoDMSList';

export class CancelamentoDMSListPage extends React.Component {
  static propTypes = {
    cancelamentoDmsService: PropTypes.object.isRequired
  };

  state = {
    solicitacoesCancelamentoDms: {},
    loading: false
  };

  searchWithPage = page => {
    this.setState({ pageConsulta: page });
    this.serviceSearch(this.state.searchParams, page);
  };

  serviceSearch = (searchParams, page) => {
    this.setState({
      loading: true,
      searchParams
    });

    this.props.cancelamentoDmsService
      .loadCancelamentosDms(searchParams, page)
      .then(this.getCancelamentosDmsSuccess)
      .catch(this.getCancelamentosDmsError);
  };

  getCancelamentosDmsSuccess = response => {
    this.setState({
      solicitacoesCancelamentoDms: response.data,
      loading: false
    });
  };

  getCancelamentosDmsError = error => {
    this.setState({ loading: false });
    Alert.error(
      {
        title:
          'Não foi possível carregar as solicitações de cancelamento de Dms.'
      },
      error
    );
  };

  fields = [
    {
      label: 'Situação',
      name: 'statusCancelamentoDMS',
      type: 'ENUM',
      options: [
        {
          name: 'AGUARDANDO_APROVACAO',
          descricao: 'Aguardando Aprovação'
        },
        {
          name: 'APROVADO',
          descricao: 'Aprovado'
        },
        {
          name: 'NAO_APROVADO',
          descricao: 'Não Aprovado'
        }
      ]
    },
    {
      label: 'Nº Solicitação',
      name: 'id',
      type: 'NUMBER'
    },
    {
      label: 'Data Solicitação',
      name: 'dataSolicitacao',
      type: 'DATE'
    },
    {
      label: 'Nome Prestador',
      name: 'cadastroGeral.pessoa.nome',
      type: 'STRING'
    },
    {
      label: 'Cnpj',
      name: 'cadastroGeral.pessoa.cnpjCpf',
      type: 'STRING'
    },
    {
      label: 'Nº DMS',
      name: 'numeroDms',
      type: 'NUMBER'
    }
  ];

  analyzeButton = id => {
    this.props.history.push(
      `/acessos-e-permissoes/cancelamento-dms/analisar/${id}`
    );
  };

  render() {
    const { solicitacoesCancelamentoDms, loading } = this.state;

    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-solicitacao-cancelamento-dms"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        <div className="panel table table-responsive">
          <div className="panel-body">
            <SearchFilter fields={this.fields} search={this.serviceSearch} />
            <CancelamentoDMSList
              solicitacoesCancelamentoDms={solicitacoesCancelamentoDms}
              analyzeButton={this.analyzeButton}
              searchWithPage={this.searchWithPage}
            />
          </div>
        </div>
      </Container>
    );
  }
}

const ComponentWithService = withService({
  cancelamentoDmsService: CancelamentoDmsService
})(CancelamentoDMSListPage);

export default ComponentWithService;
