import { CadastroGeral } from '../types/CadastroGeral';

export const formataCadastroGeral = (cadastroGeral: CadastroGeral) => {
  if (!cadastroGeral || !cadastroGeral.pessoa) return '';

  const fmt = (val: any) => (val ? `${val} - ` : val);

  const inscr = cadastroGeral.cadastroGeral;
  const doc = cadastroGeral.pessoa?.cnpjCpf;
  const nome = cadastroGeral.pessoa?.nome;

  return `${fmt(inscr)} ${fmt(doc)} ${nome}`;
};

export const isNumeric = (value: string) => {
  return /^\d+$/.test(value);
};

export const getGoogleMapsUrl = (localizacao: string) => {
  return `http://maps.google.com/maps?z=40&t=m&q=${localizacao}`;
};

export const openGoogleMaps = (localizacao: string) => {
  window.open(getGoogleMapsUrl(localizacao));
};
