import { FormikTextArea, SectionTitle } from '@elotech/components';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { Denuncia } from '../../../types/Denuncia';
import { EM_ANALISE } from '../SituacaoDenuncia';

type Props = {
  denuncia: Denuncia;
};

const ObservacaoSection: React.FC<Props> = ({ denuncia }) => {
  return (
    <>
      <SectionTitle>
        Observações do Fiscal
        <p className="text-muted">
          <small>Informações visíveis somente pelos fiscais</small>
        </p>
      </SectionTitle>

      <Row>
        <Col>
          <FormikTextArea
            name="observacao"
            noLabel
            size={12}
            disabled={denuncia.situacao !== EM_ANALISE}
          />
        </Col>
      </Row>
    </>
  );
};

export default ObservacaoSection;
