import { DisplayData, FormattedDate } from '@elotech/components';
import { Field, FieldProps } from 'formik';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { Denuncia } from '../../../types/Denuncia';
import {
  CONCLUIDO,
  EM_ANALISE,
  NOVA,
  getLabelSituacao
} from '../SituacaoDenuncia';

type Props = {
  denuncia: Denuncia;
};

const DenunciaSection: React.FC<Props> = ({ denuncia }) => {
  return (
    <>
      <Row>
        <Col md={9}>
          <div className="display-data border small mb-xs">
            <Row>
              <Col md={2}>
                <DisplayData title="Código">{denuncia.codigo}</DisplayData>
              </Col>
              <Col md={3}>
                <DisplayData title="Data">
                  <FormattedDate value={denuncia.dataCadastro} />
                </DisplayData>
              </Col>
              <Col md={7}>
                <DisplayData title="Tipo de Denúncia">
                  {denuncia.tipo && denuncia.tipo.descricao}
                </DisplayData>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <DisplayData title="Descrição">
                  <span style={{ fontStyle: 'italic' }}>
                    "{denuncia.descricao}"
                  </span>
                </DisplayData>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={3}>
          <div className="display-data border small mb-xs">
            <Row>
              <Col>
                <DisplayData title="Situação">
                  <Field
                    name="situacao"
                    render={(props: FieldProps<string>) => {
                      const situacao = props.field.value;

                      let cor;

                      switch (situacao) {
                        case NOVA:
                          cor = 'navy';
                          break;
                        case EM_ANALISE:
                          cor = 'red';
                          break;
                        case CONCLUIDO:
                          cor = 'green';
                          break;
                        default:
                          cor = 'inherited';
                      }

                      return (
                        <span style={{ fontWeight: 'bold', color: cor }}>
                          {getLabelSituacao(props.field.value)}
                        </span>
                      );
                    }}
                  />
                </DisplayData>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DenunciaSection;
