import {
  Checkbox,
  Col,
  Hint,
  InputDate,
  InputInteger,
  Row,
  SectionTitle
} from '@elotech/components';
import React from 'react';

import Parametro from '../component/Parametro';

const IssForm = ({
  onChangeParametros,
  dadosIss,
  onToggleIss,
  showFormIss
}) => {
  function preventKeyboardInput(event) {
    event.preventDefault();
  }

  return (
    <>
      <SectionTitle hasButton={true}>
        ISS
        <button
          className="dropdown-arrow"
          data-test-id="button"
          onClick={onToggleIss}
        >
          <i
            className={showFormIss ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}
          />
        </button>
      </SectionTitle>
      {showFormIss && (
        <>
          <Row>
            <Col md={3}>
              <div className="form-group">
                <label htmlFor="">ISS Alíquota Mínima(%)</label>
                <input
                  type="number"
                  placeholder="%"
                  name="ALIQUOTAMINIMA"
                  key="ALIQUOTAMINIMA"
                  value={dadosIss.ALIQUOTAMINIMA.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label htmlFor=""> ISS Alíquota Máxima(%)</label>
                <input
                  type="number"
                  placeholder="%"
                  name="ALIQUOTAMAXIMA"
                  key="ALIQUOTAMAXIMA"
                  value={dadosIss.ALIQUOTAMAXIMA.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label htmlFor=""> Ajuste ISS Calculado(%)</label>
                <input
                  type="number"
                  placeholder="%"
                  name="AJUSTEISSCALCULADO"
                  key="AJUSTEISSCALCULADO"
                  value={dadosIss.AJUSTEISSCALCULADO.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label htmlFor="">Regra Local de Incidência</label>
                <select
                  name="REGRALOCALINCIDENCIA"
                  key="REGRALOCALINCIDENCIA"
                  value={dadosIss.REGRALOCALINCIDENCIA.valor || ''}
                  onChange={onChangeParametros}
                >
                  <option value="116/03">116/03</option>
                  <option value="GERAL">Geral</option>
                </select>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">Receita ISS</label>
                <input
                  type="text"
                  name="RECEITA_ISS"
                  key="RECEITA_ISS"
                  value={dadosIss.RECEITA_ISS.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">Situação de parcela em aberto</label>
                <InputInteger
                  name="PARAM_SITUACAO_PARCELA_ABERTO"
                  key="PARAM_SITUACAO_PARCELA_ABERTO"
                  value={dadosIss.PARAM_SITUACAO_PARCELA_ABERTO.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">Situação de parcela sem movimento</label>
                <InputInteger
                  name="PARAM_SIT_PARCELA_SEM_MOV"
                  key="PARAM_SIT_PARCELA_SEM_MOV"
                  value={dadosIss.PARAM_SIT_PARCELA_SEM_MOV.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>

            <Col md={3}>
              <div className="form-group">
                <label htmlFor="">Situação de parcela cancelada</label>
                <InputInteger
                  name="SITUACAO_PARCELA_CANCELADA"
                  key="SITUACAO_PARCELA_CANCELADA"
                  value={dadosIss.SITUACAO_PARCELA_CANCELADA.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group">
                <label htmlFor="">Motivo cancelamento DMS</label>
                <InputInteger
                  name="MOTIVOCANCELAMENTODMS"
                  key="MOTIVOCANCELAMENTODMS"
                  value={dadosIss.MOTIVOCANCELAMENTODMS.valor || ''}
                  onChange={onChangeParametros}
                  autoComplete="off"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Checkbox
                data-test-id="button-habilita-entregar-dms"
                className="form-group"
                name="HABILTAMSGAVISOENTREGARDMS"
                id="HABILTAMSGAVISOENTREGARDMS"
                checked={dadosIss.HABILTAMSGAVISOENTREGARDMS.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar aviso de entrega DMS"
              />
            </Col>
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="RETERFORADOMUNICIPIO"
                id="RETERFORADOMUNICIPIO"
                checked={dadosIss.RETERFORADOMUNICIPIO.valor === 'S'}
                onChange={onChangeParametros}
                label="Reter fora do múnicipio"
              />
            </Col>
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="ISENCAOGRUPO"
                id="ISENCAOGRUPO"
                checked={dadosIss.ISENCAOGRUPO.valor === 'S'}
                onChange={onChangeParametros}
                label="Isenção grupo"
              />
            </Col>
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="HABILITAEMISSAONFSEDMSFECHADA"
                id="HABILITAEMISSAONFSEDMSFECHADA"
                checked={dadosIss.HABILITAEMISSAONFSEDMSFECHADA.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar emissão de NFS-e/DMS fechada"
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="HABILITAISSRETIDO"
                id="HABILITAISSRETIDO"
                checked={dadosIss.HABILITAISSRETIDO.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar ISS retido"
              />
            </Col>
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="CANCELADEBITODMSTOMADO"
                id="CANCELADEBITODMSTOMADO"
                checked={dadosIss.CANCELADEBITODMSTOMADO.valor === 'S'}
                onChange={onChangeParametros}
                label="Cancelar débito DMS tomado"
              />
            </Col>
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="USATIPOEMPRESAVENCIMENTODMS"
                id="USATIPOEMPRESAVENCIMENTODMS"
                checked={dadosIss.USATIPOEMPRESAVENCIMENTODMS.valor === 'S'}
                onChange={onChangeParametros}
                label="Usar tipo empresa no vencimento DMS"
              />
            </Col>
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="HABILITA_MOV_DMS_VENCIDO"
                id="HABILITA_MOV_DMS_VENCIDO"
                checked={dadosIss.HABILITA_MOV_DMS_VENCIDO.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar movimentações com DMS vencida"
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="OBRIGA_CNAE_ITEM_LANCAMENTO_SERVICO_TOMADO"
                id="OBRIGA_CNAE_ITEM_LANCAMENTO_SERVICO_TOMADO"
                checked={
                  dadosIss.OBRIGA_CNAE_ITEM_LANCAMENTO_SERVICO_TOMADO.valor ===
                  'S'
                }
                onChange={onChangeParametros}
                label="Obrigar CNAE e Item de Serviço no lançamento de serviços tomados"
              />
            </Col>
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="GERAR_NFSE_GUIA_AVULSA"
                id="GERAR_NFSE_GUIA_AVULSA"
                checked={dadosIss.GERAR_NFSE_GUIA_AVULSA.valor === 'S'}
                onChange={onChangeParametros}
                label="Gerar documento fiscal após lançamento da Guia Avulsa"
              />
            </Col>
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="OBRIGA_CNAE_SERVICO_LANCAMENTO_GUIA_AVULSA"
                id="OBRIGA_CNAE_SERVICO_LANCAMENTO_GUIA_AVULSA"
                checked={
                  dadosIss.OBRIGA_CNAE_SERVICO_LANCAMENTO_GUIA_AVULSA.valor ===
                  'S'
                }
                onChange={onChangeParametros}
                label="Deixar CNAE e serviço obrigatórios no lançamento de Guia Avulsa"
              />
            </Col>
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="VALIDA_FATURAMENTO_MEI"
                id="VALIDA_FATURAMENTO_MEI"
                checked={dadosIss.VALIDA_FATURAMENTO_MEI.valor === 'S'}
                onChange={onChangeParametros}
                label="Valida faturamento MEI"
              />
            </Col>
          </Row>
          <></>
          <Row>
            {dadosIss.VALIDA_FATURAMENTO_MEI.valor === 'S' && (
              <Col md={6}>
                <div className="form-group">
                  <label htmlFor="">Teto de Faturamento para MEI</label>
                  <input
                    initialValue={0}
                    name="TETO_FATURAMENTO_MEI"
                    key="TETO_FATURAMENTO_MEI"
                    value={dadosIss.TETO_FATURAMENTO_MEI.valor ?? '0'}
                    onChange={onChangeParametros}
                    type="number"
                    placeholder="digite o limite de faturamento das MEI's"
                  />
                </div>
              </Col>
            )}
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="HABILITAR_ENVIO_EMAIL_DMS_NAO_ENTREGUE"
                id="HABILITAR_ENVIO_EMAIL_DMS_NAO_ENTREGUE"
                checked={
                  dadosIss.HABILITAR_ENVIO_EMAIL_DMS_NAO_ENTREGUE.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Habilitar envio de e-mail em caso de DMS vencida"
              />
            </Col>
            {dadosIss.HABILITAR_ENVIO_EMAIL_DMS_NAO_ENTREGUE.valor === 'S' && (
              <Col md={3}>
                <div className="form-group">
                  <label htmlFor="">Horário de envio de e-mail</label>
                  <input
                    initialValue={0}
                    name="HORARIO_ENVIO_EMAIL_DMS_NAO_ENTREGUE"
                    key="HORARIO_ENVIO_EMAIL_DMS_NAO_ENTREGUE"
                    value={
                      dadosIss.HORARIO_ENVIO_EMAIL_DMS_NAO_ENTREGUE.valor ?? '0'
                    }
                    onChange={onChangeParametros}
                    type="time"
                    placeholder="Digite o horário em que o e-mail será enviado"
                  />
                </div>
              </Col>
            )}
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="BLOQUEIA_EMISSAO_NFSE_DMS_PENDENTE"
                id="BLOQUEIA_EMISSAO_NFSE_DMS_PENDENTE"
                checked={
                  dadosIss.BLOQUEIA_EMISSAO_NFSE_DMS_PENDENTE.valor === 'S'
                }
                onChange={onChangeParametros}
                label="Habilitar bloqueio de emissão de NFS-e em caso de DMS vencida"
              />
            </Col>
            {dadosIss.BLOQUEIA_EMISSAO_NFSE_DMS_PENDENTE.valor === 'S' && (
              <Col md={3}>
                <div className="form-group">
                  <label htmlFor="">
                    Quantidade de dias para começar a validar o bloqueio de
                    NFS-e
                  </label>
                  <input
                    initialValue={0}
                    name="QTD_DIAS_BLOQUEIO_NFSE_DMS_PENDENTE"
                    key="QTD_DIAS_BLOQUEIO_NFSE_DMS_PENDENTE"
                    value={
                      dadosIss.QTD_DIAS_BLOQUEIO_NFSE_DMS_PENDENTE.valor ?? '0'
                    }
                    onChange={onChangeParametros}
                    type="number"
                    placeholder="Digite a quantidade de dias para validar o bloqueio"
                  />
                </div>
              </Col>
            )}
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="RETEM_ISS_COM_BASE_OBRA"
                id="RETEM_ISS_COM_BASE_OBRA"
                checked={dadosIss.RETEM_ISS_COM_BASE_OBRA.valor === 'S'}
                onChange={onChangeParametros}
                label="Retem ISS com base nos dados da obra na emissão da NFS-e"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">Observação incentivo alíquota serviço</label>
                <input
                  name="OBSINCENTIVOALIQUOTASERVICO"
                  key="OBSINCENTIVOALIQUOTASERVICO"
                  type="text"
                  value={dadosIss.OBSINCENTIVOALIQUOTASERVICO.valor || ''}
                  onChange={onChangeParametros}
                  placeholder="Digite..."
                  autoComplete="off"
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="">Competência Início Verificação DMS</label>
                <InputDate
                  name="COMPINICIOVERIFICACAODMS"
                  value={dadosIss.COMPINICIOVERIFICACAODMS.valor}
                  onChange={onChangeParametros}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Checkbox
                className="form-group"
                name="HABILITA_ENTREGA_DMS_AUTOMATICO"
                id="HABILITA_ENTREGA_DMS_AUTOMATICO"
                checked={dadosIss.HABILITA_ENTREGA_DMS_AUTOMATICO.valor === 'S'}
                onChange={onChangeParametros}
                label="Habilitar entrega DMS Automático"
              />
            </Col>
          </Row>
          {dadosIss.HABILITA_ENTREGA_DMS_AUTOMATICO.valor === 'S' && (
            <Row style={{ display: 'flex', alignItems: 'end' }}>
              <Col md={3}>
                <div className="form-group">
                  <label htmlFor="">Tipo de envio de DMS</label>
                  <select
                    name="TIPO_ENVIO_DMS_AUTOMATICO"
                    key="TIPO_ENVIO_DMS_AUTOMATICO"
                    value={dadosIss.TIPO_ENVIO_DMS_AUTOMATICO?.valor || 'A'}
                    onChange={onChangeParametros}
                  >
                    <option value="A">Ambos</option>
                    <option value="P">Prestador</option>
                    <option value="T">Tomador</option>
                  </select>
                </div>
              </Col>
              <Col md={3}>
                <label htmlFor="">Dia de envio DMS automático</label>
                <Hint
                  classes={`inline clean module-color top-left fa-exclamation-circle mobile xs`}
                >
                  O envio será realizado a 00:00h todo mês no dia informado
                </Hint>
                <InputInteger
                  name="HORARIO_ENVIO_DMS_AUTOMATICO"
                  key="HORARIO_ENVIO_DMS_AUTOMATICO"
                  value={dadosIss.HORARIO_ENVIO_DMS_AUTOMATICO.valor ?? '1'}
                  onChange={onChangeParametros}
                  type="number"
                  onKeyDown={preventKeyboardInput}
                  min={1}
                  max={31}
                  label="Dia do mês para envio de DMS Automático"
                  placeholder="Digite o dia em que a DMS será enviado"
                />
              </Col>
              <Col md={3}>
                <Parametro
                  type="text"
                  name="USUARIOISSDMSAUTOMATICO"
                  value={dadosIss.USUARIOISSDMSAUTOMATICO.valor || ''}
                  onChange={onChangeParametros}
                  size={12}
                  label="Usuário DMS Automático"
                />
              </Col>
              <Col md={3}>
                <Parametro
                  type="password"
                  name="SENHAISSAUTOMATICO"
                  value={dadosIss.SENHAISSAUTOMATICO.valor || ''}
                  onChange={onChangeParametros}
                  label="Senha DMS Automático"
                  size={12}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};
export default IssForm;
