import {
  DisplayDataGrid,
  DisplayDataItem,
  FormattedDateTime,
  SectionTitle,
  formatUtils
} from '@elotech/components';
import React from 'react';
import { Row } from 'react-bootstrap';

import { getLabelSituacao } from '../../enums/SituacaoCaixaPostal';
import { CaixaPostal } from '../../types/CaixaPostal';

type Props = {
  caixaPostal: CaixaPostal;
};

const CaixaPostalSection: React.FC<Props> = ({ caixaPostal }) => {
  return (
    <>
      <SectionTitle>Mensagem</SectionTitle>
      <DisplayDataGrid>
        <Row>
          <DisplayDataItem title="Id" md={2}>
            {caixaPostal.id}
          </DisplayDataItem>

          <DisplayDataItem title="Enviado para" md={5}>
            {caixaPostal.cnpjCpfDestinatario
              ? `${formatUtils.formatCpfCnpj(
                  caixaPostal.cnpjCpfDestinatario
                )} - ${caixaPostal.nomeDestinatario}`
              : 'Fiscais Responsáveis'}
          </DisplayDataItem>

          <DisplayDataItem title="Enviado por" md={5}>
            {`${formatUtils.formatCpfCnpj(caixaPostal.cnpjCpfRemetente)} -
                  ${caixaPostal.nomeRemetente}`}
          </DisplayDataItem>
        </Row>

        <Row>
          <DisplayDataItem title="Situação" md={2}>
            {getLabelSituacao(caixaPostal.situacao)}
          </DisplayDataItem>

          <DisplayDataItem title="Data de envio" md={5}>
            {caixaPostal.dataEnvio ? (
              <FormattedDateTime value={caixaPostal.dataEnvio} />
            ) : (
              ''
            )}
          </DisplayDataItem>

          <DisplayDataItem title="Data de Leitura" md={5}>
            {caixaPostal.dataLeitura ? (
              <FormattedDateTime value={caixaPostal.dataEnvio} />
            ) : (
              'Não lido'
            )}
          </DisplayDataItem>
        </Row>

        <Row>
          <DisplayDataItem title="Título">{caixaPostal.titulo}</DisplayDataItem>
        </Row>
        <Row>
          <DisplayDataItem title="Descrição">
            {caixaPostal.descricao}
          </DisplayDataItem>
        </Row>
        {caixaPostal.resposta !== undefined && caixaPostal.resposta !== null && (
          <Row>
            <DisplayDataItem title="Resposta">
              {caixaPostal.resposta}
            </DisplayDataItem>
          </Row>
        )}
      </DisplayDataGrid>
    </>
  );
};

export default CaixaPostalSection;
