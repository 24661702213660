import {
  ActionButton,
  Col,
  ErrorText,
  FormattedDate,
  Row,
  SectionTitle
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

import DetailQuickView from './quickview/DetailQuickView';

const errorMessages = {
  motivo: 'Este campo é obrigatório!'
};

const STATUS_AGUARDANDO_FISCAL = 'F';

const AnaliseCancelamento = ({
  solicitacaoCancelamento,
  motivoCancelamentoNota,
  abrirTelaDetalheHistorico,
  error,
  aprovar,
  rejeitar,
  visualizarNotaFiscal,
  visualizarDetalheHistorico,
  fecharTelaDetalheHistorico,
  detalheHistorico,
  onChangeInputValue,
  generateUrlAnexo
}) => {
  return (
    <>
      <div>
        <SectionTitle marginTop="0">Analisar NFSe</SectionTitle>
        <div className="display-data border small mb-xs">
          <Row>
            <Col md={6}>
              <b className="dd-title">Nº Nota Fiscal</b>
              <div className="dd-content" id="numNotaFiscal">
                {solicitacaoCancelamento.numeroNota}
              </div>
            </Col>
            <Col md={6}>
              <b className="dd-title">Solicitante</b>
              <div className="dd-content" id="solicitante">
                {solicitacaoCancelamento.nomeSolicitante}
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <button
            data-test-id="buttonvisualizarNotaFiscal"
            className="btn module-color"
            onClick={visualizarNotaFiscal}
          >
            Visualizar Nota Fiscal
          </button>
        </div>
      </div>
      <SectionTitle>Histórico</SectionTitle>
      <div className="panel table table-responsive">
        <div className="panel-body">
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <thead>
                <tr>
                  <th>Situação</th>
                  <th>Data</th>
                  {solicitacaoCancelamento.nomeArquivo && <th>Evidência</th>}
                  <th>Motivo</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {solicitacaoCancelamento &&
                  solicitacaoCancelamento.historicos &&
                  solicitacaoCancelamento.historicos.map(historico => {
                    return (
                      <tr key={historico.id}>
                        <td>{historico.status}</td>
                        <td>
                          <FormattedDate
                            value={historico.data}
                            timeZone={'UTC'}
                          />
                        </td>
                        {solicitacaoCancelamento.nomeArquivo && (
                          <td>{solicitacaoCancelamento.nomeArquivo}</td>
                        )}
                        <td>
                          {historico.motivo && historico.motivo.length > 50
                            ? `Clique em ' Ver mais ' para mais informações`
                            : historico.motivo}
                        </td>
                        <td>
                          <div className="btn-actions">
                            <div className="btn-actions-inner">
                              {solicitacaoCancelamento.nomeArquivo && (
                                <ActionButton
                                  data-test-id="buttonDowload"
                                  key="download-button"
                                  icon="download"
                                  label="Baixar anexo"
                                  onClick={generateUrlAnexo}
                                />
                              )}

                              <ActionButton
                                data-test-id="buttonView"
                                key="eye-button"
                                icon="eye"
                                label="Ver mais"
                                onClick={() =>
                                  visualizarDetalheHistorico(historico)
                                }
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <DetailQuickView
          data={detalheHistorico}
          onClose={fecharTelaDetalheHistorico}
          opened={abrirTelaDetalheHistorico}
        />
      </div>
      <SectionTitle marginTop="0">Motivo da Aprovação ou Rejeição</SectionTitle>
      <Row className="mb-xs">
        <Col md={12}>
          <textarea
            className={error.motivo ? 'error' : ''}
            type="text"
            name="motivo"
            cols="30"
            rows="10"
            value={motivoCancelamentoNota.motivo}
            onChange={onChangeInputValue}
          />
          {error.motivo && <ErrorText>{errorMessages.motivo}</ErrorText>}
        </Col>
      </Row>
      <Row>
        <Col className="right">
          <button
            data-test-id="btnAprovar"
            className="btn inline positive"
            onClick={aprovar}
            disabled={
              solicitacaoCancelamento.ultimoStatus !== STATUS_AGUARDANDO_FISCAL
            }
          >
            Aprovar
          </button>
          <button
            data-test-id="btnRejeitar"
            className="btn inline outlined negative"
            onClick={rejeitar}
            disabled={
              solicitacaoCancelamento.ultimoStatus !== STATUS_AGUARDANDO_FISCAL
            }
          >
            Rejeitar
          </button>
        </Col>
      </Row>
    </>
  );
};
AnaliseCancelamento.propTypes = {
  solicitacaoCancelamento: PropTypes.object.isRequired,
  motivoCancelamentoNota: PropTypes.object.isRequired,
  abrirTelaDetalheHistorico: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
  aprovar: PropTypes.func.isRequired,
  rejeitar: PropTypes.func.isRequired,
  detalheHistorico: PropTypes.object.isRequired,
  visualizarNotaFiscal: PropTypes.func.isRequired,
  fecharTelaDetalheHistorico: PropTypes.func.isRequired,
  onChangeInputValue: PropTypes.func.isRequired
};

export default AnaliseCancelamento;
