import {
  ActionButton,
  ActionsGroup,
  Chip,
  Field,
  FormattedCurrency,
  Loading,
  Panel,
  SearchFilter,
  SearchPagination,
  SectionTitle,
  Table,
  formatUtils
} from '@elotech/components';
import { Alert } from 'iss-common/utils';
import React from 'react';

import { ConcursoService } from '../../../service';
import { Concurso } from '../../../types/Concurso';
import { ConcursoCupomParticipante } from '../../../types/ConcursoCupomParticipante';
import ConcursoCupomPremiadoForm from './ConcursoCupomPremiadoForm';
import StatusConcurso from './StatusConcurso';

type Props = {
  concurso: Concurso;
};

type State = {
  loading: boolean;
  cupons: ConcursoCupomParticipante[];
  searchParams: string;
  pagination?: any;
  testingIndex: any;
  expandedValueIndex?: number;
  loadingInnerComponent: boolean;
};

const getFields: Field[] = [
  {
    label: 'Nome',
    name: 'participante.nome',
    type: 'STRING'
  },
  {
    label: 'Cpf/Cnpj',
    name: 'participante.cnpjCpf',
    type: 'STRING'
  },
  {
    label: 'Número',
    name: 'numero',
    type: 'NUMBER'
  },
  {
    label: 'Premiado',
    name: 'premiado',
    type: 'BOOLEAN'
  }
];

class ConcursoCupomList extends React.Component<Props, State> {
  state: State = {
    loading: false,
    cupons: [],
    searchParams: '',
    pagination: undefined,
    testingIndex: undefined,
    expandedValueIndex: undefined,
    loadingInnerComponent: false
  };

  searchWithPage = (page?: any) => {
    this.setState({ pagination: page });
    this.onSearch(this.state.searchParams, page);
  };

  onSearch = (searchParams: string = this.state.searchParams, page?: any) => {
    const idConcurso = this.props.concurso.id;
    if (idConcurso !== undefined) {
      this.setState({ loading: true, searchParams }, () => {
        ConcursoService.getCuponsByConcurso(idConcurso, searchParams, page)
          .then(response => {
            const { content, ...pagination } = response.data;
            this.setState({ cupons: content, pagination });
          })
          .catch(error => {
            Alert.error(
              { title: 'Erro ao carregar os cupons do concurso' },
              error
            );
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      });
    }
  };

  paginationSearch = (page: any) => this.onSearch(undefined, page);

  onViewSorteado = (index: number) => {
    const { expandedValueIndex } = this.state;
    if (expandedValueIndex === index) {
      this.setState({
        expandedValueIndex: undefined
      });
    } else {
      this.setState({
        expandedValueIndex: index
      });
    }
  };

  salvarCupom = (cupom: ConcursoCupomParticipante, index: number) => {
    this.setState({ loading: true });
    const idConcurso = this.props.concurso.id;
    ConcursoService.cupomPremiado(
      idConcurso,
      cupom.id,
      cupom.premiado,
      cupom.observacao
    )
      .then(() => {
        this.setState(state => {
          const { cupons } = state;
          const cuponsAlterados = cupons
            .slice(0, index)
            .concat([cupom])
            .concat(cupons.slice(index + 1));
          return {
            cupons: cuponsAlterados,
            loading: false,
            expandedValueIndex: undefined
          };
        });
      })
      .catch(error => {
        this.setState({ loading: false });
        Alert.error({ title: 'Não foi possível salvar o cupom' }, error);
      });
  };

  renderFormSorteado = (cupom: ConcursoCupomParticipante, index: number) => {
    const { expandedValueIndex } = this.state;

    if (expandedValueIndex !== index) {
      return <React.Fragment />;
    }

    return (
      <ConcursoCupomPremiadoForm
        cupom={cupom}
        onSave={values => this.salvarCupom(values, index)}
      />
    );
  };

  render() {
    const { loading, cupons, pagination } = this.state;
    const { status } = this.props.concurso;
    return (
      <div>
        <SectionTitle>Cupons do concurso</SectionTitle>
        <Loading loading={loading} />
        <Panel isTable>
          <SearchFilter fields={getFields} search={this.onSearch} />
          <Table
            keyExtractor={(linha: ConcursoCupomParticipante) => linha.id}
            values={cupons}
            renderInnerComponent={this.renderFormSorteado}
          >
            <Table.Column
              header="Cpf/Cnpj"
              value={(cupom: ConcursoCupomParticipante) =>
                formatUtils.formatCpfCnpj(cupom.participante.cnpjCpf)
              }
            />
            <Table.Column
              header="Nome"
              value={(cupom: ConcursoCupomParticipante) =>
                cupom.participante.nome
              }
            />
            <Table.Column
              header="Telefone"
              value={(cupom: ConcursoCupomParticipante) =>
                formatUtils.formatCelular(cupom.participante.telefone)
              }
            />
            <Table.Column
              header="Valor Total das Notas"
              value={(cupom: ConcursoCupomParticipante) => (
                <FormattedCurrency
                  value={cupom.participante.valorTotalNotaFiscal}
                />
              )}
            />
            <Table.Column
              header="Número do Cupom"
              value={(cupom: ConcursoCupomParticipante) => cupom.numero}
            />
            {status === StatusConcurso.PUBLICADO ||
            status === StatusConcurso.ENCERRADO ? (
              <Table.Column
                header="Premiado"
                value={(cupom: ConcursoCupomParticipante) =>
                  cupom.premiado ? (
                    <Chip value={'Sim'} color="positive" outlined={false} />
                  ) : (
                    <Chip value={'Não'} color="neutral" />
                  )
                }
              />
            ) : (
              undefined
            )}
            {status === StatusConcurso.PUBLICADO ? (
              <Table.Column
                header=""
                value={(item: ConcursoCupomParticipante, index: number) => (
                  <ActionsGroup>
                    <ActionButton
                      data-test-id="select-sorteado"
                      key="select-sorteado"
                      icon="pencil-alt"
                      label="Editar"
                      onClick={() => this.onViewSorteado(index)}
                    />
                  </ActionsGroup>
                )}
              />
            ) : (
              undefined
            )}
          </Table>
          {pagination && (
            <SearchPagination
              page={pagination}
              searchWithPage={this.paginationSearch}
            />
          )}
        </Panel>
      </div>
    );
  }
}

export default ConcursoCupomList;
