import {
  ActionButton,
  ActionsGroup,
  Container,
  DisplayDataGrid,
  DisplayDataItem,
  FormattedDateTime,
  Icon,
  Loading,
  Panel,
  Row,
  Table,
  formatUtils
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { Alert } from 'iss-common/utils';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { FormGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { match } from 'react-router';

import { BloqueioService } from '../../../service';
import { Bloqueio, BloqueioCredenciamento } from '../../../types/Bloqueio';
import { CadastroGeral } from '../../../types/CadastroGeral';
import { Credenciamento } from '../../../types/Credenciamento';
import { BloqueioOperacoesFormBloqueio } from './BloqueioOperacoesFormBloqueio';
import {
  emptyBloqueio,
  getLabel,
  inflateBloqueios,
  tiposBloqueio
} from './BloqueioOperacoesTipos';

type Props = {
  match: match<{ id: string }>;
  usuario: { username: string };
};

const BloqueioOperacoesFormPage: React.FC<Props> = ({ match, usuario }) => {
  const [credenciamento, setCredenciamento] = useState<
    Credenciamento | undefined
  >(undefined);
  const [formsBloqueio, setFormBloqueio] = useState<number[]>([]);
  const [bloqueios, setBloqueios] = useState<Bloqueio[]>([emptyBloqueio]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const loadBloqueios = (idCredenciamento: number) => {
    if (idCredenciamento) {
      setLoading(false);
      setError(false);

      return BloqueioService.loadBloqueiosPorCredenciamento(
        idCredenciamento,
        tiposBloqueio
      )
        .then((response: AxiosResponse<BloqueioCredenciamento>) => {
          const { credenciamento, bloqueios } = response.data;
          setBloqueios(inflateBloqueios(bloqueios));
          setCredenciamento(credenciamento);
        })
        .catch(error => {
          Alert.error(
            { title: 'Não foi possível carregar os bloqueios' },
            error
          );
          setError(true);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    loadBloqueios(+match.params.id);
  }, [match.params.id]);

  const bloquear = (bloqueio: Bloqueio) =>
    post(
      {
        ...bloqueio,
        bloqueado: true,
        fiscal: usuario.username
      },
      'o bloqueio'
    );

  const desbloquear = (bloqueio: Bloqueio) =>
    post(
      {
        ...bloqueio,
        bloqueado: false,
        fiscal: usuario.username,
        observacao: ''
      },
      'a liberação'
    );

  const post = (bloqueio: Bloqueio, operacao: string) =>
    Alert.question({
      title: `Confirma ${operacao} da operação "${getLabel(bloqueio.tipo)}"?`
    })
      .then((result: any) => {
        if (result.value) {
          setLoading(true);

          return BloqueioService.post(
            getCadastroGeral(cadastroGeral => cadastroGeral.id),
            bloqueio
          )
            .then(() => loadBloqueios(+match.params.id))
            .catch(error =>
              Alert.error({ title: 'Erro ao realizar a operação', error })
            );
        }
      })
      .finally(() => setLoading(false))
      .catch((e: any) => console.log({ e }));

  const mostraFormBloqueio = (index: number) =>
    setFormBloqueio([...formsBloqueio, index]);

  const ocultaFormDesbloqueio = (index: number) =>
    setFormBloqueio(formsBloqueio.filter(item => item !== index));

  const submitBloqueio = (bloqueio: Bloqueio, index: number) =>
    bloquear(bloqueio).then(() => ocultaFormDesbloqueio(index));

  const cancelaDesbloqueio = (index: number) => {
    ocultaFormDesbloqueio(index);
  };

  const getCadastroGeral = (
    func?: (cadastroGeral: CadastroGeral) => any
  ): CadastroGeral | any => {
    const cadastroGeral =
      credenciamento &&
      credenciamento.agendamento &&
      credenciamento.agendamento.cadastroGeral;

    return func
      ? cadastroGeral
        ? func(cadastroGeral)
        : undefined
      : cadastroGeral;
  };

  const getOperacaoUtilizada = (tipo: string) =>
    credenciamento && credenciamento[tipo] === 'S';

  const innerContent = () => (
    <>
      <Loading loading={loading} />

      <DisplayDataGrid className="mb-xs">
        <Row>
          <DisplayDataItem md={2} title="Inscrição Municipal">
            {credenciamento?.agendamento?.cadastroGeral?.cadastroGeral}
          </DisplayDataItem>
          <DisplayDataItem md={5} title="Nome/Razão Social">
            {credenciamento?.agendamento?.cadastroGeral?.pessoa?.nome}
          </DisplayDataItem>
          <DisplayDataItem md={5} title="CPF/CNPJ">
            {formatUtils.formatCpfCnpj(
              credenciamento?.agendamento?.cadastroGeral?.pessoa?.cnpjCpf
            )}
          </DisplayDataItem>
        </Row>
      </DisplayDataGrid>
      <Panel isTable>
        <Table<Bloqueio>
          values={bloqueios}
          keyExtractor={item => `${item.tipo}`}
        >
          <Table.Column<Bloqueio>
            header="Operação"
            value={bloqueio => getLabel(bloqueio.tipo)}
            style={{ width: '20em' }}
          />
          <Table.Column<Bloqueio>
            header="Situação"
            value={(bloqueio, index) => {
              const showingForm = formsBloqueio.includes(index);
              const utilizado = getOperacaoUtilizada(bloqueio.tipo!);

              return (
                <>
                  {!showingForm &&
                    (utilizado ? (
                      <>
                        <FormGroup>
                          {bloqueio.bloqueado ? (
                            <>
                              <Icon icon="thumbs-down" danger /> Bloqueado em{' '}
                              <FormattedDateTime value={bloqueio.data} />
                            </>
                          ) : (
                            <>
                              <Icon icon="thumbs-up" positive /> Liberado
                            </>
                          )}
                        </FormGroup>
                      </>
                    ) : (
                      <>
                        <Icon icon="times" color="gray" />
                        <span style={{ color: 'gray' }}> Não utilizado</span>
                      </>
                    ))}

                  <FormGroup>
                    {showingForm && (
                      <BloqueioOperacoesFormBloqueio
                        bloqueio={bloqueio}
                        onSubmit={bloqueio => submitBloqueio(bloqueio, index)}
                        onCancel={() => cancelaDesbloqueio(index)}
                      />
                    )}
                  </FormGroup>
                </>
              );
            }}
          />
          <Table.Column<Bloqueio>
            header="Situação / Observações"
            value={(bloqueio, index) => bloqueio.observacao}
          />
          <Table.Column<Bloqueio>
            header=""
            value={(bloqueio, index) =>
              getOperacaoUtilizada(bloqueio.tipo!) && (
                <ActionsGroup>
                  {bloqueio.bloqueado ? (
                    formsBloqueio.includes(index) || (
                      <ActionButton
                        data-test-id={`unlock-btn-${bloqueio.tipo}`}
                        key="edit-button"
                        icon="unlock"
                        label="Desbloquear"
                        onClick={() => desbloquear(bloqueio)}
                      />
                    )
                  ) : (
                    <ActionButton
                      data-test-id={`lock-btn-${bloqueio.tipo}`}
                      key="edit-button"
                      icon="lock"
                      label="Bloquear"
                      onClick={() => mostraFormBloqueio(index)}
                    />
                  )}
                </ActionsGroup>
              )
            }
          />
        </Table>
      </Panel>
    </>
  );

  return (
    <Container breadcrumb>
      {!error && innerContent()}
      {!credenciamento && <div>Credenciemento não encontrado</div>}
    </Container>
  );
};

const connectedComponent = connect(
  (state: any) => ({ usuario: state.user.profile }),
  undefined
)(BloqueioOperacoesFormPage);

export { connectedComponent as default, BloqueioOperacoesFormPage };
