import {
  AutoComplete,
  Checkbox,
  Col,
  ErrorText,
  InputDate,
  Row,
  SectionTitle
} from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const errorMessages = {
  descricao: 'Este campo é obrigatório!',
  lei: 'Este campo é obrigatório!',
  cadastroGeral: 'Este campo é obrigatório!',
  data: 'Este campo é obrigatório!',
  tipoIncentivo: 'Este campo é obrigatório!',
  percentualDesconto: 'Campo obrigatório!',
  percentualDeducao: 'Campo obrigatório!',
  percentualDescontoMaxValue: 'Desconto acima de 100%',
  percentualDeducaoMinValue: 'Valor de dedução inválido!',
  percentualDeducaoMaxValue: 'Desconto acima de 100%'
};

const DadosGeraisForm = ({
  incentivoFiscal,
  error,
  loadLeis,
  loadCadastrosMobiliarios,
  onChangeInputValue,
  onSelect,
  optionLabel,
  onChangePercentualDescontoTodosServicos,
  onChangePercentualDeducao,
  onChangeDescontoServicosEspecificos,
  handleBlur
}) => {
  return (
    <Fragment>
      <SectionTitle marginTop="0">Dados Gerais</SectionTitle>
      <form className="form">
        <Row>
          <Col sm={5} md={4}>
            <div className="form-group">
              <label htmlFor="descricao">Descrição</label>
              <input
                className={error.descricao ? 'error' : ''}
                type="text"
                name="descricao"
                value={incentivoFiscal.descricao}
                onChange={onChangeInputValue}
              />
              {error.descricao && (
                <ErrorText>{errorMessages.descricao}</ErrorText>
              )}
            </div>
          </Col>
          <Col sm={5} md={8}>
            <div
              className="form-group"
              title="Cpnj - Razão Social - Tipo Cadastro - Inscrição Municipal"
            >
              <label htmlFor="cadastroGeral">Empresa</label>
              <AutoComplete
                data-test-id="autocomplete-cadastroGeral"
                name="cadastroGeral"
                className={error.cadastroGeral ? 'error' : ''}
                placeholder="Pesquisar por cnpj ou nome da empresa"
                value={incentivoFiscal.cadastroGeral}
                onSearch={loadCadastrosMobiliarios}
                onSelect={onSelect}
                getOptionLabel={optionLabel}
              />
              <i aria-hidden="true" className="fa fa-search input-icon" />
              {error.cadastroGeral && (
                <ErrorText>{errorMessages.cadastroGeral}</ErrorText>
              )}
            </div>
          </Col>
          <Col sm={5} md={12}>
            <div className="form-group">
              <label htmlFor="lei">Lei</label>
              <AutoComplete
                data-test-id="autocomplete-lei"
                name="lei"
                className={error.lei ? 'error' : ''}
                value={incentivoFiscal.lei}
                onSearch={loadLeis}
                onSelect={onSelect}
              />
              <i aria-hidden="true" className="fa fa-search input-icon" />
              {error.lei && <ErrorText>{errorMessages.lei}</ErrorText>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <div className="form-group">
              <label htmlFor="data">Data</label>
              <InputDate
                name="dataInicial"
                value={incentivoFiscal.dataInicial}
                onChange={onChangeInputValue}
                className={error.dataInicial ? 'error' : ''}
              />
              <i aria-hidden="true" className="fa fa-calendar-alt input-icon" />
              {error.dataInicial && <ErrorText>{errorMessages.data}</ErrorText>}
            </div>
          </Col>
          <Col md={2}>
            <div className="form-group">
              <label htmlFor="dataFinal">Data Final</label>
              <InputDate
                name="dataFinal"
                value={incentivoFiscal.dataFinal}
                onChange={onChangeInputValue}
              />
              <i aria-hidden="true" className="fa fa-calendar-alt input-icon" />
            </div>
          </Col>
          <Col md={incentivoFiscal.tipoIncentivo === 'DE' ? 3 : 4}>
            <div className="form-group">
              <label htmlFor="tipoIncentivo">Tipo de Incentivo</label>
              <select
                name="tipoIncentivo"
                className={error.tipoIncentivo ? 'error' : ''}
                value={incentivoFiscal.tipoIncentivo}
                onChange={onChangeInputValue}
              >
                <option value="IS">Isenção</option>
                <option value="IM">Imunidade</option>
                <option value="RE">Redução</option>
                <option value="DE">Dedução</option>
              </select>
              {error.tipoIncentivo && (
                <ErrorText>{errorMessages.tipoIncentivo}</ErrorText>
              )}
            </div>
          </Col>
          {incentivoFiscal.tipoIncentivo === 'DE' && (
            <Col md={1}>
              <div className="form-group">
                <label htmlFor="percentualDeducao">Dedução</label>
                <input
                  className={error.percentualDeducao ? 'error' : ''}
                  name="percentualDeducao"
                  type="number"
                  placeholder="%"
                  value={incentivoFiscal.percentualDeducao || ''}
                  onChange={onChangePercentualDeducao}
                  onBlur={handleBlur}
                />
                {error.percentualDeducao && (
                  <ErrorText>{errorMessages.percentualDeducao}</ErrorText>
                )}
                {error.percentualDeducaoMaxValue && (
                  <ErrorText>
                    {errorMessages.percentualDeducaoMaxValue}
                  </ErrorText>
                )}
                {error.percentualDeducaoMinValue && (
                  <ErrorText>
                    {errorMessages.percentualDeducaoMinValue}
                  </ErrorText>
                )}
              </div>
            </Col>
          )}
          <Col md={4}>
            <label className="label" />
            <Checkbox
              className="form-group"
              name="imprimeIncentivoNota"
              id="imprimeIncentivoNota"
              checked={incentivoFiscal.imprimeIncentivoNota}
              onChange={onChangeInputValue}
              label=" Permitir imprimir incentivo na observação da nota"
              disabled={false}
            />
          </Col>
        </Row>
        <Row>
          {incentivoFiscal.descServicosEspecificos ||
          incentivoFiscal.tipoIncentivo === 'IS' ||
          incentivoFiscal.tipoIncentivo === 'IM' ||
          incentivoFiscal.tipoIncentivo === 'DE' ? (
            ''
          ) : (
            <Col md={2}>
              <div className="form-group">
                <label htmlFor="percentualDesconto"> Percentual Desconto</label>
                <input
                  className={error.percentualDesconto ? 'error' : ''}
                  name="percentualDesconto"
                  type="number"
                  placeholder="%"
                  value={incentivoFiscal.percentualDesconto || ''}
                  onChange={onChangePercentualDescontoTodosServicos}
                />
                {error.percentualDesconto && (
                  <ErrorText>{errorMessages.percentualDesconto}</ErrorText>
                )}
                {error.percentualDescontoMaxValue && (
                  <ErrorText>
                    {errorMessages.percentualDescontoMaxValue}
                  </ErrorText>
                )}
              </div>
            </Col>
          )}

          <Col md={3}>
            <div className="form-group">
              <label htmlFor="tipoIncentivoContribuinte">
                Tipo Incentivo Contribuinte
              </label>
              <select
                name="tipoIncentivoContribuinte"
                className=""
                onChange={onChangeInputValue}
                value={incentivoFiscal.tipoIncentivoContribuinte}
              >
                <option value="P">Prestador</option>
                <option value="T">Tomador</option>
              </select>
            </div>
          </Col>
          <Col md={3}>
            <div className="form-group">
              <label htmlFor="tipoIncidencia">Tipo de Incidência</label>
              <select
                name="tipoIncidencia"
                className=""
                onChange={onChangeInputValue}
                value={incentivoFiscal.tipoIncidencia}
              >
                <option value="TM">
                  Incentivo válido em todos os municípios
                </option>
                <option value="SM">
                  Incentivo válido somente no município
                </option>
              </select>
            </div>
          </Col>
          <Col md={4}>
            <label className="label" />
            <Checkbox
              className={`form-group ${error.descTodosServicos ? 'error' : ''}`}
              checked={incentivoFiscal.descServicosEspecificos}
              name="descServicosEspecificos"
              id="descServicosEspecificos"
              onChange={onChangeDescontoServicosEspecificos}
              label="Descontos para serviços específicos"
              disabled={false}
            />
          </Col>
        </Row>
      </form>
    </Fragment>
  );
};

DadosGeraisForm.propTypes = {
  incentivoFiscal: PropTypes.object.isRequired,
  error: PropTypes.object,
  loadLeis: PropTypes.func.isRequired,
  loadCadastrosMobiliarios: PropTypes.func.isRequired,
  onChangeInputValue: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  optionLabel: PropTypes.func.isRequired,
  onChangePercentualDescontoTodosServicos: PropTypes.func,
  onChangeDescontoServicosEspecificos: PropTypes.func,
  onBlur: PropTypes.func
};

export default DadosGeraisForm;
