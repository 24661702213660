import {
  ActionButton,
  FormattedDate,
  SearchPagination,
  formatUtils
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const CancelamentoNfseList = ({
  historicoSolicitacoesCancelamentoNota,
  searchWithPage,
  analisarSolicitacaoCancelamento
}) => {
  return (
    <div>
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <thead>
            <tr>
              <th>Solicitante</th>
              <th>CNPJ/CPF</th>
              <th>Nº Nota Fiscal</th>
              <th>Data</th>
              <th>Último Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {historicoSolicitacoesCancelamentoNota &&
              historicoSolicitacoesCancelamentoNota.content &&
              historicoSolicitacoesCancelamentoNota.content.map(historico => {
                return (
                  <tr key={historico.id}>
                    <td>{historico.nomeSolicitante}</td>
                    <td>
                      {formatUtils.formatCpfCnpj(historico.cnpjSolicitante)}
                    </td>
                    <td>{historico.numeroNota}</td>
                    <td>
                      <FormattedDate value={historico.data} timeZone={'UTC'} />
                    </td>
                    <td>{historico.status}</td>
                    <td>
                      <div className="btn-actions">
                        <div className="btn-actions-inner">
                          <ActionButton
                            data-test-id={`buttonAnalisarSolicitacao${historico.id}`}
                            key="analyze-button"
                            icon="search"
                            label="Analisar"
                            onClick={() =>
                              analisarSolicitacaoCancelamento(
                                historico.idSolicitacaoCancelamento
                              )
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            {(!historicoSolicitacoesCancelamentoNota ||
              historicoSolicitacoesCancelamentoNota.totalElements === 0) && (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan="7">
                  Nenhum registro encontrado.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {historicoSolicitacoesCancelamentoNota && (
        <SearchPagination
          page={historicoSolicitacoesCancelamentoNota}
          searchWithPage={searchWithPage}
        />
      )}
    </div>
  );
};

CancelamentoNfseList.propTypes = {
  historicoSolicitacoesCancelamentoNota: PropTypes.object.isRequired,
  searchWithPage: PropTypes.func.isRequired
};

export default CancelamentoNfseList;
