import { Container, Loading, NotificationActions } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { AcessoWebService, withService } from '../../../service';
import ChangePasswordForm from './ChangePasswordForm';

export class ChangePasswordFormPage extends Component {
  static propTypes = {
    acessoWebService: PropTypes.object.isRequired
  };

  validators = {
    id: value => value,
    nome: value => value,
    cpf: value => value,
    novaSenha: value => value,
    confirmarSenha: value => value
  };

  state = {
    passwordData: {
      id: '',
      nome: '',
      cpf: '',
      novaSenha: '',
      confirmarSenha: ''
    },
    error: {
      id: false,
      nome: false,
      cpf: false,
      novaSenha: false,
      confirmarSenha: false,
      senhasIguais: false
    },
    validacaoAdicional: {
      senhasIguais: false
    },
    loading: false,
    inputConfirmaSenhaJaInformado: false
  };

  loadAcessoWebSuccess = response => {
    this.setState({
      passwordData: response.data,
      loading: false
    });
  };

  loadAcessoWebError = error => {
    this.setState({ loading: false });

    this.props.showNotification({
      level: 'error',
      message: 'Não foi possível carregar o acesso web.'
    });
  };

  componentDidMount() {
    const { id } = this.props.match.params;

    if (id) {
      this.setState({ loading: true });
      this.props.acessoWebService
        .loadAcessoWebById(id)
        .then(this.loadAcessoWebSuccess)
        .catch(this.loadAcessoWebError);
    }
  }

  errosFormulario() {
    const { passwordData } = this.state;

    let errors = Object.keys(passwordData).filter(field => {
      return (
        this.validators[field] && !this.validators[field](passwordData[field])
      );
    });

    const objErro = errors.reduce((total, current) => {
      total[current] = true;
      return total;
    }, {});
    this.setState({ error: objErro });

    return errors;
  }

  errosAdicionais() {
    const { validacaoAdicional } = this.state;

    let validacao = [];
    if (validacaoAdicional.senhasIguais) {
      validacao.push('senhasIguais');
    }

    const objErroAdicional = validacao.reduce((total, current) => {
      total[current] = true;
      return total;
    }, {});
    this.setState({ validacaoAdicional: objErroAdicional });

    return validacao;
  }

  hasErrors = () => {
    let errors = this.errosFormulario();
    let validacao = this.errosAdicionais();

    return errors.length > 0 || validacao.length > 0;
  };

  onTrocarSenhaSuccess = success => {
    this.props.showNotification({
      level: 'success',
      message: 'Dados salvo com sucesso'
    });
    this.props.history.replace('/acessos-e-permissoes/usuarios-issqn');
  };

  onTrocarSenhaError = error => {
    this.props.showNotification({
      level: 'error',
      message: 'Não foi possível trocar a senha do acesso web.'
    });
  };

  onSave = () => {
    if (this.hasErrors()) {
      this.props.showNotification({
        level: 'error',
        message: 'Campos obrigatórios ou inválidos'
      });
    } else {
      this.props.acessoWebService
        .trocarSenha(this.state.passwordData)
        .then(this.onTrocarSenhaSuccess)
        .catch(this.onTrocarSenhaError);
    }
  };

  verificarSenhasIguais = nameInput => {
    if (nameInput === 'confirmarSenha') {
      this.setState({
        inputConfirmaSenhaJaInformado: true
      });
    }

    if (nameInput === 'novaSenha' || nameInput === 'confirmarSenha') {
      let senhasIguais = !(
        this.state.passwordData.novaSenha ===
        this.state.passwordData.confirmarSenha
      );
      this.setState({
        validacaoAdicional: {
          ...this.state.validacaoAdicional,
          senhasIguais: senhasIguais
        }
      });
    }
  };

  onChangeInputValue = event => {
    const { name, value } = event.target;

    this.setState(
      state => {
        const { passwordData, error } = state;
        return {
          passwordData: { ...passwordData, [name]: value },
          error: { ...error, [name]: !this.validators[name](value) }
        };
      },
      () => {
        this.verificarSenhasIguais(name);
      }
    );
  };

  onBack = () => {
    this.props.history.goBack();
  };

  render() {
    const {
      passwordData,
      error,
      loading,
      inputConfirmaSenhaJaInformado,
      validacaoAdicional
    } = this.state;
    return (
      <Container breadcrumb>
        <Loading loading={loading} />
        <ChangePasswordForm
          onSave={this.onSave}
          onBack={this.onBack}
          passwordData={passwordData}
          onChangeInputValue={this.onChangeInputValue}
          error={error}
          validacaoAdicional={validacaoAdicional}
          inputConfirmaSenhaJaInformado={inputConfirmaSenhaJaInformado}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

ChangePasswordFormPage.propTypes = {
  passwordData: PropTypes.object,
  error: PropTypes.object
};

const ComponentWithService = withService({
  acessoWebService: AcessoWebService
})(ChangePasswordFormPage);

export default connect(null, mapDispatchToProps)(ComponentWithService);
