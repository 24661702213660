import {
  Alert,
  Container,
  Loading,
  NotificationActions
} from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert2';

import { CancelamentoDmsService, withService } from '../../../service';
import AnaliseCancelamentoDMS from './AnaliseCancelamentoDMSForm';

export class AnaliseCancelamentoDMSFormPage extends Component {
  static propTypes = {
    cancelamentoDmsService: PropTypes.object.isRequired
  };

  state = {
    loading: false,
    solicitacaoCancelamento: {
      motivoAprovacaoRejeicao: '',
      usuario: {}
    },
    abrirTelaDetalheHistorico: false,
    detalheHistorico: {},
    error: {
      motivoAprovacaoRejeicao: false
    }
  };

  validators = {
    motivoAprovacaoRejeicao: value => value
  };

  componentDidMount() {
    const { id } = this.props.match.params;

    if (id) {
      this.setState({ loading: true });
      this.props.cancelamentoDmsService
        .loadSolicitacaoCancelamentoById(id)
        .then(this.loadSolicitacaoCancelamentoSuccess)
        .catch(this.loadSolicitacaoCancelamentoError);
    }
  }

  loadSolicitacaoCancelamentoSuccess = response => {
    this.setState({ loading: false });
    this.setState(
      {
        solicitacaoCancelamento: response.data
      },
      () => {
        this.setState(state => {
          const { solicitacaoCancelamento } = state;
          return {
            solicitacaoCancelamento: {
              ...solicitacaoCancelamento,
              usuario: this.props.usuario
            }
          };
        });
      }
    );
  };

  loadSolicitacaoCancelamentoError = error => {
    this.setState({ loading: false });
    Alert.error(
      { title: 'Não foi possível carregar a solicitação de cancelamento.' },
      error
    );
  };

  onChangeInputValue = event => {
    const { name, value } = event.target;

    this.setState(state => {
      const { solicitacaoCancelamento, error } = state;
      return {
        solicitacaoCancelamento: { ...solicitacaoCancelamento, [name]: value },
        error: { ...error, [name]: !this.validators[name](value) }
      };
    });
  };

  hasErrors = () => {
    const { solicitacaoCancelamento } = this.state;

    const errors = Object.keys(solicitacaoCancelamento).filter(field => {
      return (
        this.validators[field] &&
        !this.validators[field](solicitacaoCancelamento[field])
      );
    });

    const objErro = errors.reduce((total, current) => {
      total[current] = true;
      return total;
    }, {});

    this.setState({ error: objErro });

    return errors.length > 0;
  };

  aprovarSolicitacaoCancelamentoDms = () => {
    if (this.hasErrors()) {
      this.props.showNotification({
        level: 'error',
        message: 'Campos Obrigatórios'
      });
    } else {
      swal({
        title: 'Deseja aprovar o cancelamento da Declaração Mensal Serviço?',
        type: 'question',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
      }).then(result => {
        if (result.value) {
          this.setState({ loading: true });
          const { solicitacaoCancelamento } = this.state;
          this.props.cancelamentoDmsService
            .aprovarSolicitacaoCancelamento(solicitacaoCancelamento)
            .then(this.solicitacaoAprovadaSuccess)
            .catch(this.solicitacaoAprovadaError);
        }
      });
    }
  };

  solicitacaoAprovadaSuccess = response => {
    this.setState({ loading: false });
    this.props.showNotification({
      level: 'success',
      message:
        'Cancelamento da Declaração Mensal dos Serviços aprovada com sucesso.'
    });

    Alert.question({
      title:
        'Deseja enviar email de notificação de cancelamento de DMS para o contribuinte?'
    }).then(result => {
      if (result.value) {
        this.setState({ loading: true });
        this.props.cancelamentoDmsService
          .enviarEmail(response.data.id)
          .then(this.enviarEmailSuccess)
          .catch(this.enviarEmailError);
      }
    }, this.props.history.push('/acessos-e-permissoes/cancelamento-dms'));
  };

  solicitacaoAprovadaError = error => {
    this.setState({ loading: false });
    Alert.error(
      { title: 'Não foi possível aprovar a solicitação de cancelamento.' },
      error
    );
  };

  rejeitarSolicitacaoCancelamentoDms = () => {
    if (this.hasErrors()) {
      this.props.showNotification({
        level: 'error',
        message: 'Campos Obrigatórios'
      });
    } else {
      swal({
        title: 'Deseja rejeitar o cancelamento da Declaração Mensal Serviço?',
        type: 'question',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
      }).then(result => {
        if (result.value) {
          this.setState({ loading: true });
          const { solicitacaoCancelamento } = this.state;
          this.props.cancelamentoDmsService
            .rejeitarSolicitacaoCancelamento(solicitacaoCancelamento)
            .then(this.solicitacaoRejeitadaSuccess)
            .catch(this.solicitacaoRejeitadaError);
        }
      });
    }
  };

  solicitacaoRejeitadaSuccess = response => {
    this.setState({ loading: false });
    this.props.showNotification({
      level: 'success',
      message: 'Cancelamento da Declaração Mensal dos Serviços rejeitada.'
    });

    Alert.question({
      title:
        'Deseja enviar email de notificação de cancelamento de DMS para o contribuinte?'
    }).then(result => {
      if (result.value) {
        this.setState({ loading: true });
        this.props.cancelamentoDmsService
          .enviarEmail(response.data.id)
          .then(this.enviarEmailSuccess)
          .catch(this.enviarEmailError);
      }
    }, this.props.history.push('/acessos-e-permissoes/cancelamento-dms'));
  };

  solicitacaoRejeitadaError = error => {
    this.setState({ loading: false });
    Alert.error(
      { title: 'Não foi possível rejeitar a solicitação de cancelamento.' },
      error
    );
  };

  enviarEmailSuccess = () => {
    this.setState({ loading: false });
    this.props.showNotification({
      level: 'success',
      message: 'Email enviado com sucesso.'
    });
    this.props.history.push('/acessos-e-permissoes/cancelamento-dms');
  };

  enviarEmailError = error => {
    this.setState({ loading: false });
    Alert.error(
      { title: 'Erro ao enviar o email para o contribuinte.' },
      error
    );

    this.props.history.push('/acessos-e-permissoes/cancelamento-dms');
  };

  openQuickViewDetail = data => {
    this.setState({
      abrirTelaDetalheHistorico: true,
      detalheHistorico: data
    });
  };

  closeQuickView = () => {
    this.setState({
      abrirTelaDetalheHistorico: false,
      detalheHistorico: {}
    });
  };

  render() {
    const {
      solicitacaoCancelamento,
      error,
      loading,
      abrirTelaDetalheHistorico,
      detalheHistorico
    } = this.state;
    return (
      <Container breadcrumb>
        <Loading loading={loading} />
        <AnaliseCancelamentoDMS
          solicitacaoCancelamento={solicitacaoCancelamento}
          error={error}
          onChangeInputValue={this.onChangeInputValue}
          aprovar={this.aprovarSolicitacaoCancelamentoDms}
          rejeitar={this.rejeitarSolicitacaoCancelamentoDms}
          exit={this.exit}
          abrirTelaDetalheHistorico={abrirTelaDetalheHistorico}
          detalheHistorico={detalheHistorico}
          visualizarDetalheHistorico={data => this.openQuickViewDetail(data)}
          fecharTelaDetalheHistorico={this.closeQuickView}
        />
      </Container>
    );
  }
}

const ComponentWithService = withService({
  cancelamentoDmsService: CancelamentoDmsService
})(AnaliseCancelamentoDMSFormPage);

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const mapStateToProps = state => ({
  usuario: state.user.profile
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithService);
