import { EnumValue } from '../types/EnumValue';

export const tipoMulta: EnumValue[] = [
  { codigo: 'FIXA', descricao: 'Fixa' },
  { codigo: 'VARIAVEL', descricao: 'Variável' }
];

export const getDescricao = (tipo: string) => {
  const found = tipoMulta.find(t => t.codigo === tipo);
  return found ? found.descricao : '';
};
