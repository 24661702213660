import { Field, ListPage } from '@elotech/components';
import { History } from 'history';
import React from 'react';

import ConcursoPessoaExclusaoService from '../../../service/ConcursoPessoaExclusaoService';
import { ConcursoPessoaExclusao } from '../../../types/ConcursoPessoaExclusao';

type Props = {
  history: Pick<History, 'push'>;
};

const field = [
  {
    type: 'STRING',
    name: 'concurso.nomeConcurso',
    label: 'Nome Concurso'
  },
  {
    type: 'STRING',
    name: 'cnpjCpf',
    label: 'CNPJ/CPF'
  },
  {
    type: 'STRING',
    name: 'nome',
    label: 'Nome'
  }
] as Field[];

const PessoaExclusaoListPage: React.FC<Props> = ({ history }) => {
  return (
    <ListPage<ConcursoPessoaExclusao>
      title="Pesquisar Exclusão de Participantes"
      icon="search"
      baseUrl="/concurso/pessoa-exclusao"
      searchFields={field}
      onSearch={ConcursoPessoaExclusaoService.filtrar}
      onDelete={(value: ConcursoPessoaExclusao) =>
        ConcursoPessoaExclusaoService.remove(value.id)
      }
      history={history}
      getId={(item: ConcursoPessoaExclusao) => item.id!}
      columns={[
        ['Número concurso', (item: ConcursoPessoaExclusao) => item.concurso.id],
        [
          'Nome Concurso',
          (item: ConcursoPessoaExclusao) => item.concurso.nomeConcurso
        ],
        ['Nome', (item: ConcursoPessoaExclusao) => item.nome],
        ['CNPJ/CPF', (item: ConcursoPessoaExclusao) => item.cnpjCpf],
        ['Motivo', (item: ConcursoPessoaExclusao) => item.motivo]
      ]}
      hideButtons={['view']}
    />
  );
};

export default PessoaExclusaoListPage;
