import {
  Button,
  Container,
  ErrorText,
  FAB,
  SectionTitle
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const errorMessages = {
  cpfCnpj: 'O CPF é obrigatório.',
  nome: 'O nome é obrigatório.',
  email: 'O email é obrigatório.'
};

const Privacy = props => {
  const { onResetPassword } = props;
  return (
    <div>
      <SectionTitle>Privacidade e Segurança</SectionTitle>
      <Button onClick={onResetPassword}>REDEFINIR A SENHA</Button>
    </div>
  );
};

const MyAccountForm = props => {
  const {
    user,
    userExists,
    error,
    onChangeField,
    onSave,
    onResetPassword,
    loading
  } = props;
  const { cpfCnpj, nome, email } = user;
  return (
    <Container title="Minha Conta" icon="user">
      <SectionTitle marginTop="0px">Dados Pessoais</SectionTitle>
      <div className="row">
        <div className="form-group col-md-3">
          <label htmlFor="cpfCnpjInput" className="label">
            CPF
          </label>
          <input
            id="cpfCnpjInput"
            type="text"
            name="cpfCnpj"
            value={cpfCnpj}
            onChange={onChangeField}
            disabled={userExists}
          />
          {error.cpfCnpj && <ErrorText>{errorMessages.cpfCnpj}</ErrorText>}
        </div>

        <div className="form-group col-md-3">
          <label htmlFor="nameInput" className="label">
            Nome Completo
          </label>
          <input
            id="nameInput"
            type="text"
            value={nome}
            name="nome"
            onChange={onChangeField}
          />
          {error.nome && <ErrorText>{errorMessages.nome}</ErrorText>}
        </div>

        <div className="form-group col-md-3">
          <label htmlFor="emailInput" className="label">
            E-mail
          </label>
          <input
            id="emailInput"
            type="text"
            value={email}
            name="email"
            onChange={onChangeField}
          />
          {error.email && <ErrorText>{errorMessages.email}</ErrorText>}
        </div>
      </div>

      {userExists ? <Privacy onResetPassword={onResetPassword} /> : null}
      <div className="btn-save">
        <FAB
          loading={loading}
          icon="check"
          iconColor="white"
          title="Salvar"
          onClick={onSave}
        />
      </div>
    </Container>
  );
};

Privacy.propTypes = {
  onResetPassword: PropTypes.func.isRequired
};

MyAccountForm.propTypes = {
  user: PropTypes.object,
  error: PropTypes.object,
  userExists: PropTypes.bool,
  loading: PropTypes.bool,
  onResetPassword: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export { Privacy };
export default MyAccountForm;
