import { Col, Row } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const ContatoCredenciamento = ({ historicoAgendamento }) => {
  return (
    <Fragment>
      <div className="display-data border small mb-xs">
        <Row>
          <Col md={6}>
            <b className="dd-title">Email</b>
            <div className="dd-content">{historicoAgendamento.email}</div>
          </Col>
          <Col md={6}>
            <b className="dd-title">Telefone</b>
            <div className="dd-content">{historicoAgendamento.telefone}</div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

ContatoCredenciamento.propTypes = {
  historicoAgendamento: PropTypes.object.isRequired
};

export default ContatoCredenciamento;
