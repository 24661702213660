import { DuvidaFormPage } from '../pages/duvida/DuvidaFormPage';
import DuvidaListPage from '../pages/duvida/DuvidaListPage';
import { Roles } from '../utils/Roles';

export const routesDuvidas = [
  {
    path: '/duvidas',
    component: DuvidaListPage,
    icon: 'question',
    title: 'Dúvidas',
    role: Roles.denuncias_read.name
  },
  {
    path: '/duvidas/analisar/:id',
    component: DuvidaFormPage,
    icon: 'pencil-alt',
    title: 'Editar',
    parent: '/duvidas'
  }
];

export default routesDuvidas;
