import { ControleDmsNaoEntregueDTO } from '../../../types';

export const getFiltrotext = (filtro?: string) => {
  if (!filtro) {
    return '';
  }

  const filtroDTO: ControleDmsNaoEntregueDTO = JSON.parse(filtro);

  return [
    filtroDTO?.pessoa ? `Pessoa/Empresa: ${filtroDTO.pessoa?.cnpjCpf}` : '',
    filtroDTO?.cadastroGeral ? `Cadastro(s): ${filtroDTO.cadastroGeral}` : '',
    filtroDTO?.competenciaInicial
      ? `Competência Inicial: ${filtroDTO.competenciaInicial}`
      : '',
    filtroDTO?.competenciaFinal
      ? `Competência Final: ${filtroDTO.competenciaFinal}`
      : '',
    filtroDTO?.regimesFiscais
      ? filtroDTO.regimesFiscais
          .map(regime => {
            return `Regime Fiscal: ${regime.id} - ${regime.descricao}`;
          })
          .join(', ')
      : '',
    filtroDTO?.somentePrestador ? 'Somente Prestador' : '',
    filtroDTO?.somenteTomador ? 'Somente Tomador' : ''
  ]
    .filter(Boolean)
    .join(', ');
};
