import {
  BasicInput,
  Button,
  Checkbox,
  Col,
  Container,
  FormikAutocomplete,
  FormikInputIntegerMultiValue,
  Loading,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import { differenceInMonths } from 'date-fns';
import { Formik, FormikProps } from 'formik';
import { Alert } from 'iss-common/utils';
import moment from 'moment';
import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';

import { PessoaService, RelatorioPGDASService } from '../../service';
import { Pessoa } from '../../types';
import { RelatorioDeclaracaoIsentaImuneDTO } from '../../types/RelatorioDeclaracaoIsentaImuneDTO';

const initialValues: RelatorioDeclaracaoIsentaImuneDTO = {
  isento: false,
  imune: false,
  reducaoAliquota: false
};

const RelatorioPGDASImuneIsentoFormPage: React.FC = () => {
  const [declaracaoIsenta, setDeclaracaoIsenta] = useState<boolean>(false);
  const [declaracaoImune, setDeclaracaoImune] = useState<boolean>(false);
  const [declaracaoRedAliquota, setDeclaracaoRedAliquota] = useState<boolean>(
    false
  );

  const schema = Yup.object().shape({
    competenciaInicial: Yup.string()
      .required()
      .label('Competência inicial')
      .min(7)
      .test('dataValida', 'Competência inicial inválida!', value => {
        const data = moment(`01/${value}`, 'DD/MM/YYYY', true);
        return data.isValid();
      }),
    competenciaFinal: Yup.string()
      .label('Competência Final')
      .min(7)
      .notRequired()
      .test('dataFinalValida', 'Competência final inválida!', value => {
        if (value === undefined) {
          return true;
        }
        const data = moment(`01/${value}`, 'DD/MM/YYYY', true);
        return data.isValid();
      })
      .test(
        'dataFinalMenorQueInicial',
        'Competência final maior que competência inicial!',
        function(value) {
          if (value === undefined) {
            return true;
          }
          const dataInicial = moment(
            `01/${this.parent.competenciaInicial}`,
            'DD/MM/YYYY',
            true
          );
          const data = moment(`01/${value}`, 'DD/MM/YYYY', true);
          return data.isSame(dataInicial) || dataInicial.isBefore(data);
        }
      )
      .test(
        'periodoBuscaExcedeUmAno',
        'O período da busca não pode exceder 12 meses',
        function(ano) {
          if (ano === undefined) {
            return true;
          }
          const dataInicial = moment(
            `01/${this.parent.competenciaInicial}`,
            'DD/MM/YYYY',
            true
          );
          const data = moment(`01/${ano}`, 'DD/MM/YYYY', true);
          const diferenca = differenceInMonths(
            dataInicial.format('DD/MM/YYYY'),
            data.format('DD/MM/YYYY')
          );
          return diferenca > -12;
        }
      )
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: any) => {
    if (
      values.isento === false &&
      values.imune === false &&
      values.reducaoAliquota === false
    ) {
      Alert.warning({ title: 'Deve ter pelo menos um checkbox marcado' });
      return;
    }
    setLoading(true);
    await RelatorioPGDASService.carregarRelatorioPGDASDeclaracaoIsentaImune(
      values
    )
      .then((response: any) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);
      })
      .catch((error: any) => {
        Alert.error({ title: `Ocorreu um erro ao gerar o relatório` }, error);
      })
      .finally(() => setLoading(false));
  };

  const onChangeDeclaracao = (
    field: string,
    formProps: FormikProps<RelatorioDeclaracaoIsentaImuneDTO>,
    state: boolean,
    setState: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    formProps.setFieldValue(field, !state);
    setState(!state);
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <SectionTitle>Filtro</SectionTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
        render={(form: FormikProps<RelatorioDeclaracaoIsentaImuneDTO>) => (
          <>
            <Row>
              <BasicInput
                size={2}
                label="Competência Inicial"
                name="competenciaInicial"
                render={({ field }) => (
                  <MaskedInput
                    {...field}
                    type="text"
                    name="competenciaInicial"
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  />
                )}
              />
              <BasicInput
                size={2}
                label="Competência Final"
                name="competenciaFinal"
                render={({ field }) => (
                  <MaskedInput
                    {...field}
                    type="text"
                    name="competenciaFinal"
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  />
                )}
              />
              <FormikAutocomplete
                data-test-id="autocomplete-pessoa"
                name="pessoa"
                placeholder="Pesquisar por Cnpj/Cpf ou Nome"
                label="Pessoa/Empresa (CPF/CNPJ)"
                onSearch={PessoaService.search}
                size={4}
                getOptionLabel={(value: Pessoa) =>
                  `${value?.cnpjCpf} - ${value.nome}`
                }
                getOptionValue={(value: Pessoa) => value.id}
              />
              <FormikInputIntegerMultiValue
                id="cadastroGeral"
                name="cadastroGeral"
                label="Cadastro Geral"
                size={3}
              />
            </Row>
            <Row>
              <Col md={2}>
                <Checkbox
                  className="form-group"
                  name="isento"
                  id="isento"
                  checked={declaracaoIsenta}
                  onChange={() =>
                    onChangeDeclaracao(
                      'isento',
                      form,
                      declaracaoIsenta,
                      setDeclaracaoIsenta
                    )
                  }
                  label="Declaração Isenta"
                ></Checkbox>
              </Col>
              <Col md={2}>
                <Checkbox
                  className="form-group"
                  name="imune"
                  id="imune"
                  checked={declaracaoImune}
                  onChange={() =>
                    onChangeDeclaracao(
                      'imune',
                      form,
                      declaracaoImune,
                      setDeclaracaoImune
                    )
                  }
                  label="Declaração Imune"
                ></Checkbox>
              </Col>
              <Col md={4}>
                <Checkbox
                  className="form-group"
                  name="reducaoAliquota"
                  id="reducaoAliquota"
                  checked={declaracaoRedAliquota}
                  onChange={() =>
                    onChangeDeclaracao(
                      'reducaoAliquota',
                      form,
                      declaracaoRedAliquota,
                      setDeclaracaoRedAliquota
                    )
                  }
                  label="Declaração com Red. alíquota"
                ></Checkbox>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="form-group">
                  <Button onClick={form.submitForm} type="submit">
                    Imprimir
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      />
    </Container>
  );
};

export default RelatorioPGDASImuneIsentoFormPage;
