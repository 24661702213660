import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'nsu' };

const search = (params: any) => (params ? `search=${params}` : '');

export const searchNfseImportacoes = (searchParams: string, page?: any) =>
  axios.get(
    `${CONTEXT_PATH}/nfse-nacional-importacao?${search(searchParams)}`,
    {
      params: { ...defaultSort, ...page }
    }
  );
