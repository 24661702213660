import { Container, Loading, NotificationActions } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { GrupoDocumentoService, withService } from './../../../service';
import GrupoAnexoPublicoForm from './GrupoAnexoPublicoForm';

export class GrupoAnexoPublicoFormPage extends Component {
  static propTypes = {
    grupoDocumentoService: PropTypes.object.isRequired
  };

  loadGrupoDocumentoSuccess = response => {
    this.setState({
      dadosDocumento: response.data,
      loading: false
    });
  };

  loadGrupoDocumentoError = error => {
    this.setState({ loading: false });

    this.props.showNotification({
      level: 'error',
      message: 'Não foi possível carregar o grupo de documento.'
    });
  };

  salvarGrupoDocumentoSuccess = success => {
    this.setState({ loading: false });

    this.props.showNotification({
      level: 'success',
      message: 'Documento salvo com sucesso'
    });

    this.props.history.replace('/anexos-publicos/grupo-anexo-publico');
  };

  salvarGrupoDocumentoError = error => {
    this.setState({ loading: false });

    this.props.showNotification({
      level: 'error',
      message: 'Não foi possível salvar o grupo de documento.'
    });
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.setState({ loading: true });
      this.props.grupoDocumentoService
        .loadGrupoDocumentoById(id)
        .then(this.loadGrupoDocumentoSuccess)
        .catch(this.loadGrupoDocumentoError);
    }
  }

  validators = {
    id: value => true,
    titulo: value => value,
    descricao: value => value
  };

  state = {
    dadosDocumento: {
      id: '',
      titulo: '',
      descricao: ''
    },
    error: {
      id: false,
      titulo: false,
      descricao: false
    },
    loading: false
  };

  save = () => {
    if (this.hasErrors()) {
      this.props.showNotification({
        level: 'error',
        message: 'Campos Obrigatórios'
      });
    } else {
      this.setState({ loading: true });
      this.props.grupoDocumentoService
        .save(this.state.dadosDocumento)
        .then(this.salvarGrupoDocumentoSuccess)
        .catch(this.salvarGrupoDocumentoError);
    }
  };

  hasErrors = () => {
    const { dadosDocumento } = this.state;

    const errors = Object.keys(dadosDocumento).filter(field => {
      return !this.validators[field](dadosDocumento[field]);
    });

    const objErro = errors.reduce((total, current) => {
      total[current] = true;
      return total;
    }, {});

    this.setState({ error: objErro });

    return errors.length > 0;
  };

  onChangeInputValue = event => {
    const { name, value } = event.target;

    this.setState(state => {
      const { dadosDocumento, error } = state;
      return {
        dadosDocumento: { ...dadosDocumento, [name]: value },
        error: { ...error, [name]: !this.validators[name](value) }
      };
    });
  };

  render() {
    const { error, loading } = this.state;

    return (
      <Container breadcrumb>
        <Loading loading={loading} />
        <GrupoAnexoPublicoForm
          save={this.save}
          dadosDocumento={this.state.dadosDocumento}
          onChangeInputValue={this.onChangeInputValue}
          error={error}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

GrupoAnexoPublicoFormPage.propTypes = {
  dadosDocumento: PropTypes.object,
  error: PropTypes.object
};

const ComponentWithService = withService({
  grupoDocumentoService: GrupoDocumentoService
})(GrupoAnexoPublicoFormPage);

export default connect(null, mapDispatchToProps)(ComponentWithService);
