import {
  Alert,
  Container,
  Hint,
  Loading,
  NotificationActions,
  SectionTitle
} from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { PrestacaoContasEleicoesService, withService } from '../../../service';
import ArquivosList from './ArquivosList';
import UploadArquivos from './UploadArquivos';

export class ImportacaoCandidatosListPage extends Component {
  static propTypes = {
    prestacaoContasEleicoesService: PropTypes.object.isRequired
  };

  state = {
    loading: false,
    arquivosImportados: [],
    uploadArquivo: undefined,
    erros: {}
  };

  importCnpjError = () => {
    Alert.error({
      title: 'Ocorreu um erro ao Importar arquivo.'
    });
  };

  tamanhoValido = files => files && files.tamanho <= 209715200;
  extensaoValida = files =>
    files && files.nomeArquivo.split('.').pop() === 'zip';

  onUploadFile = event => {
    const { files } = event.target;
    this.getBase64(files[0])
      .then(data => {
        this.setState(state => {
          return {
            uploadArquivo: {
              arquivoBase64: data,
              nomeArquivo: files[0].name,
              tamanho: files[0].size
            },
            erros: {}
          };
        });
      })
      .catch(this.importCnpjError);
  };

  onClick = event => {
    event.target.value = null;
  };

  getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.replace(/^data:(.*;base64,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = error => reject(error);
    });
  };

  componentDidMount() {
    this.serviceSearch('');
  }

  getImportacaoCnpjSuccess = response => {
    this.setState({
      arquivosImportados: response.data,
      loading: false
    });
  };

  getImportacaoCnpjError = error => {
    this.setState({ loading: false });
    Alert.error(
      {
        title:
          'Ocorreu uma falha ao carregar importação dos CNPJ dos candidatos.'
      },
      error
    );
  };

  serviceSearch = (searchParams, page) => {
    this.setState({ loading: true });

    this.props.prestacaoContasEleicoesService
      .loadImportacaoCnpj(searchParams, page)
      .then(this.getImportacaoCnpjSuccess)
      .catch(this.getImportacaoCnpjError);
  };

  importacaoCnpjError = error => {
    this.setState({
      loading: false,
      uploadArquivo: undefined
    });

    Alert.error(
      {
        title: 'Não foi possivel importar arquivo.'
      },
      error
    );
  };

  importacaoCnpjSuccess = () => {
    this.serviceSearch('');
    this.setState({
      loading: false,
      uploadArquivo: undefined
    });

    this.props.showNotification({
      level: 'success',
      message: 'Arquivo importado com sucesso.'
    });
  };

  onImportCnpj = () => {
    const { uploadArquivo } = this.state;
    let erros = {};

    if (!uploadArquivo) {
      erros = { ...erros, vazio: 'Não há arquivo selecionado' };
    } else {
      if (!this.tamanhoValido(uploadArquivo)) {
        erros = {
          ...erros,
          tamanho: 'O tamanho do arquivo não pode exceder 50mb.'
        };
      }

      if (!this.extensaoValida(uploadArquivo)) {
        erros = {
          ...erros,
          extensao: "Extensão inválida. Permitido somente '.zip'."
        };
      }
    }

    if (Object.keys(erros).length > 0) {
      this.setState({
        uploadArquivo: {},
        erros
      });
    } else {
      this.setState({ loading: true });
      this.props.prestacaoContasEleicoesService
        .importArquivoCnpj(uploadArquivo)
        .then(this.importacaoCnpjSuccess)
        .catch(this.importacaoCnpjError);
    }
  };

  render() {
    const { arquivosImportados, uploadArquivo, loading, erros } = this.state;
    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-importacao-candidatos"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        <SectionTitle marginTop="0">
          Importar informações de CNPJs de candidatos e partidos
        </SectionTitle>

        <UploadArquivos
          onUploadFile={this.onUploadFile}
          uploadArquivo={uploadArquivo}
          onImportCnpj={this.onImportCnpj}
          onClick={this.onClick}
          error={erros}
        />
        <ArquivosList arquivosImportados={arquivosImportados} />
      </Container>
    );
  }
}
const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const ComponentWithService = withService({
  prestacaoContasEleicoesService: PrestacaoContasEleicoesService
})(ImportacaoCandidatosListPage);

export default connect(null, mapDispatchToProps)(ComponentWithService);
