import {
  ActionButton,
  ActionsGroup,
  Alert,
  Chip,
  Container,
  FAB,
  Field,
  FormattedDateTime,
  Loading,
  SearchFilter,
  SearchPagination,
  Table,
  formatUtils,
  usePagedQuery
} from '@elotech/components';
import { History } from 'history';
import React from 'react';

import {
  SituacoesCaixaPostal,
  getLabelSituacao
} from '../../enums/SituacaoCaixaPostal';
import { CaixaPostalService } from '../../service';
import { CaixaPostal } from '../../types/CaixaPostal';

type Props = {
  history: Pick<History, 'push'>;
};

const searchFields: Field[] = [
  {
    label: 'Nome Remetente',
    name: 'nomeRemetente',
    type: 'STRING'
  },
  {
    label: 'CNPJ Remetente',
    name: 'nomeRemetente',
    type: 'STRING'
  },
  {
    label: 'Nome Destinatário',
    name: 'nomeDestinatario',
    type: 'STRING'
  },
  {
    label: 'Data de Envio',
    name: 'dataEnvio',
    type: 'DATETIME'
  },
  {
    label: 'Situação',
    name: 'situacao',
    type: 'ENUM',
    options: SituacoesCaixaPostal.map(situacao => ({
      name: situacao.situacao,
      descricao: situacao.label
    }))
  }
];

const CaixaPostalListPage: React.FC<Props> = ({ history }) => {
  const {
    loading,
    values,
    pagination,
    doSearch,
    doPagedSearch
  } = usePagedQuery<CaixaPostal>({
    search: CaixaPostalService.loadCaixaPostal,
    onError: error => {
      Alert.error({ title: 'Erro ao buscar as mensagens.' }, error);
    }
  });

  const onEdit = (item: CaixaPostal) => {
    history.push(`/caixa-postal/editar/${item.id}`);
  };

  const newMensagem = () => {
    history.push('/caixa-postal/editar/novo');
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <div className="panel table table-responsive">
        <div className="panel-body">
          <SearchFilter fields={searchFields} search={doSearch} />
          <Table<CaixaPostal>
            values={values}
            keyExtractor={item => `${item.id}`}
          >
            <Table.Column<CaixaPostal>
              header="Enviado para"
              value={item =>
                item.cnpjCpfDestinatario
                  ? `${formatUtils.formatCpfCnpj(item.cnpjCpfDestinatario)} - ${
                      item.nomeDestinatario
                    }`
                  : 'Fiscais Responsáveis'
              }
            />
            <Table.Column<CaixaPostal>
              header="Enviado por"
              value={item =>
                `${formatUtils.formatCpfCnpj(item.cnpjCpfRemetente)} - ${
                  item.nomeRemetente
                }`
              }
            />
            <Table.Column<CaixaPostal>
              header="Título"
              value={item => item.titulo}
            />

            <Table.Column<CaixaPostal>
              header="Data de envio"
              value={item => <FormattedDateTime value={item.dataEnvio} />}
            />

            <Table.Column<CaixaPostal>
              header="Data de leitura"
              value={item =>
                item.dataLeitura ? (
                  <FormattedDateTime value={item.dataLeitura} />
                ) : (
                  'Não lido'
                )
              }
            />

            <Table.Column<CaixaPostal>
              header="Situação"
              value={item => (
                <Chip inline value={getLabelSituacao(item!.situacao)} />
              )}
            />

            <Table.Column<CaixaPostal>
              data-test-id="buttons-col"
              header=""
              value={item => (
                <ActionsGroup>
                  <ActionButton
                    key="analyse-button"
                    icon="eye"
                    label="Ver Mensagem"
                    onClick={() => onEdit(item)}
                  />
                </ActionsGroup>
              )}
            />
          </Table>
          {pagination && (
            <SearchPagination
              page={pagination}
              searchWithPage={doPagedSearch}
            />
          )}
        </div>
      </div>
      <div className="btn-save">
        <FAB
          icon="plus"
          iconColor="white"
          title="Nova Mensagem"
          onClick={newMensagem}
        />
      </div>
    </Container>
  );
};

export default CaixaPostalListPage;
