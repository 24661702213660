import { AutoComplete, Col, Row } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const AutoCompleteAdicionarCnae = ({
  disabled,
  cnaeAutoComplete,
  loadCnaes,
  onSelectCnae,
  filterCnae,
  optionLabelCnae,
  onClearAutoComplete,
  onAdicionarCnae
}) => {
  return (
    <Fragment>
      <Row>
        <Col md={6}>
          <div className="form-group">
            <AutoComplete
              isDisabled={disabled}
              data-test-id="autocomplete-cnae"
              name="cnae"
              value={cnaeAutoComplete.cnae}
              onSearch={loadCnaes}
              onSelect={onSelectCnae}
              filter={filterCnae}
              getOptionLabel={optionLabelCnae}
              placeholder="Pesquisar por código ou descrição"
              isClearable
              onClear={onClearAutoComplete}
            />
          </div>
        </Col>
        <Col md={2}>
          <div className="form-group">
            <button
              disabled={disabled}
              data-test-id="buttonAdd"
              className="btn positive module-color inline mt-xs-md"
              onClick={onAdicionarCnae}
            >
              ADICIONAR
            </button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

AutoCompleteAdicionarCnae.propType = {
  cnaeAutoComplete: PropTypes.array.isRequired,
  loadCnaes: PropTypes.func.isRequired,
  onSelectCnae: PropTypes.func.isRequired,
  filterCnae: PropTypes.func.isRequired,
  optionLabelCnae: PropTypes.func.isRequired,
  onClearAutoComplete: PropTypes.func.isRequired,
  onAdicionarCnae: PropTypes.func.isRequired
};

export default AutoCompleteAdicionarCnae;
