import { KeycloakProvider } from '@elotech/components';
import { ConfigOperations } from 'iss-common/state/config';
import { UserOperations } from 'iss-common/state/user';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

class AppKeycloakProvider extends React.Component {
  static propTypes = {
    isKeycloakLoaded: PropTypes.bool,
    setKeycloakLoaded: PropTypes.func,
    checkUserExists: PropTypes.func,
    getUserData: PropTypes.func,
    keycloakService: PropTypes.object,
    loadUserProfile: PropTypes.func
  };

  render() {
    const {
      keycloakService,
      setKeycloakLoaded,
      getUserData,
      checkUserExists,
      isKeycloakLoaded,
      loadUserProfile,
      children
    } = this.props;
    return (
      <KeycloakProvider
        keycloakService={keycloakService}
        checkUserExists={checkUserExists}
        getUserData={getUserData}
        isKeycloakLoaded={isKeycloakLoaded}
        loadUserProfile={loadUserProfile}
        setKeycloakLoaded={setKeycloakLoaded}
      >
        {children}
      </KeycloakProvider>
    );
  }
}

const mapStateToProps = state => ({
  isKeycloakLoaded: state.config.keycloakLoaded
});

export default connect(mapStateToProps, {
  setKeycloakLoaded: ConfigOperations.setKeycloakLoaded,
  checkUserExists: UserOperations.checkUserExists,
  getUserData: UserOperations.getUserData,
  loadUserProfile: UserOperations.loadUserProfile
})(AppKeycloakProvider);
