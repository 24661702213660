import {
  Alert,
  Button,
  FormikSelect,
  FormikTextArea,
  Loading,
  Panel,
  Row,
  TableChild,
  Yup
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';

import {
  TipoSolicitanteCancelamento,
  TiposSolicitanteCancelamento
} from '../../../enums/TipoSolicitanteCancelamento';
import { CancelamentoNotaFiscalService } from '../../../service';
import { ParametrosCancelamentoNotaFiscalDTO } from '../../../types/ParametrosCancelamentoNotaFiscalDTO';

type Props = { idNotaFiscal: number; onCloseForms(): void; onSearch(): void };

const validationSchema = Yup.object().shape({
  motivo: Yup.string()
    .label('Motivo')
    .required(),
  tipoSolicitanteCancelamento: Yup.string()
    .label('Solicitante')
    .required()
});

const CancelarNfseFormPage: React.FC<Props> = ({
  idNotaFiscal,
  onCloseForms,
  onSearch
}) => {
  const [loading, setLoading] = useState(false);

  const onSave = (motivo: ParametrosCancelamentoNotaFiscalDTO) => {
    Alert.question({
      title: 'Deseja confirmar o cancelamento de NFS-e?'
    }).then((result: any) => {
      if (result.value) {
        setLoading(true);
        motivo.idNotaFiscal = idNotaFiscal;

        CancelamentoNotaFiscalService.cancelarNota(motivo)
          .then((response: any) => {
            if (response.status === 202) {
              Alert.info({
                title: `Não foi possível cancelar NFS-e`,
                text: response.data
              });
            } else {
              onCloseForms();
              onSearch();

              Alert.success({
                title: 'NFS-e cancelada com sucesso'
              });
            }
          })
          .catch(error => {
            Alert.error({ title: 'Ocorreu um erro ao cancelar NFS-e' }, error);
          })
          .finally(() => setLoading(false));
      }
    });
  };

  return (
    <TableChild>
      <Loading loading={loading} />
      <Panel>
        <Formik
          initialValues={{}}
          onSubmit={onSave}
          validationSchema={validationSchema}
          render={(form: FormikProps<ParametrosCancelamentoNotaFiscalDTO>) => (
            <>
              <Row>
                <FormikSelect
                  fast={false}
                  name="tipoSolicitanteCancelamento"
                  label="Solicitante"
                  size={3}
                  options={TiposSolicitanteCancelamento}
                  getOptionLabel={(option: TipoSolicitanteCancelamento) =>
                    option.descricao
                  }
                  getOptionValue={(option: TipoSolicitanteCancelamento) =>
                    option.codigo
                  }
                />
              </Row>
              <Row>
                <FormikTextArea
                  size={12}
                  name="motivo"
                  label="Motivo Cancelamento"
                />
              </Row>
              <Row>
                <div className="form-group ml-xs">
                  <label className="label" />
                  <Button
                    id="cancelar-nota"
                    className={'inline'}
                    onClick={form.submitForm}
                  >
                    Cancelar Nota
                  </Button>
                </div>
              </Row>
            </>
          )}
        />
      </Panel>
    </TableChild>
  );
};

export default CancelarNfseFormPage;
