import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

export const loadProcessos = (searchParams: string, page?: any) =>
  axios.get(`${CONTEXT_PATH}/tipo-processo`, {
    params: {
      search: searchParams,
      ...page,
      fields: 'id,descricao,tiposProcessoAto'
    }
  });

export const findById = (id: any) =>
  axios.get(`${CONTEXT_PATH}/tipo-processo/${id}`);
