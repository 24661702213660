import { PageRequest, PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { BatchInstanceDTO, HistoricoBatchDTO } from '../types';
import { CONTEXT_PATH } from './contextPath';

const PATH_BATCH = `${CONTEXT_PATH}/batch`;

export const findAll = (
  search: string,
  pagination?: PageRequest
): AxiosPromise<PagedResponse<BatchInstanceDTO>> =>
  axios.get(`${PATH_BATCH}/tarefas`, {
    params: {
      search,
      sort: 'id',
      ...pagination
    }
  });

export const getTarefas = (
  search: string,
  pagination?: PageRequest
): AxiosPromise<PagedResponse<BatchInstanceDTO>> => {
  console.log(PATH_BATCH, CONTEXT_PATH);
  return axios.get(`${PATH_BATCH}/tarefas`, {
    params: {
      search,
      ...pagination
    }
  });
};

export const getExecutionFromInstanceId = (
  instanceId: number
): AxiosPromise<HistoricoBatchDTO[]> =>
  axios.get(`${PATH_BATCH}/historico/${instanceId}`);

export const retry = (id: number): AxiosPromise<void> =>
  axios.post(`${PATH_BATCH}/retry/${id}`);

export const stop = (id: number): AxiosPromise<void> =>
  axios.post(`${PATH_BATCH}/stop/${id}`);
