import { BasicInput, Button, Col, Row, Yup } from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import React from 'react';

import { Bloqueio } from '../../../types/Bloqueio';

type FormBloqueioProps = {
  bloqueio: Bloqueio;
  onSubmit(bloqueio: Bloqueio): void;
  onCancel(): void;
};

export const BloqueioOperacoesFormBloqueio: React.FC<FormBloqueioProps> = ({
  bloqueio,
  onSubmit,
  onCancel
}) => {
  return (
    <Formik
      data-test-id="formik-form-bloqueio"
      initialValues={{}}
      onSubmit={values => onSubmit({ ...bloqueio, ...values })}
      validationSchema={Yup.object().shape({
        observacao: Yup.string()
          .label('Observações')
          .required()
      })}
    >
      {(form: FormikProps<Bloqueio>) => (
        <>
          <Row>
            <BasicInput
              name="observacao"
              label="Observações para o bloqueio"
              size={12}
            />
          </Row>
          <Row>
            <Col className="mt-xs" md={12}>
              <Button
                data-test-id="submit-form-button"
                className="inline"
                onClick={form.submitForm}
              >
                Salvar
              </Button>
              <Button
                data-test-id="cancel-form-button"
                className="inline"
                color="negative"
                onClick={() => onCancel()}
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Formik>
  );
};
