import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id,titulo' };

const search = params => (params ? `search=${params}` : '');

export const loadDocumentosCadastrados = (searchParams, page) =>
  axios.get(`${CONTEXT_PATH}/documento?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const removerDocumentoCadastrado = id =>
  axios.delete(`${CONTEXT_PATH}/documento/remover/${id}`);

export const loadDocumentosCadastradosById = id =>
  axios.get(`${CONTEXT_PATH}/documento/${id}`);

export const save = documento => {
  if (documento.id > 0) {
    return axios.put(`${CONTEXT_PATH}/documento/${documento.id}`, documento);
  }
  return axios.post(`${CONTEXT_PATH}/documento`, documento);
};
