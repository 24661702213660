import axios from 'axios';
import { isNumeric } from 'iss-fiscalizacao/src/utils/utils';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };

export const findById = id => axios.get(`${CONTEXT_PATH}/cadastro-geral/${id}`);

const filterAutocompleteCnpj = search => {
  if (isNumeric(search)) {
    return `cnpjCpf==*${search}*`;
  }
  return `nome=='*${search}*'`;
};

export const search = (search, page = 20) => {
  return axios.get(`${CONTEXT_PATH}/pessoas/search`, {
    params: {
      search: filterAutocompleteCnpj(search),
      ...defaultSort,
      ...page
    }
  });
};
