import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };

const search = params => (params ? `search=${params}` : '');

export const loadNotaFiscal = (searchParams, page) =>
  axios.get(`${CONTEXT_PATH}/nota-fiscal?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const downloadNotaFiscal = searchParams =>
  axios.get(
    `${CONTEXT_PATH}/nota-fiscal/download-notas-pdf?${search(searchParams)}`,
    {
      params: { ...defaultSort },
      responseType: 'blob'
    }
  );
