import {
  AutoComplete,
  Checkbox,
  Col,
  InputDate,
  InputInteger,
  Row,
  SectionTitle
} from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const EditarTomadorForm = ({
  dadosNfse,
  onChangeCheckbox,
  loadCadastrosMobiliarios,
  onSelect,
  optionLabel
}) => {
  return (
    <Fragment>
      <SectionTitle marginTop="0">Dados da NFS-e</SectionTitle>
      <Row>
        <Col md={3}>
          <div className="form-group">
            <label htmlFor="numeroNotaFiscal">Nº NFS-e</label>
            <InputInteger disabled defaultValue={dadosNfse.numeroNotaFiscal} />
          </div>
        </Col>
        <Col md={3}>
          <div className="form-group">
            <label htmlFor="dataEmissao">Data Emissão</label>
            <InputDate disabled value={dadosNfse.dataEmissao} />
          </div>
        </Col>
      </Row>

      <SectionTitle>Dados do Tomador</SectionTitle>
      <Row>
        <Col md={3}>
          <div className="form-group">
            <label htmlFor="numeroNotaFiscal">CPF/CNPJ</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosTomador.cnpjCpf}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="form-group">
            <label htmlFor="cadastroGeralVo">Inscrição Municipal</label>
            <AutoComplete
              data-test-id="autocomplete-inscricaoMunicipal"
              name="cadastroGeralVo"
              placeholder="Digite a Inscrição Municipal"
              value={dadosNfse.cadastroGeralVo}
              onSearch={loadCadastrosMobiliarios}
              onSelect={onSelect}
              getOptionLabel={optionLabel}
              isDisabled={!dadosNfse.dadosTomador.tomadorIdentificado}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={7}>
          <div className="form-group">
            <label htmlFor="nomeRazaoSocial">Nome/Razão Social</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosTomador.razaoSocialNome}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="form-group">
            <label htmlFor="cep">CEP</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosTomador.cep}
            />
          </div>
        </Col>
        <Col md={5}>
          <div className="form-group">
            <label htmlFor="logradouro">Logradouro</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosTomador.logradouro}
            />
          </div>
        </Col>
        <Col md={2}>
          <div className="form-group">
            <label htmlFor="numero">Nº</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosTomador.numero}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="form-group">
            <label htmlFor="bairro">Bairro</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosTomador.bairro}
            />
          </div>
        </Col>
        <Col md={2}>
          <div className="form-group">
            <label htmlFor="complemento">Complemento</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosTomador.complemento}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="form-group">
            <label htmlFor="cidade">Cidade</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosTomador.cidade}
            />
          </div>
        </Col>
        <Col md={2}>
          <div className="form-group">
            <label htmlFor="unidadeFederativa">UF</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosTomador.uf}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="form-group">
            <label htmlFor="telefone">Telefone</label>
            <InputInteger
              disabled
              defaultValue={dadosNfse.dadosTomador.telefone}
            />
          </div>
        </Col>
        <Col md={5}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosTomador.email}
            />
          </div>
        </Col>
      </Row>

      <SectionTitle>Dados do Prestador</SectionTitle>
      <Row>
        <Col md={4}>
          <div className="form-group">
            <label htmlFor="razaoSocial">Razão Social</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosPrestador.razaoSocialNome}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="form-group">
            <label htmlFor="nomeFantasia">Nome Fantasia</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosPrestador.nomeFantasia}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="form-group">
            <label htmlFor="cpfCnpj">CPF/CNPJ</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosPrestador.cnpjCpf}
            />
          </div>
        </Col>
        <Col md={4}>
          <div className="form-group">
            <label htmlFor="inscricaoMunicipal">Inscrição Municipal</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosPrestador.inscricaoMunicipal}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="form-group">
            <label htmlFor="cep">CEP</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosPrestador.cep}
            />
          </div>
        </Col>
        <Col md={5}>
          <div className="form-group">
            <label htmlFor="logradouro">Logradouro</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosPrestador.logradouro}
            />
          </div>
        </Col>
        <Col md={2}>
          <div className="form-group">
            <label htmlFor="numero">Nº</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosPrestador.numero}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="form-group">
            <label htmlFor="bairro">Bairro</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosPrestador.bairro}
            />
          </div>
        </Col>
        <Col md={2}>
          <div className="form-group">
            <label htmlFor="complemento">Complemento</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosPrestador.complemento}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="form-group">
            <label htmlFor="cidade">Cidade</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosPrestador.cidade}
            />
          </div>
        </Col>
        <Col md={2}>
          <div className="form-group">
            <label htmlFor="unidadeFederativa">UF</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosPrestador.uf}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="form-group">
            <label htmlFor="telefone">Telefone</label>
            <InputInteger
              disabled
              defaultValue={dadosNfse.dadosPrestador.telefone}
            />
          </div>
        </Col>
        <Col md={5}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              disabled
              defaultValue={dadosNfse.dadosPrestador.email}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <label className="label" />
          <Checkbox
            className="form-group"
            name="processadoTomador"
            id="processarTomador"
            checked={dadosNfse.processadoTomador}
            onChange={onChangeCheckbox}
            label="Processar Tomador"
          />
        </Col>
        <Col md={2}>
          <label className="label" />
          <Checkbox
            className="form-group"
            name="processadoPrestador"
            id="processarPrestador"
            checked={dadosNfse.processadoPrestador}
            onChange={onChangeCheckbox}
            label="Processar Prestador"
          />
        </Col>
      </Row>
    </Fragment>
  );
};

EditarTomadorForm.propTypes = {
  loadCadastrosMobiliarios: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  optionLabel: PropTypes.func.isRequired
};

export default EditarTomadorForm;
