import { DisplayData, FormattedDate, formatUtils } from '@elotech/components';
import { Field, FieldProps } from 'formik';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { Duvida } from '../../types/Duvida';
import {
  CONCLUIDO,
  EM_ANALISE,
  NOVA,
  getLabelSituacao
} from './SituacaoDuvida';

type Props = {
  duvida: Duvida;
};

const DuvidaSection: React.FC<Props> = ({ duvida }) => {
  return (
    <>
      <Row>
        <Col>
          <div className="display-data border small mb-xs">
            <Row>
              <Col md={2}>
                <DisplayData title="Id">{duvida.id}</DisplayData>
              </Col>
              <Col md={2}>
                <DisplayData title="CPF/CNPJ">
                  {formatUtils.formatCpfCnpj(duvida.cpf)}
                </DisplayData>
              </Col>
              <Col md={2}>
                <DisplayData title="Nome">{duvida.nome}</DisplayData>
              </Col>
              <Col md={3}>
                <DisplayData title="Situação">
                  <Field
                    name="situacao"
                    render={(props: FieldProps<string>) => {
                      const situacao = props.field.value;

                      let cor;

                      switch (situacao) {
                        case NOVA:
                          cor = 'navy';
                          break;
                        case EM_ANALISE:
                          cor = 'red';
                          break;
                        case CONCLUIDO:
                          cor = 'green';
                          break;
                        default:
                          cor = 'inherited';
                      }

                      return (
                        <span style={{ fontWeight: 'bold', color: cor }}>
                          {getLabelSituacao(props.field.value)}
                        </span>
                      );
                    }}
                  />
                </DisplayData>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <DisplayData title="Email">{duvida.email}</DisplayData>
              </Col>
              <Col md={2}>
                <DisplayData title="Telefone">
                  {formatUtils.formatCelular(duvida.telefone)}
                </DisplayData>
              </Col>
              <Col md={3}>
                <DisplayData title="Data">
                  <FormattedDate value={duvida.dataCadastro} />
                </DisplayData>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <DisplayData title="Descrição">{duvida.descricao}</DisplayData>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DuvidaSection;
