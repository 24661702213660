import { FilterUtils, PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import ServiceUtils from '../utils/ServiceUtils';

const searchUserByDocument = (tipoDocumento: 'JURIDICA' | 'FISICA') => (
  documento: string
): AxiosPromise<any> =>
  axios.get(`/api/usuarios?cpf-cnpj=${documento}&tipo-pessoa=${tipoDocumento}`);

export const getUserData = (): AxiosPromise<any> =>
  axios.get(`/gateway/usuarios/me`);

export const getUserDataDirectly = (): AxiosPromise<any> =>
  axios.get(`/api/usuarios/me`);

export const patchUser = (userData: object): AxiosPromise<any> =>
  axios.patch(`/api/usuarios/me`, userData);

export const userExists = (): AxiosPromise<any> =>
  axios.get(`/gateway/public/user-exists`);

export const addUser = (userData: object): AxiosPromise<any> =>
  axios.post(`/api/usuarios/add-user`, userData);

export const searchUserByCpf = searchUserByDocument('FISICA');

export const searchUserByCnpj = searchUserByDocument('JURIDICA');

export const updateAuthorizedUsers = (userIds: string[]): AxiosPromise<any> =>
  axios.put('/api/usuarios/me/usuarios-autorizados', userIds);

export const resetPassword = (): AxiosPromise<any> =>
  axios.get('/api/usuarios/reset-password');

export const searchUsuarioByNome = (
  searchParam: string = '',
  page: object
): AxiosPromise<any> =>
  axios.get(`/api/usuarios/search?search=${searchParam}`, {
    params: page
  });

export const searchUsuarioOnSameEntidade = (
  searchParam: string = '',
  role: string = '',
  page: object
): AxiosPromise<any> =>
  axios.get(`/api/usuarios/grupo?search=${searchParam}&role=${role}`, {
    params: page
  });

export const adicionarUsuarioAutorizado = (
  idUsuarioAutorizado: string
): AxiosPromise<any> =>
  axios.post(`/api/usuarios/me/usuario-autorizado/${idUsuarioAutorizado}`);

export const getUsuarioAutorizadoById = (): AxiosPromise<any> =>
  axios.get('/api/usuarios/me/usuario-autorizado');

export const removerUsuarioAutorizado = (
  idUsuarioAutorizado: string
): AxiosPromise<any> =>
  axios.delete(`/api/usuarios/me/usuario-autorizado/${idUsuarioAutorizado}`);

export const getUsuarios = (searchParams: string, page: object) =>
  axios.get(`/api/usuarios?${ServiceUtils.buildSearchParams(searchParams)}`, {
    params: { defaultSort: { sort: 'nome' }, ...page }
  });

export const autoComplete = (searchParams: string) =>
  axios.get(`/api/usuarios`, {
    params: {
      defaultSort: {
        sort: 'nome',
        search: FilterUtils.buildFilter(['cpfCnpj', 'nome'])(searchParams)
      }
    }
  });

export const getMatriculas = (cpf: string): AxiosPromise<PagedResponse<any>> =>
  axios.get(`/api/usuarios/${cpf}/matriculas`);

export const getUsuarioByCpfCnpj = (cpfCnpj: string) =>
  axios.get(`/api/usuarios/cpf-cnpj/${cpfCnpj}`);

export const getUsuarioAcessoCidadao = (): AxiosPromise<any> =>
  axios.get(`/api/usuarios/acesso-cidadao`);
