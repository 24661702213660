export const PRESTADOR = 'PRESTADOR';
export const TOMADOR = 'TOMADOR';

export type TipoSolicitanteCancelamento = {
  codigo: string;
  descricao: string;
};

export const TiposSolicitanteCancelamento: TipoSolicitanteCancelamento[] = [
  { codigo: PRESTADOR, descricao: 'Prestador' },
  { codigo: TOMADOR, descricao: 'Tomador' }
];

export const getLabelSituacao = (codigo?: string) => {
  if (!codigo) return undefined;

  const obj = TiposSolicitanteCancelamento.find(t => t.codigo === codigo);

  if (obj && obj.descricao) return obj.descricao;

  return undefined;
};
