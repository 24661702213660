import { PagedResponse } from '@elotech/components';
import { FilterUtils } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { Concurso } from '../types/Concurso';
import { ConcursoCupomParticipante } from '../types/ConcursoCupomParticipante';
import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };
const sortByIdDesc = { sort: 'id,desc' };

const search = (params: any) => (params ? `search=${params}` : '');

export const filtrar = (
  search: string,
  page?: any
): AxiosPromise<PagedResponse<Concurso>> =>
  axios.get(`${CONTEXT_PATH}/concursos/filter`, {
    params: {
      search,
      ...sortByIdDesc,
      ...page
    }
  });

export const findById = (id: number): AxiosPromise<Concurso> =>
  axios.get(`${CONTEXT_PATH}/concursos/${id}`);

export const apurar = (id?: number): AxiosPromise<Concurso> =>
  axios.post(`${CONTEXT_PATH}/concursos/${id}/apurar`);

export const publicar = (id?: number): AxiosPromise<Concurso> =>
  axios.post(`${CONTEXT_PATH}/concursos/${id}/publicar`);

export const generateCupons = (id: number): AxiosPromise<Concurso> =>
  axios.post(`${CONTEXT_PATH}/concursos/${id}/gerar-cupons`);

export const getCuponsByConcurso = (
  id: number,
  searchParams: string,
  page?: any
) =>
  axios.get(`${CONTEXT_PATH}/concursos/${id}/cupons?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const save = (Concurso: Concurso): AxiosPromise<Concurso> =>
  axios.post(`${CONTEXT_PATH}/concursos/`, Concurso);

export const remove = (Concurso: Concurso) =>
  axios.delete(`${CONTEXT_PATH}/concursos/${Concurso.id}`);

const filterAutocomplete = (search: any) => {
  if (isNaN(search)) {
    return FilterUtils.buildFilter(['nomeConcurso'])(search);
  }

  return FilterUtils.buildDefaultFilter('id', 'nomeConcurso')(search);
};

const downloadRegulamento = (id: any) =>
  axios.get(`${CONTEXT_PATH}/concursos/download-regulamento/${id}`, {
    responseType: 'blob'
  });

const downloadPlanilhaCupons = (id: any) =>
  axios.get(`${CONTEXT_PATH}/concursos/${id}/download-planilha-cupons`, {
    responseType: 'blob'
  });

export const cupomPremiado = (
  idConcurso: any,
  idCupom: any,
  premiado: boolean,
  observacao: string
): AxiosPromise<ConcursoCupomParticipante> =>
  axios.post(
    `${CONTEXT_PATH}/concursos/${idConcurso}/cupons/${idCupom}/premiado`,
    {
      premiado: premiado,
      observacao: observacao
    }
  );

export const concursoAutoComplete = (
  search: string = ''
): AxiosPromise<Concurso[]> =>
  axios.get(`${CONTEXT_PATH}/concursos/filterVigentes`, {
    params: {
      search: filterAutocomplete(search),
      sort: 'id'
    }
  });

export const encerrar = (id: number): AxiosPromise<Concurso> =>
  axios.put(`${CONTEXT_PATH}/concursos/${id}/encerrar`);

export default {
  filtrar,
  findById,
  save,
  remove,
  concursoAutoComplete,
  downloadRegulamento,
  apurar,
  generateCupons,
  getCuponsByConcurso,
  publicar,
  downloadPlanilhaCupons,
  cupomPremiado,
  encerrar
};
