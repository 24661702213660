import { Col, Row } from '@elotech/components';
import React, { useEffect, useState } from 'react';

type Props = {
  uploadArquivo: any;
  onChangeArquivo: any;
  label: string;
  name: string;
};

const UploadCertificado: React.FC<Props> = ({
  uploadArquivo,
  onChangeArquivo,
  label,
  name
}) => {
  const [nomeArquivo, setNomeArquivo] = useState<String>();
  useEffect(() => {
    setNomeArquivo(
      uploadArquivo && uploadArquivo.name && uploadArquivo.name.length > 0
        ? uploadArquivo.name
        : 'Clique ou arraste para anexar'
    );
  }, [uploadArquivo]);

  return (
    <Row>
      <Col md={12}>
        <div className="form-group">
          <label className="label" htmlFor={'upload-file-input'}>
            {label}
          </label>
          <div className="file-uploader">
            <input
              id="upload-file-input"
              type="file"
              name={name}
              accept=".pfx,.p12"
              multiple={false}
              className={`file-uploader-input`}
              data-title={nomeArquivo}
              onChange={onChangeArquivo}
            />

            <label
              htmlFor="upload-file-input"
              className="input"
              data-title={nomeArquivo}
            />
            <label htmlFor="upload-file-input" className="file-uploader-icon" />
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default UploadCertificado;
