import { FilterUtils } from '@elotech/components';
import axios from 'axios';
import { ServiceUtils } from 'iss-common/utils';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id,titulo' };

const filterAutocomplete = searchParams =>
  searchParams ? FilterUtils.buildFilter(['titulo'])(searchParams) : '';

export const loadGrupoDocumento = (searchParams, page) =>
  axios.get(
    `${CONTEXT_PATH}/documentogrupo?${ServiceUtils.buildSearchParams(
      filterAutocomplete(searchParams)
    )}`,
    {
      params: { ...defaultSort, ...page }
    }
  );

export const removerGrupoDocumento = id =>
  axios.delete(`${CONTEXT_PATH}/documentogrupo/remover/${id}`);

export const loadGrupoDocumentoById = id =>
  axios.get(`${CONTEXT_PATH}/documentogrupo/${id}`);

export const save = grupoDocumento => {
  if (grupoDocumento.id > 0) {
    return axios.put(
      `${CONTEXT_PATH}/documentogrupo/${grupoDocumento.id}`,
      grupoDocumento
    );
  }
  return axios.post(`${CONTEXT_PATH}/documentogrupo`, grupoDocumento);
};
