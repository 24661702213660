import {
  BasicInput,
  Button,
  Chip,
  Col,
  DataPage,
  FAB,
  FabSpeedDial,
  FormikInputDate,
  FormikInputNumber,
  FormikTextArea,
  Loading,
  Row,
  Yup,
  useShowNotification
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { FormikProps } from 'formik';
import { Alert } from 'iss-common/utils';
import React, { ReactNode, useState } from 'react';
import { match, useHistory } from 'react-router';

import ConcursoService from '../../../service/ConcursoService';
import { Concurso } from '../../../types/Concurso';
import ConcursoCupomList from './ConcursoCupomList';
import StatusConcurso from './StatusConcurso';

type Props = {
  match: match<{ id: string }>;
};

const initialValues: Concurso = {
  id: undefined,
  status: 'VIGENTE',
  dataInicio: '',
  dataFim: '',
  termoAdesao: '',
  valorCupom: undefined,
  textoInformativo: '',
  dataSorteio: '',
  statusDescricao: 'Vigente',
  premiacao: '',
  regulamentoBase64: undefined,
  podeEditar: true,
  nomeConcurso: '',
  exibeDownload: false,
  exibeDownloadPlanilhaCupons: false
};

const podeMostrarCupons = (concurso: Concurso): boolean => {
  return (
    concurso.status !== StatusConcurso.VIGENTE &&
    concurso.status !== StatusConcurso.APURANDO
  );
};

const validationSchema = Yup.object().shape({
  dataInicio: Yup.date()
    .required()
    .label('Data início'),
  dataFim: Yup.date()
    .required()
    .label('Data fim')
    .test(
      'dataFinalMaiorQueInicial',
      'A data fim não pode ser menor do que o inicial',
      function(value: Date) {
        const { dataInicio } = this.parent;
        return dataInicio <= value;
      }
    ),
  dataSorteio: Yup.date()
    .required()
    .label('Data sorteio')
    .test(
      'dataSorteioMenorQueFinal',
      'A data sorteio não pode ser menor do que a data fim',
      function(value: Date) {
        const { dataFim } = this.parent;
        return dataFim <= value;
      }
    ),
  premiacao: Yup.string()
    .required()
    .label('Premiação'),
  valorCupom: Yup.number()
    .required()
    .positive()
    .label('Valor cupom'),
  termoAdesao: Yup.string()
    .required()
    .max(4000)
    .label('Termo de adesão'),
  textoInformativo: Yup.string()
    .required()
    .max(4000)
    .label('Texto informativo'),
  nomeConcurso: Yup.string()
    .required()
    .label('Nome Concurso')
});

const ConcursoFormPage: React.FC<Props> = ({ match }) => {
  const fileToByteArray = (file: any) => {
    return new Promise((resolve, reject) => {
      try {
        let reader = new FileReader();
        let fileByteArray: any[] = [];
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt: any) => {
          if (evt.target.readyState === FileReader.DONE) {
            let arrayBuffer = evt.target.result,
              array = new Uint8Array(arrayBuffer);
            for (let byte of array) {
              fileByteArray.push(byte);
            }
          }
          resolve(fileByteArray);
        };
      } catch (e) {
        reject(e);
      }
    });
  };

  const onDownloadArquivo = (concurso: Concurso) => {
    return ConcursoService.downloadRegulamento(concurso.id)
      .then(response =>
        onDownloadArquivoSuccess(
          response,
          'regulamento - ' + concurso.nomeConcurso + '.pdf'
        )
      )
      .catch(onDownloadArquivoError);
  };
  const onDownloadArquivoError = (error: any) => {
    Alert.info(
      {
        title: 'Ocorreu uma falha ao fazer o download do regulamento.'
      },
      error
    );
  };
  const onDownloadArquivoSuccess = (response: any, nomeArquivo: any) => {
    const file = new Blob([response.data], { type: response.data.type });
    const fileURL = URL.createObjectURL(file);
    saveAs(fileURL, nomeArquivo);
  };

  const onDownloadPlanilhaCupons = (concurso: Concurso) => {
    return ConcursoService.downloadPlanilhaCupons(concurso.id)
      .then(response =>
        onDownloadArquivoSuccess(
          response,
          'cupons-' + concurso.nomeConcurso + '.xls'
        )
      )
      .catch(onDownloadArquivoError);
  };

  const [loading, setLoading] = useState(false);
  const [values] = useState<Concurso>(initialValues);
  const showNotification = useShowNotification();
  const history = useHistory();

  const salvarConcurso: any = async (form: FormikProps<Concurso>) => {
    const isValid = await validationSchema.isValid(form.values);

    if (!isValid) {
      form.submitForm();
      return;
    }

    setLoading(true);
    ConcursoService.save(form.values)
      .then((response: AxiosResponse<Concurso>) => {
        showNotification({
          level: 'success',
          message: 'Registro salvo com sucesso.'
        });
        history.replace(`/concurso/consulta`);
        return response.data;
      })
      .catch(error => {
        Alert.error({ title: 'Não foi possível salvar o concurso' }, error);
      })
      .finally(() => setLoading(false));
  };

  const apurarConcurso = (form: FormikProps<Concurso>, reload: () => void) => {
    setLoading(true);
    ConcursoService.apurar(form.values.id)
      .then(async (response: AxiosResponse<Concurso>) => {
        await reload();
      })
      .catch(error => {
        Alert.error({ title: 'Não foi possível apurar o concurso' }, error);
      })
      .finally(() => setLoading(false));
  };

  const gerarCupons = (form: any) => {
    Alert.question({
      title: `Os cupons do concurso ${form.values.nomeConcurso} serão gerados. Notas emitidas após este momento não farão parte do sorteio. Deseja Prosseguir?`
    }).then((result: any) => {
      if (result.value) {
        setLoading(true);
        ConcursoService.generateCupons(form.values.id)
          .then((response: AxiosResponse<Concurso>) => {
            form.setValues(response.data);
          })
          .catch(error => {
            Alert.error(
              { title: 'Não foi possível gerar os cupons para o concurso' },
              error
            );
          })
          .finally(() => setLoading(false));
      }
    });
  };

  const gerarCuponsPlanilha = (form: any) => {
    Alert.question({
      title: `Após geração da planilha, não será mais possível editar dados do concurso. Deseja prosseguir?`
    }).then((result: any) => {
      if (result.value) {
        setLoading(true);
        ConcursoService.publicar(form.values.id)
          .then((response: AxiosResponse<Concurso>) => {
            form.setValues(response.data);
            onDownloadPlanilhaCupons(form.values);
          })
          .catch(error => {
            Alert.error(
              { title: 'Não foi possível publicar os cupons para o concurso' },
              error
            );
          })
          .finally(() => setLoading(false));
      }
    });
  };
  const encerrarConcurso = (form: FormikProps<Concurso>) => {
    Alert.question({
      title: `Após encerrar o concurso, não será mais possível marcar os cupons como sorteado. `,
      text: 'Deseja prosseguir?'
    }).then((result: any) => {
      if (result.value) {
        setLoading(true);
        ConcursoService.encerrar(form.values.id!)
          .then(response => {
            form.setValues(response.data);
          })
          .catch(error => {
            Alert.error(
              { title: 'Não foi possível encerrar o concurso' },
              error
            );
          })
          .finally(() => setLoading(false));
      }
    });
  };

  const load: any = async (id: number) => {
    return await ConcursoService.findById(id);
  };

  return (
    <>
      <Loading loading={loading} />
      <DataPage<Concurso>
        match={match}
        name="concurso-iss"
        icon="trophy"
        load={load}
        validationSchema={validationSchema}
        title="Concurso"
        initialValues={values}
        render={(form, pageActions): ReactNode => (
          <>
            <Row>
              <BasicInput
                label="Nome Concurso"
                name="nomeConcurso"
                fast={false}
                disabled={!form.values.podeEditar}
              />
              <FormikInputDate
                name="dataInicio"
                type="date"
                label="Data Início"
                fast={false}
                disabled={!form.values.podeEditar}
              />
              <FormikInputDate
                name="dataFim"
                type="date"
                label="Data Fim"
                fast={false}
                disabled={!form.values.podeEditar}
              />
              <FormikInputDate
                name="dataSorteio"
                type="date"
                label="Data Sorteio"
                fast={false}
                disabled={!form.values.podeEditar}
              />
            </Row>
            <Row>
              <BasicInput
                label="Premiação"
                name="premiacao"
                fast={false}
                disabled={!form.values.podeEditar}
              />
              <FormikInputNumber
                name="valorCupom"
                label="Valor Cupom"
                fast={false}
                disabled={!form.values.podeEditar}
              />
              <BasicInput
                name="status"
                label="Status"
                fast={false}
                render={props => <Chip value={form.values.statusDescricao} />}
              />
              {form.values.podeEditar && (
                <>
                  <BasicInput
                    id="arquivo-jasper"
                    label="Regulamento"
                    name="regulamento"
                    fast={false}
                    render={({ field }) => (
                      <div className="file-uploader">
                        <input
                          type="file"
                          accept=".pdf"
                          className={`file-uploader-input`}
                          id={field.id}
                          title="Clique ou arraste para anexar"
                          onChange={async (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            form.setFieldValue(
                              'regulamento',
                              event.target.files![0]
                            );
                            form.setFieldValue(
                              'arquivoRegulamento',
                              await fileToByteArray(event.target.files![0])
                            );
                            form.setFieldTouched('regulamento');
                          }}
                        />

                        <label
                          htmlFor={field.id}
                          className="input"
                          data-title={
                            field.value
                              ? field.value.name
                              : 'Clique ou arraste para anexar'
                          }
                        />

                        <label
                          htmlFor={field.id}
                          className="file-uploader-icon"
                        />
                      </div>
                    )}
                  />
                </>
              )}
              {form.values.exibeDownload && (
                <Col className="form-group" md={2}>
                  <label className="label" />
                  <Button
                    id="download-regulamento"
                    onClick={async () => {
                      setLoading(true);
                      await onDownloadArquivo(form.values);
                      setLoading(false);
                    }}
                    className={'inline'}
                  >
                    Download Regulamento
                  </Button>
                </Col>
              )}
              {form.values.exibeDownloadPlanilhaCupons && (
                <Col className="form-group" md={2}>
                  <label className="label" />
                  <Button
                    id="download-planilha-cupons"
                    onClick={async () => {
                      setLoading(true);
                      await onDownloadPlanilhaCupons(form.values);
                      setLoading(false);
                    }}
                    className={'inline'}
                  >
                    Download Planilha Cupons
                  </Button>
                </Col>
              )}
            </Row>
            <Row>
              <FormikTextArea
                size={6}
                label="Termo de Adesão"
                name="termoAdesao"
                fast={false}
                disabled={!form.values.podeEditar}
                maxLength={4000}
              />
              <FormikTextArea
                size={6}
                label="Texto Informativo"
                name="textoInformativo"
                fast={false}
                disabled={!form.values.podeEditar}
                maxLength={4000}
              />
            </Row>
            {podeMostrarCupons(form.values) && (
              <ConcursoCupomList concurso={form.values} />
            )}
            <FabSpeedDial icon="ellipsis-v" title="Ações">
              {form.values.status === StatusConcurso.VIGENTE &&
                form.values.id !== undefined && (
                  <FAB
                    icon="trophy"
                    iconColor="white"
                    title="Apurar Concurso"
                    data-test-id="button-apurar-concurso"
                    onClick={() => apurarConcurso(form, pageActions?.reload)}
                  />
                )}
              {form.values.status === StatusConcurso.APURANDO && (
                <FAB
                  icon="trophy"
                  iconColor="white"
                  title="Gerar Cupons"
                  data-test-id="button-gerar-cupons"
                  onClick={() => gerarCupons(form)}
                />
              )}
              {form.values.status === StatusConcurso.CUPONS_GERADOS && (
                <FAB
                  icon="check"
                  iconColor="white"
                  title="Gerar Cupons em Planilha"
                  data-test-id="button-gerar-planilha"
                  onClick={() => gerarCuponsPlanilha(form)}
                />
              )}
              {form.values.status === StatusConcurso.VIGENTE && (
                <FAB
                  icon="check"
                  iconColor="white"
                  title="Salvar"
                  onClick={() => salvarConcurso(form)}
                />
              )}
              {form.values.status === StatusConcurso.PUBLICADO && (
                <FAB
                  icon="check"
                  iconColor="white"
                  title="Encerrar"
                  onClick={() => encerrarConcurso(form)}
                />
              )}
            </FabSpeedDial>
          </>
        )}
      />
    </>
  );
};

export default ConcursoFormPage;
