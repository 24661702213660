import {
  AutoComplete,
  Col,
  ErrorText,
  FAB,
  InputDate,
  InputInteger,
  Row,
  SectionTitle
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const errorMessages = {
  anoCompetencia: 'Este campo é obrigatório!',
  mesCompetencia: 'Este campo é obrigatório!',
  dataVencimento: 'Este campo é obrigatório!',
  declaracaoMensalTipoEmpresaVencimento: 'Este campo é obrigatório!',
  guiaRecolhimento: 'Este campo é obrigatório!'
};

const VencimentoDividaMensalForm = ({
  save,
  divida,
  error,
  onChangeInputValue,
  loadGuiasRecolhimento,
  onSelect,
  onChangeAnoCompetencia
}) => {
  return (
    <>
      <SectionTitle marginTop="0">Dados de DMS</SectionTitle>
      <form className="form">
        <Row>
          <Col sm={5} md={2}>
            <div className="form-group">
              <label htmlFor="Exercicio">Exercício</label>
              <InputInteger
                className={error.anoCompetencia ? 'error' : ''}
                name="anoCompetencia"
                placeholder="ex: 2017"
                value={divida.anoCompetencia}
                onChange={onChangeAnoCompetencia}
              />
              {error.anoCompetencia && (
                <ErrorText>{errorMessages.anoCompetencia}</ErrorText>
              )}
            </div>
          </Col>
          <Col sm={5} md={2}>
            <div className="form-group">
              <label htmlFor="Mês">Mês</label>
              <InputInteger
                className={error.mesCompetencia ? 'error' : ''}
                name="mesCompetencia"
                placeholder="ex: 02"
                value={divida.mesCompetencia}
                onChange={onChangeInputValue}
              />
              {error.mesCompetencia && (
                <ErrorText>{errorMessages.mesCompetencia}</ErrorText>
              )}
            </div>
          </Col>
          <Col sm={5} md={2}>
            <div className="form-group">
              <label htmlFor="Data de Vencimento">Data de Vencimento</label>
              <InputDate
                name="dataVencimento"
                value={divida.dataVencimento}
                onChange={onChangeInputValue}
                className={error.dataVencimento ? 'error' : ''}
              />
              {error.dataVencimento && (
                <ErrorText>{errorMessages.dataVencimento}</ErrorText>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div className="form-group">
              <label htmlFor="Tipo de Empresa">Tipo de Empresa</label>
              <select
                name="declaracaoMensalTipoEmpresaVencimento"
                className={
                  error.declaracaoMensalTipoEmpresaVencimento ? 'error' : ''
                }
                value={divida.declaracaoMensalTipoEmpresaVencimento}
                onChange={onChangeInputValue}
              >
                <option value="">Selecione</option>
                <option value="DPT">Demais Prestadores e Tomadores</option>
                <option value="IF">Instituição Financeira</option>
              </select>
              {error.declaracaoMensalTipoEmpresaVencimento && (
                <ErrorText>
                  {errorMessages.declaracaoMensalTipoEmpresaVencimento}
                </ErrorText>
              )}
            </div>
          </Col>
          <Col md={4}>
            <div className="form-group">
              <label htmlFor="Guia de Recolhimento">Guia de Recolhimento</label>
              <AutoComplete
                data-test-id="autocomplete-guiaRecolhimento"
                className={error.guiaRecolhimento ? 'error' : ''}
                name="guiaRecolhimento"
                value={divida.guiaRecolhimento}
                onSearch={search => {
                  return loadGuiasRecolhimento(search, divida.anoCompetencia);
                }}
                loadOnClick={false}
                onSelect={onSelect}
                isDisabled={!divida.anoCompetencia}
                placeholder="Pesquise pela descrição da guia"
                getOptionLabel={item =>
                  `${item.guiaRecolhimento} - ${item.descricao}`
                }
              />
              {error.guiaRecolhimento && (
                <ErrorText>{errorMessages.guiaRecolhimento}</ErrorText>
              )}
            </div>
          </Col>
        </Row>
      </form>
      <div className="btn-save">
        <FAB
          data-test-id="buttonSave"
          typeButton="module-color"
          icon="check"
          title="Salvar"
          onClick={() => save(divida)}
        />
      </div>
    </>
  );
};

VencimentoDividaMensalForm.propTypes = {
  save: PropTypes.func,
  divida: PropTypes.object,
  error: PropTypes.object,
  onChangeInputValue: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default VencimentoDividaMensalForm;
