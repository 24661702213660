import {
  ActionButton,
  ActionsGroup,
  Alert,
  Container,
  Field,
  Hint,
  Loading,
  PagedResponse,
  SearchFilter,
  SearchPagination,
  Table,
  formatUtils
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { History } from 'history';
import React, { useState } from 'react';

import { HomologacaoService } from '../../../service';
import { Credenciamento } from '../../../types/Credenciamento';

type Props = {
  history: Pick<History, 'push'>;
};

const searchFields: Field[] = [
  {
    label: 'Número de Inscrição Municipal',
    name: 'agendamento.cadastroGeral.cadastroGeral',
    type: 'NUMBER'
  },
  {
    label: 'Razão Social',
    name: 'agendamento.cadastroGeral.pessoa.nome',
    type: 'STRING'
  },
  {
    label: 'CPF/CNPJ Contribuinte',
    name: 'agendamento.cadastroGeral.pessoa.cnpjCpf',
    type: 'STRING'
  }
];

const URL_HELP =
  'https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-bloqueio-operacoes';

const BloqueioOperacoesListPage: React.FC<Props> = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [credenciamentos, setCredenciamentos] = useState<Credenciamento[]>([]);
  const [pagination, setPagination] = useState<any>(undefined);

  const onSearch = (searchParams: string, page?: number) => {
    setLoading(true);

    HomologacaoService.loadCredenciamentos(searchParams, page)
      .then((response: AxiosResponse<PagedResponse<Credenciamento>>) => {
        const { content, ...pagination } = response.data;
        setCredenciamentos(content);
        setPagination(pagination);
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Não foi possível carregar os agendamentos.'
          },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const onEdit = (item: Credenciamento) => {
    history.push(`/acessos-e-permissoes/bloqueio-operacoes/${item.id}`);
  };

  return (
    <Container
      breadcrumb
      titleRightComponent={
        <a href={URL_HELP} target="_blank" rel="noopener noreferrer">
          <Hint
            classes={`inline clean module-color center-right fa-question-circle`}
          >
            Ajuda?
          </Hint>
        </a>
      }
    >
      <Loading loading={loading} />
      <div className="panel table table-responsive">
        <div className="panel-body">
          <SearchFilter
            fields={searchFields}
            search={search => onSearch(search, undefined)}
          />
          <Table<Credenciamento>
            values={credenciamentos}
            keyExtractor={item => item.id}
          >
            <Table.Column<Credenciamento>
              header="Inscrição Municipal"
              value={item => item.agendamento!.cadastroGeral!.cadastroGeral}
            />
            <Table.Column<Credenciamento>
              header="Nome/Razão Social"
              value={item => item.agendamento!.cadastroGeral!.pessoa!.nome}
            />
            <Table.Column<Credenciamento>
              header="CPF/CNPJ"
              value={item =>
                formatUtils.formatCpfCnpj(
                  item.agendamento!.cadastroGeral!.pessoa!.cnpjCpf
                )
              }
            />
            <Table.Column<Credenciamento>
              data-test-id="buttons-col"
              header=""
              value={item => (
                <ActionsGroup>
                  <ActionButton
                    data-test-id="edit-button"
                    key="edit-button"
                    icon="pencil-alt"
                    label="Editar Bloqueios"
                    onClick={() => onEdit(item)}
                  />
                </ActionsGroup>
              )}
            />
          </Table>
          {pagination && (
            <SearchPagination
              page={pagination}
              searchWithPage={(page: any) => onSearch('', page)}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default BloqueioOperacoesListPage;
