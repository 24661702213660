import { Col, InputInteger } from '@elotech/components';
import React, { Fragment } from 'react';

const GerarArquivosPrestacaoContas = ({
  onChangeInputValue,
  gerarArquivosInputValue
}) => {
  return (
    <Fragment>
      <article className="info warning mt-xs">
        Para substituir um arquivo de prestação de contas, informe os campos
        abaixo.
      </article>
      <Col md={3}>
        <div className="form-group">
          <label htmlFor="">Nº Remessa correção</label>
          <InputInteger
            name="numeroRemessaCorrecao"
            value={gerarArquivosInputValue.numeroRemessaCorrecao}
            onChange={onChangeInputValue}
          />
        </div>
      </Col>
      <Col md={3}>
        <div className="form-group">
          <label htmlFor="">Nº Lote correção</label>
          <InputInteger
            name="numeroLoteCorrecao"
            value={gerarArquivosInputValue.numeroLoteCorrecao}
            onChange={onChangeInputValue}
          />
        </div>
      </Col>
    </Fragment>
  );
};
export default GerarArquivosPrestacaoContas;
