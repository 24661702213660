import { ActionButton, SectionTitle } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import AcessosList from './AcessosList';
import Arquivo from './Arquivo';
import ArquivosList from './ArquivosList';
import DadosEmpresariais from './DadosEmpresariais';

const DadosGerais = ({
  disabled,
  historicoAgendamento,
  onChangeDadosEmpresariais,
  error,
  errorArquivo,
  tiposImagens,
  visualizarAcessos,
  visualizarArquivos,
  showFormAcessos,
  showFormArquivos,
  excluirArquivo,
  downloadArquivo,
  arquivoEmpresa,
  onUploadFile,
  onChangeTipoImagem
}) => {
  return (
    <Fragment>
      <SectionTitle>Dados Empresariais</SectionTitle>
      <DadosEmpresariais
        disabled={disabled}
        historicoAgendamento={historicoAgendamento}
        onChangeDadosEmpresariais={onChangeDadosEmpresariais}
        error={error}
      />

      <SectionTitle hasButton={true}>
        Arquivos
        <ActionButton
          key="view-button"
          icon="eye"
          label="Visualizar Arquivos"
          onClick={visualizarArquivos}
        />
      </SectionTitle>
      {showFormArquivos ? (
        <Arquivo
          disabled={disabled}
          arquivo={arquivoEmpresa}
          tiposImagens={tiposImagens}
          errorArquivo={errorArquivo}
          onUploadFile={onUploadFile}
          onChangeTipoImagem={onChangeTipoImagem}
        />
      ) : (
        ''
      )}
      {showFormArquivos ? (
        <ArquivosList
          disabled={disabled}
          arquivos={historicoAgendamento.arquivos}
          excluirArquivo={excluirArquivo}
          downloadArquivo={downloadArquivo}
        />
      ) : (
        ''
      )}

      <SectionTitle hasButton={true}>
        Acessos
        <ActionButton
          key="view-button"
          icon="eye"
          label="Visualizar acessos"
          onClick={visualizarAcessos}
        />
      </SectionTitle>
      {showFormAcessos ? (
        <AcessosList acessos={historicoAgendamento.acessos} />
      ) : (
        ''
      )}
    </Fragment>
  );
};

DadosGerais.propTypes = {
  historicoAgendamento: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  errorArquivo: PropTypes.object.isRequired,
  visualizarAcessos: PropTypes.func.isRequired,
  showFormAcessos: PropTypes.bool.isRequired,
  visualizarArquivos: PropTypes.func.isRequired,
  showFormArquivos: PropTypes.bool.isRequired,
  arquivoEmpresa: PropTypes.object.isRequired,
  tiposImagens: PropTypes.array,
  onChangeTipoImagem: PropTypes.func.isRequired
};

export default DadosGerais;
