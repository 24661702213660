import './textarea.css';

import {
  Alert,
  BasicInput,
  Button,
  Col,
  Container,
  FAB,
  Hint,
  Loading,
  NotificationActions,
  Row,
  Yup
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { Formik, FormikProps } from 'formik';
import { History } from 'history';
import { Delta, Sources } from 'quill';
import React, { useEffect, useRef, useState } from 'react';
import { Notification } from 'react-notification-system';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { match } from 'react-router';

import {
  TemplateCampoEmailService,
  TemplateEmailService
} from '../../../service';
import { TemplateEmail } from '../../../types/TemplateEmail';
import { TemplateEmailCampo } from '../../../types/TemplateEmailCampo';
import { reactQuillFormats, reactQuillModules } from './ConfiguracaoReactQuill';

type Props = {
  history: Pick<History, 'replace'>;
  match: match<{ id: string }>;
  showNotification(notification: Notification): void;
};

const initialValues: TemplateEmail = {
  id: undefined,
  assunto: undefined,
  template: undefined
};

const URL_HELP_MENSAGEM_TEMPLATE_EMAIL =
  'https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-mensagem-template-email';

const MensagemTemplateEmail: React.FC<Props> = ({
  match,
  history,
  showNotification
}) => {
  const [loading, setLoading] = useState(false);
  const [emailCampo, setEmailCampo] = useState('');
  const [mensagemTemplateEmail, setMensagemTemplateEmail] = useState<
    TemplateEmail
  >(initialValues);
  const [templateEmailCampos, setTemplateEmailCampos] = useState<
    TemplateEmailCampo[]
  >([]);
  const reactQuillRef = useRef<ReactQuill>(null);

  const onSave = async (templaEmail: TemplateEmail) => {
    TemplateEmailService.save(templaEmail)
      .then(() => {
        showNotification({
          level: 'success',
          message: 'Template de email salvo com sucesso.'
        });
        history.replace('/configuracoes/template-email');
      })
      .catch(() => {
        Alert.error({
          title: 'Não foi possível salvar o template de email.'
        });
      });
  };

  const onAddEmailCampo = () => {
    if (reactQuillRef && reactQuillRef.current) {
      var range = reactQuillRef.current!.getEditor()!.getSelection();
      let position = range ? range.index : 0;
      reactQuillRef.current!.getEditor()!.insertText(position, emailCampo);
    }
  };

  const loadTemplateEmail = (id: number) => {
    if (id) {
      return TemplateEmailService.loadById(id)
        .then((response: AxiosResponse<TemplateEmail>) => {
          setMensagemTemplateEmail(response.data);
        })
        .catch((error: any) => {
          Alert.error(
            { title: 'Não foi possível carregar a solicitação' },
            error
          );
        });
    }
  };

  const loadTemplateCamposEmail = () => {
    return TemplateCampoEmailService.loadTemplateCampoEmail()
      .then((response: AxiosResponse<TemplateEmailCampo[]>) => {
        setTemplateEmailCampos(response.data);
      })
      .catch((error: any) => {
        Alert.error(
          { title: 'Não foi possível carregar a solicitação' },
          error
        );
      });
  };

  useEffect(() => {
    setLoading(true);

    Promise.all([
      loadTemplateEmail(+match.params.id),
      loadTemplateCamposEmail()
    ]).then(() => setLoading(false));
  }, [match.params.id]);

  return (
    <Container
      breadcrumb
      titleRightComponent={
        <a
          href={URL_HELP_MENSAGEM_TEMPLATE_EMAIL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Hint
            classes={`inline clean module-color center-right fa-question-circle`}
          >
            Ajuda?
          </Hint>
        </a>
      }
    >
      <Loading loading={loading} />

      <Formik
        enableReinitialize
        data-test-id="formik-form-template-email"
        initialValues={mensagemTemplateEmail}
        onSubmit={onSave}
        validationSchema={Yup.object().shape({
          assunto: Yup.string()
            .label('Assunto')
            .max(50)
            .required(),
          template: Yup.string()
            .label('Template')
            .required()
        })}
        render={(formProps: FormikProps<TemplateEmail>) => (
          <>
            <Row>
              <BasicInput name="assunto" label="Assunto" size={12} />
            </Row>

            <Row>
              <BasicInput
                name="template"
                label="Template"
                render={({ field }) => (
                  <ReactQuill
                    theme={'snow'}
                    ref={reactQuillRef}
                    modules={reactQuillModules}
                    formats={reactQuillFormats}
                    value={field.value}
                    onChange={(
                      content: any,
                      delta: Delta,
                      source: Sources,
                      editor
                    ) => {
                      formProps.setFieldValue('template', editor.getHTML());
                    }}
                  />
                )}
                size={12}
              />
            </Row>

            <Row>
              <Col sm={3}>
                <div className="form-group">
                  <label htmlFor="emailCampos" className="label">
                    Email campos
                  </label>
                  <select
                    name="emailCampos"
                    onChange={e => setEmailCampo(e.target.value)}
                  >
                    <option value="">Selecione</option>
                    {templateEmailCampos.map(item => (
                      <option key={item.id} value={item.campo}>
                        {item.campo}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>

              <Col sm={2}>
                <div className="form-group">
                  <Button
                    className="inline mt-sm"
                    iconPosition="left"
                    onClick={onAddEmailCampo}
                    type="submit"
                  >
                    <i className="fa fa-plus" />
                    {'Adicionar'}
                  </Button>
                </div>
              </Col>
            </Row>

            <div className="btn-save">
              <FAB
                data-test-id="btn-save"
                icon="check"
                onClick={formProps.submitForm}
                title="Salvar"
              />
            </div>
          </>
        )}
      />
    </Container>
  );
};

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const connectedComponent = connect(
  null,
  mapDispatchToProps
)(MensagemTemplateEmail);

export { connectedComponent as default, MensagemTemplateEmail };
