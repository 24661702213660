import {
  BasicInput,
  Button,
  Col,
  Container,
  FormikAutocomplete,
  FormikInputDate,
  Loading,
  RadioButton,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import { Alert } from 'iss-common/utils';
import moment from 'moment';
import React, { useState } from 'react';

import {
  tipoRelatorioGerencialIssCodigo,
  tipoRelatorioGerencialIssSelect
} from '../../enums/TipoRelatorioGerencialIss';
import { RelatorioGerencialISSService, ServicoService } from '../../service';
import { FiltroRelatorioGerencialISSDTO } from '../../types';
import { Servico } from '../../types/Servico';

const initialValues: FiltroRelatorioGerencialISSDTO = {
  tipoRelatorio:
    tipoRelatorioGerencialIssCodigo.SERVICOS_PRESTADOS_EMPRESAS_FORA_MUNICIPIO
};

const RelatorioGerencialISSFormPage: React.FC = () => {
  const schema = Yup.object().shape({
    periodoInicial: Yup.string()
      .required()
      .label('Período Inicial'),
    periodoFinal: Yup.string()
      .label('Período Final')
      .required()
      .test(
        'dataFinalMenorQueInicial',
        'Período Final maior que Período Inicial!',
        function(value) {
          if (value === undefined) {
            return true;
          }
          const { periodoInicial } = this.parent;
          return periodoInicial <= value;
        }
      )
      .test(
        'intervaloMaiorQueUmAno',
        'Período não pode ser igual ou superior a um ano!',
        function(value) {
          const { periodoInicial } = this.parent;
          if (value === undefined || periodoInicial > value) {
            return true;
          }

          return moment(value).diff(moment(periodoInicial), 'years') === 0;
        }
      ),
    tipoRelatorio: Yup.string()
      .label('Tipo Relatório')
      .required()
  });

  const [loading, setLoading] = useState(false);
  const [
    desabilitaFiltrosTipoRelatorioServicosPrestados,
    setDesabilitaFiltrosTipoRelatorioServicosPrestados
  ] = useState(true);

  const onFilter = async (filtro: FiltroRelatorioGerencialISSDTO) => {
    setLoading(true);
    await RelatorioGerencialISSService.generateReportGerencialISS(filtro)
      .then((response: any) => {
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);
      })
      .catch((error: any) => {
        Alert.error({ title: `Ocorreu um erro ao gerar o relatório` }, error);
      })
      .finally(() => setLoading(false));
  };

  const onChangeTipoRelatorio = (
    event: React.ChangeEvent<HTMLInputElement>,
    formProps: FormikProps<FiltroRelatorioGerencialISSDTO>
  ) => {
    const tipoRelatorio = event.target.value;
    setDesabilitaFiltrosTipoRelatorioServicosPrestados(false);
    if (
      tipoRelatorio !==
        tipoRelatorioGerencialIssCodigo.SERVICOS_COM_MAIORES_ARRECADACOES &&
      tipoRelatorio !==
        tipoRelatorioGerencialIssCodigo.SERVICOS_COM_MAIORES_DECLARACOES
    ) {
      formProps.setFieldValue('servico', undefined, true);
      setDesabilitaFiltrosTipoRelatorioServicosPrestados(true);
    }

    formProps.setFieldValue('tipoRelatorio', tipoRelatorio, true);
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <SectionTitle>Filtro</SectionTitle>
      <Col md={12}>
        <Formik<FiltroRelatorioGerencialISSDTO>
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onFilter}
          render={(formProps: FormikProps<FiltroRelatorioGerencialISSDTO>) => (
            <>
              <Row>
                <FormikInputDate
                  size={2}
                  label="Período Inicial"
                  name="periodoInicial"
                />
                <FormikInputDate
                  size={2}
                  label="Período Final"
                  name="periodoFinal"
                />
              </Row>
              <Row>
                <BasicInput
                  data-test-id="tipoRelatorio"
                  size={12}
                  name="tipoRelatorio"
                  label="Tipo Relatório"
                  render={({ field }) => (
                    <RadioButton
                      {...field}
                      options={tipoRelatorioGerencialIssSelect}
                      selectedValue={field.value}
                      onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
                        onChangeTipoRelatorio(value, formProps)
                      }
                    />
                  )}
                />
              </Row>
              <Row>
                <FormikAutocomplete<Servico>
                  name="servico"
                  label="Serviço"
                  onSearch={ServicoService.loadServicos}
                  getOptionLabel={value =>
                    `${value.codigo} - ${value.descricao}`
                  }
                  getOptionValue={value => value.id}
                  size={9}
                  fast={false}
                  disabled={desabilitaFiltrosTipoRelatorioServicosPrestados}
                />
              </Row>
              <div className="form-group">
                <Button
                  className="inline"
                  iconPosition="left"
                  onClick={formProps.submitForm}
                  type="submit"
                >
                  <i className="fa fa-print" />
                  Imprimir
                </Button>
              </div>
            </>
          )}
        />
      </Col>
    </Container>
  );
};

export default RelatorioGerencialISSFormPage;
