import { FAB, InputInteger, SectionTitle } from '@elotech/components';
import { Col, ErrorText, Row } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const errorMessages = {
  acesso: 'Este campo é obrigatório!',
  nome: 'Este campo é obrigatório!',
  cpf: 'Este campo é obrigatório!',
  novaSenha: 'Este campo é obrigatório!',
  confirmarSenha: 'Este campo é obrigatório!',
  senhasIguais: 'As senhas devem ser iguais!'
};

const ChangePassword = props => {
  const {
    onSave,
    onBack,
    error,
    passwordData,
    onChangeInputValue,
    inputConfirmaSenhaJaInformado,
    validacaoAdicional
  } = props;
  return (
    <>
      <SectionTitle marginTop="0">Alterar Senha</SectionTitle>
      <form className="form">
        <Row>
          <Col md={1}>
            <div className="form-group">
              <label htmlFor="id">Acesso</label>
              <InputInteger
                readOnly={true}
                className={error.id ? 'error' : ''}
                name="id"
                placeholder=""
                value={passwordData.id}
                onChange={onChangeInputValue}
              />
              {error.id && <ErrorText>{errorMessages.acesso}</ErrorText>}
            </div>
          </Col>
          <Col md={5}>
            <div className="form-group">
              <label htmlFor="nome">Nome</label>
              <input
                readOnly={true}
                className={error.nome ? 'error' : ''}
                type="text"
                name="nome"
                placeholder="ex: Celço Chiles da Roxa Neto"
                value={passwordData.nome}
                onChange={onChangeInputValue}
              />
              {error.nome && <ErrorText>{errorMessages.nome}</ErrorText>}
            </div>
          </Col>
          <Col md={5}>
            <div className="form-group">
              <label htmlFor="cpf">CPF</label>
              <input
                readOnly={true}
                className={error.cpf ? 'error' : ''}
                type="text"
                name="cpf"
                placeholder="ex: 098.203.201-54"
                value={passwordData.cpf}
                onChange={onChangeInputValue}
              />
              {error.cpf && <ErrorText>{errorMessages.cpf}</ErrorText>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <div className="form-group">
              <label htmlFor="novaSenha">Nova Senha</label>
              <input
                className={error.novaSenha ? 'error' : ''}
                type="password"
                name="novaSenha"
                placeholder=""
                value={passwordData.novaSenha}
                onChange={onChangeInputValue}
                autoFocus={true}
                autoComplete="off"
              />
              {error.novaSenha && (
                <ErrorText>{errorMessages.novaSenha}</ErrorText>
              )}
            </div>
          </Col>
          <Col md={3}>
            <div className="form-group">
              <label htmlFor="confirmarSenha">Confirmar Nova Senha</label>
              <input
                className={error.confirmarSenha ? 'error' : ''}
                type="password"
                name="confirmarSenha"
                placeholder=""
                value={passwordData.confirmarSenha}
                onChange={onChangeInputValue}
                autoComplete="off"
              />
              {error.confirmarSenha && (
                <ErrorText>{errorMessages.confirmarSenha}</ErrorText>
              )}
              {!error.confirmarSenha &&
                inputConfirmaSenhaJaInformado &&
                validacaoAdicional.senhasIguais && (
                  <ErrorText>{errorMessages.senhasIguais}</ErrorText>
                )}
            </div>
          </Col>
        </Row>
      </form>
      <div className="btn-save">
        <FAB
          data-test-id="buttonSave"
          typeButton="positive"
          iconColor="white"
          title="Salvar"
          onClick={onSave}
        />
        <FAB
          data-test-id="buttonCancelar"
          typeButton="negative"
          iconColor="white"
          title="Cancelar"
          onClick={onBack}
        />
      </div>
    </>
  );
};

ChangePassword.propTypes = {
  onSave: PropTypes.func.isRequired,
  error: PropTypes.object,
  passwordData: PropTypes.object.isRequired,
  onChangeInputValue: PropTypes.func.isRequired
};

export default ChangePassword;
