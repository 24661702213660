import { Container, NotificacoesFrame } from '@elotech/components';
import React from 'react';

type Props = {};

const NotificacaoPage: React.FC<Props> = () => {
  return (
    <Container breadcrumb>
      <NotificacoesFrame />
    </Container>
  );
};

export default NotificacaoPage;
