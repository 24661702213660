import { FilterUtils, PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { Lei } from '../types/Lei';
import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };

export const loadLeis = (search: any, page: any = 50) =>
  axios.get(`${CONTEXT_PATH}/lei`, {
    params: {
      search: FilterUtils.buildDefaultFilter()(search),
      ...defaultSort,
      ...page
    }
  });

export const leiAutoComplete = (
  searchParams: string
): AxiosPromise<PagedResponse<Lei>> =>
  axios.get(`${CONTEXT_PATH}/lei`, {
    params: {
      search: FilterUtils.buildNameFilter('numero', 'descricao')(searchParams),
      fields: 'descricao,numero,id'
    }
  });
