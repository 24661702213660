import { ActionButton, formatUtils } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import ServicosDetailList from './ServicosDetailList';

export class AtividadesListRow extends Component {
  state = {
    expanded: false
  };

  toggleExpandedServices = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  renderServicosDetailList = () => {
    const { expanded } = this.state;
    const { expandedAllServices, atividade } = this.props;
    if (expanded || expandedAllServices) {
      return (
        <tr>
          <td colSpan="5">
            <ServicosDetailList servicos={atividade.servicos} />
          </td>
        </tr>
      );
    }
  };

  renderActionButton = () => {
    const {
      atividade,
      disabled,
      index,
      atribuirCnaePrincipal,
      autorizarOuNaoCnae,
      excluirCnae
    } = this.props;
    let links = [
      {
        label: 'Não Autorizar',
        onClick: () => autorizarOuNaoCnae(index, false)
      },
      {
        label: 'Autorizar',
        onClick: () => autorizarOuNaoCnae(index, true)
      }
    ];

    if (disabled) {
      return '';
    } else {
      return (
        <div className="btn-actions">
          <div className="btn-actions-inner">
            {atividade.principal === 'S' ? (
              ''
            ) : (
              <ActionButton
                data-test-id="buttonPrincipal"
                key="principal-button"
                icon="star"
                label="Marcar como Principal"
                onClick={() => {
                  atribuirCnaePrincipal(index);
                }}
              />
            )}
            <ActionButton
              data-test-id="buttonOptionsAutorizarNaoAutorizar"
              key="autorizarNaoAutorizar-button"
              icon="file-alt"
              links={links}
            />
            <ActionButton
              data-test-id="buttonServices"
              key="service-button"
              icon="folder"
              label="Serviços"
              onClick={this.toggleExpandedServices}
            />
            <ActionButton
              disabled={disabled}
              data-test-id="buttonExclude"
              key="exclude-button"
              icon="trash-alt"
              label="Excluir"
              onClick={() => excluirCnae(index)}
            />
          </div>
        </div>
      );
    }
  };

  render() {
    const { atividade } = this.props;

    return (
      <Fragment>
        <tr>
          <td>{formatUtils.formatCnae(atividade.codigo)}</td>
          <td>{atividade.descricao}</td>
          <td>
            {atividade.habilitado === 'S' ? (
              <div className="positive center status">Autorizado</div>
            ) : (
              <div className="negative center status"> Não Autorizado</div>
            )}
          </td>
          <td>{atividade.principal === 'S' ? 'Principal' : ''}</td>
          <td>{this.renderActionButton()}</td>
        </tr>
        {this.renderServicosDetailList()}
      </Fragment>
    );
  }
}

AtividadesListRow.propTypes = {
  index: PropTypes.number.isRequired,
  atividade: PropTypes.object.isRequired,
  autorizarOuNaoCnae: PropTypes.func.isRequired,
  excluirCnae: PropTypes.func.isRequired,
  atribuirCnaePrincipal: PropTypes.func.isRequired
};

export default AtividadesListRow;
