import { EnumValue } from '../types';

export type TipoRelatorioGerencialIss = EnumValue;

export const tipoRelatorioGerencialIssCodigo = {
  SERVICOS_PRESTADOS_EMPRESAS_FORA_MUNICIPIO: 'SPFM',
  SERVICOS_PRESTADOS_EMPRESAS_LOCAIS: 'SPEL',
  SERVICOS_PRESTADOS_OUTROS_MUNICIPIOS_EMPRESAS_LOCAIS: 'SPOM',
  SERVICOS_COM_MAIORES_DECLARACOES: 'SCMD',
  SERVICOS_COM_MAIORES_ARRECADACOES: 'SCMA'
};

export const tipoRelatorioGerencialIss: TipoRelatorioGerencialIss[] = [
  {
    codigo: 'SPFM',
    descricao: 'Serviços Prestados Por Empresas De Fora Do Município'
  },
  { codigo: 'SPEL', descricao: 'Serviços Prestados Por Empresas Locais' },
  {
    codigo: 'SPOM',
    descricao: 'Serviços Prestados Em Outros Municípios Por Empresas Locais'
  },
  { codigo: 'SCMD', descricao: 'Serviços Com Maiores Declarações' },
  { codigo: 'SCMA', descricao: 'Serviços Com Maiores Arrecadações' }
];

export const tipoRelatorioGerencialIssSelect = tipoRelatorioGerencialIss.map(
  tipo => ({
    key: tipo.codigo,
    value: tipo.codigo,
    label: tipo.descricao
  })
);
