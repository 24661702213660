import {
  Alert,
  Button,
  Checkbox,
  Col,
  Container,
  FormikAutocomplete,
  FormikInputDate,
  FormikInputNumber,
  Loading,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import download from 'downloadjs';
import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';

import {
  PessoaService,
  RelatorioValorEstimadoValorPrestadoService
} from '../../service';
import { Pessoa } from '../../types';
import { RelatorioEmpresasLiberadasEmissaoNfseDTO } from '../../types/RelatorioEmpresasLiberadasEmissaoNfseDTO';

const RelatorioComparativoValorEstimadoValorPrestadoFormPage: React.FC = () => {
  const [valorEstimado, setValorEstimado] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    dataInicial: Yup.date()
      .label('Data inicial')
      .required(),
    dataFinal: Yup.date()
      .label('Data final')
      .required()
      .test(
        'dataFinalMaiorQueInicial',
        'A data final não pode ser menor do que a data inicial',
        function(value: Date) {
          const { dataInicial } = this.parent;
          return dataInicial <= value;
        }
      )
      .test(
        'datasExcedemAno',
        'A data inicial e a data final devem estar no mesmo ano',
        function(value: Date) {
          const { dataInicial } = this.parent;
          const dataInic = new Date(dataInicial);
          const dataFin = new Date(value);
          return dataInic.getFullYear() === dataFin.getFullYear();
        }
      )
  });

  const [loading, setLoading] = useState(false);

  const onChangeDeclaracao = (
    state: boolean,
    formProps: FormikProps<RelatorioEmpresasLiberadasEmissaoNfseDTO>
  ) => {
    formProps.setFieldValue('valorIssEstimado', null);
    setValorEstimado(!state);
  };

  function dataURLtoFile(dataurl: any, filename: any) {
    var bstr = atob(dataurl),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename);
  }

  const onSubmit = async (values: any) => {
    setLoading(true);
    await RelatorioValorEstimadoValorPrestadoService.carregarRelatorioValorEstimadoValorPrestado(
      values
    )
      .then((response: any) => {
        const data = response.data;
        const filename = `Relatorio Valor Estimado X Valor Prestado.xls`;

        download(dataURLtoFile(data, filename), filename);
      })
      .catch((error: any) => {
        Alert.error({ title: `Ocorreu um erro ao gerar o relatório.` }, error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container breadcrumb>
      <Loading loading={loading} />
      <SectionTitle>Filtro</SectionTitle>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={{}}
        onSubmit={onSubmit}
        render={(
          form: FormikProps<RelatorioEmpresasLiberadasEmissaoNfseDTO>
        ) => (
          <>
            <Row>
              <FormikAutocomplete
                data-test-id="autocomplete-pessoa"
                name="pessoa"
                placeholder="Pesquisar por Cnpj/Cpf ou Nome"
                label="Pessoa/Empresa (CPF/CNPJ)"
                onSearch={PessoaService.search}
                size={4}
                getOptionLabel={(value: Pessoa) =>
                  `${value?.cnpjCpf} - ${value.nome}`
                }
                getOptionValue={(value: Pessoa) => value.id}
              />
              <FormikInputDate
                size={2}
                label="Data Inicial"
                name="dataInicial"
              />
              <FormikInputDate size={2} label="Data Final" name="dataFinal" />
              {valorEstimado && (
                <FormikInputNumber
                  label="Valor estimado"
                  name="valorIssEstimado"
                  size={2}
                  prefix="R$"
                  allowNegative={false}
                />
              )}
            </Row>
            <Row>
              <Col md={3}>
                <Checkbox
                  className="form-group"
                  name="valorEstimado"
                  id="valorEstimado"
                  checked={valorEstimado}
                  onChange={() => onChangeDeclaracao(valorEstimado, form)}
                  label="Informar valor estimado"
                ></Checkbox>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="form-group">
                  <Button onClick={form.submitForm} type="submit">
                    Imprimir
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      />
    </Container>
  );
};

export default RelatorioComparativoValorEstimadoValorPrestadoFormPage;
