import { FormattedDateTime, Icon } from '@elotech/components';
import React from 'react';

import { SolicitacaoRPS } from '../../../types/SolicitacaoRPS';
import { InfoBlock } from './InfoBlock';
import {
  APROVADO,
  PENDENTE,
  REJEITADO,
  getLabelSituacaoSolicitacaoRps
} from './SolicitacaoRpsTipos';

type SituacaoProps = {
  solicitacao: SolicitacaoRPS;
  leftBar?: boolean;
};

const SituacaoSolicitacao: React.FC<SituacaoProps> = ({
  solicitacao,
  leftBar = false
}) => (
  <InfoBlock active={leftBar}>
    <div style={{ marginRight: '5px' }}>
      {solicitacao.situacao === PENDENTE && <Icon icon="clock" warning />}
      {solicitacao.situacao === APROVADO && <Icon icon="thumbs-up" positive />}
      {solicitacao.situacao === REJEITADO && (
        <Icon icon="thumbs-down" danger />
      )}{' '}
    </div>

    <div>
      {getLabelSituacaoSolicitacaoRps(solicitacao.situacao)}

      {solicitacao.situacao !== PENDENTE && (
        <div>
          {solicitacao.nomeAuditor
            ? `Por ${solicitacao.nomeAuditor} em `
            : 'Em '}
          <FormattedDateTime value={solicitacao.dataParecerAuditor} />
        </div>
      )}
      {solicitacao.situacao === REJEITADO && (
        <div>Motivo: {solicitacao.justificativa}</div>
      )}
    </div>
  </InfoBlock>
);

export default SituacaoSolicitacao;
