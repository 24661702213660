import {
  ActionButton,
  ActionsGroup,
  Alert,
  Chip,
  Container,
  Field,
  FormattedDate,
  Hint,
  Loading,
  PagedResponse,
  SearchFilter,
  SearchPagination,
  Table,
  formatUtils
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { History } from 'history';
import React, { useState } from 'react';

import { DenunciaService } from '../../service';
import { Denuncia, TipoDenuncia } from '../../types/Denuncia';
import { SituacoesDenuncia, getLabelSituacao } from './SituacaoDenuncia';

type Props = {
  history: Pick<History, 'push'>;
};

const URL_HELP = '.';

const searchFields: Field[] = [
  {
    label: 'Código',
    name: 'codigo',
    type: 'STRING'
  },
  {
    label: 'Data da denúncia',
    name: 'dataCadastro',
    type: 'DATE'
  },
  {
    label: 'Empresa Denunciada',
    name: 'prestador.pessoa.nome',
    type: 'STRING'
  },
  {
    label: 'Número do RPS',
    name: 'numeroRPS',
    type: 'NUMBER'
  },
  {
    label: 'Tipo',
    name: 'tipo.id',
    type: 'AUTOCOMPLETE',
    getOptionValue: (value: TipoDenuncia) => value.id,
    getOptionLabel: (value: TipoDenuncia) => value.descricao!,
    loadOptions: (search: string) =>
      DenunciaService.tiposDenunciasAutoComplete(search)
  },
  {
    label: 'Situação',
    name: 'situacao',
    type: 'ENUM',
    options: SituacoesDenuncia.map(situacao => ({
      name: situacao.situacao,
      descricao: situacao.label
    }))
  }
];

const DenunciaListPage: React.FC<Props> = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [denuncias, setDenuncias] = useState<Denuncia[]>([]);
  const [pagination, setPagination] = useState<any>(undefined);

  const onSearch = (searchParams: string, page?: number) => {
    setLoading(true);

    DenunciaService.loadDenuncias(searchParams, page)
      .then((response: AxiosResponse<PagedResponse<Denuncia>>) => {
        const { content, ...pagination } = response.data;
        setDenuncias(content);
        setPagination(pagination);
      })
      .catch((error: any) => {
        Alert.error(
          {
            title: 'Não foi possível carregar as denúncias.'
          },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const onEdit = (item: Denuncia) => {
    history.push(`/denuncias/analisar/${item.id}`);
  };

  return (
    <Container
      breadcrumb
      titleRightComponent={
        <a href={URL_HELP} target="_blank" rel="noopener noreferrer">
          <Hint
            classes={`inline clean module-color center-right fa-question-circle`}
          >
            Ajuda?
          </Hint>
        </a>
      }
    >
      <Loading loading={loading} />
      <div className="panel table table-responsive">
        <div className="panel-body">
          <SearchFilter
            fields={searchFields}
            search={search => onSearch(search, undefined)}
          />
          <Table<Denuncia>
            values={denuncias}
            keyExtractor={item => `${item.id}`}
          >
            <Table.Column<Denuncia>
              header="Código"
              value={item => item.codigo}
            />
            <Table.Column<Denuncia>
              data-test-id="table-row"
              header="CNPJ do Denunciado"
              value={item =>
                formatUtils.formatCpfCnpj(item.prestador.pessoa.cnpjCpf)
              }
            />

            <Table.Column<Denuncia>
              data-test-id="table-row"
              header="Razão Social do Denunciado"
              value={item => item.prestador.pessoa.nome}
            />

            <Table.Column<Denuncia>
              data-test-id="table-row"
              header="Tipo"
              value={item => item.tipo!.descricao}
            />

            <Table.Column<Denuncia>
              data-test-id="table-row"
              header="RPS"
              value={item =>
                item.numeroRPS && (
                  <span>
                    {item.numeroRPS} de{' '}
                    <FormattedDate value={item.dataEmissaoRPS} />
                  </span>
                )
              }
            />

            <Table.Column<Denuncia>
              data-test-id="table-data"
              header="Data da Denúncia"
              value={item => <FormattedDate value={item.dataCadastro} />}
            />

            <Table.Column<Denuncia>
              data-test-id="table-row"
              header="Situação"
              value={item => (
                <Chip inline value={getLabelSituacao(item!.situacao)} />
              )}
            />

            <Table.Column<Denuncia>
              data-test-id="buttons-col"
              header=""
              value={item => (
                <ActionsGroup>
                  <ActionButton
                    data-test-id="analyze-button"
                    key="analyse-button"
                    icon="eye"
                    label="Ver Denúncia"
                    onClick={() => onEdit(item)}
                  />
                </ActionsGroup>
              )}
            />
          </Table>
          {pagination && (
            <SearchPagination
              page={pagination}
              searchWithPage={(page: any) => onSearch('', page)}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default DenunciaListPage;
