import { Alert, BlockDash, Container } from '@elotech/components';
import { AxiosResponse } from 'axios';
import { History } from 'history';
import React, { useEffect, useState } from 'react';

import { DashboardService } from '../../service';

type Props = {
  history: Pick<History, 'push'>;
};

const Home: React.FC<Props> = ({ history }) => {
  const [
    loadingCredenciamentosPendente,
    setLoadingCredenciamentosPendente
  ] = useState(false);
  const [credenciamentosPendente, setCredenciamentosPendente] = useState(0);

  const [
    loadingCancelamentoNotaPendente,
    setLoadingCancelamentoNotaPendente
  ] = useState(false);
  const [cancelamentoNotaPendente, setCancelamentoNotaPendente] = useState(0);

  const [
    loadingSubstituicaoNotaPendente,
    setLoadingSubstituicaoNotaPendente
  ] = useState(false);
  const [substituicaoNotaPendente, setSubstituicaoNotaPendente] = useState(0);

  const [
    loadingCancelamentoDmsPendente,
    setLoadingCancelamentoDmsPendente
  ] = useState(false);
  const [cancelamentoDmsPendente, setCancelamentoDmsPendente] = useState(0);

  const [
    loadingCancelamentoGuiaAvulsaPendente,
    setLoadingCancelamentoGuiaAvulsaPendente
  ] = useState(false);
  const [
    cancelamentoGuiaAvulsaPendente,
    setCancelamentoGuiaAvulsaPendente
  ] = useState(0);

  useEffect(() => {
    obterQuantidadeCredenciamentosPendentes();
    obterQuantidadeSolicitacaoCancelamentoNotaPendentes();
    obterQuantidadeSolicitacaoSubstituicaoNotaPendentes();
    obterQuantidadeSolicitacaoCancelamentoDmsPendentes();
    obterQuantidadeSolicitacaoCancelamentoGuiasAvulsasPendentes();
  }, []);

  const obterQuantidadeCredenciamentosPendentes = () => {
    setLoadingCredenciamentosPendente(true);
    DashboardService.credenciamentosPendentesAnalise()
      .then((response: AxiosResponse<number>) => {
        setCredenciamentosPendente(response.data);
      })
      .catch(error => {
        Alert.error(
          {
            title:
              'Não foi possível obter a quantidade de credenciamentos pendentes de análise.'
          },
          error
        );
      })
      .finally(() => setLoadingCredenciamentosPendente(false));
  };

  const onCredenciamento = () =>
    history.push('/acessos-e-permissoes/credenciamento');

  const obterQuantidadeSolicitacaoCancelamentoNotaPendentes = () => {
    setLoadingCancelamentoNotaPendente(true);
    DashboardService.cancelamentosNotaFiscal()
      .then((response: AxiosResponse<number>) => {
        setCancelamentoNotaPendente(response.data);
      })
      .catch(error => {
        Alert.error(
          {
            title:
              'Não foi possível obter a quantidade de solicitações de cancelamento de notas pendentes de análise.'
          },
          error
        );
      })
      .finally(() => setLoadingCancelamentoNotaPendente(false));
  };

  const onCancelamentoNota = () =>
    history.push('/acessos-e-permissoes/cancelamento-nfse');

  const obterQuantidadeSolicitacaoSubstituicaoNotaPendentes = () => {
    setLoadingSubstituicaoNotaPendente(true);
    DashboardService.substituicaoNotaFiscal()
      .then((response: AxiosResponse<number>) => {
        setSubstituicaoNotaPendente(response.data);
      })
      .catch(error => {
        Alert.error(
          {
            title:
              'Não foi possível obter a quantidade de solicitações de substituicao de notas pendentes de análise.'
          },
          error
        );
      })
      .finally(() => setLoadingSubstituicaoNotaPendente(false));
  };

  const onSubstituicaoNota = () =>
    history.push('/acessos-e-permissoes/substituicao-nfse');

  const obterQuantidadeSolicitacaoCancelamentoDmsPendentes = () => {
    setLoadingCancelamentoDmsPendente(true);
    DashboardService.cancelamentosDms()
      .then((response: AxiosResponse<number>) => {
        setCancelamentoDmsPendente(response.data);
      })
      .catch(error => {
        Alert.error(
          {
            title:
              'Não foi possível obter a quantidade de solicitações de cancelamento de Dms pendentes de análise.'
          },
          error
        );
      })
      .finally(() => setLoadingCancelamentoDmsPendente(false));
  };

  const onCancelamentoDms = () =>
    history.push('/acessos-e-permissoes/cancelamento-dms');

  const obterQuantidadeSolicitacaoCancelamentoGuiasAvulsasPendentes = () => {
    setLoadingCancelamentoGuiaAvulsaPendente(true);
    DashboardService.cancelamentosGuiaAvulsa()
      .then((response: AxiosResponse<number>) => {
        setCancelamentoGuiaAvulsaPendente(response.data);
      })
      .catch(error => {
        Alert.error(
          {
            title:
              'Não foi possível obter a quantidade de solicitações de cancelamento de Guias avulsas pendentes de análise.'
          },
          error
        );
      })
      .finally(() => setLoadingCancelamentoGuiaAvulsaPendente(false));
  };

  const onCancelamentoGuiaAvulsa = () =>
    history.push('/acessos-e-permissoes/cancelamento-guia');

  return (
    <Container title="Início" icon="home">
      <BlockDash
        md={6}
        icon="fa fa-medal"
        title="Atualizações cadastrais pendentes"
        number={credenciamentosPendente || '0'}
        loading={loadingCredenciamentosPendente}
        onClick={onCredenciamento}
      />

      <BlockDash
        md={6}
        icon="fa fa-ban"
        title="Solic. Cancelamento de notas pendentes"
        number={cancelamentoNotaPendente || '0'}
        loading={loadingCancelamentoNotaPendente}
        onClick={onCancelamentoNota}
      />

      <BlockDash
        md={6}
        icon="fa fa-exchange-alt"
        title="Solic. Substituição de notas pendentes"
        number={substituicaoNotaPendente || '0'}
        loading={loadingSubstituicaoNotaPendente}
        onClick={onSubstituicaoNota}
      />

      <BlockDash
        md={6}
        icon="fa fa-ban"
        title="Solic. Declaração mensal de serviços pendentes"
        number={cancelamentoDmsPendente || '0'}
        loading={loadingCancelamentoDmsPendente}
        onClick={onCancelamentoDms}
      />

      <BlockDash
        md={6}
        icon="fa fa-ban"
        title="Solic. Guias avulsas pendentes"
        number={cancelamentoGuiaAvulsaPendente || '0'}
        loading={loadingCancelamentoGuiaAvulsaPendente}
        onClick={onCancelamentoGuiaAvulsa}
      />
    </Container>
  );
};

export default Home;
