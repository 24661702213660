import {
  Checkbox,
  Col,
  ErrorText,
  FAB,
  InputDate,
  InputInteger,
  Masks,
  ProtectedComponent,
  Row,
  SectionTitle
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';

import { Roles } from '../../../utils/Roles';

const errorMessages = {
  acesso: 'Este campo é obrigatório!',
  nome: 'Este campo é obrigatório!',
  cpf: 'Este campo é obrigatório!',
  cep: 'Este campo é obrigatório!',
  endereco: 'Este campo é obrigatório!',
  numero: 'Este campo é obrigatório!',
  bairro: 'Este campo é obrigatório!',
  uf: 'Este campo é obrigatório!',
  cidade: 'Este campo é obrigatório!',
  complemento: 'Este campo é obrigatório!',
  telefone: 'Este campo é obrigatório!',
  ramal: 'Este campo é obrigatório!',
  celular: 'Este campo é obrigatório!',
  email: 'Este campo é obrigatório!',
  emailInvalido: 'Email inválido',
  dataNascimento: 'Este campo é obrigatório!'
};

const UsuariosIssqnForm = props => {
  const {
    onSave,
    dadosAcessos,
    error,
    unidadesFederacao,
    cidades,
    onChangeInputValue,
    onChangeSelectEstado,
    onChangeSelectCidade,
    onBack,
    onChangeFieldMask,
    onChangeInputEmail,
    onChangeAcessoAdmin
  } = props;

  return (
    <>
      <SectionTitle marginTop="0">Dados Pessoais</SectionTitle>
      <form className="form">
        <Row>
          <Col md={2}>
            <div className="form-group">
              <ProtectedComponent role={Roles.usuarios_issqn_admin_write.name}>
                <Checkbox
                  name="administrador"
                  id="administrador"
                  checked={dadosAcessos.administrador === 'S'}
                  onChange={onChangeAcessoAdmin}
                  label="Acesso Administrador"
                />
              </ProtectedComponent>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <div className="form-group">
              <label htmlFor="acesso">Acesso</label>
              <InputInteger
                className={error.acesso ? 'error' : ''}
                readOnly={true}
                name="acesso"
                placeholder=""
                value={dadosAcessos.id}
                onChange={onChangeInputValue}
              />
              {error.acesso && <ErrorText>{errorMessages.acesso}</ErrorText>}
            </div>
          </Col>
        </Row>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              <label htmlFor="nome">Nome</label>
              <input
                className={error.nome ? 'error' : ''}
                type="text"
                name="nome"
                placeholder="ex: José da Silva"
                value={dadosAcessos.nome}
                onChange={onChangeInputValue}
              />
              {error.nome && <ErrorText>{errorMessages.nome}</ErrorText>}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="cpf">CPF</label>
              <MaskedInput
                id="cpf"
                type="text"
                name="cpf"
                readOnly={true}
                defaultValue={dadosAcessos.cpf}
                mask={Masks.MASK_CPF}
                className={error.cpf ? 'error' : ''}
                maxLength={15}
              />
              {error.cpf && <ErrorText>{errorMessages.cpf}</ErrorText>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                className={error.email || error.emailInvalido ? 'error' : ''}
                type="text"
                name="email"
                placeholder="email@dominio.com"
                value={dadosAcessos.email}
                onChange={onChangeInputEmail}
              />
              {error.email && <ErrorText>{errorMessages.email}</ErrorText>}
              {!error.email && error.emailInvalido && (
                <ErrorText>{errorMessages.emailInvalido}</ErrorText>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="dataNascimento">Data de Nascimento</label>
              <InputDate
                name="dataNascimento"
                value={dadosAcessos.dataNascimento}
                onChange={onChangeInputValue}
                className={error.dataNascimento ? 'error' : ''}
              />
              {error.dataNascimento && (
                <ErrorText>{errorMessages.dataNascimento}</ErrorText>
              )}
            </div>
          </div>
        </div>
        <Row>
          <Col md={4}>
            <div className="form-group">
              <label htmlFor="telefone">Telefone</label>
              <MaskedInput
                className={error.telefone ? 'error' : ''}
                type="text"
                name="telefone"
                placeholder=""
                onChange={onChangeFieldMask}
                mask={Masks.MASK_TELEFONE}
                defaultValue={dadosAcessos.telefone}
                maxLength={17}
              />
              {error.telefone && (
                <ErrorText>{errorMessages.telefone}</ErrorText>
              )}
            </div>
          </Col>
          <Col md={4}>
            <div className="form-group">
              <label htmlFor="ramal">Ramal</label>
              <InputInteger
                className={error.ramal ? 'error' : ''}
                name="ramal"
                placeholder=""
                value={dadosAcessos.ramal || ''}
                onChange={onChangeInputValue}
              />
              {error.ramal && <ErrorText>{errorMessages.ramal}</ErrorText>}
            </div>
          </Col>
          <Col md={4}>
            <div className="form-group">
              <label htmlFor="celular">Celular</label>
              <MaskedInput
                className={error.celular ? 'error' : ''}
                type="text"
                name="celular"
                placeholder=""
                defaultValue={dadosAcessos.celular}
                onChange={onChangeFieldMask}
                mask={Masks.MASK_TELEFONE}
              />
              {error.celular && <ErrorText>{errorMessages.celular}</ErrorText>}
            </div>
          </Col>
        </Row>
        <SectionTitle>Endereço</SectionTitle>
        <Row>
          <Col md={2}>
            <div className="form-group">
              <label htmlFor="cep">CEP</label>
              <MaskedInput
                className={error.cep ? 'error' : ''}
                type="text"
                name="cep"
                placeholder="ex: 87047-000"
                defaultValue={dadosAcessos.cep}
                onChange={onChangeFieldMask}
                mask={Masks.MASK_CEP}
                maxLength={10}
              />
              {error.cep && <ErrorText>{errorMessages.cep}</ErrorText>}
            </div>
          </Col>
          <Col md={6}>
            <div className="form-group">
              <label htmlFor="endereco">Endereço</label>
              <input
                className={error.endereco ? 'error' : ''}
                type="text"
                name="endereco"
                placeholder="ex: Rua Rio Tocantins"
                value={dadosAcessos.endereco}
                onChange={onChangeInputValue}
              />
              {error.endereco && (
                <ErrorText>{errorMessages.endereco}</ErrorText>
              )}
            </div>
          </Col>
          <Col md={2}>
            <div className="form-group">
              <label htmlFor="numero">Número</label>
              <InputInteger
                className={error.numero ? 'error' : ''}
                name="numero"
                placeholder="ex: 854"
                value={dadosAcessos.numero}
                onChange={onChangeInputValue}
              />
              {error.numero && <ErrorText>{errorMessages.numero}</ErrorText>}
            </div>
          </Col>
          <Col md={2}>
            <div className="form-group">
              <label htmlFor="complemento">Complemento</label>
              <input
                className={error.complemento ? 'error' : ''}
                type="text"
                name="complemento"
                placeholder=""
                value={dadosAcessos.complemento || ''}
                onChange={onChangeInputValue}
              />
              {error.complemento && (
                <ErrorText>{errorMessages.complemento}</ErrorText>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div className="form-group">
              <label htmlFor="bairro">Bairro</label>
              <input
                className={error.bairro ? 'error' : ''}
                type="text"
                name="bairro"
                placeholder="ex: Jardim São Francisco"
                value={dadosAcessos.bairro}
                onChange={onChangeInputValue}
              />
              {error.bairro && <ErrorText>{errorMessages.bairro}</ErrorText>}
            </div>
          </Col>
          <Col md={4}>
            <div className="form-group">
              <label htmlFor="unidadeFederacao">Estado</label>
              <select
                name="unidadeFederacao"
                data-test-id="unidadeFederacao"
                className={error.unidadeFederacao ? 'error' : ''}
                onChange={onChangeSelectEstado}
                value={dadosAcessos.unidadeFederacao}
              >
                <option value="">Selecione</option>
                {unidadesFederacao.map(uf => (
                  <option
                    key={uf.id.unidadeFederacao}
                    value={uf.id.unidadeFederacao}
                  >
                    {uf.descricao}
                  </option>
                ))}
              </select>
              {error.unidadeFederacao && (
                <ErrorText>{errorMessages.uf}</ErrorText>
              )}
            </div>
          </Col>
          <Col md={4}>
            <div className="form-group">
              <label htmlFor="cidade">Cidade</label>
              <select
                name="cidade"
                data-test-id="cidade"
                className={error.cidade ? 'error' : ''}
                onChange={onChangeSelectCidade}
                value={dadosAcessos.cidade && dadosAcessos.cidade.id}
              >
                <option value="">Selecione</option>
                {cidades.map(cidade => (
                  <option key={cidade.id} value={cidade.id}>
                    {cidade.descricao}
                  </option>
                ))}
              </select>
              {error.cidade && <ErrorText>{errorMessages.cidade}</ErrorText>}
            </div>
          </Col>
        </Row>
        <div className="btn-save">
          <FAB
            data-test-id="buttonSave"
            typeButton="positive"
            iconColor="white"
            title="Salvar"
            onClick={onSave}
          />
          <FAB
            data-test-id="buttonCancelar"
            typeButton="negative"
            iconColor="white"
            title="Cancelar"
            onClick={onBack}
          />
        </div>
      </form>
    </>
  );
};

UsuariosIssqnForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  dadosAcessos: PropTypes.object.isRequired,
  error: PropTypes.object,
  onChangeInputValue: PropTypes.func.isRequired,
  unidadesFederacao: PropTypes.array.isRequired,
  onChangeSelectEstado: PropTypes.func.isRequired,
  onChangeSelectCidade: PropTypes.func.isRequired
};

export default UsuariosIssqnForm;
