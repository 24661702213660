import { Yup } from '@elotech/components';

export const validationSchema = Yup.object().shape({
  modeloRelatorio: Yup.string()
    .required()
    .max(100)
    .label('Tipo Relatório'),

  anexo: Yup.mixed()
    .test('', '', function(this: Yup.TestContext, value: any) {
      if (value === undefined || value === '') {
        return this.createError({
          path: this.path,
          message: `\${path} é obrigatório`
        });
      } else if (!/.*.jrxml$/.test(value.name)) {
        return this.createError({
          path: this.path,
          message: `Extensão do \${path} deve ser .jrxml`
        });
      }
      return true;
    })
    .nullable(true)
    .label('Modelo Arquivo')
});
