import { PagedResponse } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };

const search = (params: string) => (params ? `search=${params}` : '');

export const loadSubstituicoesNotasFiscais = (
  searchParams: string,
  page: any
): AxiosPromise<PagedResponse<any>> =>
  axios.get(
    `${CONTEXT_PATH}/substituicao-nota-fiscal?${search(searchParams)}`,
    {
      params: { ...defaultSort, ...page }
    }
  );

export const loadSolicitacaoSubstituicaoById = (
  id: number
): AxiosPromise<any> =>
  axios.get(`${CONTEXT_PATH}/substituicao-nota-fiscal/${id}`);

export const rejeitarSolicitacaoSubstituicao = (
  solicitacao: any
): AxiosPromise<any> =>
  axios.put(
    `${CONTEXT_PATH}/substituicao-nota-fiscal/rejeitar/${solicitacao.id}`,
    solicitacao
  );

export const aprovarSolicitacaoSubstituicao = (
  solicitacao: any
): AxiosPromise<any> =>
  axios.put(
    `${CONTEXT_PATH}/substituicao-nota-fiscal/aprovar/${solicitacao.id}`,
    solicitacao
  );

export const enviarEmail = (idSubstituicao: number): AxiosPromise<any> =>
  axios.post(
    `${CONTEXT_PATH}/substituicao-nota-fiscal/enviar-email/${idSubstituicao}`
  );

export const enviarEmailNfse = (idNotaFiscal: number): AxiosPromise<any> =>
  axios.post(
    `${CONTEXT_PATH}/substituicao-nota-fiscal/enviar-email-nfse/${idNotaFiscal}`
  );
