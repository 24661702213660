import {
  AutoComplete,
  Checkbox,
  Col,
  ErrorText,
  FAB,
  InputInteger,
  Row,
  SectionTitle
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const errorMessages = {
  exercicio: 'Este campo é obrigatório!',
  guiaRecolhimento: 'Este campo é obrigatório!',
  tipoDeclaracao: 'Este campo é obrigatório!',
  tipoMovimento: 'Este campo é obrigatório!'
};

const DividaDmsForm = ({
  onSave,
  configuracaoDividaInput,
  error,
  onChange,
  onChangeExercicio,
  loadGuiasRecolhimento,
  onSelect,
  onChangeCheckbox
}) => {
  return (
    <>
      <SectionTitle marginTop="0">Adicionar Configuração</SectionTitle>
      <form className="form">
        <Row style={{ alignItems: 'end', display: 'flex' }}>
          <Col md={2}>
            <div className="form-group">
              <label htmlFor="exercicio">Exercício</label>
              <InputInteger
                className={error.exercicio ? 'error' : ''}
                name="exercicio"
                placeholder="ex: 2019"
                value={configuracaoDividaInput.exercicio}
                onChange={onChangeExercicio}
              />
              {error.exercicio && (
                <ErrorText>{errorMessages.exercicio}</ErrorText>
              )}
            </div>
          </Col>
          <Col md={4}>
            <div className="form-group">
              <Checkbox
                name="desabilitaEnvioSubdivida"
                id="desabilitaEnvioSubdivida"
                label="Não enviar subdivida para geração do débito"
                onChange={onChangeCheckbox}
                checked={configuracaoDividaInput.desabilitaEnvioSubdivida}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="form-group">
              <label htmlFor="tipoDeclaracao">Tipo Declaração</label>
              <select
                className={error.tipoDeclaracao ? 'error' : ''}
                name="tipoDeclaracao"
                data-test-id="tipoDeclaracao"
                onChange={onChange}
                value={configuracaoDividaInput.tipoDeclaracao}
              >
                <option value="" selected>
                  Selecione...
                </option>
                <option value="C">Construção Civil</option>
                <option value="R">Retificadora</option>
                <option value="F">Fiscalização ISS</option>
                <option value="S">Sem Movimento</option>
                <option value="M">Retificadora Sem Movimento</option>
                <option value="A">Declaração Avulsa</option>
                <option value="N">Normal</option>
              </select>
              {error.tipoDeclaracao && (
                <ErrorText>{errorMessages.tipoDeclaracao}</ErrorText>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="form-group">
              <label htmlFor="tipoMovimento">Tipo Movimento</label>
              <select
                className={error.tipoMovimento ? 'error' : ''}
                name="tipoMovimento"
                data-test-id="tipoMovimento"
                onChange={onChange}
                value={configuracaoDividaInput.tipoMovimento}
              >
                <option value="" selected>
                  Selecione...
                </option>
                <option value="T">Tomador</option>
                <option value="P">Prestador</option>
              </select>
              {error.tipoMovimento && (
                <ErrorText>{errorMessages.tipoMovimento}</ErrorText>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="form-group">
              <label htmlFor="guiaRecolhimento">Guia Recolhimento</label>
              <AutoComplete
                data-test-id="autocomplete-guiaRecolhimento"
                className={error.guiaRecolhimento ? 'error' : ''}
                name="guiaRecolhimento"
                value={configuracaoDividaInput.guiaRecolhimento}
                onSearch={search =>
                  loadGuiasRecolhimento(
                    search,
                    configuracaoDividaInput.exercicio
                  )
                }
                loadOnClick={false}
                onSelect={onSelect}
                isDisabled={!configuracaoDividaInput.exercicio}
                placeholder="Pesquise pela descrição da guia"
                getOptionLabel={item =>
                  `${item.guiaRecolhimento} - ${item.descricao}`
                }
              />
            </div>
            {error.guiaRecolhimento && (
              <ErrorText>{errorMessages.guiaRecolhimento}</ErrorText>
            )}
          </Col>
        </Row>
        <div className="btn-save">
          <FAB
            data-test-id="buttonSave"
            icon="check"
            typeButton="module-color"
            iconColor="white"
            title="Salvar"
            onClick={() => onSave(configuracaoDividaInput)}
          />
        </div>
      </form>
    </>
  );
};

DividaDmsForm.propTypes = {
  configuracaoDividaInput: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  error: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

export default DividaDmsForm;
