import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };

const search = params => (params ? `search=${params}` : '');

export const loadNotaFiscal = (searchParams, page) =>
  axios.get(`${CONTEXT_PATH}/nota-fiscal?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const loadNotaFiscalById = id =>
  axios.get(`${CONTEXT_PATH}/nota-fiscal/${id}`);

export const loadEditarNotaFiscalVo = id =>
  axios.get(`${CONTEXT_PATH}/nota-fiscal/editar-nota-fiscal/${id}`);

export const salvarEdicaoNotaFiscal = edicaoNotaFiscalVo =>
  axios.put(
    `${CONTEXT_PATH}/nota-fiscal/salvar-edicao-nota-fiscal`,
    edicaoNotaFiscalVo
  );

export const excluirXML = edicaoNotaFiscalVo =>
  axios.put(`${CONTEXT_PATH}/nota-fiscal/excluir-pdf`, edicaoNotaFiscalVo);
