import {
  Alert,
  Container,
  FAB,
  FormattedCurrency,
  FormattedDate,
  Hint,
  Loading,
  NotificationActions,
  Panel,
  SearchPagination,
  Table,
  formatUtils
} from '@elotech/components';
import { differenceInMonths } from 'date-fns';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  CadastroGeralService,
  ExportarNotaFiscalService,
  withService
} from '../../../service';
import ExportarNfseInputSearch from './ExportarNfseInputSearch';

export class ExportarNfseListPage extends Component {
  state = {
    loading: false,
    notasFiscais: [],
    pagination: {},
    usuario: {},
    motivo: '',
    listarNotasFiscais: false,

    exportarNotafiscalInputSearch: {
      contribuinte: '',
      dataInicial: '',
      dataFinal: '',
      numeroDocInicial: '',
      numeroDocFinal: ''
    },

    errorExportarNotaFiscalInputSearch: {
      contribuinte: false,
      dataInicial: false,
      dataFinal: false
    }
  };

  searchWithPage = page => {
    this.serviceSearch(page);
  };

  serviceSearch = page => {
    if (this.hasErrorsInputSearch()) {
      this.props.showNotification({
        level: 'error',
        message: 'Campos obrigatórios.'
      });
    } else if (!this.isValidDateRange()) {
      Alert.info({
        title: 'O intervalo entre as datas deve ser de no máximo 3 meses.'
      });
    } else {
      const searchParams = this.montarParametrosFiltroUrl();

      this.setState({
        loading: true,
        searchParams
      });

      this.props.exportarNotaFiscalService
        .loadNotaFiscal(searchParams, page)
        .then(this.getLoadNotaFiscalSuccess)
        .catch(this.getLoadNotaFiscalError);
    }
  };

  montarParametrosFiltroUrl = () => {
    const {
      contribuinte,
      dataInicial,
      dataFinal,
      numeroDocInicial,
      numeroDocFinal
    } = this.state.exportarNotafiscalInputSearch;

    let parametrosPesquisa = ``;

    if (contribuinte) {
      parametrosPesquisa = parametrosPesquisa.concat(
        `notaFiscalPrestador.cadastroGeral.id==${contribuinte.id} `
      );
    }

    if (dataInicial) {
      parametrosPesquisa = parametrosPesquisa.concat(`and 
        dataEmissao=ge='${dataInicial}' `);
    }

    if (dataFinal) {
      parametrosPesquisa = parametrosPesquisa.concat(`and 
        dataEmissao=le='${dataFinal}' `);
    }

    if (numeroDocInicial) {
      parametrosPesquisa = parametrosPesquisa.concat(`and 
          numeroNotaFiscal=ge='${numeroDocInicial}' `);
    }

    if (numeroDocFinal) {
      parametrosPesquisa = parametrosPesquisa.concat(`and 
          numeroNotaFiscal=le='${numeroDocFinal}' `);
    }

    return parametrosPesquisa;
  };

  getLoadNotaFiscalSuccess = response => {
    const {
      content,
      number,
      totalPages,
      first,
      last,
      numberOfElements,
      size
    } = response.data;

    this.setState({
      notasFiscais: content,
      loading: false,
      pagination: {
        number,
        totalPages,
        first,
        last,
        numberOfElements,
        size
      }
    });

    if (content.length === 0) {
      Alert.info({
        title:
          'Não foram encontrados documentos fiscais para os filtros informados'
      });
    }
  };

  getLoadNotaFiscalError = () => {
    this.setState({ loading: false });
    Alert.error({
      title: 'Ocorreu uma falha ao carregar notas fiscais!'
    });
  };

  validadorsExportarNotaFiscalInputSearch = {
    contribuinte: value => value,
    dataInicial: value => value,
    dataFinal: value => value
  };

  onChangeInputSearch = event => {
    const { name, value } = event.target;

    this.setState(prevState => {
      const {
        exportarNotafiscalInputSearch,
        errorExportarNotaFiscalInputSearch
      } = prevState;

      return {
        exportarNotafiscalInputSearch: {
          ...exportarNotafiscalInputSearch,
          [name]: value
        },
        errorExportarNotaFiscalInputSearch: {
          ...errorExportarNotaFiscalInputSearch,
          [name]:
            this.validadorsExportarNotaFiscalInputSearch[name] &&
            !this.validadorsExportarNotaFiscalInputSearch[name](value)
        }
      };
    });
  };

  hasErrorsInputSearch = () => {
    const { exportarNotafiscalInputSearch } = this.state;

    const errorsCamposObrigatorios = Object.keys(
      exportarNotafiscalInputSearch
    ).filter(field => {
      return (
        this.validadorsExportarNotaFiscalInputSearch[field] &&
        !this.validadorsExportarNotaFiscalInputSearch[field](
          exportarNotafiscalInputSearch[field]
        )
      );
    });

    const objErro = errorsCamposObrigatorios.reduce((total, current) => {
      total[current] = true;
      return total;
    }, {});

    this.setState({ errorExportarNotaFiscalInputSearch: objErro });
    return errorsCamposObrigatorios.length > 0;
  };

  isValidDateRange = () => {
    const { dataInicial, dataFinal } = this.state.exportarNotafiscalInputSearch;

    if (dataInicial && dataFinal) {
      const dataInicio = new Date(dataInicial);
      const dataFim = new Date(dataFinal);

      const diferenca = differenceInMonths(dataInicio, dataFim);
      return diferenca >= -3;
    }
    return true;
  };

  onSearch = () => {
    if (this.hasErrorsInputSearch()) {
      this.props.showNotification({
        level: 'error',
        message: 'Campos obrigatórios.'
      });
    }
  };

  onSelect = (name, value) => {
    this.setState(state => {
      const { exportarNotafiscalInputSearch } = state;

      return {
        exportarNotafiscalInputSearch: {
          ...exportarNotafiscalInputSearch,
          [name]: value
        }
      };
    });
  };

  optionLabel = option => {
    if (option.pessoa) {
      return (
        formatUtils.formatCpfCnpj(option.pessoa.cnpjCpf) +
        ' - ' +
        option.pessoa.nome +
        ' - ' +
        option.tipoCadastro +
        ' - ' +
        option.cadastroGeral
      );
    }
    return '';
  };

  exportarNotaFiscal = () => {
    const searchParams = this.montarParametrosFiltroUrl();
    this.setState({
      searchParams,
      loading: true
    });

    this.props.exportarNotaFiscalService
      .downloadNotaFiscal(searchParams)
      .then(this.getBaixarNotaFiscal)
      .catch(this.getExportarNotaFiscalError);
  };

  getBaixarNotaFiscal = response => {
    this.setState({ loading: false });
    const url = window.URL.createObjectURL(response.data);
    this.createLinkDownload(url, 'nota-fiscal-pdf.zip');
  };

  createLinkDownload(url, fileName) {
    let a = document.createElement('a');
    document.body.appendChild(a);

    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  getExportarNotaFiscalError = error => {
    this.setState({ loading: false });
    Alert.error(
      {
        title: 'Ocorreu uma falha ao exportar as notas fiscais!'
      },
      error
    );
  };

  render() {
    const {
      exportarNotafiscalInputSearch,
      errorExportarNotaFiscalInputSearch,
      loading,
      notasFiscais,
      pagination
    } = this.state;

    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-exportar-nota-fiscal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        <ExportarNfseInputSearch
          onChangeInputSearch={this.onChangeInputSearch}
          exportarNotafiscalInputSearch={exportarNotafiscalInputSearch}
          errorExportarNotaFiscalInputSearch={
            errorExportarNotaFiscalInputSearch
          }
          search={this.serviceSearch}
          loadCadastrosMobiliarios={
            this.props.cadastroGeralService.loadCadastroMobiliarios
          }
          onSelect={this.onSelect}
          optionLabel={this.optionLabel}
          pagination={pagination}
        />
        {notasFiscais.length > 0 && (
          <Panel isTable className="mt-xs">
            <Table values={notasFiscais || []} keyExtractor={nota => nota.id}>
              <Table.Column
                header="Nº Documento"
                value={nota => nota.numeroNotaFiscal}
              />
              <Table.Column
                header="Razão Social"
                value={nota => nota.razaoSocialNome}
              />
              <Table.Column header="CNPJ/CPF" value={nota => nota.cnpjCpf} />
              <Table.Column
                header="Data Emissão"
                value={nota => (
                  <FormattedDate
                    value={nota.dataEmissao || ''}
                    timeZone="UTC"
                  />
                )}
              />
              <Table.Column
                header="Situação"
                value={nota => nota.descricaoSituacao}
              />
              <Table.Column
                header="Valor Documento"
                value={nota => (
                  <FormattedCurrency value={nota.valorTotalNota} />
                )}
              />
              <Table.Column
                header="Valor ISS Retido"
                value={nota => (
                  <FormattedCurrency value={nota.valorIssRetido} />
                )}
              />
            </Table>

            {notasFiscais && (
              <SearchPagination
                page={pagination}
                searchWithPage={this.searchWithPage}
              />
            )}
          </Panel>
        )}
        <div className="btn-save">
          {notasFiscais.length > 0 && (
            <FAB
              icon="download"
              iconColor="white"
              title="Exportar Notas Fiscais"
              onClick={this.exportarNotaFiscal}
            />
          )}
        </div>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const mapStateToProps = state => ({
  usuario: state.user.profile
});

const ComponentWithService = withService({
  exportarNotaFiscalService: ExportarNotaFiscalService,
  cadastroGeralService: CadastroGeralService
})(ExportarNfseListPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithService);
