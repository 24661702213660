import {
  ActionButton,
  ActionsGroup,
  Col,
  DisplayData
} from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const EmpresasListRow = ({ empresa, index, onRemoveEmpresa }) => {
  return (
    <div key={index}>
      <div className="display-data border small mt-xs has-btn-actions">
        <div className="row" tabIndex="0" autoFocus>
          <Col sm={3} md={2}>
            <DisplayData title="Cadastro">
              {empresa.cadastroGeral.cadastroGeral}
            </DisplayData>
          </Col>
          <Col sm={3} md={6}>
            <DisplayData title="Nome">
              {empresa.cadastroGeral.pessoa.nome}
            </DisplayData>
          </Col>
          <Col sm={2} md={3}>
            <DisplayData title="CPF/CNPJ">
              {empresa.cadastroGeral.pessoa.cnpjCpf}
            </DisplayData>
          </Col>
          <ActionsGroup>
            <Fragment>
              <ActionButton
                data-test-id={`buttonDelete${index}`}
                key="delete-button-empresa"
                icon="trash-alt"
                label="Remover Mobiliário"
                onClick={() => onRemoveEmpresa(index)}
              />
            </Fragment>
          </ActionsGroup>
        </div>
      </div>
    </div>
  );
};
EmpresasListRow.propTypes = {
  onRemoveEmpresa: PropTypes.func,
  empresa: PropTypes.object.isRequired
};
export default EmpresasListRow;
