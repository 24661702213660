import { ActionButton, SearchPagination } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const GrupoAnexoPublicoList = props => {
  const { documento, edit, exclude, searchWithPage } = props;
  return (
    <>
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <thead>
            <tr>
              <th>Código</th>
              <th>Título</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {documento &&
              documento.content &&
              documento.content.map(document => {
                return (
                  <tr key={document.id}>
                    <td>{document.id}</td>
                    <td>{document.titulo}</td>
                    <td>
                      <div className="btn-actions">
                        <div className="btn-actions-inner">
                          <ActionButton
                            data-test-id="buttonEdit"
                            key="edit-button"
                            icon="pencil-alt"
                            label="Editar"
                            onClick={() => edit(document.id)}
                          />
                          <ActionButton
                            data-test-id="buttonExclude"
                            key="exclude-button"
                            icon="trash-alt"
                            label="Excluir"
                            onClick={() => exclude(document.id)}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            {(!documento || documento.totalElements === 0) && (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan="7">
                  Sem registros
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {documento && (
        <SearchPagination page={documento} searchWithPage={searchWithPage} />
      )}
    </>
  );
};

GrupoAnexoPublicoList.propTypes = {
  documento: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
  exclude: PropTypes.func.isRequired,
  searchWithPage: PropTypes.func.isRequired
};
export default GrupoAnexoPublicoList;
