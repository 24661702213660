import * as Types from './types';

const handleGetUserData = (state, action) => ({
  ...state,
  currentUser: action.payload
});

const handleUserExists = (state, action) => ({
  ...state,
  userExists: action.payload
});

const handleLoadUserProfile = (state, action) => ({
  ...state,
  authenticated: true,
  profile: action.payload
});

const handlers = {
  [Types.GET_USER_DATA]: handleGetUserData,
  [Types.USER_EXISTS_VALIDATION]: handleUserExists,
  [Types.LOAD_USER_PROFILE]: handleLoadUserProfile
};

const initialState = {
  userExists: {},
  currentUser: {},
  authenticated: false,
  profile: {}
};

const UserReducer = (state = initialState, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};

export default UserReducer;
