import * as Types from './types';

const handleGetEntidadePrincipal = (state, action) => ({
  ...state,
  current: action.payload
});

const handlers = {
  [Types.LOAD_ENTIDADE_PRINCIPAL]: handleGetEntidadePrincipal
};

const initialState = {
  current: {}
};

const EntidadeReducer = (state = initialState, action) => {
  const handler = handlers[action.type];
  return handler ? handler(state, action) : state;
};

export default EntidadeReducer;
