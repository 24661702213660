export const NOVA = 'NOVA';
export const EM_ANALISE = 'EM_ANALISE';
export const CONCLUIDO = 'CONCLUIDA';

export type SituacaoDuvida = {
  situacao: string;
  label: string;
};

export const SituacoesDuvida: SituacaoDuvida[] = [
  { situacao: NOVA, label: 'Nova' },
  { situacao: EM_ANALISE, label: 'Em Análise' },
  { situacao: CONCLUIDO, label: 'Concluída' }
];

export const getLabelSituacao = (situacao?: string) => {
  if (!situacao) return undefined;

  const obj = SituacoesDuvida.find(t => t.situacao === situacao);

  if (obj && obj.label) return obj.label;

  return undefined;
};
