import axios, { AxiosPromise } from 'axios';

import { Rubrica } from '../types/Rubrica';
import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };

const search = (params: any) => (params ? `search=${params}` : '');

export const carregarPlanoOrientador = (searchParams: string, page?: any) =>
  axios.get(`${CONTEXT_PATH}/plano-orientador?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const loadRubrica = (searchParams: string, page?: any) =>
  axios.get(`${CONTEXT_PATH}/plano-orientador?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const save = (rubricaForm: Rubrica): AxiosPromise<Rubrica> =>
  rubricaForm.id === undefined
    ? axios.post(`${CONTEXT_PATH}/plano-orientador/`, rubricaForm)
    : axios.put(
        `${CONTEXT_PATH}/plano-orientador/${rubricaForm.id}`,
        rubricaForm
      );

export const findById = (id: number | string): AxiosPromise<Rubrica> =>
  axios.get(`${CONTEXT_PATH}/plano-orientador/${id}`);

export const deleteRubrica = (id?: number): AxiosPromise<Rubrica> =>
  axios.delete(`${CONTEXT_PATH}/plano-orientador/remover/${id}`);
