import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };

const search = params => (params ? `search=${params}` : '');

export const loadAgendamentos = (searchParams, page) =>
  axios.get(`${CONTEXT_PATH}/homologacao?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const loadCredenciamentos = (searchParams, page) =>
  axios.get(
    `${CONTEXT_PATH}/homologacao/credenciamentos?${search(searchParams)}`,
    {
      params: { ...defaultSort, ...page }
    }
  );

export const imprimirMapaCredenciamento = searchParams =>
  axios.get(
    `${CONTEXT_PATH}/homologacao/imprimir-mapa?${search(searchParams)}`,
    { responseType: 'blob' }
  );

export const loadHistoricoAgendamento = id =>
  axios.get(`${CONTEXT_PATH}/homologacao/${id}`);

export const downloadArquivo = id =>
  axios.get(`${CONTEXT_PATH}/homologacao/download-arquivo/${id}`, {
    responseType: 'blob'
  });

export const aprovarHomologacao = dados =>
  axios.put(
    `${CONTEXT_PATH}/homologacao/aprovar/${dados.idHistoricoAgendamento}`,
    dados
  );

export const rejeitarHomologacao = dados =>
  axios.put(
    `${CONTEXT_PATH}/homologacao/rejeitar/${dados.idHistoricoAgendamento}`,
    dados
  );

export const credenciar = dados =>
  axios.put(
    `${CONTEXT_PATH}/homologacao/credenciar/${dados.idHistoricoAgendamento}`,
    dados
  );

export const enviarEmail = dados =>
  axios.post(`${CONTEXT_PATH}/homologacao/enviar-email`, dados);
