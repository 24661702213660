export const NOVA = 'NOVA';
export const EM_ANALISE = 'EM_ANALISE';
export const RESPONDIDA = 'RESPONDIDA';

export type SituacaoCaixaPostal = {
  situacao: string;
  label: string;
};

export const SituacoesCaixaPostal: SituacaoCaixaPostal[] = [
  { situacao: NOVA, label: 'Nova' },
  { situacao: EM_ANALISE, label: 'Em Análise' },
  { situacao: RESPONDIDA, label: 'Respondida' }
];

export const getLabelSituacao = (situacao?: string) => {
  if (!situacao) return undefined;

  const obj = SituacoesCaixaPostal.find(t => t.situacao === situacao);

  if (obj && obj.label) return obj.label;

  return undefined;
};
