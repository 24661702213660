import { formatUtils } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const ServicosDetailList = ({ servicos }) => {
  return (
    <div className="panel table table-responsive">
      <div className="panel-body">
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <thead>
              <tr>
                <th>Serviço</th>
                <th>Descrição</th>
                <th style={{ textAlign: 'center' }}>Alíquota</th>
              </tr>
            </thead>
            <tbody>
              {servicos?.map((servico, index) => {
                return (
                  <tr key={index}>
                    <td>{formatUtils.formatServico(servico.codigo)}</td>
                    <td>{servico.descricao}</td>
                    <td style={{ textAlign: 'center' }}>{servico.aliquota}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

ServicosDetailList.propTypes = {
  servicos: PropTypes.array
};

export default ServicosDetailList;
