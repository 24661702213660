import { FilterUtils } from '@elotech/components';
import axios from 'axios';
import { ServiceUtils } from 'iss-common/utils';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };

const filterAutocomplete = search => {
  if (search) {
    if (isNaN(search)) {
      return FilterUtils.buildDefaultFilter('descricao')(search);
    }

    return FilterUtils.buildDefaultFilter('codigo')(search);
  }

  return '';
};

export const loadServicos = (searchParams, page = 50) =>
  axios.get(
    `${CONTEXT_PATH}/servico?${ServiceUtils.buildSearchParams(
      filterAutocomplete(searchParams)
    )}`,
    {
      params: {
        ...defaultSort,
        ...page
      }
    }
  );
