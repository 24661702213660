import {
  Alert,
  BasicInput,
  Checkbox,
  Col,
  Container,
  FAB,
  FormikAutocomplete,
  FormikAutocompleteMultiple,
  FormikInputIntegerMultiValue,
  Loading,
  Panel,
  Row,
  SectionTitle,
  Yup
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import MaskedInput from 'react-text-mask';

import {
  ControleDeclaracaoMensalNaoEntregueService,
  PessoaService
} from '../../../service';
import RegimeFiscalService from '../../../service/RegimeFiscalService';
import { ControleDmsNaoEntregueDTO, Pessoa } from '../../../types';
import { RegimeFiscalType } from '../../../types/RegimeFiscal';

const initialValues: ControleDmsNaoEntregueDTO = {
  cadastroGeral: '',
  pessoa: undefined
};

function isCompetenciaValida(competencia: string) {
  const data = moment(`01/${competencia}`, 'DD/MM/YYYY', true);
  return data.isValid();
}

const validationSchema = Yup.object().shape({
  cadastroGeral: Yup.string().label('Cadastro(s)'),
  competenciaInicial: Yup.string()
    .label('Competência Inicial')
    .test(
      'competenciaInicialInvalida',
      'Competência inicial inválida',
      function(competenciaInicial: string) {
        return (
          (!competenciaInicial &&
            (!!this.parent.cadastroGeral || this.parent.pessoa)) ||
          (competenciaInicial?.length > 0 &&
            isCompetenciaValida(competenciaInicial))
        );
      }
    ),
  competenciaFinal: Yup.string()
    .label('Competência Final')
    .test('competenciaFinalInvalida', 'Competência final inválida', function(
      competenciaFinal: string
    ) {
      return (
        (!competenciaFinal &&
          (!!this.parent.cadastroGeral || this.parent.pessoa)) ||
        (competenciaFinal?.length > 0 && isCompetenciaValida(competenciaFinal))
      );
    })
    .test(
      'dataFinalMenorQueInicial',
      'Competência final menor que competência inicial!',
      function(competenciaFinal: string) {
        if (!this.parent.competenciaInicial) {
          return true;
        }

        const vInicial = moment(
          `01/${this.parent.competenciaInicial}`,
          'DD/MM/YYYY',
          true
        );
        const vFinal = moment(`01/${competenciaFinal}`, 'DD/MM/YYYY', true);
        return !!competenciaFinal && !vFinal.isBefore(vInicial);
      }
    )
    .test(
      'intervaloMaiorQueUmAno',
      'Quando não for informado dados do Cadastro/Pessoa/Empresa o intervalo das competência não pode ultrapassar 1 ano.',
      function(value) {
        if (!!this.parent.cadastroGeral || this.parent.pessoa) {
          return true;
        }

        const competenciaInicial = moment(
          `01/${this.parent.competenciaInicial}`,
          'DD/MM/YYYY',
          true
        );

        const competenciaFinal = moment(`01/${value}`, 'DD/MM/YYYY', true);

        return competenciaFinal.diff(competenciaInicial, 'month') + 1 <= 12;
      }
    )
});

const ControleDeclaracaoMensalNaoEntregueFormPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const [somenteTomador, setSomenteTomador] = useState<boolean>(false);
  const [somentePrestador, setSomentePrestador] = useState<boolean>(false);

  const onChangePrestador = (
    formProps: FormikProps<ControleDmsNaoEntregueDTO>
  ) => {
    formProps.setFieldValue('somentePrestador', !somentePrestador);
    setSomentePrestador(!somentePrestador);
  };

  const onChangeTomador = (
    formProps: FormikProps<ControleDmsNaoEntregueDTO>
  ) => {
    formProps.setFieldValue('somenteTomador', !somenteTomador);
    setSomenteTomador(!somenteTomador);
  };

  const onSubmit = (value: ControleDmsNaoEntregueDTO) => {
    setLoading(true);
    ControleDeclaracaoMensalNaoEntregueService.generateControleDmsNaoEntregues(
      value
    )
      .then(_ => {
        Alert.success({
          title:
            'Iniciado com sucesso a geração do controle de DMS não entregue.',
          text:
            'Será enviada uma notificação assim que o processamento finalizar.'
        }).then(() => {
          history.replace('/rotinas/controle-dms-nao-entregues');
        });
      })
      .catch(error => {
        Alert.error(
          { title: 'Não foi possível gerar o controle de DMS não entregue.' },
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container breadcrumb status={'new'}>
      <Loading loading={loading} />
      <Panel isTable>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          render={(formProps: FormikProps<ControleDmsNaoEntregueDTO>) => (
            <>
              <SectionTitle marginTop="0px">Dados Gerais</SectionTitle>
              <Row>
                <BasicInput
                  size={2}
                  label="Competência Inicial"
                  name="competenciaInicial"
                  render={({ field }) => (
                    <MaskedInput
                      {...field}
                      type="text"
                      name="competenciaInicial"
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    />
                  )}
                />
                <BasicInput
                  size={2}
                  label="Competência Final"
                  name="competenciaFinal"
                  render={({ field }) => (
                    <MaskedInput
                      {...field}
                      type="text"
                      name="competenciaFinal"
                      mask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    />
                  )}
                />
                <FormikAutocomplete
                  data-test-id="autocomplete-pessoa"
                  name="pessoa"
                  placeholder="Pesquisar por Cnpj/Cpf ou Nome"
                  label="Pessoa/Empresa (CPF/CNPJ)"
                  onSearch={PessoaService.search}
                  size={4}
                  getOptionLabel={(value: Pessoa) =>
                    `${value?.cnpjCpf} - ${value.nome}`
                  }
                  getOptionValue={(value: Pessoa) => value.id}
                />
                <FormikInputIntegerMultiValue
                  id="cadastroGeral"
                  name="cadastroGeral"
                  label="Cadastro Geral"
                  size={3}
                />
              </Row>
              <Row>
                <FormikAutocompleteMultiple<RegimeFiscalType>
                  name="regimesFiscais"
                  label="Regime Fiscal"
                  onSearch={RegimeFiscalService.carregarRegimeFiscal}
                  getOptionLabel={value => `${value.id} - ${value.descricao}`}
                  getOptionValue={value => value.descricao}
                  size={8}
                />
              </Row>
              <Row>
                <Col md={2}>
                  <Checkbox
                    className="form-group"
                    name="somenteTomador"
                    id="somenteTomador"
                    checked={somenteTomador}
                    onChange={() => onChangeTomador(formProps)}
                    label="Somente Tomador"
                    disabled={somentePrestador}
                  ></Checkbox>
                </Col>
                <Col md={2}>
                  <Checkbox
                    className="form-group"
                    name="somentePrestador"
                    id="somentePrestador"
                    checked={somentePrestador}
                    onChange={() => onChangePrestador(formProps)}
                    label="Somente prestador"
                    disabled={somenteTomador}
                  ></Checkbox>
                </Col>
              </Row>
              <div className="btn-save">
                <FAB
                  icon="check"
                  title="Filtrar"
                  onClick={formProps.submitForm}
                />
              </div>
            </>
          )}
        />
      </Panel>
    </Container>
  );
};

export default ControleDeclaracaoMensalNaoEntregueFormPage;
