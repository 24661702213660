import {
  AutoComplete,
  Col,
  ErrorText,
  InputInteger,
  Row
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const errorMessages = {
  documentoGrupo: 'Este campo é obrigatório!',
  titulo: 'Este campo é obrigatório!',
  descricao: 'Este campo é obrigatório!'
};

const AnexoPublicoNewEditForm = ({
  cadastroDocumento,
  error,
  onChange,
  loadGrupoDocumento,
  onSelect,
  optionLabel
}) => {
  return (
    <>
      <form className="form">
        <Row>
          <Col sm={5} md={2}>
            <div className="form-group">
              <label htmlFor="documento">Documento</label>
              <input
                disabled
                type="text"
                name="id"
                value={cadastroDocumento.id || ''}
                onChange={onChange}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={5} md={2}>
            <div className="form-group">
              <label htmlFor="numero">Número</label>
              <InputInteger
                name="numero"
                onChange={onChange}
                value={cadastroDocumento.numero || ''}
              />
            </div>
          </Col>
          <Col md={2}>
            <div className="form-group">
              <label htmlFor="ano">ano</label>
              <InputInteger
                name="ano"
                value={cadastroDocumento.ano || ''}
                onChange={onChange}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={5} md={10}>
            <div className="form-group">
              <label htmlFor="grupoDocumento">Grupo Documentos</label>
              <AutoComplete
                data-test-id="autocomplete-documentoGrupo"
                className={error.documentoGrupo ? 'error' : ''}
                name="documentoGrupo"
                value={cadastroDocumento.documentoGrupo}
                onSearch={loadGrupoDocumento}
                onSelect={onSelect}
                getOptionLabel={optionLabel}
              />
              {error.documentoGrupo && (
                <ErrorText>{errorMessages.documentoGrupo}</ErrorText>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={5} md={10}>
            <div className="form-group">
              <label htmlFor="titulo">Título</label>
              <input
                className={error.titulo ? 'error' : ''}
                type="text"
                name="titulo"
                value={cadastroDocumento.titulo}
                onChange={onChange}
              />
              {error.titulo && <ErrorText>{errorMessages.titulo}</ErrorText>}
            </div>
          </Col>
          <Col md={10}>
            <div className="form-group">
              <label htmlFor="descricao">Descrição</label>
              <textarea
                className={error.descricao ? 'error' : ''}
                type="text"
                name="descricao"
                value={cadastroDocumento.descricao}
                onChange={onChange}
              />
              {error.descricao && (
                <ErrorText>{errorMessages.descricao}</ErrorText>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <div className="form-group">
              <label htmlFor="link">Link</label>
              <input
                type="text"
                name="link"
                value={cadastroDocumento.link || ''}
                placeholder="URL"
                onChange={onChange}
              />
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

AnexoPublicoNewEditForm.propTypes = {
  cadastroDocumento: PropTypes.object.isRequired,
  error: PropTypes.object,
  onChange: PropTypes.func,
  loadGrupoDocumento: PropTypes.func,
  onSelect: PropTypes.func,
  onFilter: PropTypes.func
};

export default AnexoPublicoNewEditForm;
