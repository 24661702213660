import { FilterUtils } from '@elotech/components';
import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };

const search = params => (params ? `search=${createSearchString(params)}` : '');

function isInteger(str) {
  const num = Number(str);
  return Number.isInteger(num);
}

const createSearchString = params => {
  if (params) {
    if (isInteger(params)) {
      return `codigo==${params}*`;
    } else {
      return `descricao==*${params}*`;
    }
  }
  return '';
};

export const loadCnaes = (searchParams, page = 50) =>
  axios.get(`${CONTEXT_PATH}/cnae?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const autoCompleteCnaesComServico = (searchParams, page = 50) =>
  axios.get(`${CONTEXT_PATH}/cnae/servicos`, {
    params: {
      search: `${FilterUtils.buildFilter(['codigo', 'descricao'])(
        searchParams
      )}`,
      ...defaultSort,
      ...page
    }
  });
