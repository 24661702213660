import { FormattedDate, Panel, SectionTitle, Table } from '@elotech/components';
import React from 'react';

import { DeclaracaoMensalVencimento } from '../../../types/DeclaracaoMensalVencimento';
import { GuiaRecolhimento } from '../../../types/GuiaRecolhimento';

type Props = {
  dmsVencimentos: DeclaracaoMensalVencimento[];
};

const descricaoGuiaRecolhimento = (valor: GuiaRecolhimento) => {
  return `${valor.guiaRecolhimento} - ${valor.descricao}`;
};

const descricaoStatus = (valor: number) => {
  if (valor) {
    return 'Cadastrado';
  }
  return 'Não Cadastrado';
};

const descricaoTipoDeclaracao = (valor: string) => {
  switch (valor) {
    case 'DPT':
      return 'Demais Prestadores e Tomadores';
    case 'IF':
      return 'Instituição Financeira';
    default:
      return 'Não encontrado';
  }
};

const DeclaracaoVencimentoDividaList: React.FC<Props> = ({
  dmsVencimentos
}) => {
  return (
    <>
      <SectionTitle>Configuração Vencimento Mensal da Dívida</SectionTitle>

      <Panel isTable>
        <Table
          values={dmsVencimentos}
          keyExtractor={(item: DeclaracaoMensalVencimento) =>
            `${item.anoCompetencia}${item.mesCompetencia}${item.guiaRecolhimento.guiaRecolhimento}${item.dataVencimento}${item.declaracaoMensalTipoEmpresaVencimento}`
          }
        >
          <Table.Column<DeclaracaoMensalVencimento>
            header="Ano"
            value={value => `${value.anoCompetencia}`}
          />
          <Table.Column<DeclaracaoMensalVencimento>
            header="Mês"
            value={value => value.mesCompetencia}
          />
          <Table.Column<DeclaracaoMensalVencimento>
            header="Guia Recolhimento"
            value={value => descricaoGuiaRecolhimento(value.guiaRecolhimento)}
          />
          <Table.Column<DeclaracaoMensalVencimento>
            header="Data Vencimento"
            value={(item: DeclaracaoMensalVencimento) => (
              <FormattedDate value={item.dataVencimento} />
            )}
          />
          <Table.Column<DeclaracaoMensalVencimento>
            header="Tipo Declaração"
            value={value =>
              descricaoTipoDeclaracao(
                value.declaracaoMensalTipoEmpresaVencimento
              )
            }
          />
          <Table.Column<DeclaracaoMensalVencimento>
            header="Status"
            value={value => descricaoStatus(value.id)}
          />
        </Table>
      </Panel>
    </>
  );
};

export default DeclaracaoVencimentoDividaList;
