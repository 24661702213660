import {
  ActionButton,
  ActionsGroup,
  Alert,
  DateUtils,
  FormattedDateTime,
  Icon,
  Table
} from '@elotech/components';
import { BatchStatusChip } from 'iss-common/components';
import { JobStatusValue } from 'iss-common/enums';
import React, { ReactNode, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { JobType, JobTypeValue } from '../../enums';
import { BatchService } from '../../service';
import { BatchInstanceDTO } from '../../types';
import HistoricoExecucaoList from './HistoricoExecucaoList';

type Props = {
  tarefas: BatchInstanceDTO[];
  loading: boolean;
};

const shouldRenderRetry = (status: JobStatusValue) => status === 'FAILED';
const shouldRenderStop = (status: JobStatusValue) => status === 'STARTED';

const linkRenderer: {
  [K in JobTypeValue]: (tarefa: BatchInstanceDTO) => ReactNode;
} = {
  CONTROLE_DMS_NAO_ENTREGUES: tarefa => {
    const id = tarefa.jobParameters.parameters.idControle.value;
    const url = `/rotinas/controle-dms-nao-entregues/${id}/resumo`;
    return <Link to={url}>{`${id}`}</Link>;
  },
  IMPORTACAO_ARQUIVO_DIMP: tarefa => {
    const competencia = tarefa.jobParameters.parameters.competencia.value;
    const url = '/relatorios/dms-pgdas-geral';
    return <Link to={url}>{`${competencia}`}</Link>;
  }
};

const TarefaBatchListPage: React.FC<Props> = ({ tarefas, loading }) => {
  const history = useHistory();

  const [loadingRetryOrStop, setLoadingRetryOrStop] = useState<boolean>(false);
  const [idsHistoricoVisivel, setIdsHistoricoVisivel] = useState<number[]>([]);

  const onRetry = (tarefa: BatchInstanceDTO) => {
    setLoadingRetryOrStop(true);

    BatchService.retry(tarefa.executionId)
      .then(() => {
        setLoadingRetryOrStop(false);
        history.push('/tarefas?filters==status_Igual=STARTED');
        Alert.success({
          title: 'Tarefa reiniciada com sucesso.'
        });
      })
      .catch(error => {
        setLoadingRetryOrStop(false);
        Alert.error(
          {
            title: 'Erro ao recomeçar o processo.'
          },
          error
        );
      });
  };

  const onStop = (tarefa: BatchInstanceDTO) => {
    setLoadingRetryOrStop(true);

    BatchService.stop(tarefa.executionId)
      .then(() => {
        setLoadingRetryOrStop(false);
        history.push(`/tarefas?filters==instanceId_Igual=${tarefa.instanceId}`);
        Alert.success({
          title: 'Tarefa marcada com sucesso para ser interrompida.'
        });
      })
      .catch(error => {
        setLoadingRetryOrStop(false);
        Alert.error(
          {
            title: 'Erro ao parar o processo.'
          },
          error
        );
      });
  };

  const onViewHistorico = (id: number) =>
    idsHistoricoVisivel.includes(id)
      ? setIdsHistoricoVisivel(
          idsHistoricoVisivel.filter(instanceId => instanceId !== id)
        )
      : setIdsHistoricoVisivel([...idsHistoricoVisivel, id]);

  const renderHistorico = (tarefa: BatchInstanceDTO) => {
    if (!idsHistoricoVisivel.includes(tarefa.instanceId)) {
      return;
    }
    return <HistoricoExecucaoList tarefa={tarefa} />;
  };

  const renderLink = (tarefa: BatchInstanceDTO) => {
    return linkRenderer[tarefa.type]
      ? linkRenderer[tarefa.type](tarefa)
      : undefined;
  };

  return (
    <Table
      loading={loading || loadingRetryOrStop}
      values={tarefas}
      renderInnerComponent={renderHistorico}
      keyExtractor={item => item.instanceId}
      messageEmpty="Nenhuma tarefa encontrada"
    >
      <Table.Column<BatchInstanceDTO>
        header=" "
        value={item => {
          return item.status === 'STARTED' ? (
            <Icon icon="spinner" spin primary title="Em execução" />
          ) : (
            undefined
          );
        }}
      />
      <Table.Column<BatchInstanceDTO>
        header="Processo"
        value={item => item.instanceId}
      />
      <Table.Column<BatchInstanceDTO>
        header="Tarefa"
        value={item => JobType[item.type]}
      />
      <Table.Column<BatchInstanceDTO>
        header="Identificador"
        value={renderLink}
      />
      <Table.Column<BatchInstanceDTO>
        header="Início"
        value={item => <FormattedDateTime value={item.dataInicio} />}
      />
      <Table.Column<BatchInstanceDTO>
        header="Fim"
        value={item => <FormattedDateTime value={item.dataFim} />}
      />
      <Table.Column<BatchInstanceDTO>
        header="Duração"
        value={item => {
          return (
            item.dataInicio &&
            item.dataFim &&
            DateUtils.getDiferencaDatasFormatada(item.dataInicio, item.dataFim)
          );
        }}
      />
      <Table.Column<BatchInstanceDTO>
        header="Situação"
        headerClassName="center"
        value={item => <BatchStatusChip jobStatus={item.status} />}
      />
      <Table.Column<BatchInstanceDTO>
        header=""
        value={item => (
          <ActionsGroup>
            <ActionButton
              key="viewHistoricoExecutions"
              icon="eye"
              label="Visualizar Histórico de Execução"
              onClick={() => onViewHistorico(item.instanceId)}
            />
            {shouldRenderRetry(item.status) && (
              <ActionButton
                key="tentarNovamente"
                icon="sync"
                label="Tentar Novamente"
                onClick={() => onRetry(item)}
              />
            )}
            {shouldRenderStop(item.status) && (
              <ActionButton
                key="parar"
                icon="stop-circle"
                label="Parar"
                onClick={() => onStop(item)}
              />
            )}
          </ActionsGroup>
        )}
      />
    </Table>
  );
};

export default TarefaBatchListPage;
