import { NotificationActions } from '@elotech/components';
import { UserService } from 'iss-common/service';
import { UserOperations } from 'iss-common/state/user';
import { ObjectUtils } from 'iss-common/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import MyAccountForm from './MyAccountForm';

const defaultUser = {
  cpfCnpj: '',
  nome: '',
  email: '',
  procurador: false,
  termoResponsabilidade: true
};

class MyAccountPage extends React.Component {
  static propTypes = {
    userData: PropTypes.object,
    userExists: PropTypes.object,
    getUserData: PropTypes.func.isRequired,
    setUserExists: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired
  };

  state = {
    user: defaultUser,
    userExists: undefined,
    error: {
      cpfCnpj: false,
      nome: false,
      email: ''
    },
    loading: false
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.userExists !== undefined &&
      state.userExists === undefined &&
      !ObjectUtils.isEmptyObject(props.userExists)
    ) {
      return { userExists: props.userExists.userExists };
    }

    if (props.userData !== undefined && state.user === defaultUser) {
      return { user: props.userData };
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.userExists === undefined && this.state.userExists === false) {
      this.setState({ user: defaultUser });
    }
  }

  onChangeField = event => {
    const { name, value, checked, type } = event.target;
    this.setState(state => {
      const newValue = type === 'checkbox' ? checked : value;
      const { user, error } = state;
      const newError = error[name] ? !newValue : error[name];
      return {
        user: { ...user, [name]: newValue },
        error: { ...error, [name]: newError }
      };
    });
  };

  onSave = () => {
    const { user, userExists, error } = this.state;

    const newError = {};

    if (!user.cpfCnpj) {
      newError.cpfCnpj = true;
    }

    if (!user.nome) {
      newError.nome = true;
    }

    if (!user.email) {
      newError.email = true;
    }
    if (!ObjectUtils.isEmptyObject(newError)) {
      return this.setState({ error: { ...error, ...newError } });
    }

    const updateFn = userExists ? UserService.patchUser : UserService.addUser;
    const { showNotification, getUserData, setUserExists } = this.props;
    return new Promise(resolve => {
      this.setState({ loading: true }, () => {
        updateFn(user)
          .then(() => {
            showNotification({
              level: 'success',
              title: 'Sucesso',
              message: 'Seus dados foram atualizados com sucesso.'
            });
            setUserExists(true);
            getUserData();
          })
          .catch(() => {
            this.props.showNotification({
              level: 'warning',
              message: 'Não foi possível atualizar os seus dados'
            });
          })
          .finally(() => {
            this.setState({ loading: false });
            resolve();
          });
      });
    });
  };

  onResetPassword = () => {
    return UserService.resetPassword()
      .then(() => {
        this.props.showNotification({
          level: 'success',
          message:
            'Foi enviado um email para o seu endereço cadastrado com um link que irá permitir a' +
            ' alteração da sua senha.'
        });
      })
      .catch(() => {
        this.props.showNotification({
          level: 'warning',
          message:
            'Não foi possível redefinir a senha, verifique o email cadastrado.'
        });
      });
  };

  render() {
    const { user, userExists, error, loading } = this.state;
    if (!user || ObjectUtils.isEmptyObject(user)) {
      return null;
    }
    return (
      <MyAccountForm
        error={error}
        user={user}
        userExists={userExists}
        loading={loading}
        onResetPassword={this.onResetPassword}
        onChangeField={this.onChangeField}
        onSave={this.onSave}
      />
    );
  }
}

const mapStateToProps = state => ({
  userExists: state.user.userExists,
  userData: state.user.currentUser
});

const ConnectedComponent = connect(mapStateToProps, {
  showNotification: NotificationActions.showNotification,
  getUserData: UserOperations.getUserData,
  setUserExists: UserOperations.setUserExists
})(MyAccountPage);

export { ConnectedComponent as default, MyAccountPage };
