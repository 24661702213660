import {
  ActionButton,
  ActionsGroup,
  Container,
  FAB,
  Field,
  Hint,
  Loading,
  SearchFilter,
  SearchPagination,
  Table
} from '@elotech/components';
import { History } from 'history';
import { Alert } from 'iss-common/utils';
import React, { useState } from 'react';

import { ModeloRelatorioService } from '../../../service';
import { ModeloRelatorio } from '../../../types/ModeloRelatorio';

type Props = {
  history: Pick<History, 'push'>;
};

const searchFields: Field[] = [
  {
    label: 'Modelo',
    name: 'nomeArquivo',
    type: 'STRING'
  }
];

const classes = 'inline clean module-color center-right fa-question-circle';

const URL_HELP =
  'href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-modelo-relatorio"';

const ModeloRelatorioListPage: React.FC<Props> = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [modelosRelatorio, setModelosRelatorio] = useState<ModeloRelatorio[]>(
    []
  );
  const [pagination, setPagination] = useState<any>(undefined);

  const onSearch = (searchParams: string, page?: number) => {
    setLoading(true);
    ModeloRelatorioService.loadModeloRelatorio(searchParams, page)
      .then(response => {
        const { content, ...pagination } = response.data;
        setModelosRelatorio(content);
        setPagination(pagination);
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Ops!, não foi possível carregar modelos de relatório.'
          },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const onDelete = (id?: number) => {
    Alert.question({
      title: 'Deseja excluir o modelo ?'
    }).then((result: any) => {
      if (result.value) {
        setLoading(true);
        ModeloRelatorioService.deleteModeloRelatorio(id)
          .then(onDeleteSuccess)
          .catch(onDeleteError);
      }
    });
  };

  const downloadArquivo = (id: any, fileName: string) => {
    setLoading(true);
    ModeloRelatorioService.downloadArquivo(id)
      .then(response => downloadArquivoSuccess(response, fileName))
      .catch(downloadArquivoError);
  };

  const saveData = (function() {
    let a = document.createElement('a');
    document.body.appendChild(a);
    return function(data: any, fileName: string) {
      let blob = new Blob([data], { type: 'text/xml' }),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  })();

  const downloadArquivoSuccess = (response: any, fileName: string) => {
    setLoading(false);
    saveData(response.data, fileName);
  };

  const downloadArquivoError = (error: any) => {
    setLoading(false);
    Alert.error(
      {
        title: 'Não foi possível fazer o download do arquivo.'
      },
      error
    );
  };

  const onDeleteSuccess = () => {
    setLoading(false);
    Alert.success({
      title: 'Modelo de Relatório excluído'
    });
    searchWithPage();
  };

  const searchWithPage = (page?: any) => {
    setPagination(page);
    onSearch('', page);
  };

  const onDeleteError = () => {
    setLoading(false);
    Alert.error({
      title: 'Ops!, ocorreu um erro ao excluir Modelo de Relatório'
    });
  };

  const onNew = () => {
    history.push('/configuracoes/modelo-de-relatorio/novo');
  };

  const keyExtractor = (relatorio: ModeloRelatorio) => relatorio.id!;

  const paginationSearch = (page: any) => onSearch('', page);

  return (
    <Container
      breadcrumb
      titleRightComponent={
        <a href={URL_HELP} target="_blank" rel="noopener noreferrer">
          <Hint classes={classes}>Ajuda?</Hint>
        </a>
      }
    >
      <Loading loading={loading} />
      <div className="panel table table-responsive">
        <div className="panel-body">
          <SearchFilter
            fields={searchFields}
            search={search => onSearch(search, undefined)}
          />
          <Table
            values={modelosRelatorio}
            keyExtractor={keyExtractor}
            reduced={false}
          >
            <Table.Column
              header="Tipo Relatório"
              value={(item: ModeloRelatorio) => item.descricaoModeloRelatorio}
            />
            <Table.Column
              header="Modelo"
              value={(item: ModeloRelatorio) => item.nomeArquivo}
            />
            <Table.Column
              header="Versão"
              value={(item: ModeloRelatorio) => item.versaoArquivo}
            />
            <Table.Column
              data-test-id="buttons"
              header=""
              value={(item: ModeloRelatorio) => (
                <ActionsGroup>
                  <ActionButton
                    data-test-id={`download-button-${item.id}`}
                    key="download-button"
                    icon="download"
                    label="Download"
                    onClick={() => downloadArquivo(item.id, item.nomeArquivo!)}
                  />
                  <ActionButton
                    data-test-id={`delete-button-${item.id}`}
                    key="delete-button"
                    icon="trash-alt"
                    label="Excluir"
                    onClick={() => onDelete(item.id)}
                  />
                </ActionsGroup>
              )}
            />
          </Table>
          {pagination && (
            <SearchPagination
              page={pagination}
              searchWithPage={paginationSearch}
            />
          )}
        </div>
      </div>
      <div className="btn-save">
        <FAB
          data-test-id="btn-new"
          icon="plus"
          title="Adicionar Novo"
          onClick={onNew}
        />
      </div>
    </Container>
  );
};
export default ModeloRelatorioListPage;
