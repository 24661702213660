import {
  Alert,
  Container,
  FAB,
  Hint,
  Loading,
  SearchFilter
} from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import swal from 'sweetalert2';

import { DocumentoService, withService } from '../../../service';
import AnexoPublicoList from './AnexoPublicoList';

export class AnexoPublicoListPage extends Component {
  static propTypes = {
    documentoService: PropTypes.object.isRequired
  };

  state = {
    cadastroDocumento: {},
    loading: false,
    pageConsulta: 0,
    filter: [
      {
        codigo: '',
        titulo: '',
        ano: '',
        numero: '',
        descricao: ''
      }
    ]
  };

  getCadastroDocumentoSuccess = response => {
    this.setState({
      cadastroDocumento: response.data,
      loading: false
    });
  };

  getCadastroDocumentoError = error => {
    this.setState({ loading: false });
    Alert.error(
      {
        title: 'Ocorreu uma falha ao carregar os Documentos Cadastrados'
      },
      error
    );
  };

  serviceSearch = (searchParams, page) => {
    this.setState({ loading: true });

    this.props.documentoService
      .loadDocumentosCadastrados(searchParams, page)
      .then(this.getCadastroDocumentoSuccess)
      .catch(this.getCadastroDocumentoError);
  };

  onDeleteSuccess = () => {
    this.setState({ loading: false });
    swal('Excluido', 'Documento foi excluido!', 'success');

    this.searchWithPage(this.state.pageConsulta);
  };

  onDeleteError = error => {
    this.setState({ loading: false });
    Alert.error({ title: 'Não foi possível excluir o Documento.' }, error);
  };

  searchWithPage = page => {
    this.setState({ pageConsulta: page });
    this.serviceSearch(this.state.searchParams, page);
  };

  fields = [
    {
      label: 'Código',
      name: 'id',
      type: 'NUMBER'
    },
    {
      label: 'Título',
      name: 'titulo',
      type: 'STRING'
    },
    {
      label: 'Ano',
      name: 'ano',
      type: 'NUMBER'
    },
    {
      label: 'Número',
      name: 'numero',
      type: 'STRING'
    },
    {
      label: 'Descrição',
      name: 'descricao',
      type: 'STRING'
    }
  ];

  new = () => {
    this.props.history.push('/anexos-publicos/anexo-publico/novo');
  };

  edit = id => {
    this.props.history.push(`/anexos-publicos/anexo-publico/editar/${id}`);
  };

  exclude = id =>
    swal({
      title: 'Você tem certeza?',
      text: 'Esta ação não poderá ser revertida',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim'
    }).then(result => {
      if (result.value) {
        this.setState({ loading: true });
        this.props.documentoService
          .removerDocumentoCadastrado(id)
          .then(this.onDeleteSuccess)
          .catch(this.onDeleteError);
      }
    });

  render() {
    const { cadastroDocumento, loading } = this.state;
    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-cadastro-documento"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        <div className="panel table table-responsive">
          <div className="panel-body">
            <SearchFilter fields={this.fields} search={this.serviceSearch} />
            <AnexoPublicoList
              cadastroDocumento={cadastroDocumento}
              edit={this.edit}
              exclude={this.exclude}
              searchWithPage={this.searchWithPage}
            />
          </div>
        </div>
        <div className="btn-save">
          <FAB
            icon="plus"
            iconColor="white"
            title="Adicionar Novo"
            onClick={this.new}
          />
        </div>
      </Container>
    );
  }
}

export default withService({
  documentoService: DocumentoService
})(AnexoPublicoListPage);
