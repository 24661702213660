import {
  Alert,
  BasicInput,
  Button,
  Col,
  Container,
  FAB,
  Hint,
  Loading,
  NotificationActions,
  Row
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { Notification } from 'react-notification-system';
import { connect } from 'react-redux';

import { ViradaExercicioService } from '../../../service';
import { DeclaracaoMensalDividaConfig } from '../../../types/DeclaracaoMensalDividaConfig';
import { DeclaracaoMensalVencimento } from '../../../types/DeclaracaoMensalVencimento';
import { ViradaExercicio } from '../../../types/ViradaExercicio';
import DeclaracaoMensalDividaConfigList from './DeclaracaoMensalDividaConfigList';
import DeclaracaoVencimentoDividaList from './DeclaracaoVencimentoDividaList';
import { validationSchema } from './validationSchema';

type Props = {
  showNotification(notification: Notification): void;
};

const URL_HELP =
  'https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-virada-exercicio';

const initialValue: ViradaExercicio = {};

const ViradaExercicioFormPage: React.FC<Props> = ({ showNotification }) => {
  const [loading, setLoading] = useState(false);
  const [disabledBtnSave, setDisabledBtnSave] = useState(true);
  const [viradaExercicio, setViradaExercicio] = useState(initialValue);

  const atualizarViradaExercicio = () => {
    ViradaExercicioService.atualizarViradaExercicio(viradaExercicio)
      .then(() => {
        showNotification({
          level: 'success',
          message: 'Virada de exercício realizada com sucesso!'
        });

        onVerificarConfiguracaoExercicioEDivida(viradaExercicio);
      })
      .catch(error => {
        Alert.error(
          { title: 'Não foi possível atualizar a virada de exercício.' },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const onDisabledBtnSave = (
    dmsDividasConfig: DeclaracaoMensalDividaConfig[] | undefined,
    dmsVencimentos: DeclaracaoMensalVencimento[] | undefined
  ) => {
    if (dmsDividasConfig && dmsVencimentos) {
      const disabledBtnSave =
        dmsDividasConfig.filter(d => d.id === undefined || d.id < 0).length >
          0 ||
        dmsVencimentos.filter(d => d.id === undefined || d.id < 0).length > 0;
      setDisabledBtnSave(!disabledBtnSave);
    }
  };

  const onVerificarConfiguracaoExercicioEDivida = (
    viradaExercicio: ViradaExercicio
  ) => {
    setLoading(true);

    ViradaExercicioService.verificarConfiguracaoExercicioEDivida(
      viradaExercicio
    )
      .then((result: AxiosResponse<ViradaExercicio>) => {
        setViradaExercicio(result.data);
        onDisabledBtnSave(
          result.data.dmsDividasConfig,
          result.data.dmsVencimentos
        );
      })
      .catch(error => {
        Alert.error(
          { title: 'Não foi possível verificar a virada de exercício.' },
          error
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container
      breadcrumb
      titleRightComponent={
        <a href={URL_HELP} target="_blank" rel="noopener noreferrer">
          <Hint
            classes={`inline clean module-color center-right fa-question-circle`}
          >
            Ajuda?
          </Hint>
        </a>
      }
    >
      <Loading loading={loading} />
      <Formik<ViradaExercicio>
        enableReinitialize
        initialValues={initialValue}
        onSubmit={onVerificarConfiguracaoExercicioEDivida}
        validationSchema={() => validationSchema}
        render={(formProps: FormikProps<ViradaExercicio>) => (
          <>
            <Row>
              <BasicInput
                name="exercicioBase"
                label="Exercício base"
                size={2}
              />
              <BasicInput
                name="exercicioNovo"
                label="Exercício novo"
                size={2}
              />
              <Col md={3}>
                <div className="form-group">
                  <label className="label" />
                  <Button
                    data-test-id="submit-form-verificar"
                    onClick={formProps.submitForm}
                  >
                    Verificar Configurações
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        )}
      />

      {viradaExercicio.dmsDividasConfig && (
        <DeclaracaoMensalDividaConfigList
          dmsDividasConfig={viradaExercicio.dmsDividasConfig}
        />
      )}

      {viradaExercicio.dmsVencimentos && (
        <DeclaracaoVencimentoDividaList
          dmsVencimentos={viradaExercicio.dmsVencimentos}
        />
      )}

      <div className="btn-save">
        <FAB
          data-test-id="btn-save"
          disabled={disabledBtnSave}
          icon="check"
          onClick={atualizarViradaExercicio}
          title="Salvar"
        />
      </div>
    </Container>
  );
};
const ConnectedComponent = connect(null, {
  showNotification: NotificationActions.showNotification
})(ViradaExercicioFormPage);

export { ConnectedComponent as default, ViradaExercicioFormPage };
