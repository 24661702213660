import {
  Alert,
  Container,
  Hint,
  Loading,
  SearchFilter
} from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { CancelamentoNotaFiscalService, withService } from '../../../service';
import CancelamentoNfseList from './CancelamentoNfseList';

export class CancelamentoNfseListPage extends Component {
  static propTypes = {
    cancelamentoNotaFiscalService: PropTypes.object.isRequired
  };

  state = {
    historicoSolicitacoesCancelamentoNota: {},
    loading: false,
    pageConsulta: 0
  };

  fields = [
    {
      label: 'Situação',
      name: 'status',
      type: 'ENUM',
      options: [
        {
          name: 'AGUARDANDO_FISCAL',
          descricao: 'Aguardando Fiscal'
        },
        {
          name: 'AUTORIZADO',
          descricao: 'Autorizado'
        },
        {
          name: 'AGUARDANDO_TOMADOR',
          descricao: 'Aguardando Tomador'
        },
        {
          name: 'AGUARDANDO_PRESTADOR',
          descricao: 'Aguardando Prestador'
        },
        {
          name: 'REJEITADO',
          descricao: 'Rejeitado'
        }
      ]
    },
    {
      label: 'Nome Solicitante',
      name: 'solicitacaoCancelamento.cadastroSolicitante.pessoa.nome',
      type: 'STRING'
    },
    {
      label: 'Cnpj Solicitante',
      name: 'solicitacaoCancelamento.cadastroSolicitante.pessoa.cnpjCpf',
      type: 'STRING'
    },
    {
      label: 'Nº Nota Fiscal',
      name: 'solicitacaoCancelamento.notaFiscal.numeroNotaFiscal',
      type: 'NUMBER'
    }
  ];

  searchWithPage = page => {
    this.setState({ pageConsulta: page });
    this.serviceSearch(this.state.searchParams, page);
  };

  serviceSearch = (searchParams, page) => {
    this.setState({
      loading: true,
      searchParams
    });

    this.props.cancelamentoNotaFiscalService
      .loadCancelamentosNotasFiscais(searchParams, page)
      .then(this.getCancelamentosNotasFiscaisSuccess)
      .catch(this.getCancelamentosNotasFiscaisError);
  };

  getCancelamentosNotasFiscaisSuccess = response => {
    this.setState({
      historicoSolicitacoesCancelamentoNota: response.data,
      loading: false
    });
  };

  getCancelamentosNotasFiscaisError = error => {
    this.setState({ loading: false });
    Alert.error(
      { title: 'Não foi possível carregar as solicitações de cancelamento.' },
      error
    );
  };

  analisarSolicitacaoCancelamento = id => {
    this.props.history.push(
      `/acessos-e-permissoes/cancelamento-nfse/analisar/${id}`
    );
  };

  render() {
    const { historicoSolicitacoesCancelamentoNota, loading } = this.state;
    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-solicitacao-cancelamento-nfse"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        <div className="panel table table-responsive">
          <div className="panel-body">
            <SearchFilter fields={this.fields} search={this.serviceSearch} />
            <CancelamentoNfseList
              historicoSolicitacoesCancelamentoNota={
                historicoSolicitacoesCancelamentoNota
              }
              searchWithPage={this.searchWithPage}
              analisarSolicitacaoCancelamento={
                this.analisarSolicitacaoCancelamento
              }
            />
          </div>
        </div>
      </Container>
    );
  }
}

const ComponentWithService = withService({
  cancelamentoNotaFiscalService: CancelamentoNotaFiscalService
})(CancelamentoNfseListPage);

export default ComponentWithService;
