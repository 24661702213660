import BatchStatusChip from './BatchStatusChip';
import BooleanChip from './BooleanChip';
import * as icons from './icons/IconsLibrary';
import NotificacaoPage from './NotificacaoPage';
import RouteWithRedirect from './routes/RouteWithRedirect';

const IconLibrary = Object.values(icons);

export {
  BatchStatusChip,
  BooleanChip,
  IconLibrary,
  RouteWithRedirect,
  NotificacaoPage
};
