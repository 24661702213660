import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { ErrorBoundary } from '../../service/BugsnagService';

const RouteWithRedirect = ({
  path,
  redirectTo,
  shouldRedirect,
  hasPermission,
  component: Component,
  ...props
}) => {
  return (
    <Route
      path="path"
      {...props}
      render={renderProps => (
        <ErrorBoundary {...renderProps}>
          {hasPermission !== undefined && !hasPermission ? (
            <Redirect to={'/sem-permissao'} />
          ) : (
            undefined
          )}

          {shouldRedirect && path !== redirectTo ? (
            <Redirect to={redirectTo} />
          ) : (
            <Component {...renderProps} />
          )}
        </ErrorBoundary>
      )}
    />
  );
};
RouteWithRedirect.propTypes = {
  redirectTo: PropTypes.string,
  shouldRedirect: PropTypes.bool,
  havePermission: PropTypes.bool,
  computedMatch: PropTypes.object, // private, from <Switch>
  path: PropTypes.string,
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  sensitive: PropTypes.bool,
  component: PropTypes.elementType,
  render: PropTypes.func,
  location: PropTypes.object
};

export default RouteWithRedirect;
