import { ReactComponent as Logo } from '@elotech/arc/src/assets/img/modules/oxy-arrecadacao.svg';
import { Header, KeycloakService } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { ControleIntegracaoControl } from './ControleIntegracaoControl';
import JobButton from './JobButton';

class AppHeader extends React.Component {
  state = {
    showQuickView: false,
    buildInfo: {}
  };
  onLogout = () => {
    KeycloakService.logout();
  };

  onMyAccount = () => {
    this.props.history.push('/minha-conta');
  };

  onClickNovidadesVersao = () => {
    window.open(
      'https://elotech.movidesk.com/kb/pt-br/category/arr-oxy-iss',
      '_blank'
    );
  };

  render() {
    const { currentUser, showToggleButton } = this.props;

    return (
      <React.Fragment>
        <Header
          title={this.props.entidade.nome}
          brasao={this.props.entidade.brasao}
          showToggleButton={showToggleButton}
          logoModulo={Logo}
        >
          <Header.Features>
            <ControleIntegracaoControl />
            <JobButton />
            <Header.Features.AccessibilityButton />
            <Header.Features.NotificationButton />
            <Header.Features.AjustesButton />
            <Header.Features.ModulosButton />
          </Header.Features>
          <Header.FeaturesContent>
            <Header.FeaturesContent.AccessibilityContent />
            <Header.FeaturesContent.EloNotificationContent />
            <Header.FeaturesContent.AjustesContent
              userProfile={currentUser}
              onLogout={this.onLogout}
              showNovidadesVersao={true}
              onClickNovidadesVersao={this.onClickNovidadesVersao}
            />
            <Header.FeaturesContent.ModulosContent />
          </Header.FeaturesContent>
        </Header>
      </React.Fragment>
    );
  }
}

AppHeader.propTypes = {
  userExists: PropTypes.bool,
  currentUser: PropTypes.object
};

const mapStateToProps = state => ({
  entidade: state.entidade.current
});

const connectedComponent = connect(mapStateToProps)(AppHeader);

export default withRouter(connectedComponent);
