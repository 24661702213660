import {
  Container,
  Hint,
  NotificationActions,
  Wizard
} from '@elotech/components';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ConfigEmailFields from './EmailFields';
import ConfigMensagemFields from './MensagemFields';

export class ConfigEmailMensagemPage extends Component {
  state = {
    step1: { valid: true, errorMessage: undefined },
    step2: { valid: true, errorMessage: undefined },

    configEmailInput: {
      nome: 'Lorem Ipsum Lorem Ipsum',
      email: 'email@dominio.com.br',
      servidorSMTP: 'zmail.barbosaferraz.pr.gov.br',
      portaTCP: '587',
      servidorAutenticacao: false,
      servidorConexaoSegura: false,
      pop3Autenticacao: false,
      pop3ConexaoSegura: false,
      servidorPOP3: 'zmail.barbosaferraz.pr.gov.br',
      portaTCP2: '100',
      usuario: 'nfse@barbosaferraz.pr.gov.br',
      senha: '123456'
    },

    configMensagemInput: {
      emitirNfse: 'Notificação de Emissão de NFS-e',
      substituirNfse: 'Notificação de Substituição de NFS-e',
      cancelarNfse: 'Notificação de Cancelamento de NFS-e',
      movimentacaoDeCancelamentoNfse:
        'Notificação de Solicitação de Cancelamento',
      envioSenha: 'Notificação de Cadastro de Usuário - Mais ISS',
      confirmacaoHomologacao: 'Notificação de Homologação - Mais ISS',
      confirmacaoHomologacaoTemporaria: 'Notificação de Homologação Temporária',
      confirmacaoRejeicaoHomolagacao: 'Notificação de Homologação Rejeitada',
      aprovacaoCancelamentoDMS: 'Notificação de Cancelamento de DMS Aprovado',
      rejeicaoCancelamentoDMS: 'Notificação de Cancelamento de DMS Rejeitado',
      envioSenhaWebService: 'Notificação WebService - Mais ISS'
    }
  };

  onBeforeChange = async (oldStepData, newStepData) => {
    if (newStepData.index < oldStepData.index) {
      return { oldStepData, newStepData };
    }

    return { oldStepData: { ...oldStepData, valid: true }, newStepData };
  };

  onChangeInputValue = event => {
    const { name, value, checked, type } = event.target;
    this.setState(state => {
      const { configEmailInput, configMensagemInput } = state;
      const novoValor = type === 'checkbox' ? checked : value;
      return {
        configEmailInput: { ...configEmailInput, [name]: novoValor },
        configMensagemInput: { ...configMensagemInput, [name]: novoValor }
      };
    });
  };

  writeMessage = () => {
    this.props.history.push('/configuracoes/email-mensagem/mensagem');
  };

  onFinish = () => {
    this.props.showNotification({
      level: 'success',
      message: 'Salvo com Sucesso!'
    });
    this.props.history.replace('/');
  };

  render() {
    const { step1, step2, errorMessage } = this.state;

    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-configuracao-email-mensagem"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Wizard beforeChange={this.onBeforeChange} onFinish={this.onFinish}>
          <Wizard.Step
            stepId="1"
            label="Configurações de Email"
            icon="fa fa-at"
            valid={step1.valid}
            errorMessage={errorMessage}
          >
            <ConfigEmailFields
              configEmailInput={this.state.configEmailInput}
              onChange={this.onChangeInputValue}
            />
          </Wizard.Step>

          <Wizard.Step
            stepId="2"
            label="Configurações de Mensagem"
            icon="fa fa-envelope"
            valid={step2.valid}
            errorMessage={errorMessage}
          >
            <ConfigMensagemFields
              configMensagemInput={this.state.configMensagemInput}
              onChange={this.onChangeInputValue}
              mensagem={this.writeMessage}
            />
          </Wizard.Step>
        </Wizard>
      </Container>
    );
  }
}
const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

export default connect(null, mapDispatchToProps)(ConfigEmailMensagemPage);
