import { ActionButton } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyleViewList = styled.div`
  position: relative;
`;

const ViewList = props => {
  const { acessoEmpressas, onRemove } = props;

  return (
    <StyleViewList className="panel-table-outer">
      <table className="panel-table striped fancy">
        <thead>
          <tr>
            <th>Insc. Mun.</th>
            <th>CPF/CNPJ</th>
            <th>Nome/Razão Social</th>
            <th>Data Liberação</th>
            <th>Tipo Acesso</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {acessoEmpressas &&
            acessoEmpressas.map(empresa => {
              return (
                <tr key={empresa.id}>
                  <td>{empresa.inscricaoMunicipal}</td>
                  <td>{empresa.cpfCnpj}</td>
                  <td>{empresa.nome}</td>
                  <td>{empresa.dataLiberacao}</td>
                  <td>{empresa.tipoAcesso}</td>
                  <td>
                    {empresa.tipoAcesso === 'Secundário' && (
                      <div className="btn-actions">
                        <div className="btn-actions-inner">
                          <ActionButton
                            data-test-id={`buttonRemoveAccess${empresa.id}`}
                            key="RemoveAccess-button"
                            icon="trash-alt"
                            label="Remover Acesso"
                            onClick={() => onRemove(empresa.id)}
                          />
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          {(!acessoEmpressas || acessoEmpressas.length === 0) && (
            <tr>
              <td style={{ textAlign: 'center' }} colSpan="6">
                Sem registros
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </StyleViewList>
  );
};

ViewList.propTypes = {
  acessoEmpressas: PropTypes.array.isRequired,
  onRemove: PropTypes.func
};
export default ViewList;
