import {
  Alert,
  Container,
  FAB,
  Loading,
  NotificationActions
} from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  EditarNotaFiscalService,
  TomadorService,
  withService
} from './../../../service';
import EditarTomadorForm from './EditarTomadorForm';

export class EditarTomadorFormPage extends Component {
  static propTypes = {
    editarNotaFiscalService: PropTypes.object.isRequired,
    tomadorService: PropTypes.object.isRequired
  };

  state = {
    dadosNfse: {
      usuario: {},
      motivo: ''
    },
    motivo: '',
    loading: true
  };

  componentDidMount() {
    const { id } = this.props.match.params;

    if (id) {
      this.props.editarNotaFiscalService
        .loadEditarNotaFiscalVo(id)
        .then(this.loadEditarNotaFiscalVoSuccess)
        .catch(this.loadEditarNotaFiscalVoError)
        .finally(() => this.setState({ loading: false }));
    }
  }

  loadEditarNotaFiscalVoSuccess = response => {
    this.setState(
      {
        dadosNfse: response.data
      },
      () => {
        this.setState(state => {
          const { dadosNfse } = state;
          return {
            dadosNfse: {
              ...dadosNfse,
              usuario: this.props.usuario
            }
          };
        });
      }
    );
  };

  loadEditarNotaFiscalVoError = error => {
    Alert.error(
      {
        title: 'Erro',
        text: 'Não foi possível carregar a Nota Fiscal.'
      },
      error
    );
  };

  onChangeCheckbox = event => {
    const { name } = event.target;
    this.setState(state => {
      const { dadosNfse } = state;
      return {
        dadosNfse: { ...dadosNfse, [name]: !dadosNfse[name] }
      };
    });
  };

  motivoEdicaoTomadorValidator = value => {
    return new Promise(resolve => {
      if (value) {
        resolve();
      } else {
        resolve('Descreva o motivo da edição!');
      }
    });
  };

  onSaveEditarPrestador = () => {
    Alert.question({
      title: 'Qual o motivo da edição?',
      input: 'textarea',
      inputPlaceholder: 'Descreva o motivo da edição',
      inputValidator: this.motivoEdicaoTomadorValidator,
      confirmButtonText: 'Salvar e notificar',
      cancelButtonText: 'Cancelar'
    })
      .then(result => {
        if (result.value) {
          const { dadosNfse } = this.state;

          const edicaoNotaFiscalVo = {
            ...dadosNfse,
            motivo: result.value
          };

          this.props.editarNotaFiscalService
            .salvarEdicaoNotaFiscal(edicaoNotaFiscalVo)
            .then(this.salvarEdicaoSuccess)
            .catch(this.salvarEdicaoError)
            .finally(this.setState({ loading: false }));
        }
      })
      .catch(error => {
        Alert.error(
          {
            title: 'Erro',
            text: 'Não foi possível editar a Nota Fiscal.'
          },
          error
        );
      });
  };

  salvarEdicaoSuccess = () => {
    Alert.success({ title: 'Edição Finalizada' });
    this.props.history.goBack();
  };

  salvarEdicaoError = error => {
    Alert.error(
      {
        title: 'Erro',
        text: 'Ocorreu um problema ao tentar carregar a Nota Fiscal.'
      },
      error
    );
  };

  optionLabel = option => {
    if (option.pessoa) {
      return option.cadastroGeral + ' - ' + option.pessoa.nome;
    }
    return '';
  };

  onSelectTomador = (name, value) => {
    this.setState(state => {
      const { dadosNfse } = state;
      return {
        dadosNfse: { ...dadosNfse, [name]: value }
      };
    });
  };

  render() {
    const { dadosNfse, loading } = this.state;
    const { tomadorService } = this.props;

    return (
      <Container breadcrumb>
        <Loading loading={loading} />
        {!loading && (
          <EditarTomadorForm
            dadosNfse={dadosNfse}
            onChangeCheckbox={this.onChangeCheckbox}
            optionLabel={this.optionLabel}
            loadCadastrosMobiliarios={tomadorService.autocomplete}
            onSelect={this.onSelectTomador}
          />
        )}
        <div className="btn-save">
          <FAB
            data-test-id="buttonSave"
            typeButton="module-color"
            icon="check"
            title="Salvar"
            onClick={this.onSaveEditarPrestador}
          />
        </div>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const mapStateToProps = state => ({
  usuario: state.user.profile
});

const ComponentWithService = withService({
  editarNotaFiscalService: EditarNotaFiscalService,
  tomadorService: TomadorService
})(EditarTomadorFormPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithService);
