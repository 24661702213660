import { Col, ErrorText, FAB, InputInteger, Row } from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const errorMessages = {
  titulo: 'Este campo é obrigatório!'
};

const GrupoAnexoPublico = props => {
  const { save, dadosDocumento, error, onChangeInputValue } = props;

  return (
    <>
      <form className="form">
        <Row>
          <Col md={2}>
            <div className="form-group">
              <label htmlFor="codigo">Código</label>
              <InputInteger
                disabled
                name="codigo"
                placeholder=""
                value={dadosDocumento.id}
                onChange={onChangeInputValue}
              />
            </div>
          </Col>
          <Col md={8}>
            <div className="form-group">
              <label htmlFor="titulo">Título</label>
              <input
                className={error.titulo ? 'error' : ''}
                type="text"
                name="titulo"
                placeholder=""
                value={dadosDocumento.titulo}
                onChange={onChangeInputValue}
              />
              {error.titulo && <ErrorText>{errorMessages.titulo}</ErrorText>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={10}>
            <div className="form-group">
              <label htmlFor="tipodeDescricao">Descrição</label>
              <textarea
                className={error.descricao ? 'error' : ''}
                name="descricao"
                placeholder=""
                value={dadosDocumento.descricao}
                onChange={onChangeInputValue}
              />
              {error.descricao && <ErrorText>{errorMessages.titulo}</ErrorText>}
            </div>
          </Col>
        </Row>
      </form>
      <div className="btn-save">
        <FAB
          data-test-id="buttonSave"
          icon="check"
          iconColor="white"
          title="Salvar"
          onClick={save}
        />
      </div>
    </>
  );
};

GrupoAnexoPublico.propTypes = {
  save: PropTypes.func.isRequired,
  dadosDocumento: PropTypes.object,
  error: PropTypes.object,
  onChangeInputValue: PropTypes.func.isRequired
};

export default GrupoAnexoPublico;
