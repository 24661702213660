import './textarea.css';

import {
  Col,
  Container,
  NotificationActions,
  Row,
  SectionTitle
} from '@elotech/components';
import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';

export class TextareaMensagem extends Component {
  state = {
    assunto: '',
    body: ''
  };

  onHandleChange = e => {
    this.setState({ body: e });
  };

  onChange = e => {
    this.setState({ assunto: e.target.value });
  };

  onSubmit = () => {
    this.props.showNotification({
      level: 'success',
      message: 'Enviado!'
    });
    this.props.history.replace('/configuracoes/email-mensagem');
  };

  onCancel = () => {
    this.props.history.push('/configuracoes/email-mensagem');
  };

  render() {
    return (
      <Container breadcrumb>
        <SectionTitle marginTop="0">Escrever Mensagem</SectionTitle>
        <form>
          <div className="form-group">
            <input
              value={this.state.assunto}
              type="text"
              name="assunto"
              placeholder="Assunto"
              onChange={this.onChange}
              ref="assunto"
            />
          </div>
          <div className="form-group">
            <ReactQuill
              modules={TextareaMensagem.modules}
              formats={TextareaMensagem.formats}
              value={this.state.body}
              placeholder="Escrever Mensagem"
              onChange={this.onHandleChange}
            />
          </div>
          <div className="form group">
            <Row>
              <Col sm={6} md={1}>
                <label className="label" />
                <button
                  data-test-id="button-enviar"
                  className="btn module-color"
                  onClick={this.onSubmit}
                >
                  Enviar
                </button>
              </Col>
              <Col sm={6} md={1}>
                <label className="label" />
                <button
                  data-test-id="button-cancel"
                  className="btn neutral"
                  onClick={this.onCancel}
                >
                  cancelar
                </button>
              </Col>
            </Row>
          </div>
        </form>
        <br />
      </Container>
    );
  }
}

TextareaMensagem.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
    ['code-block'],
    [{ align: [] }]
  ]
};

TextareaMensagem.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'background',
  'color',
  'bullet',
  'link',
  'image',
  'code-block',
  'align'
];

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

export default connect(null, mapDispatchToProps)(TextareaMensagem);
