import {
  ActionButton,
  ActionsGroup,
  Alert,
  Container,
  FAB,
  Field,
  FormattedDate,
  Panel,
  SearchFilter,
  SearchPagination,
  Table,
  usePagedQuery
} from '@elotech/components';
import { BatchStatusChip } from 'iss-common/components';
import React, { useCallback } from 'react';

import { ControleDeclaracaoMensalNaoEntregueService } from '../../../service';
import { DeclaracaoMensalNaoEntregueControle } from '../../../types';
import { getFiltrotext } from './FiltroUtil';

type Props = {};

const searchFields: Field[] = [
  {
    label: 'Controle',
    name: 'id',
    type: 'NUMBER'
  },
  {
    label: 'Data Geração',
    name: 'dataGeracao',
    type: 'DATE'
  },
  {
    label: 'Usuário',
    name: 'usuario',
    type: 'STRING'
  }
];

const ControleDeclaracaoMensalNaoEntregueListPage: React.FC<Props> = props => {
  const {
    loading,
    doSearch,
    doPagedSearch,
    values,
    pagination
  } = usePagedQuery<DeclaracaoMensalNaoEntregueControle>({
    search: ControleDeclaracaoMensalNaoEntregueService.search,
    onError: useCallback(error => {
      Alert.error(
        { title: `Erro na pesquisa do controle de DMS não entregues` },
        error
      );
    }, [])
  });

  return (
    <Container breadcrumb>
      <Panel isTable>
        <SearchFilter fields={searchFields} search={doSearch} />
        <Table values={values} loading={loading}>
          <Table.Column<DeclaracaoMensalNaoEntregueControle>
            header="Controle"
            name="id"
            value={item => item.id}
          />
          <Table.Column<DeclaracaoMensalNaoEntregueControle>
            header="Data Geração"
            name="dataGeracao"
            value={item => <FormattedDate value={item.dataGeracao} />}
          />
          <Table.Column<DeclaracaoMensalNaoEntregueControle>
            header="Usuário"
            name="usuario"
            value={item => item.usuario}
          />
          <Table.Column<DeclaracaoMensalNaoEntregueControle>
            header="Filtro"
            name="filtro"
            value={item => getFiltrotext(item.filtro)}
          />
          <Table.Column<DeclaracaoMensalNaoEntregueControle>
            header="Situação do Processamento"
            value={item => (
              <BatchStatusChip
                jobStatus={item.batchJobExecutionControle?.status}
              />
            )}
          />

          <Table.Column<DeclaracaoMensalNaoEntregueControle>
            header=""
            value={item => (
              <ActionsGroup>
                <ActionButton
                  key="view-button"
                  icon="eye"
                  label="Visualizar Resultado"
                  path={`/rotinas/controle-dms-nao-entregues/${item.id}/resumo`}
                />
              </ActionsGroup>
            )}
          />
        </Table>

        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
      <div className="btn-save">
        <FAB
          icon="plus"
          title="Nova Consulta"
          path="/rotinas/controle-dms-nao-entregues/novo"
        />
      </div>
    </Container>
  );
};

export default ControleDeclaracaoMensalNaoEntregueListPage;
