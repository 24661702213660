import { FormikTextArea, SectionTitle } from '@elotech/components';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { Denuncia } from '../../../types/Denuncia';
import { CONCLUIDO } from '../SituacaoDenuncia';

type Props = {
  denuncia: Denuncia;
};

const RespostaSection: React.FC<Props> = ({ denuncia }) => {
  return (
    <>
      <SectionTitle>
        Resposta ao Denunciante
        <p className="text-muted">
          <small>
            Poderá será enviada ao denunciante ao concluir a denúncia
          </small>
        </p>
      </SectionTitle>

      <Row>
        <Col>
          <FormikTextArea
            name="resposta"
            noLabel
            size={12}
            disabled={denuncia.situacao === CONCLUIDO}
          />
        </Col>
      </Row>
    </>
  );
};

export default RespostaSection;
