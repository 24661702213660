import {
  Alert,
  Container,
  FAB,
  Hint,
  Loading,
  SearchFilter
} from '@elotech/components';
import React, { Component } from 'react';
import swal from 'sweetalert2';

import { ConfiguracaoDividaService, withService } from '../../../service';
import DividaDmsList from './DividaDmsList';

export class DividaDmsListPage extends Component {
  state = {
    loading: false,
    configuracaoDivida: {},
    pageConsulta: 0
  };

  getFields = [
    {
      label: 'Exercício',
      name: 'exercicio',
      type: 'NUMBER'
    },
    {
      label: 'Tipo Declaracao',
      name: 'tipoDeclaracao',
      type: 'ENUM',
      options: [
        {
          name: 'selecione',
          descricao: 'Selecione'
        },
        {
          name: 'C',
          descricao: 'Construção Civil'
        },
        {
          name: 'R',
          descricao: 'Retificadora'
        },
        {
          name: 'F',
          descricao: 'Fiscalização ISS'
        },
        {
          name: 'S',
          descricao: 'Sem Movimento'
        },
        {
          name: 'M',
          descricao: 'Retificadora Sem Movimento'
        },
        {
          name: 'A',
          descricao: 'Declaração Avulsa'
        },
        {
          name: 'N',
          descricao: 'Normal'
        }
      ]
    },
    {
      label: 'Tipo Movimento',
      name: 'tipoMovimento',
      type: 'ENUM',
      options: [
        {
          name: 'selecione',
          descricao: 'Selecione'
        },
        {
          name: 'P',
          descricao: 'Prestador'
        },
        {
          name: 'T',
          descricao: 'Tomador'
        }
      ]
    },
    {
      label: 'Descricao Dívida',
      name: 'guiaRecolhimento.descricao',
      type: 'STRING'
    }
  ];

  searchWithPage = page => {
    this.setState({ pageConsulta: page });
    this.serviceSearch(this.state.searchParams, page);
  };

  serviceSearch = (searchParams, page) => {
    this.setState({
      loading: true,
      searchParams
    });
    this.props.configuracaoDividaService
      .loadConfiguracaoDivida(searchParams, page)
      .then(this.getConfiguracaoDividaSuccess)
      .catch(this.getConfiguracaoDividaError);
  };

  getConfiguracaoDividaSuccess = response => {
    this.setState({
      configuracaoDivida: response.data,
      loading: false
    });
  };

  getConfiguracaoDividaError = error => {
    this.setState({ loading: false });
    Alert.error(
      { title: 'Não foi possível carregar a Configuração de Dívida.' },
      error
    );
  };

  onExcludeSuccess = () => {
    this.setState({ loading: false });
    swal('Excluido', 'Configuração de Dívida excluido!', 'success');

    this.searchWithPage(this.state.pageConsulta);
  };

  onExcludeErrror = error => {
    this.setState({ loading: false });
    Alert.error(
      { title: 'Não foi possível excluir Configuração de Dívida.' },
      error
    );
  };

  onNew = () => {
    this.props.history.push('/configuracoes/divida-dms/novo');
  };

  exclude = id =>
    swal({
      title: 'Excluir?',
      text: 'Deseja excluir Configuração de Dívida?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim'
    }).then(result => {
      if (result.value) {
        this.setState({ loading: true });
        this.props.configuracaoDividaService
          .removerConfiguracaoDivida(id)
          .then(this.onExcludeSuccess)
          .catch(this.onExcludeErrror);
        swal('Excluido', '', 'success');
      }
    });

  onEdit = id => {
    this.props.history.push(`/configuracoes/divida-dms/editar/${id}`);
  };

  render() {
    const { configuracaoDivida, loading } = this.state;

    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/42175/oxy-issadmin-configuracao-de-divida"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        <div className="panel table table-responsive">
          <div className="panel-body">
            <SearchFilter fields={this.getFields} search={this.serviceSearch} />
            <DividaDmsList
              configuracaoDivida={configuracaoDivida}
              exclude={this.exclude}
              edit={this.onEdit}
              searchWithPage={this.searchWithPage}
            />
          </div>
        </div>
        <div className="btn-save">
          <FAB
            data-test-id="buttonNew"
            icon="plus"
            iconColor="white"
            title="Adicionar Novo"
            onClick={this.onNew}
          />
        </div>
      </Container>
    );
  }
}

const ComponentWithService = withService({
  configuracaoDividaService: ConfiguracaoDividaService
})(DividaDmsListPage);

export default ComponentWithService;
