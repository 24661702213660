import {
  Alert,
  BasicInput,
  Container,
  FAB,
  Hint,
  Loading,
  NotificationActions,
  Row
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { Formik, FormikProps } from 'formik';
import { History } from 'history';
import React from 'react';
import { Notification } from 'react-notification-system';
import { connect } from 'react-redux';
import { match } from 'react-router';

import { FebrabanService } from '../../../service';
import { Febraban } from '../../../types/Febraban';
import { validationSchema } from './validationSchema';

type Props = {
  match: match<{ id: string }>;
  history: Pick<History, 'replace'>;
  showNotification(notification: Notification): void;
};

type State = {
  febrabanForm: Febraban;
  loading: boolean;
};

const URL_HELP_CADASTROFEBRABAN =
  'https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-cadastro-febraban';

class CadastroFebrabanFormPage extends React.Component<Props, State> {
  state: State = {
    febrabanForm: {
      id: undefined,
      codigoCompensacao: '',
      nomeInstituicao: '',
      enderecoEletronico: ''
    },
    loading: false
  };

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      this.setState({ loading: true });
      FebrabanService.findById(match.params.id)
        .then(this.loadFebrabanByIdSuccess)
        .catch(this.loadFebrabanByIdError);
    }
  }

  loadFebrabanByIdSuccess = (response: AxiosResponse<Febraban>) => {
    this.setState({
      febrabanForm: response.data,
      loading: false
    });
  };

  loadFebrabanByIdError = () => {
    this.setState({ loading: false });
    Alert.error({
      message: 'Não foi possível obter cadastro FEBRABAN.'
    });
  };

  onSave = (values: Febraban) => {
    this.setState({ loading: false }, () => {
      FebrabanService.save(values)
        .then(() => {
          this.props.showNotification({
            level: 'success',
            message: 'Salvo com sucesso.'
          });
          this.props.history.replace('/acessos-e-permissoes/cadastro-febraban');
        })
        .catch((error: any) => {
          this.setState({ loading: false });
          Alert.error({ title: 'Ocorreu um erro ao salvar ' }, error);
        });
    });
  };

  render() {
    const { loading, febrabanForm } = this.state;
    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href={URL_HELP_CADASTROFEBRABAN}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        <Formik
          enableReinitialize
          initialValues={febrabanForm}
          onSubmit={this.onSave}
          validationSchema={() => validationSchema}
          render={(formProps: FormikProps<Febraban>) => (
            <>
              <Row>
                <BasicInput
                  name="codigoCompensacao"
                  type="text"
                  label="Cod. Compensação"
                  size={2}
                />
                <BasicInput
                  name="nomeInstituicao"
                  type="text"
                  label="Insituição"
                  size={4}
                />
              </Row>
              <Row>
                <BasicInput
                  name="enderecoEletronico"
                  type="text"
                  label="Endereço Eletrônico"
                  size={6}
                />
              </Row>
              <div className="btn-save">
                <FAB
                  data-test-id="btn-save"
                  icon="check"
                  onClick={formProps.submitForm}
                  title="Salvar"
                />
              </div>
            </>
          )}
        />
      </Container>
    );
  }
}
const ConnectedComponent = connect(null, {
  showNotification: NotificationActions.showNotification
})(CadastroFebrabanFormPage);

export { ConnectedComponent as default, CadastroFebrabanFormPage };
