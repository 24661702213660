import {
  ActionButton,
  ActionsGroup,
  Container,
  Field,
  Loading,
  Panel,
  SearchFilter,
  SearchPagination,
  Table,
  usePagedQuery
} from '@elotech/components';
import { History } from 'history';
import React from 'react';
import { FormattedDate } from 'react-intl';

import { CancelamentoGuiaService } from '../../../service';
import { CancelamentoGuia } from '../../../types/CancelamentoGuia';

type Props = {
  history: Pick<History, 'push'>;
};

const searchFields: Field[] = [
  {
    name: 'declaracaoGuiaAvulsa.cadastroGeral.pessoa.cnpjCpf',
    label: 'CPF/CNPJ',
    type: 'STRING'
  },
  {
    name: 'statusCancelamentoDMS',
    label: 'Situação',
    type: 'ENUM',
    options: [
      { name: 'AGUARDANDO_APROVACAO', descricao: 'Aguardando Aprovação' },
      { name: 'APROVADO', descricao: 'Aprovado' },
      { name: 'NAO_APROVADO', descricao: 'Não Aprovado' }
    ]
  },
  {
    name: 'declaracaoGuiaAvulsa.id',
    label: 'Solicitação',
    type: 'NUMBER'
  },
  {
    name: 'declaracaoGuiaAvulsa.cadastroGeral.pessoa.nome',
    label: 'Solicitante',
    type: 'STRING'
  },
  {
    name: 'dataSolicitacao',
    label: 'Data',
    type: 'DATE'
  }
];

const CancelamentoGuiaListPage: React.FC<Props> = ({ history }) => {
  const {
    doSearch,
    doPagedSearch,
    values,
    pagination,
    loading
  } = usePagedQuery({
    search: CancelamentoGuiaService.loadCancelamentosDms
  });

  const onViewClick = (id: number) => {
    history.push(`/acessos-e-permissoes/cancelamento-guia/${id}/visualizar`);
  };

  return (
    <Container breadcrumb>
      <Panel isTable>
        <Loading loading={loading} />
        <SearchFilter fields={searchFields} search={doSearch} />
        <Table values={values}>
          <Table.Column
            header="Solicitante"
            value={(cancelamento: CancelamentoGuia) =>
              cancelamento.nomeSolicitante
            }
          ></Table.Column>
          <Table.Column
            header="CPF/CNPJ"
            value={(cancelamento: CancelamentoGuia) => cancelamento.cnpjCpf}
          ></Table.Column>
          <Table.Column
            header="Nº Solicitação"
            value={(cancelamento: CancelamentoGuia) => cancelamento.numeroGuia}
          ></Table.Column>
          <Table.Column
            header="Situação"
            value={(cancelamento: CancelamentoGuia) => cancelamento.status}
          ></Table.Column>
          <Table.Column
            header="Data"
            value={(cancelamento: CancelamentoGuia) => (
              <FormattedDate
                value={cancelamento.dataSolicitacao}
                timeZone={'UTC'}
              />
            )}
          ></Table.Column>
          <Table.Column<CancelamentoGuia>
            header=""
            value={item => (
              <ActionsGroup>
                <ActionButton
                  data-testid="view-btn"
                  key={'view-button'}
                  icon="eye"
                  label="Visualizar"
                  onClick={() => onViewClick(item.id)}
                />
              </ActionsGroup>
            )}
          />
        </Table>
        {pagination && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
    </Container>
  );
};

export default CancelamentoGuiaListPage;
