import { FormattedDate, Panel, SectionTitle, Table } from '@elotech/components';
import React from 'react';

const RegimeFiscalList = ({ regimesFiscais }) => {
  return (
    <>
      <SectionTitle>Regimes Fiscais</SectionTitle>
      <Panel isTable className="mt-xs">
        <Table
          values={regimesFiscais || []}
          keyExtractor={regimeFiscal => regimeFiscal.idPreCadastroRegimeFiscal}
        >
          <Table.Column
            header="Regime Fiscal"
            value={regimeFiscal => regimeFiscal.descricaoRegimeFiscal}
          />
          <Table.Column
            header="Data Inicial"
            value={regimeFiscal => (
              <FormattedDate
                value={regimeFiscal.dataInicio || ''}
                timeZone="UTC"
              />
            )}
          />
          <Table.Column
            header="Data Final"
            value={regimeFiscal => (
              <FormattedDate
                value={regimeFiscal.dataFinal || ''}
                timeZone="UTC"
              />
            )}
          />
        </Table>
      </Panel>
    </>
  );
};
export default RegimeFiscalList;
