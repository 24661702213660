import axios, { AxiosPromise } from 'axios';

import { Febraban } from '../types/Febraban';
import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };

const search = (params: any) => (params ? `search=${params}` : '');

export const loadFebraban = (searchParams: string, page?: any) =>
  axios.get(`${CONTEXT_PATH}/febraban?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const save = (febrabanForm: Febraban): AxiosPromise<Febraban> =>
  febrabanForm.id === undefined
    ? axios.post(`${CONTEXT_PATH}/febraban/`, febrabanForm)
    : axios.put(`${CONTEXT_PATH}/febraban/${febrabanForm.id}`, febrabanForm);

export const findById = (id: number | string): AxiosPromise<Febraban> =>
  axios.get(`${CONTEXT_PATH}/febraban/${id}`);

export const deleteFebraban = (id?: number): AxiosPromise<Febraban> =>
  axios.delete(`${CONTEXT_PATH}/febraban/remover/${id}`);
