import {
  Alert,
  BasicInput,
  Container,
  FAB,
  FormikAutocomplete,
  Hint,
  NotificationActions,
  Row,
  SectionTitle
} from '@elotech/components';
import { Formik, FormikProps } from 'formik';
import { History } from 'history';
import React from 'react';
import { Notification } from 'react-notification-system';
import { connect } from 'react-redux';

import { ModeloRelatorioService } from '../../../service';
import { ModeloRelatorio, TipoRelatorio } from '../../../types/ModeloRelatorio';
import { validationSchema } from './validationSchema';

type Props = {
  username: string;
  history: Pick<History, 'replace'>;
  showNotification(notification: Notification): void;
};

const URL_HELP = 'teste';

type ModeloRelatorioEnum = {
  valor: string;
  descricao: string;
};

export type ModeloRelatorioForm = {
  id?: number;
  modeloRelatorio?: ModeloRelatorioEnum;
  nomeArquivo?: string;
  anexo?: File;
  versaoArquivo?: string;
};

const initialValue: ModeloRelatorioForm = {};

const ModeloRelatorioFormPage: React.FC<Props> = ({
  username,
  history,
  showNotification
}) => {
  const getBase64 = (file: File) => {
    if (file.size === 0) return;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        //@ts-ignore
        let encoded = reader!.result!.replace(/^data:(.*;base64,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = error => reject(error);
    });
  };

  const onSave = async (values: ModeloRelatorioForm) => {
    const modelo: ModeloRelatorio = {
      id: values.id,
      modeloRelatorioForm: values.modeloRelatorio!.valor,
      modeloArquivoBase64: await getBase64(values.anexo!),
      nomeArquivo: values.anexo!.name,
      versaoArquivo: values.versaoArquivo,
      usuario: username
    };

    ModeloRelatorioService.save(modelo)
      .then(() => {
        showNotification({
          level: 'success',
          message: 'Salvo com sucesso.'
        });
        history.replace('/configuracoes/modelo-de-relatorio');
      })
      .catch(() => {
        Alert.error({
          title: 'Não foi possível salvar o modelo de relatório.'
        });
      });
  };

  return (
    <Container
      breadcrumb
      titleRightComponent={
        <a href={URL_HELP} target="_blank" rel="noopener noreferrer">
          <Hint
            classes={`inline clean module-color center-right fa-question-circle`}
          >
            Ajuda?
          </Hint>
        </a>
      }
    >
      <Formik<ModeloRelatorioForm>
        enableReinitialize
        initialValues={initialValue}
        onSubmit={onSave}
        validationSchema={() => validationSchema}
        render={(formProps: FormikProps<ModeloRelatorioForm>) => (
          <>
            <SectionTitle marginTop="0">Novo Modelo de Relatório</SectionTitle>
            <Row>
              <FormikAutocomplete
                name="modeloRelatorio"
                label="Tipo Relatório"
                onSearch={ModeloRelatorioService.getTipoRelatorio}
                getOptionLabel={(tipoRelatorio: TipoRelatorio) =>
                  `${tipoRelatorio.descricao}`
                }
                getOptionValue={(tipoRelatorio: TipoRelatorio) =>
                  tipoRelatorio.valor
                }
                size={5}
              />
              <BasicInput
                name="anexo"
                label="Modelo Arquivo"
                size={4}
                render={() => (
                  <div className="file-uploader">
                    <input
                      accept=".jrxml"
                      type="file"
                      id="upload-file-input"
                      className="file-uploader-input"
                      onChange={(event: any) => {
                        formProps.setFieldValue('anexo', event.target.files[0]);
                      }}
                    />
                    <label
                      className="input"
                      data-title={
                        formProps.values.anexo
                          ? formProps.values.anexo.name
                          : 'Clique ou arraste para anexar'
                      }
                    />
                    <label className="file-uploader-icon" />
                  </div>
                )}
              />
            </Row>
            <div className="btn-save">
              <FAB
                data-test-id="btn-save"
                icon="check"
                onClick={formProps.submitForm}
                title="Salvar"
              />
            </div>
          </>
        )}
      />
    </Container>
  );
};
const ConnectedComponent = connect(
  (state: any) => ({ username: state.user.profile.username }),
  {
    showNotification: NotificationActions.showNotification
  }
)(ModeloRelatorioFormPage);

export { ConnectedComponent as default, ModeloRelatorioFormPage };
