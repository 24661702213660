import {
  Alert,
  Button,
  DisplayData,
  FormattedCurrency,
  FormattedDate,
  Icon,
  Loading,
  SectionTitle
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { DenunciaService, NotaFiscalService } from '../../../service';
import { Denuncia } from '../../../types/Denuncia';
import { NotaFiscal } from '../../../types/NotaFiscal';
import { RpsManual } from '../../../types/SolicitacaoRPS';

type Props = {
  denuncia: Denuncia;
};

const DocumentoSection: React.FC<Props> = ({ denuncia }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [notaFiscal, setNotaFiscal] = useState<NotaFiscal | undefined>(
    undefined
  );
  const [rpsManual, setRpsManual] = useState<RpsManual | undefined>(undefined);

  useEffect(() => {
    Promise.all([
      denuncia.id ? loadRpsManual(denuncia.id) : undefined,
      denuncia.id ? loadNotaFiscal(denuncia.id) : undefined
    ])
      .catch(error => {
        Alert.error(
          { title: 'Não foi possível carregar os dados de RPS/NotaFiscal' },
          error
        );
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [denuncia]);

  const loadRpsManual = (id: number) =>
    DenunciaService.loadRpsManual(id).then(
      (response: AxiosResponse<RpsManual>) => {
        setRpsManual(response.data);
      }
    );

  const loadNotaFiscal = (id: number) =>
    DenunciaService.loadNotaFiscal(id).then(
      (response: AxiosResponse<NotaFiscal>) => {
        setNotaFiscal(response.data);
      }
    );

  return (
    <>
      <SectionTitle>RPS Objeto da Denúncia</SectionTitle>

      <div className="display-data border small mb-xs">
        <Row>
          <Col md={4}>
            <DisplayData title="Número">{denuncia.numeroRPS}</DisplayData>
          </Col>
          <Col md={4}>
            <DisplayData title="Data de Emissão">
              <FormattedDate value={denuncia.dataEmissaoRPS} />
            </DisplayData>
          </Col>
          <Col md={4}>
            <DisplayData title="Valor">
              <FormattedCurrency value={denuncia.valorRPS} />
            </DisplayData>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <DisplayData
              title="Possui Solicitação RPS"
              titleStyle={{ width: '11em' }}
            >
              <Info<RpsManual>
                loading={loading}
                value={rpsManual}
                error={error}
                render={(rps: RpsManual) => (
                  <>
                    {rps.solicitacaoRps!.id} de{' '}
                    <FormattedDate value={rps.solicitacaoRps!.data} />
                  </>
                )}
              />
            </DisplayData>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <DisplayData
              title="Possui NFS-e Emitida"
              titleStyle={{ width: '11em' }}
            >
              <Info<NotaFiscal>
                loading={loading}
                value={notaFiscal}
                error={error}
                render={(nf: NotaFiscal) => (
                  <>
                    Nº {nf.numeroNotaFiscal} de{' '}
                    <FormattedDate value={nf.dataEmissao} />
                    {', '}
                    <FormattedCurrency value={nf.valorTotalNota} />
                  </>
                )}
              />
            </DisplayData>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              data-test-id="btn-open-rps"
              onClick={() =>
                window.open(
                  `/acessos-e-permissoes/solicitacoes-rps/analisar/${
                    rpsManual!.solicitacaoRps!.id
                  }`
                )
              }
              className="inline btn btn-link"
              disabled={!rpsManual}
            >
              Visualizar Solicitação RPS
            </Button>
            <Button
              data-test-id="btn-open-nfse"
              onClick={() =>
                NotaFiscalService.visualizarNotaFiscal(notaFiscal!.id || 0)
              }
              className="inline btn btn-link"
              disabled={!notaFiscal}
            >
              Visualizar NFS-e
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

type InfoProps<T> = {
  loading: boolean;
  value: T | undefined;
  error: boolean;
  render: any;
};

const Info = <T extends {}>({ loading, value, error, render }: InfoProps<T>) =>
  loading ? (
    <Loading loading={true} />
  ) : !!value ? (
    <>
      <Icon icon="thumbs-up" positive style={{ marginRight: '10px' }} />
      {render(value)}
    </>
  ) : (
    <span>{!error && 'Não'}</span>
  );

export default DocumentoSection;
