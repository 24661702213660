import { Button, Col, ErrorText, Row } from '@elotech/components';
import React from 'react';

const UploadArquivos = ({
  uploadArquivo = {
    nomeArquivo: '',
    arquivoBase64: ''
  },
  onUploadFile,
  onImportCnpj,
  onClick,
  error
}) => {
  const nomeArquivo =
    uploadArquivo &&
    uploadArquivo.nomeArquivo &&
    uploadArquivo.nomeArquivo.length > 0
      ? uploadArquivo.nomeArquivo
      : 'Clique ou arraste para anexar';

  const classError =
    error.tamanho || error.extensao || error.vazio ? ' error' : '';

  return (
    <Row>
      <Col md={6}>
        <div className="form-group">
          <div className="file-uploader">
            <input
              id="upload-file-input"
              type="file"
              name="arquivoBase64"
              accept=".zip"
              multiple={false}
              className={`file-uploader-input ${classError}`}
              data-title={nomeArquivo}
              onChange={onUploadFile}
              onClick={onClick}
            />

            <label
              htmlFor="upload-file-input"
              className="input"
              data-title={nomeArquivo}
            />
            <label htmlFor="upload-file-input" className="file-uploader-icon" />
            <ErrorText>{error.tamanho}</ErrorText>
            <ErrorText>{error.extensao}</ErrorText>
            <ErrorText>{error.vazio}</ErrorText>
          </div>
        </div>
      </Col>
      <Col md={3}>
        <div className="form-group">
          <Button className="inline" iconPosition="left" onClick={onImportCnpj}>
            <i className="fa fa-file-import" />
            IMPORTAR
          </Button>
        </div>
      </Col>
    </Row>
  );
};
export default UploadArquivos;
