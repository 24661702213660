import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'id' };

const search = params => (params ? `search=${params}` : '');

export const loadIncentivosFiscais = (searchParams, page) =>
  axios.get(`${CONTEXT_PATH}/incentivo-fiscal?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const save = incentivoFiscal => {
  if (incentivoFiscal.id > 0) {
    return axios.put(
      `${CONTEXT_PATH}/incentivo-fiscal/${incentivoFiscal.id}`,
      incentivoFiscal
    );
  }
  return axios.post(`${CONTEXT_PATH}/incentivo-fiscal`, incentivoFiscal);
};

export const loadIncentivoFiscalById = id =>
  axios.get(`${CONTEXT_PATH}/incentivo-fiscal/${id}`);

export const removerIncentivoFiscal = id =>
  axios.delete(`${CONTEXT_PATH}/incentivo-fiscal/remover/${id}`);
