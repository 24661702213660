import {
  ActionButton,
  FormattedDate,
  SearchPagination,
  formatUtils
} from '@elotech/components';
import PropTypes from 'prop-types';
import React from 'react';

const ConsultarAgendamentoList = ({
  agendamentos,
  searchWithPage,
  atualizarEmpresa,
  credenciarEmpresa,
  loading
}) => {
  return (
    !loading && (
      <div>
        <div className="panel-table-outer ">
          <table className="panel-table striped fancy">
            <thead>
              <tr>
                <th>Número</th>
                <th>Data</th>
                <th>Nome/Razão Social</th>
                <th>CPF/CNPJ</th>
                <th>Situação</th>
                <th>Tipo Agendamento</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {agendamentos &&
                agendamentos.content &&
                agendamentos.content.map(agendamento => {
                  return (
                    <tr key={agendamento.idAgendamento}>
                      <td>{agendamento.idAgendamento}</td>
                      <td>
                        <FormattedDate
                          value={agendamento.data}
                          timeZone={'UTC'}
                        />
                      </td>
                      <td>{agendamento.razao}</td>
                      <td>{formatUtils.formatCpfCnpj(agendamento.cnpjCpf)}</td>
                      <td>{agendamento.situacao}</td>
                      <td>{agendamento.tipoAgendamento}</td>
                      <td>
                        <div className="btn-actions">
                          <div className="btn-actions-inner">
                            <ActionButton
                              data-test-id={`buttonCredenciamento${agendamento.idAgendamento}`}
                              icon={
                                agendamento.situacao !== 'Não Autorizado'
                                  ? 'eye'
                                  : 'medal'
                              }
                              label={
                                agendamento.situacao !== 'Não Autorizado'
                                  ? 'Visualizar'
                                  : 'Credenciar'
                              }
                              onClick={() => {
                                agendamento.tipoAgendamento === 'Credenciamento'
                                  ? credenciarEmpresa(
                                      agendamento.idHistoricoAgendamento
                                    )
                                  : atualizarEmpresa(
                                      agendamento.idHistoricoAgendamento
                                    );
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              {(!agendamentos || agendamentos.totalElements === 0) && (
                <tr>
                  <td
                    data-test-id="msgNenhumRegistroEncontrado"
                    style={{ textAlign: 'center' }}
                    colSpan="7"
                  >
                    Nenhum registro encontrado.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {agendamentos && (
          <SearchPagination
            page={agendamentos}
            searchWithPage={searchWithPage}
          />
        )}
      </div>
    )
  );
};

ConsultarAgendamentoList.propTypes = {
  agendamentos: PropTypes.object.isRequired,
  searchWithPage: PropTypes.func.isRequired,
  accreditButtonAtualizacaoCadastral: PropTypes.func,
  accreditButtonCredencimento: PropTypes.func,
  loading: PropTypes.bool.isRequired
};

export default ConsultarAgendamentoList;
