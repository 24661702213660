import {
  BasicInput,
  Col,
  Container,
  FAB,
  FabSpeedDial,
  FormikAutocomplete,
  FormikTextArea,
  Loading,
  Row
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { Formik, FormikProps } from 'formik';
import { History } from 'history';
import { Alert } from 'iss-common/utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router';

import { EM_ANALISE, NOVA, RESPONDIDA } from '../../enums/SituacaoCaixaPostal';
import { CaixaPostalService, PessoaService } from '../../service';
import { Pessoa } from '../../types';
import { CaixaPostal } from '../../types/CaixaPostal';
import CaixaPostalSection from './CaixaPostalSection';

type Props = {
  match: match<{ id: string }>;
  history: Pick<History, 'replace'>;
};

const CaixaPostalFormPage: React.FC<Props> = ({ match, history }) => {
  const { id } = match.params;
  const [loading, setLoading] = useState(false);
  const [mensagem, setMensagem] = useState<CaixaPostal>();

  const load = (id: string) => {
    if (id === 'novo') {
      setLoading(false);
      return;
    }
    setLoading(true);

    CaixaPostalService.loadMensagem(+id)
      .then((result: AxiosResponse<CaixaPostal>) => {
        setMensagem(result.data);
      })
      .catch((error: any) => {
        setLoading(false);
        Alert.error({ title: `Erro ao carregar os dados da mensagem` }, error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    load(id);
  }, [id]);

  const onEdit = (formProps: FormikProps<CaixaPostal>) => {
    setLoading(true);
    CaixaPostalService.iniciarAnalise(formProps.values)
      .then(() => {
        load(id);
      })
      .catch((error: any) => {
        Alert.error({ title: 'Erro ao salvar resposta' }, error);
      })
      .finally(() => setLoading(false));
  };

  const onSave = (values: CaixaPostal) => {
    CaixaPostalService.saveMensagem(values)
      .then(response => {
        setLoading(true);
        history.replace('/caixa-postal');
      })
      .catch((error: any) => {
        Alert.error({ title: 'Erro ao salvar resposta' }, error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Container breadcrumb status={id === 'novo' ? 'new' : 'edit'}>
        <Loading loading={loading} />
        <Formik
          enableReinitialize
          initialValues={mensagem || {}}
          onSubmit={onSave}
        >
          {(formProps: FormikProps<CaixaPostal>) => (
            <>
              {mensagem && (
                <>
                  <CaixaPostalSection caixaPostal={mensagem} />
                  {formProps.values.situacao === EM_ANALISE && (
                    <>
                      <Row>
                        <Col>
                          <FormikTextArea
                            name="resposta"
                            data-test-id="resposta-field"
                            label="Resposta"
                            size={12}
                            disabled={
                              mensagem.situacao === RESPONDIDA ||
                              mensagem.situacao === NOVA
                            }
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
              {id === 'novo' && (
                <>
                  <Row>
                    <BasicInput name="titulo" label="TÍtulo" />
                    <Col>
                      <FormikAutocomplete
                        data-test-id="autocomplete-pessoa"
                        name="pessoaVo"
                        placeholder="Pesquisar por Cnpj/Cpf ou Nome"
                        label="Pessoa/Empresa (CPF/CNPJ)"
                        onSearch={PessoaService.search}
                        size={3}
                        getOptionLabel={(value: Pessoa) =>
                          `${value?.cnpjCpf} - ${value.nome}`
                        }
                        getOptionValue={(value: Pessoa) => value.id}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormikTextArea
                        name="descricao"
                        data-test-id="descricao-field"
                        label="Descrição"
                        size={12}
                      />
                    </Col>
                  </Row>
                </>
              )}

              <FabSpeedDial icon="ellipsis-v" title="Ações">
                {mensagem && mensagem.situacao === NOVA && (
                  <FAB
                    data-test-id="btn-analise"
                    icon="file-invoice-dollar"
                    onClick={() => onEdit(formProps)}
                    title="Iniciar análise da mensagem"
                  />
                )}
                {formProps.values.situacao !== NOVA && (
                  <FAB
                    data-test-id="btn-concluir"
                    icon="check"
                    onClick={() => onSave(formProps.values)}
                    title="Salvar"
                    disabled={
                      mensagem && formProps.values.situacao === RESPONDIDA
                    }
                  />
                )}
              </FabSpeedDial>
            </>
          )}
        </Formik>
      </Container>
    </>
  );
};

const ConnectedComponent = connect(null, {})(CaixaPostalFormPage);

export { ConnectedComponent as default, CaixaPostalFormPage };
