import {
  Alert,
  Container,
  DisplayDataGrid,
  DisplayDataItem,
  Row,
  SectionTitle,
  formatUtils
} from '@elotech/components';
import { History } from 'history';
import React from 'react';
import { useEffect, useState } from 'react';
import { match, useParams } from 'react-router';

import { VisualizacaoGuiasEventuaisService } from '../../../service';
import { DeclaracaoGuiaAvulso } from '../../../types/DeclaracaoGuiaAvulso';

type Props = {
  match: match<{ id: string }>;
  history: Pick<History, 'replace'>;
};

const VisualizacaoGuiasEventuaisFormPage: React.FC<Props> = ({
  match,
  history
}) => {
  const params = useParams<{ id: string }>();
  const [guia, setParametro] = useState<DeclaracaoGuiaAvulso>();

  useEffect(() => {
    const { id } = params;

    VisualizacaoGuiasEventuaisService.findById(id)
      .then(response => {
        setParametro(response.data);
      })
      .catch(error => {
        Alert.error(
          {
            title: ` Não foi possivel carregar guia com id: ${id}.`
          },
          error
        );
      })
      .finally();
  }, [params]);

  return (
    <Container breadcrumb>
      <SectionTitle>Dados da Guia Eventual</SectionTitle>
      <DisplayDataGrid>
        <Row>
          <DisplayDataItem md={4} title="Código">
            {guia?.id}
          </DisplayDataItem>
          <DisplayDataItem md={4} title="Status">
            {guia?.status === 'F' ? 'Fechada' : 'Cancelada'}
          </DisplayDataItem>
          <DisplayDataItem md={4} title="Emissão">
            {guia?.id}
          </DisplayDataItem>
        </Row>
        <Row>
          <DisplayDataItem md={4} title="Tipo Movimento">
            {guia?.tipoMovimento === 'P' ? 'Prestado' : 'Tomado'}
          </DisplayDataItem>
          <DisplayDataItem md={4} title="Acesso Web">
            {guia?.acessoWeb} - {formatUtils.formatCpfCnpj(guia?.acessoWebCpf)}-{' '}
            {guia?.acessoWebNome}
          </DisplayDataItem>
        </Row>
      </DisplayDataGrid>

      <SectionTitle>Resumo NFS-e</SectionTitle>
      <DisplayDataGrid>
        <Row>
          <DisplayDataItem md={4} title="Número Nota">
            {guia?.numeroDocumento}
          </DisplayDataItem>
          <DisplayDataItem md={4} title="Tipo Documento">
            {guia?.tipoDocumentoNota}
          </DisplayDataItem>
        </Row>
        <Row>
          <DisplayDataItem md={4} title="Prestador">
            {guia?.prestador}
          </DisplayDataItem>
          <DisplayDataItem md={4} title="Tomador">
            {guia?.tomador}
          </DisplayDataItem>
          <DisplayDataItem md={4} title="Nº cadastro onde foi gerado o ISS">
            {guia?.cadastroGeral}
          </DisplayDataItem>
        </Row>
        <Row>
          <DisplayDataItem md={3} title="Base Cálculo">
            {guia?.baseCalculo}
          </DisplayDataItem>
          <DisplayDataItem md={3} title="Valor Dedução">
            {guia?.valorDeducao}
          </DisplayDataItem>
          <DisplayDataItem md={3} title="Alíquota">
            {guia?.aliquota}%
          </DisplayDataItem>
          <DisplayDataItem md={3} title="Valor Total">
            {guia?.valorTotalNota}
          </DisplayDataItem>
          <DisplayDataItem md={3} title="Valor ISS">
            {guia?.valorIss}
          </DisplayDataItem>
        </Row>
      </DisplayDataGrid>
    </Container>
  );
};

export default VisualizacaoGuiasEventuaisFormPage;
