import axios from 'axios';

import { FiltroRelatorioGerencialISSDTO } from '../types';
import { CONTEXT_PATH } from './contextPath';

export const generateReportGerencialISS = (
  filtro: FiltroRelatorioGerencialISSDTO
) =>
  axios.post(`${CONTEXT_PATH}/relatorio-gerencial-iss`, filtro, {
    responseType: 'blob'
  });

export default {
  generateReportGerencialISS
};
