import axios from 'axios';

import { CONTEXT_PATH } from './contextPath';

export const carregarRelatorioDmsxPGDASGeral = (search: any) =>
  axios.post(`${CONTEXT_PATH}/relatorio-pgdas/geral/`, search, {
    responseType: 'blob'
  });

export const carregarRelatorioPGDASRegimeFixo = (search: any) =>
  axios.post(`${CONTEXT_PATH}/relatorio-pgdas/regime-fixo/`, search, {
    responseType: 'blob'
  });

export const carregarRelatorioPGDASServicosAoExterior = (search: any) =>
  axios.post(`${CONTEXT_PATH}/relatorio-pgdas/servicos-ao-exterior/`, search, {
    responseType: 'blob'
  });

export const carregarRelatorioPGDASDeclaracaoBensMoveis = (search: any) =>
  axios.post(
    `${CONTEXT_PATH}/relatorio-pgdas/declaracao-bens-moveis/`,
    search,
    {
      responseType: 'blob'
    }
  );

export const carregarRelatorioPGDASEmpresasNaoEstabelecidas = (search: any) =>
  axios.post(
    `${CONTEXT_PATH}/relatorio-pgdas/empresas-nao-estabelecidas/`,
    search,
    { responseType: 'blob' }
  );

export const carregarRelatorioPGDASDeclaracaoIsentaImune = (search: any) =>
  axios.post(
    `${CONTEXT_PATH}/relatorio-pgdas/print-declaracao-isenta-imune/`,
    search,
    {
      responseType: 'blob'
    }
  );

export const carregarRelatorioPGDASReceitasOutrosMunicipios = (search: any) =>
  axios.post(
    `${CONTEXT_PATH}/relatorio-pgdas/receitas-outros-municipios/`,
    search,
    { responseType: 'blob' }
  );
