import axios, { AxiosPromise } from 'axios';

import { ModeloRelatorio } from '../types/ModeloRelatorio';
import { CONTEXT_PATH } from './contextPath';

const defaultSort = { sort: 'modeloRelatorio' };

const search = (params: any) => (params ? `search=${params}` : '');

export const loadModeloRelatorio = (searchParams: string, page?: any) =>
  axios.get(`${CONTEXT_PATH}/modelo-relatorio?${search(searchParams)}`, {
    params: { ...defaultSort, ...page }
  });

export const deleteModeloRelatorio = (
  id?: number
): AxiosPromise<ModeloRelatorio> =>
  axios.delete(`${CONTEXT_PATH}/modelo-relatorio/remover/${id}`);

export const save = (
  modeloRelatorio: ModeloRelatorio
): AxiosPromise<ModeloRelatorio> =>
  modeloRelatorio.id === undefined
    ? axios.post(`${CONTEXT_PATH}/modelo-relatorio/`, modeloRelatorio)
    : axios.put(
        `${CONTEXT_PATH}/modelo-relatorio/${modeloRelatorio.id}`,
        modeloRelatorio
      );

export const getTipoRelatorio = (searchParams: string) =>
  axios.get(
    `${CONTEXT_PATH}/modelo-relatorio/tipo-relatorio?${search(searchParams)}`
  );

export const downloadArquivo = (id?: number) =>
  axios.get(`${CONTEXT_PATH}/modelo-relatorio/download-arquivo/${id}`, {
    responseType: 'blob'
  });
