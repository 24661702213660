import axios, { AxiosPromise } from 'axios';

import { CONTEXT_PATH } from './contextPath';

export const importarDocDimp = (
  files: any,
  competencia: string
): AxiosPromise<any> =>
  axios.post(
    `${CONTEXT_PATH}/dimp-valores/importar-doc-dimp/${competencia}`,
    files
  );
