import { SectionTitle } from '@elotech/components';
import {
  Alert,
  Container,
  FAB,
  NotificationActions
} from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert2';

import { HomologacaoService, withService } from '../../../service';
import DadosEmpresariais from './step1-dados-gerais/DadosEmpresariais';
import Credenciamento from './step4-credenciamento/Credenciamento';

export class CredenciarFormPage extends Component {
  static propTypes = {
    homologacaoService: PropTypes.object.isRequired
  };

  state = {
    loading: false,
    historicoAgendamento: {
      usuario: {}
    },
    error: {
      observacaoText: false
    }
  };

  componentDidMount() {
    const { id } = this.props.match.params;

    if (id) {
      this.setState({ loading: true });
      this.props.homologacaoService
        .loadHistoricoAgendamento(id)
        .then(this.loadHistoricoAgendamentoSuccess)
        .catch(this.loadHistoricoAgendamentoError);
    }
  }

  loadHistoricoAgendamentoSuccess = response => {
    this.setState({ loading: false });
    this.setState(
      {
        historicoAgendamento: response.data
      },
      () => {
        this.setState(state => {
          const { historicoAgendamento } = state;
          return {
            historicoAgendamento: {
              ...historicoAgendamento,
              usuario: this.props.usuario
            }
          };
        });
      }
    );
  };

  loadHistoricoAgendamentoError = error => {
    this.setState({ loading: false });
    Alert.error({ title: 'Não foi possível carregar o agendamento.' }, error);
  };

  rejeitarOuHomologarCredenciar = (name, homologarOuRejeitar) => {
    this.setState(state => {
      const { historicoAgendamento } = state;
      return {
        historicoAgendamento: {
          ...historicoAgendamento,
          [name]: homologarOuRejeitar
        }
      };
    });
  };

  onChangeInputValue = event => {
    const { name, value } = event.target;

    this.setState(state => {
      const { historicoAgendamento } = state;
      return {
        historicoAgendamento: { ...historicoAgendamento, [name]: value }
      };
    });
  };

  homologarCredenciamento = () => {
    const { historicoAgendamento } = this.state;
    this.setState({ loading: true });
    this.props.homologacaoService
      .credenciar(historicoAgendamento)
      .then(this.onCredenciarHomologacaoSuccess)
      .catch(this.onCredenciarHomologacaoError);
  };

  onCredenciarHomologacaoSuccess = response => {
    this.setState({ loading: false });

    this.props.showNotification({
      level: 'success',
      message: 'Credenciado realizado com sucesso.'
    });

    Alert.question({
      title: 'Deseja enviar email de notificação de credenciamento da empresa?'
    }).then(result => {
      if (result.value) {
        this.setState({ loading: true });
        const { historicoAgendamento } = this.state;
        this.props.homologacaoService
          .enviarEmail(historicoAgendamento)
          .then(this.enviarEmailSuccess)
          .catch(this.enviarEmailError);
      }

      this.props.history.push('/acessos-e-permissoes/credenciamento');
    });
  };

  onCredenciarHomologacaoError = error => {
    this.setState({ loading: false });
    Alert.error(
      {
        title: 'Não foi possível credenciar.'
      },
      error
    );
  };

  rejeitarAgendamento = () => {
    swal
      .queue([
        {
          title: 'Rejeitar homologação',
          type: 'warning',
          text: 'Motivo rejeição',
          input: 'textarea',
          inputPlaceholder: 'Justificativa',
          showCancelButton: true,
          confirmButtonColor: '#0091e6',
          cancelButtonColor: '#999999',
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
        }
      ])
      .then(result => {
        if (result.value) {
          this.setState(
            state => {
              return {
                historicoAgendamento: {
                  ...state.historicoAgendamento,
                  observacaoRejeicao: result.value[0]
                }
              };
            },
            () => {
              const { historicoAgendamento } = this.state;
              this.setState({ loading: true });
              this.props.homologacaoService
                .rejeitarHomologacao(historicoAgendamento)
                .then(this.onRejeitarHomologacaoSuccess)
                .catch(this.onRejeitarHomologacaoError);
            }
          );
        } else if (result.dismiss === swal.DismissReason.cancel) {
          this.props.history.push('/acessos-e-permissoes/credenciamento');
        }
      });
  };

  onRejeitarHomologacaoSuccess = response => {
    this.setState({ loading: false });

    this.props.showNotification({
      level: 'success',
      message: 'Credenciamento rejeitado com sucesso.'
    });

    Alert.question({
      title: 'Deseja enviar email de notificação de credenciamento da empresa?'
    }).then(result => {
      if (result.value) {
        this.setState({ loading: true });
        const { historicoAgendamento } = this.state;
        this.props.homologacaoService
          .enviarEmail(historicoAgendamento)
          .then(this.enviarEmailSuccess)
          .catch(this.enviarEmailError);
      }

      this.props.history.push('/acessos-e-permissoes/credenciamento');
    });
  };

  onRejeitarHomologacaoError = error => {
    this.setState({ loading: false });
    Alert.error(
      {
        title: 'Não foi possível rejeitar o credenciamento.'
      },
      error
    );
  };

  enviarEmailSuccess = response => {
    this.setState({ loading: false });
    this.props.showNotification({
      level: 'success',
      message: 'Email enviado com sucesso.'
    });
  };

  enviarEmailError = error => {
    this.setState({ loading: false });
    Alert.error(
      { title: 'Erro ao enviar o email para o contribuinte.' },
      error
    );
  };

  render() {
    const { historicoAgendamento, error } = this.state;
    return (
      <Container breadcrumb>
        <SectionTitle>Dados Empresariais</SectionTitle>
        <DadosEmpresariais
          disabled={!historicoAgendamento.permiteAprovarOuRejeitar}
          historicoAgendamento={historicoAgendamento}
          error={error}
        />
        <Credenciamento
          disabled={!historicoAgendamento.permiteAprovarOuRejeitar}
          historicoAgendamento={historicoAgendamento}
          rejeitarOuHomologarCredenciar={this.rejeitarOuHomologarCredenciar}
          onChangeObservacoes={this.onChangeInputValue}
        />
        <div className="btn-save">
          <FAB
            data-test-id="button-homologarCredenciamento"
            typeButton="positive"
            title="Homologar"
            onClick={this.homologarCredenciamento}
            disabled={!historicoAgendamento.permiteAprovarOuRejeitar}
          />
          <FAB
            data-test-id="button-rejeitarAgendamento"
            typeButton="negative"
            title="Rejeitar"
            onClick={this.rejeitarAgendamento}
            disabled={!historicoAgendamento.permiteAprovarOuRejeitar}
          />
        </div>
      </Container>
    );
  }
}

const ComponentWithService = withService({
  homologacaoService: HomologacaoService
})(CredenciarFormPage);

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const mapStateToProps = state => ({
  usuario: state.user.profile
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithService);
