import Axios from 'axios';

import { CONTEXT_PATH } from '../common/service/contextPath';
import { CrudService } from '../common/service/CrudService';

const crudService = CrudService('/guiaRecolhimentoReceita', {
  sort: 'id'
});

const buscaGuiaReceitaVo = (sort: string) => {
  return Axios.get(
    `${CONTEXT_PATH}/guiaRecolhimentoReceita/vo/?search=${sort}`
  );
};

export default {
  ...crudService,
  buscaGuiaReceitaVo
};
