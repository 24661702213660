import {
  ActionButton,
  ActionsGroup,
  Alert,
  Container,
  FormattedDate,
  Hint,
  Loading,
  NotificationActions,
  Panel,
  SearchPagination,
  Table,
  formatUtils
} from '@elotech/components';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  CadastroGeralService,
  EditarNotaFiscalService,
  withService
} from '../../../service';
import CancelarNfseFormPage from './CancelarNfseFormPage';
import EditarNfseInputSearch from './EditarNfseInputSearch';

export class EditarNfseListPage extends Component {
  state = {
    loading: false,
    notasFiscais: [],
    pagination: {},
    usuario: {},
    motivo: '',
    expandedValueIndex: undefined,

    editarNotaFiscalInputSearch: {
      contribuinte: '',
      tipoServico: 'P',
      homologacao: 'N',
      dataInicial: '',
      dataFinal: '',
      numDocumento: ''
    },

    errorEditarNotaFiscalInputSearch: {
      contribuinte: false,
      dataInicial: false,
      dataFinal: false
    }
  };

  searchWithPage = page => {
    this.serviceSearch(page);
  };

  serviceSearch = page => {
    if (this.hasErrorsInputSearch()) {
      this.props.showNotification({
        level: 'error',
        message: 'Campos obrigatórios.'
      });
    } else {
      const searchParams = this.montarParametrosFiltroUrl();

      this.setState({
        loading: true,
        searchParams
      });

      this.props.editarNotaFiscalService
        .loadNotaFiscal(searchParams, page)
        .then(this.getLoadNotaFiscalSuccess)
        .catch(this.getLoadNotaFiscalError);
    }
  };

  montarParametrosFiltroUrl = () => {
    const {
      contribuinte,
      tipoServico,
      homologacao,
      dataInicial,
      dataFinal,
      numDocumento
    } = this.state.editarNotaFiscalInputSearch;

    let parametrosPesquisa = `tipoMovimento=='${tipoServico}' and homologacao=='${homologacao}' `;

    if (contribuinte) {
      parametrosPesquisa = parametrosPesquisa.concat(
        `and notaFiscalPrestador.cadastroGeral.id==${contribuinte.id} `
      );
    }

    if (numDocumento) {
      parametrosPesquisa = parametrosPesquisa.concat(`and 
      numeroNotaFiscal==${numDocumento} `);
    }

    if (dataInicial) {
      parametrosPesquisa = parametrosPesquisa.concat(`and 
      dataEmissao=ge='${dataInicial}' `);
    }

    if (dataFinal) {
      parametrosPesquisa = parametrosPesquisa.concat(`and 
      dataEmissao=le='${dataFinal}' `);
    }

    return parametrosPesquisa;
  };

  getLoadNotaFiscalSuccess = response => {
    const {
      content,
      number,
      totalPages,
      first,
      last,
      numberOfElements,
      size
    } = response.data;

    this.setState({
      notasFiscais: content,
      loading: false,
      pagination: {
        number,
        totalPages,
        first,
        last,
        numberOfElements,
        size
      }
    });
  };

  getLoadNotaFiscalError = () => {
    this.setState({ loading: false });
    Alert.error({
      title: 'Ocorreu uma falha ao carregar notas fiscais!'
    });
  };

  validatorsEditarNotaFiscalInputSearch = {
    contribuinte: value => value,
    dataInicial: value => value,
    dataFinal: value => value
  };

  onChangeInputSearch = event => {
    const { name, value } = event.target;

    this.setState(prevState => {
      const {
        editarNotaFiscalInputSearch,
        errorEditarNotaFiscalInputSearch
      } = prevState;

      return {
        editarNotaFiscalInputSearch: {
          ...editarNotaFiscalInputSearch,
          [name]: value
        },
        errorEditarNotaFiscalInputSearch: {
          ...errorEditarNotaFiscalInputSearch,
          [name]:
            this.validatorsEditarNotaFiscalInputSearch[name] &&
            !this.validatorsEditarNotaFiscalInputSearch[name](value)
        }
      };
    });
  };

  onEditTomador = idNotaFiscal => {
    this.props.history.push(`/rotinas/editar-tomador/${idNotaFiscal}`);
  };

  onExcludeXML = idNotaFiscal => {
    Alert.question({
      title: 'Deseja excluir XML?',
      input: 'textarea',
      inputPlaceholder: 'Descreva o motivo da exclusão',
      inputValidator: this.motivoExclusaoXmlValidator,
      text: 'Esta ação não poderá ser revertida!'
    }).then(result => {
      if (result.value) {
        const dadosExclusao = {
          idNotaFiscal: idNotaFiscal,
          motivo: result.value,
          usuario: this.props.usuario
        };
        this.props.editarNotaFiscalService
          .excluirXML(dadosExclusao)
          .then(Alert.success({ title: 'XML excluido com sucesso!' }))
          .catch(error => {
            Alert.error(
              { title: 'Ocorreu uma falha ao tentar excluir XML!' },
              error
            );
          });
      }
    });
  };

  motivoExclusaoXmlValidator = value => {
    return new Promise(resolve => {
      if (value) {
        resolve();
      } else {
        resolve('Escreva o motivo da exclusão!');
      }
    });
  };

  hasErrorsInputSearch = () => {
    const { editarNotaFiscalInputSearch } = this.state;

    const errorsCamposObrigatorios = Object.keys(
      editarNotaFiscalInputSearch
    ).filter(field => {
      return (
        this.validatorsEditarNotaFiscalInputSearch[field] &&
        !this.validatorsEditarNotaFiscalInputSearch[field](
          editarNotaFiscalInputSearch[field]
        )
      );
    });

    const objErro = errorsCamposObrigatorios.reduce((total, current) => {
      total[current] = true;
      return total;
    }, {});

    this.setState({ errorEditarNotaFiscalInputSearch: objErro });
    return errorsCamposObrigatorios.length > 0;
  };

  onSearch = () => {
    if (this.hasErrorsInputSearch()) {
      this.props.showNotification({
        level: 'error',
        message: 'Campos obrigatórios.'
      });
    }
  };

  onSelect = (name, value) => {
    this.setState(state => {
      const { editarNotaFiscalInputSearch } = state;

      return {
        editarNotaFiscalInputSearch: {
          ...editarNotaFiscalInputSearch,
          [name]: value
        }
      };
    });
  };

  optionLabel = option => {
    if (option.pessoa) {
      return (
        formatUtils.formatCpfCnpj(option.pessoa.cnpjCpf) +
        ' - ' +
        option.pessoa.nome
      );
    }
    return '';
  };

  renderFormCancelarNfse = (nota, index) => {
    const { expandedValueIndex } = this.state;

    if (expandedValueIndex !== index) {
      return null;
    }

    return (
      <CancelarNfseFormPage
        idNotaFiscal={nota.id}
        onCloseForms={this.onCloseForms}
        onSearch={this.serviceSearch}
      />
    );
  };

  onCancelNfse = index => {
    const { expandedValueIndex } = this.state;
    if (expandedValueIndex === index) {
      this.setState({
        expandedValueIndex: undefined
      });
    } else {
      this.setState({
        expandedValueIndex: index
      });
    }
  };

  onCloseForms = () => {
    this.setState({
      expandedValueIndex: undefined,
      notasFiscais: []
    });
  };

  habilitaBotao = nota => {
    if (nota.situacao === 1) {
      return true;
    }
    return false;
  };

  render() {
    const {
      editarNotaFiscalInputSearch,
      errorEditarNotaFiscalInputSearch,
      notasFiscais,
      loading,
      pagination
    } = this.state;

    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href="https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-editar-nota-fiscal"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        <EditarNfseInputSearch
          onChangeInputSearch={this.onChangeInputSearch}
          editarNotaFiscalInputSearch={editarNotaFiscalInputSearch}
          errorEditarNotaFiscalInputSearch={errorEditarNotaFiscalInputSearch}
          search={this.serviceSearch}
          loadCadastrosMobiliarios={
            this.props.cadastroGeralService.loadCadastroMobiliarios
          }
          onSelect={this.onSelect}
          optionLabel={this.optionLabel}
          pagination={pagination}
        />
        <Panel isTable className="mt-xs">
          <Table
            values={notasFiscais || []}
            keyExtractor={nota => nota.id}
            renderInnerComponent={this.renderFormCancelarNfse}
          >
            <Table.Column
              header="Nº Documento"
              value={nota => nota.numeroNotaFiscal}
            />
            <Table.Column
              header="Razão Social"
              value={nota => nota.razaoSocialNome}
            />
            <Table.Column
              header="Tipo Documento"
              value={nota => nota.tipoDocumento}
            />
            <Table.Column
              header="Data Emissão"
              value={nota => (
                <FormattedDate value={nota.dataEmissao || ''} timeZone="UTC" />
              )}
            />
            <Table.Column
              header="Situação"
              value={nota => nota.descricaoSituacao}
            />

            <Table.Column
              header=""
              value={(nota, index) => (
                <ActionsGroup>
                  <ActionButton
                    data-test-id={`buttonEdit${nota.id}`}
                    key="edit-button"
                    icon="pencil-alt"
                    label="Editar"
                    onClick={() => this.onEditTomador(nota.id)}
                  />
                  {nota.situacao !== 1 && (
                    <ActionButton
                      data-test-id={`buttonCancel${nota.id}`}
                      key="delete-button"
                      icon="file-excel"
                      label="Cancelar NFSe"
                      disabled={this.habilitaBotao(nota)}
                      onClick={() => this.onCancelNfse(index)}
                    />
                  )}
                  <ActionButton
                    data-test-id={`buttonDelete${nota.id}`}
                    key="delete-button"
                    icon="trash-alt"
                    label="Excluir XML"
                    onClick={() => this.onExcludeXML(nota.id)}
                  />
                </ActionsGroup>
              )}
            />
          </Table>

          {notasFiscais && (
            <SearchPagination
              page={pagination}
              searchWithPage={this.searchWithPage}
            />
          )}
        </Panel>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  showNotification: NotificationActions.showNotification
};

const mapStateToProps = state => ({
  usuario: state.user.profile
});

const ComponentWithService = withService({
  editarNotaFiscalService: EditarNotaFiscalService,
  cadastroGeralService: CadastroGeralService
})(EditarNfseListPage);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentWithService);
