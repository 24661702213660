import { Col, ErrorText, Row } from '@elotech/components';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const errorMessages = {
  codigo: 'Campo obrigatório!',
  art: 'Campo obrigatório!'
};

const ObraNewEditForm = ({
  obra,
  errorObra,
  onChangeObra,
  onSaveObra,
  onCancelObra
}) => {
  return (
    <div className="panel-body">
      <Fragment>
        <Row>
          <Col md={3}>
            <label htmlFor="codigoObra">Código da obra</label>
            <input
              className={errorObra.codigoObra ? 'error' : ''}
              name="codigoObra"
              type="text"
              value={obra.codigoObra}
              onChange={onChangeObra}
            />
            {errorObra.codigoObra && (
              <ErrorText>{errorMessages.codigo}</ErrorText>
            )}
          </Col>
          <Col md={3}>
            <label htmlFor="art">ART</label>
            <input
              className={errorObra.art ? 'error' : ''}
              type="text"
              name="art"
              placeholder=""
              value={obra.art}
              onChange={onChangeObra}
            />
            {errorObra.art && <ErrorText>{errorMessages.art}</ErrorText>}
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <button
              data-test-id="btnSalvarObra"
              className="btn positive inline mt-xs"
              type="button"
              onClick={onSaveObra}
            >
              Salvar
            </button>
            <button
              data-test-id="btnCancelarObra"
              className="btn negative inline mt-xs"
              type="button"
              onClick={onCancelObra}
            >
              Cancelar
            </button>
          </Col>
        </Row>
      </Fragment>
    </div>
  );
};

ObraNewEditForm.propTypes = {
  obra: PropTypes.object.isRequired,
  errorObra: PropTypes.object.isRequired,
  onChangeObra: PropTypes.func.isRequired,
  onSaveObra: PropTypes.func.isRequired,
  onCancelObra: PropTypes.func.isRequired
};

export default ObraNewEditForm;
