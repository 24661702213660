import {
  Alert,
  BasicInput,
  Container,
  FAB,
  FormikAutocomplete,
  Hint,
  Loading,
  NotificationActions,
  Row
} from '@elotech/components';
import { AxiosResponse } from 'axios';
import { Formik, FormikProps } from 'formik';
import { History } from 'history';
import React from 'react';
import { Notification } from 'react-notification-system';
import { connect } from 'react-redux';
import { match } from 'react-router';

import {
  CosifService,
  PlanoOrientadorService,
  ServicoService
} from '../../../service';
import { Cosif } from '../../../types/Cosif';
import { Rubrica } from '../../../types/Rubrica';
import { Servico } from '../../../types/Servico';
import { validationSchema } from './validationSchema';

type Props = {
  match: match<{ id: string }>;
  history: Pick<History, 'replace'>;
  showNotification(notification: Notification): void;
};

type State = {
  rubricaForm: Rubrica;
  loading: boolean;
};

const URL_HELP_CADASTRORUBRICAPLANOORIENTADOR =
  'https://atendimento.elotech.com.br/kb/pt-br/article/null/oxy-issadmin-cadastro-febraban';

class CadastroRubricaPlanoOrientadorFormPage extends React.Component<
  Props,
  State
> {
  state: State = {
    rubricaForm: {
      id: undefined,
      rubrica: '',
      descricao: '',
      servico: undefined,
      cosif: undefined
    },
    loading: false
  };

  componentDidMount() {
    const { match } = this.props;
    if (match.params.id) {
      this.setState({ loading: true });
      PlanoOrientadorService.findById(match.params.id)
        .then(this.loadRubricaByIdSuccess)
        .catch(this.loadRubricaByIdError);
    }
  }

  loadRubricaByIdSuccess = (response: AxiosResponse<Rubrica>) => {
    this.setState({
      rubricaForm: response.data,
      loading: false
    });
  };

  loadRubricaByIdError = () => {
    this.setState({ loading: false });
    Alert.error({
      message: 'Não foi possível obter cadastro FEBRABAN.'
    });
  };

  onSave = (rubrica: Rubrica) => {
    this.setState({ loading: false }, () => {
      PlanoOrientadorService.save(rubrica)
        .then(() => {
          this.props.showNotification({
            level: 'success',
            message: 'Salvo com sucesso.'
          });
          this.props.history.replace('/rotinas/plano-orientador');
        })
        .catch((error: any) => {
          this.setState({ loading: false });
          Alert.error({ title: 'Ocorreu um erro ao salvar ' }, error);
        });
    });
  };

  render() {
    const { loading, rubricaForm } = this.state;
    return (
      <Container
        breadcrumb
        titleRightComponent={
          <a
            href={URL_HELP_CADASTRORUBRICAPLANOORIENTADOR}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hint
              classes={`inline clean module-color center-right fa-question-circle`}
            >
              Ajuda?
            </Hint>
          </a>
        }
      >
        <Loading loading={loading} />
        <Formik
          enableReinitialize
          initialValues={rubricaForm}
          onSubmit={this.onSave}
          validationSchema={() => validationSchema}
          render={(formProps: FormikProps<Rubrica>) => (
            <>
              <Row>
                <BasicInput
                  name="rubrica"
                  type="text"
                  label="Rubrica"
                  size={2}
                />
                <BasicInput
                  name="descricao"
                  type="text"
                  label="Descrição"
                  size={4}
                />
              </Row>
              <Row>
                <FormikAutocomplete<Servico>
                  name="servico"
                  label="Serviço"
                  onSearch={ServicoService.loadServicos}
                  getOptionLabel={(servico: Servico) =>
                    `${servico.codigo} - ${servico.descricao}`
                  }
                  getOptionValue={(servico: Servico) => servico.id}
                  size={6}
                />
              </Row>
              <Row>
                <FormikAutocomplete<Cosif>
                  name="cosif"
                  label="Conta Cosif"
                  onSearch={CosifService.loadCosif}
                  getOptionLabel={(cosif: Cosif) =>
                    `${cosif.contaContabil} - ${cosif.titulo}`
                  }
                  getOptionValue={(cosif: Cosif) => cosif.id}
                  size={6}
                />
              </Row>
              <div className="btn-save">
                <FAB
                  data-test-id="btn-save"
                  icon="check"
                  onClick={formProps.submitForm}
                  title="Salvar"
                />
              </div>
            </>
          )}
        />
      </Container>
    );
  }
}
const ConnectedComponent = connect(null, {
  showNotification: NotificationActions.showNotification
})(CadastroRubricaPlanoOrientadorFormPage);

export {
  ConnectedComponent as default,
  CadastroRubricaPlanoOrientadorFormPage
};
